import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import "../../Styles/Request.css";
import rectangle from "../../Images/ReservationImage1.png";
import ConciergeImage from "../../Images/image 22.png";
import { Link, useParams } from "react-router-dom";
import logo from "../../Images/barbaar logo.png";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Hourglass } from "react-loader-spinner";

const ValentineInvitee = () => {
  const { randomReservationID, type } = useParams();
  const [bookingData, setbookingData] = useState(null);
  useEffect(() => {
    if (randomReservationID) {
      const hostname = window.location.hostname;
      let apiUrl;
      if (hostname.includes("testgrouple.com") || hostname.includes("localhost")) {
        apiUrl =`https://server.testgrouple.com/api/v1/beta/enduser/viewValentinesReservation/${type}/${randomReservationID}`;
      }
      if (hostname.includes("grouple.tech")) {
        apiUrl =
        `https://production.grouple.tech/api/v1/beta/enduser/viewValentinesReservation/${type}/${randomReservationID}`;
      }

      fetch(apiUrl)
        .then((res) => res.json())
        .then((data) => {
          setbookingData(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [randomReservationID]);
  if (!bookingData) {
    return (
      <p
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "80vh",
        }}
      >
        <Hourglass
          visible={true}
          height="100"
          width="100"
          ariaLabel="hourglass-loading"
          wrapperStyle={{}}
          wrapperClass=""
          colors={["#306cce", "#72a1ed"]}
        />
      </p>
    );
  }
  const foodOption = [
    { id: 1, name: "Appetizer" },
    { id: 2, name: "Main Course" },
    { id: 3, name: "Dessert" },
  ];

  const renderFoodItemsThree = (categoryName) => {
    let renderFoodItemArray = [];
    switch (categoryName) {
      case "Appetizer":
        renderFoodItemArray =
          bookingData.data.bookingFoodItemSelected.food.appetizer;
        break;
      case "Main Course":
        renderFoodItemArray =
          bookingData.data.bookingFoodItemSelected.food.main_course;
        break;
      case "Dessert":
        renderFoodItemArray =
          bookingData.data.bookingFoodItemSelected.food.dessert;
        break;
     
      default:
        break;
    }
    return renderFoodItemArray.map((item, index) => (
      <div key={index} className="selected-food3">
        <div className="ItemDetailLogo3">
          {item.item_category === "Vegetarian" ? (
            <div className="veg-div">
              <svg
                width="13"
                height="13"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="15"
                  height="15"
                  rx="1.5"
                  stroke="#009600"
                />
                <circle cx="8" cy="8" r="4" fill="#009600" />
              </svg>
            </div>
          ) : item.item_category === "Non Vegetarian" ? (
            <div className="nonveg-div">
              <svg
                width="13"
                height="13"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="15"
                  height="15"
                  rx="1.5"
                  stroke="#963415"
                />
                <path
                  d="M4.38833 12C4.20992 12 4.09388 11.8122 4.17367 11.6527L7.78534 4.42932C7.87378 4.25243 8.12622 4.25243 8.21466 4.42933L11.8263 11.6527C11.9061 11.8122 11.7901 12 11.6117 12H4.38833Z"
                  fill="#963415"
                />
              </svg>
            </div>
          ) : null}
        </div>
        <div className="ItemDetailName3">{item.item_name}</div>
      </div>
    ));
  };

  let beverages = bookingData.data.bookingFoodItemSelected.beverage;
  const keysArray = Object.keys(beverages);

  const beverageOptions = keysArray.map((key, index) => {
    let modifiedName = key
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    modifiedName =
      modifiedName === "Non Alcoholic" ? "Non-Alcoholic" : modifiedName;

    return {
      id: index + 1,
      name: modifiedName,
    };
  });

  const renderBeverageItemsThree = (categoryName) => {
    let renderBeverageItemsArray = [];
    switch (categoryName) {
     
      case "Wines":
        renderBeverageItemsArray =
          bookingData.data.bookingFoodItemSelected.beverage.wines;
        break;
      default:
        break;
    }
    return renderBeverageItemsArray.map((item, index) => (
      <div key={index} className="selected-food3">
        <div className="ItemDetailLogo3">
          <div className="veg-div">
            <svg
              width="13"
              height="13"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="15"
                height="15"
                rx="1.5"
                stroke="#009600"
              />
              <circle cx="8" cy="8" r="4" fill="#009600" />
            </svg>
          </div>
        </div>
        <div className="ItemDetailName3">{item.item_name}</div>
      </div>
    ));
  };

  return (
    <div>
      <nav className="navbar-div2" style={{ display: "flex" }}>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Link to="/landingPage/barbaar" style={{ textDecoration: "none" }}>
            <img src={logo} alt="" className="barbaar-logo" />
          </Link>
        </div>
      </nav>

      <div className="RequestWrapper">
        {/* LEFT */}
        <div className="RequestWrapperLeft">
          <div className="RequestStatusWrapper">
            <h1 className="RequestStatusHeading">
              You’re Invited by {bookingData.data.bookerFirstName}{" "}
              {bookingData.data.bookerLastName}
            </h1>
            <h1 className="RequestStatusHeading3">
            Reservation ID <span style={{color:"#BE360D"}}>#{bookingData.data.reservationCode}</span>
            </h1>
            <p className="RequestStatusDetails">
              Join us! We look forward to your company, adding joy and fostering
              a spirit of camaraderie. Save the date, and let's create
              unforgettable memories together.
            </p>
            <p className="RequestEmail">
              *In case of no email, Please check spam emails.
            </p>
            <p className="RequestMeanTime">
              Check the details and RSVP to confirm your attendance. Looking
              forward to celebrating with you!
            </p>
          </div>
          <div className="ConciergeWrapper">
            <div className="ConciergeWrapperDetail">
              The personal concierge will reach out to you shortly. You can
              Contact him for any enquiries you have.
            </div>
            <div className="ConciergeWrapperCall">
              <div>
                <img src={ConciergeImage} alt="ConciergeImage" />
              </div>
              <div>
              <div className="ConciergeName">Mr. Harmesh Vaidya</div>
                <button className="ConciergeCallBtn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M20.8472 14.8554L16.4306 12.8764L16.4184 12.8707C16.1892 12.7727 15.939 12.7333 15.6907 12.7562C15.4424 12.7792 15.2037 12.8636 14.9963 13.002C14.9718 13.0181 14.9484 13.0357 14.9259 13.0545L12.6441 14.9998C11.1984 14.2976 9.70595 12.8164 9.00376 11.3895L10.9519 9.07294C10.9706 9.0495 10.9884 9.02606 11.0053 9.00075C11.1407 8.79384 11.2229 8.55667 11.2445 8.31035C11.2661 8.06402 11.2264 7.81618 11.1291 7.58887V7.57762L9.14438 3.15356C9.0157 2.85662 8.79444 2.60926 8.51362 2.44841C8.2328 2.28756 7.9075 2.22184 7.58626 2.26106C6.31592 2.42822 5.14986 3.05209 4.30588 4.01615C3.4619 4.98021 2.99771 6.21852 3.00001 7.49981C3.00001 14.9436 9.05626 20.9998 16.5 20.9998C17.7813 21.0021 19.0196 20.5379 19.9837 19.6939C20.9477 18.85 21.5716 17.6839 21.7388 16.4136C21.7781 16.0924 21.7125 15.7672 21.5518 15.4864C21.3911 15.2056 21.144 14.9843 20.8472 14.8554ZM16.5 19.4998C13.3185 19.4963 10.2682 18.2309 8.01856 15.9813C5.76888 13.7316 4.50348 10.6813 4.50001 7.49981C4.49648 6.58433 4.82631 5.69887 5.42789 5.00879C6.02947 4.3187 6.86167 3.87118 7.76907 3.74981C7.7687 3.75355 7.7687 3.75732 7.76907 3.76106L9.73782 8.16731L7.80001 10.4867C7.78034 10.5093 7.76247 10.5335 7.74657 10.5589C7.60549 10.7754 7.52273 11.0246 7.5063 11.2825C7.48988 11.5404 7.54035 11.7981 7.65282 12.0307C8.5022 13.7679 10.2525 15.5051 12.0084 16.3536C12.2428 16.465 12.502 16.5137 12.7608 16.495C13.0196 16.4762 13.2692 16.3907 13.485 16.2467C13.5091 16.2305 13.5322 16.2129 13.5544 16.1942L15.8334 14.2498L20.2397 16.2232C20.2397 16.2232 20.2472 16.2232 20.25 16.2232C20.1301 17.1319 19.6833 17.9658 18.9931 18.5689C18.3028 19.172 17.4166 19.5029 16.5 19.4998Z"
                      fill="white"
                    />
                  </svg>
                  +971 54 320 2102
                </button>
              </div>
            </div>
          </div>

          <div>
            <div className="CustomerFoodMenuHead">Food menu</div>
            {/* {/ ************* Food menu  ********* /} */}
            <div className="closure-div2">
              {foodOption.map((category, index) => (
                <div key={category.id} className="w-full">
                  <div className="w-full rounded-2xl bg-white pb-2 pt-2">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className="flex w-full justify-between rounded-lg"
                            style={{
                              background: "white",
                              padding: "0.75rem 0.5rem",
                              borderRadius: open
                                ? " 0.5rem 0.5rem 0 0"
                                : " 0.5rem",
                              border: "1px solid #E5E5EA",
                              borderBottom: open ? "none" : "1px solid #E5E5EA",
                              boxShadow:
                                "0px 4px 12px 0px rgba(34, 34, 34, 0.06)",
                            }}
                          >
                            <span
                              style={{
                                fontWeight: "400",
                                paddingLeft: "0.5rem",
                              }}
                            >
                              {category.name}
                            </span>
                            <ChevronDownIcon
                              className={`${
                                open ? "rotate-180 transform" : ""
                              } h-5 w-5 text-black-700`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                            <div className="selected-food-list2">
                              {renderFoodItemsThree(category.name)}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              ))}
            </div>

            {/* {/ ****************Beverage menu ***** /} */}
            <div className="CustomerFoodMenuHead">Beverages menu</div>

            <div className="closure-div2">
              {beverageOptions.map((category, index) => (
                <div key={category.id} className="w-full">
                  <div className="w-full rounded-2xl bg-white pb-2 pt-2">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className="flex w-full justify-between rounded-lg"
                            style={{
                              background: "white",
                              padding: "0.75rem 0.5rem",
                              borderRadius: open
                                ? " 0.5rem 0.5rem 0 0"
                                : " 0.5rem",
                              border: "1px solid #E5E5EA",
                              borderBottom: open ? "none" : "1px solid #E5E5EA",
                              boxShadow:
                                "0px 4px 12px 0px rgba(34, 34, 34, 0.06)",
                            }}
                          >
                            <span
                              style={{
                                fontWeight: "400",
                                paddingLeft: "0.5rem",
                              }}
                            >
                              {category.name}{" "}
                            </span>
                            <ChevronDownIcon
                              className={`${
                                open ? "rotate-180 transform" : ""
                              } h-5 w-5 text-black-700`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                            <div className="selected-food-list2">
                              {renderBeverageItemsThree(category.name)}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div style={{ paddingBottom: "2rem" }}>
            <div className="MapHeading">Where you’ll be</div>
            <iframe
              title="Google Maps"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d18708.585519147462!2d55.311497065028014!3d25.221622679203087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f433d48cf3833%3A0xb2e8ac77b1ee9451!2sBar%20Baar%20Freestyle%20Kitchen%20%26%20Bar!5e0!3m2!1sen!2sin!4v1701754283435!5m2!1sen!2sin"
              width="100%"
              height="450"
              style={{ border: "0px", borderRadius: "8px" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>

        {/* RIGHT */}
        <div className="RequestWrapperRight">
          <div className="RequestWrapperChild">
            <img className="resCardImgOne" src={rectangle} alt="" />

            <div className="RequestDateAndTimeWrapper">
              <div className="RequestDateChild">
                <div>Date of Reservation</div>
                <div>{bookingData.data.bookingDate}</div>
              </div>
              <div className="RequestTimeChild">
                <div>Time of Reservation</div>
                <div>
                  {bookingData.data.bookingSlotStartTime}
                </div>
              </div>
            </div>

            <div className="RequestSummaryWrapper">
              <div className="RequestSummaryChild">
                <div
                  className="reservationSumaryAdress"
                  style={{ alignItems: "flex-start" }}
                >
                  {" "}
                  <div>{bookingData.data.bookingOutlet}</div>
                  <div>{bookingData.data.bookingOutletAddress}</div>
                </div>
              </div>
              <div className="RequestSummaryChild">
                <div>{bookingData.data.bookingTotalGuest} Guests</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ValentineInvitee;
