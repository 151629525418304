import React from "react";
import Footer from "../Components/Footer";
import ReservationIndividual from "../Components/IndividualBooking/ReservationIndividual";
import NavbarThree from "../Components/NavbarThree";

const ReservationPageIndividual = () => {
  return (
    <>
      <NavbarThree />
      <ReservationIndividual />
      <Footer />
    </>
  );
};

export default ReservationPageIndividual;
