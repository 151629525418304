import React, { useState } from "react";
import axios from "axios";
import "../../Styles/Reservation.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CountryFlag from "react-country-flag";
import rectangle from "../../Images/ReservationImage1.png";
import { useLocation } from "react-router-dom";
import { Navbar2 } from "../../Components/NavbarTwo";
import Footer from "../../Components/Footer";

const ValentineReservation = () => {
  const hostname = window.location.hostname;
  let apiUrl;
  if (hostname.includes("testgrouple.com") || hostname.includes("localhost")) {
    apiUrl = "https://server.testgrouple.com/api/v1/beta/enduser/submitDetails";
  }
  if (hostname.includes("grouple.tech")) {
    apiUrl =
      "https://production.grouple.tech/api/v1/beta/enduser/submitDetails";
  }

  const location = useLocation();
  const { valentineReservationData } = location.state || {};
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dialCode: "",
    phoneNumber: "",
    note: "",
    confirmEmail: "",
  });

  const [errorMessage, setErrorMessage] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    confirmEmail: "",
  });
  let displayTime;

  const formatTimeTo12hr = (time) => {
    let [hours, minutes] = time.split(":");
    let period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${period}`;
  };

  displayTime = formatTimeTo12hr(valentineReservationData.timeSlot);

  const [isHappyEatingPopupVisible2, setIsHappyEatingPopupVisible2] =
    useState(false);

  let foodData = valentineReservationData.selectedItems;
  let newUpdatedFoodData = {};
  for (let category in foodData) {
    newUpdatedFoodData[category] = foodData[category].map((item) => ({
      item_code: item.id,
      item_name: item.item,
      item_category: item.category,
    }));
  }

  const isValidEmail = (email) => {
    return (
      /\S+@\S+\.\S+/.test(email) &&
      (email.endsWith("@gmail.com") ||
        email.endsWith("@yahoo.com") ||
        email.endsWith(".in"))
    );
  };

  const validateForm = () => {
    let valid = true;
    const errors = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      confirmEmail: "",
      note: "",
    };
    const nameRegex = /^\S+$/;

    if (
      formData.firstName.trim() === "" ||
      !nameRegex.test(formData.firstName)
    ) {
      valid = false;
      errors.firstName =
        "Please enter a valid first name (one word, no spaces).";
    }
    if (formData.lastName.trim() === "" || !nameRegex.test(formData.lastName)) {
      valid = false;
      errors.lastName = "Please enter a valid last name (one word, no spaces).";
    }
    if (formData.email.trim() === "" || !isValidEmail(formData.email)) {
      valid = false;
      errors.email =
        "Please enter a valid Gmail address (e.g., mailto:example@gmail.com).";
    }
    if (formData.phoneNumber.trim() === "") {
      valid = false;
      errors.phoneNumber = "Please enter your phone number.";
    }
    if (formData.confirmEmail !== formData.email) {
      valid = false;
      errors.confirmEmail = "Emails does not match";
    }
    setErrorMessage(errors);
    return valid;
  };
  const [requestSent, setRequestSent] = useState(false);

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const isFormValid = validateForm();
      if (!isFormValid) {
        return;
      }
      if (!requestSent) {
        setRequestSent(true);
        setTimeout(() => {
          setRequestSent(false);
        }, 8000);
      }

      const d = formData.dialCode.split("+");
      const phoneNumberWithoutDialCode = formData.phoneNumber.replace(d[1], "");
      const apiData = {
        bookerFirstName: formData.firstName,
        bookerLastName: formData.lastName,
        bookerEmail: formData.email,
        bookerInternationalCode: formData.dialCode,
        bookerPhone: phoneNumberWithoutDialCode,
        bookingCompany: "Bar Baar",
        bookingOutlet: "Bar Baar Freestyle kitchen and Bar",
        bookingOutletAddress: "Occidental AI Jaddaf, Dubai",
        bookingOutletCuisine: "International",
        bookingPackageCategory: valentineReservationData.selectedPackage,
        bookingPackage: valentineReservationData.packageName,
        bookingPackageDuration: valentineReservationData.duration,
        bookingDate: valentineReservationData.date,
        bookingSlotStartTime: valentineReservationData.timeSlot,
        bookingSlotEndTime: valentineReservationData.timeSlot,
        bookingTotalGuest: valentineReservationData.selectedPeople,
        bookingCurrency: "AED",
        bookingPerPersonCost: valentineReservationData.price,
        bookingPerPersonCostWithoutDiscount: 249,
        bookingTotalCost: valentineReservationData.totalCost,
        bookingDiscountedAmount: 0,
        bookingNotes: formData.note || null,
        maxCapacity: 180,
        floatingCapacity: 350,
        foodItemSelected: {
          food: {
            appetizer: newUpdatedFoodData.appetizers,
            main_course: newUpdatedFoodData.mainCourses,
            dessert: newUpdatedFoodData.desserts,
          },
          beverage: {
            wines: newUpdatedFoodData.Wines,
          },
        },
      };
      let response = await axios.post(apiUrl, apiData);

      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        note: "",
        confirmEmail: "",
      });

      setIsHappyEatingPopupVisible2(true);

      setTimeout(() => {
        setIsHappyEatingPopupVisible2(false);
        let baseUrl = "/valentineReservation/6J8kN1x5bE";
        baseUrl += `/${response.data.data.random_reservation_code}`;
        window.open(baseUrl, "_blank");
        window.location.href = "/landingPage/barbaar";
      }, 3000);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const [value, setValue] = useState("");

  const getIconAndColor = (category) => {
    switch (category) {
      case "Vegetarian":
        return { icon: "vegetarian", color: "#009600" };
      case "Non Vegetarian":
        return { icon: "nonveg", color: "#963415" };
      default:
        return { icon: "default", color: "#000000" };
    }
  };
  const renderCategoryDiv = (category) => {
    const { icon, color } = getIconAndColor(category);

    return (
      <div className={`${icon}-div`}>
        <svg
          width="13"
          height="13"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            x="0.5"
            y="0.5"
            width="15"
            height="15"
            rx="1.5"
            stroke={color}
          />
          {icon === "vegetarian" && <circle cx="8" cy="8" r="4" fill={color} />}
          {icon === "nonveg" && (
            <path
              d="M4.38833 12C4.20992 12 4.09388 11.8122 4.17367 11.6527L7.78534 4.42932C7.87378 4.25243 8.12622 4.25243 8.21466 4.42933L11.8263 11.6527C11.9061 11.8122 11.7901 12 11.6117 12H4.38833Z"
              fill={color}
            />
          )}
        </svg>
      </div>
    );
  };

  return (
    <>
    
    <Navbar2 />
    <div className="reservationBody">
      <div className="reservationBody-inner1">
        <div>
          <div className="reservationCard">
            <img className="resCardImgOne" src={rectangle} alt="" />

            <div className="resDateAndTimeWrapper">
              <div className="resDateChild">
                <div>Date of Reservation</div>
                <div>{valentineReservationData.displayDate}</div>
              </div>
              <div className="resTimeChild">
                <div>Time of Reservation</div>
                <div>{displayTime}</div>
              </div>
            </div>

            <div className="resSummaryWrapper">
              <div className="resSummaryChild">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <path
                      d="M25 28H18.8175C19.8561 27.0727 20.8355 26.0811 21.75 25.0312C25.1812 21.085 27 16.925 27 13C27 10.0826 25.8411 7.28473 23.7782 5.22183C21.7153 3.15893 18.9174 2 16 2C13.0826 2 10.2847 3.15893 8.22183 5.22183C6.15893 7.28473 5 10.0826 5 13C5 16.925 6.81375 21.085 10.25 25.0312C11.1645 26.0811 12.1439 27.0727 13.1825 28H7C6.73478 28 6.48043 28.1054 6.29289 28.2929C6.10536 28.4804 6 28.7348 6 29C6 29.2652 6.10536 29.5196 6.29289 29.7071C6.48043 29.8946 6.73478 30 7 30H25C25.2652 30 25.5196 29.8946 25.7071 29.7071C25.8946 29.5196 26 29.2652 26 29C26 28.7348 25.8946 28.4804 25.7071 28.2929C25.5196 28.1054 25.2652 28 25 28ZM7 13C7 10.6131 7.94821 8.32387 9.63604 6.63604C11.3239 4.94821 13.6131 4 16 4C18.3869 4 20.6761 4.94821 22.364 6.63604C24.0518 8.32387 25 10.6131 25 13C25 20.1537 18.0662 26.125 16 27.75C13.9338 26.125 7 20.1537 7 13ZM21 13C21 12.0111 20.7068 11.0444 20.1573 10.2221C19.6079 9.3999 18.827 8.75904 17.9134 8.3806C16.9998 8.00216 15.9945 7.90315 15.0245 8.09607C14.0546 8.289 13.1637 8.7652 12.4645 9.46447C11.7652 10.1637 11.289 11.0546 11.0961 12.0245C10.9031 12.9945 11.0022 13.9998 11.3806 14.9134C11.759 15.827 12.3999 16.6079 13.2221 17.1573C14.0444 17.7068 15.0111 18 16 18C17.3261 18 18.5979 17.4732 19.5355 16.5355C20.4732 15.5979 21 14.3261 21 13ZM13 13C13 12.4067 13.1759 11.8266 13.5056 11.3333C13.8352 10.8399 14.3038 10.4554 14.8519 10.2284C15.4001 10.0013 16.0033 9.94189 16.5853 10.0576C17.1672 10.1734 17.7018 10.4591 18.1213 10.8787C18.5409 11.2982 18.8266 11.8328 18.9424 12.4147C19.0581 12.9967 18.9987 13.5999 18.7716 14.1481C18.5446 14.6962 18.1601 15.1648 17.6667 15.4944C17.1734 15.8241 16.5933 16 16 16C15.2044 16 14.4413 15.6839 13.8787 15.1213C13.3161 14.5587 13 13.7956 13 13Z"
                      fill="#4A6AFE"
                    />
                  </svg>
                </div>
                <div
                  className="reservationSumaryAdress"
                  style={{ alignItems: "flex-start" }}
                >
                  {" "}
                  <div>BarBaar</div>
                  <div>Occidental AI Jaddaf, Dubai</div>
                </div>
              </div>
              <div className="resSummaryChild">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    viewBox="0 0 22 22"
                    fill="none"
                  >
                    <path
                      d="M20.625 0H1.375C1.01033 0 0.660591 0.115892 0.402728 0.322183C0.144866 0.528472 0 0.808262 0 1.1V20.9C0 21.1917 0.144866 21.4715 0.402728 21.6778C0.660591 21.8841 1.01033 22 1.375 22H20.625C20.9897 22 21.3394 21.8841 21.5973 21.6778C21.8551 21.4715 22 21.1917 22 20.9V1.1C22 0.808262 21.8551 0.528472 21.5973 0.322183C21.3394 0.115892 20.9897 0 20.625 0Z"
                      fill="#4A6AFE"
                    />
                    <path
                      d="M8.25 0H13.75V11L11 8.25L8.25 11V0Z"
                      fill="#DBE1FF"
                    />
                  </svg>
                </div>
                <div>Valentine's Day Package</div>
              </div>
              <div className="resSummaryChild">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <path
                      d="M12.2134 14.4937C12.0801 14.4803 11.9201 14.4803 11.7734 14.4937C8.60008 14.387 6.08008 11.787 6.08008 8.58699C6.08008 5.32033 8.72008 2.66699 12.0001 2.66699C15.2667 2.66699 17.9201 5.32033 17.9201 8.58699C17.9067 11.787 15.3867 14.387 12.2134 14.4937Z"
                      stroke="#4A6AFE"
                      stroke-width="2.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M21.8799 5.33301C24.4665 5.33301 26.5465 7.42634 26.5465 9.99967C26.5465 12.5197 24.5465 14.573 22.0532 14.6663C21.9465 14.653 21.8265 14.653 21.7065 14.6663"
                      stroke="#4A6AFE"
                      stroke-width="2.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M5.54646 19.413C2.3198 21.573 2.3198 25.093 5.54646 27.2397C9.21313 29.693 15.2265 29.693 18.8931 27.2397C22.1198 25.0797 22.1198 21.5597 18.8931 19.413C15.2398 16.973 9.22646 16.973 5.54646 19.413Z"
                      stroke="#4A6AFE"
                      stroke-width="2.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M24.4531 26.667C25.4131 26.467 26.3198 26.0803 27.0665 25.507C29.1465 23.947 29.1465 21.3737 27.0665 19.8137C26.3331 19.2537 25.4398 18.8803 24.4931 18.667"
                      stroke="#4A6AFE"
                      stroke-width="2.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div>{valentineReservationData.selectedPeople} Guests</div>
              </div>
              <div className="resSummaryChild">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <path
                      d="M9 13C9 12.7348 9.10536 12.4804 9.29289 12.2929C9.48043 12.1054 9.73478 12 10 12H22C22.2652 12 22.5196 12.1054 22.7071 12.2929C22.8946 12.4804 23 12.7348 23 13C23 13.2652 22.8946 13.5196 22.7071 13.7071C22.5196 13.8946 22.2652 14 22 14H10C9.73478 14 9.48043 13.8946 9.29289 13.7071C9.10536 13.5196 9 13.2652 9 13ZM10 18H22C22.2652 18 22.5196 17.8946 22.7071 17.7071C22.8946 17.5196 23 17.2652 23 17C23 16.7348 22.8946 16.4804 22.7071 16.2929C22.5196 16.1054 22.2652 16 22 16H10C9.73478 16 9.48043 16.1054 9.29289 16.2929C9.10536 16.4804 9 16.7348 9 17C9 17.2652 9.10536 17.5196 9.29289 17.7071C9.48043 17.8946 9.73478 18 10 18ZM29 7V26C28.9999 26.1704 28.9563 26.338 28.8732 26.4869C28.7901 26.6357 28.6704 26.7608 28.5254 26.8503C28.3803 26.9399 28.2148 26.9909 28.0446 26.9985C27.8743 27.0061 27.7049 26.97 27.5525 26.8937L24 25.1175L20.4475 26.8937C20.3086 26.9633 20.1554 26.9995 20 26.9995C19.8446 26.9995 19.6914 26.9633 19.5525 26.8937L16 25.1175L12.4475 26.8937C12.3086 26.9633 12.1554 26.9995 12 26.9995C11.8446 26.9995 11.6914 26.9633 11.5525 26.8937L8 25.1175L4.4475 26.8937C4.29508 26.97 4.12569 27.0061 3.95543 26.9985C3.78516 26.9909 3.61965 26.9399 3.47463 26.8503C3.32961 26.7608 3.20988 26.6357 3.12681 26.4869C3.04374 26.338 3.00009 26.1704 3 26V7C3 6.46957 3.21071 5.96086 3.58579 5.58579C3.96086 5.21071 4.46957 5 5 5H27C27.5304 5 28.0391 5.21071 28.4142 5.58579C28.7893 5.96086 29 6.46957 29 7ZM27 7H5V24.3825L7.5525 23.105C7.69143 23.0355 7.84465 22.9993 8 22.9993C8.15535 22.9993 8.30857 23.0355 8.4475 23.105L12 24.8825L15.5525 23.105C15.6914 23.0355 15.8446 22.9993 16 22.9993C16.1554 22.9993 16.3086 23.0355 16.4475 23.105L20 24.8825L23.5525 23.105C23.6914 23.0355 23.8446 22.9993 24 22.9993C24.1554 22.9993 24.3086 23.0355 24.4475 23.105L27 24.3825V7Z"
                      fill="#4A6AFE"
                    />
                  </svg>
                </div>
                <div> AED {valentineReservationData.totalCost} /- </div>
              </div>

              <div className="resSummaryChild">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                  >
                    <path
                      d="M28.707 7.29329L24.707 3.29329C24.6141 3.20031 24.5039 3.12655 24.3825 3.07623C24.2611 3.0259 24.1309 3 23.9995 3C23.8681 3 23.738 3.0259 23.6166 3.07623C23.4952 3.12655 23.3849 3.20031 23.292 3.29329L11.292 15.2933C11.1992 15.3862 11.1256 15.4965 11.0754 15.6179C11.0252 15.7393 10.9994 15.8694 10.9995 16.0008V20.0008C10.9995 20.266 11.1049 20.5204 11.2924 20.7079C11.4799 20.8954 11.7343 21.0008 11.9995 21.0008H15.9995C16.1309 21.0009 16.261 20.9751 16.3824 20.9249C16.5038 20.8747 16.6141 20.8011 16.707 20.7083L28.707 8.70829C28.8 8.61541 28.8737 8.50513 28.9241 8.38373C28.9744 8.26233 29.0003 8.1322 29.0003 8.00079C29.0003 7.86937 28.9744 7.73924 28.9241 7.61785C28.8737 7.49645 28.8 7.38616 28.707 7.29329ZM15.5858 19.0008H12.9995V16.4145L20.9995 8.41454L23.5858 11.0008L15.5858 19.0008ZM24.9995 9.58704L22.4133 7.00079L23.9995 5.41454L26.5858 8.00079L24.9995 9.58704ZM27.9995 15.0008V26.0008C27.9995 26.5312 27.7888 27.0399 27.4137 27.415C27.0387 27.7901 26.5299 28.0008 25.9995 28.0008H5.99951C5.46908 28.0008 4.96037 27.7901 4.5853 27.415C4.21023 27.0399 3.99951 26.5312 3.99951 26.0008V6.00079C3.99951 5.47035 4.21023 4.96165 4.5853 4.58657C4.96037 4.2115 5.46908 4.00079 5.99951 4.00079H16.9995C17.2647 4.00079 17.5191 4.10614 17.7066 4.29368C17.8942 4.48122 17.9995 4.73557 17.9995 5.00079C17.9995 5.266 17.8942 5.52036 17.7066 5.70789C17.5191 5.89543 17.2647 6.00079 16.9995 6.00079H5.99951V26.0008H25.9995V15.0008C25.9995 14.7356 26.1049 14.4812 26.2924 14.2937C26.4799 14.1061 26.7343 14.0008 26.9995 14.0008C27.2647 14.0008 27.5191 14.1061 27.7066 14.2937C27.8942 14.4812 27.9995 14.7356 27.9995 15.0008Z"
                      fill="#4A6AFE"
                    />
                  </svg>
                </div>
                <div style={{ paddingLeft: "0.5rem" }}>
                  {" "}
                  Reservation notes (Optional)
                </div>
              </div>
              <div className="below-summary">
                <textarea
                  onChange={(e) => {
                    setFormData({ ...formData, note: e.target.value });
                  }}
                  value={formData.note}
                  className="reservationInput3"
                  type="text"
                  name="note"
                  id="note"
                ></textarea>
              </div>
            </div>
          </div>
        </div>

        <div>
          <form className="reservationForm">
            <h1>Personal Details</h1>

            <label htmlFor="firstName">First Name</label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={(e) => {
                const capitalizedValue =
                  e.target.value.charAt(0).toUpperCase() +
                  e.target.value.slice(1);

                setErrorMessage({ ...errorMessage, firstName: "" });
                setFormData({ ...formData, firstName: capitalizedValue });
              }}
              required
            />
            {errorMessage.firstName && (
              <p style={{ color: "red" }}>{errorMessage.firstName}</p>
            )}

            <label htmlFor="lastName">Last Name</label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={(e) => {
                const capitalizedValueTwo =
                  e.target.value.charAt(0).toUpperCase() +
                  e.target.value.slice(1);

                setErrorMessage({ ...errorMessage, lastName: "" });
                setFormData({ ...formData, lastName: capitalizedValueTwo });
              }}
              required
            />
            {errorMessage.lastName && (
              <p style={{ color: "red" }}>{errorMessage.lastName}</p>
            )}

            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={(e) => {
                setErrorMessage({ ...errorMessage, email: "" });
                setFormData({ ...formData, email: e.target.value });
              }}
              required
            />
            {errorMessage.email && (
              <p style={{ color: "red" }}>{errorMessage.email}</p>
            )}

            <label htmlFor="email">Confirm Email Address</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.confirmEmail}
              onChange={(e) => {
                setErrorMessage({ ...errorMessage, confirmEmail: "" });
                setFormData({ ...formData, confirmEmail: e.target.value });
              }}
              required
            />
            {errorMessage.confirmEmail && (
              <p style={{ color: "red" }}>{errorMessage.confirmEmail}</p>
            )}

            <label htmlFor="phoneNumber">Phone Number</label>
            <PhoneInput
              country={"ae"}
              inputStyle={{
                width: "100%",
                height: "2.5rem",
                borderRadius: "4px",
                border: "1px solid #a6a6a6",
              }}
              dropdownStyle={{
                padding: "1rem",
                height: "80rem",
                width: "30rem",
              }}
              buttonStyle={{
                marginTop: "0.3rem",
                marginBottom: "0.3rem",
                borderRight: "2px solid #293897",
                background: "#fff",
                borderTop: "none",
                borderBottom: "none",
              }}
              name="phoneNumber"
              value={formData.phoneNumber}
              onChange={(value, country) => {
                setErrorMessage({ ...errorMessage, phoneNumber: "" });
                setFormData({
                  ...formData,
                  dialCode: country ? `+${country.dialCode}` : `+971`,
                  phoneNumber: value || "",
                });
              }}
              maxLength={10}
              buttonComponent={<CountryFlag dialCode={formData.dialCode} svg />}
            />
            {errorMessage.phoneNumber && (
              <p style={{ color: "red" }}>{errorMessage.phoneNumber}</p>
            )}
          </form>
          <div className="ConfirmButtondiv">
            <h4>
              <label
                style={{
                  marginRight: "1rem",
                  position: "relative",
                  display: "inline-block",
                }}
              >
                <input
                  type="checkbox"
                  name="agreement"
                  checked={value}
                  onChange={(e) => setValue(e.target.checked)}
                  style={{ display: "none" }}
                />
                <span
                  style={{
                    display: "inline-block",
                    width: "20px",
                    height: "20px",
                    backgroundColor: value ? "#4A6AFE" : "#e5e5ea",
                    border: `1px solid ${value ? "#4A6AFE" : "#CCCCCC"}`,
                    borderRadius: "3px",
                    cursor: "pointer",
                  }}
                />
                {value && (
                  <span
                    style={{
                      position: "absolute",
                      top: "10%",
                      left: "30%",
                      transform: "rotate(45deg)",
                      width: "8px",
                      height: "12px",
                      borderStyle: "solid",
                      borderWidth: "0 2px 2px 0",
                      borderColor: "#FFFFFF",
                    }}
                  />
                )}
              </label>
              By clicking ”confirm” you agree to{" "}
              <a
                href="https://grouple.in/privacy"
                target="blank"
                style={{ margin: " 0rem 0.3rem" }}
              >
                Terms and Condition
              </a>
            </h4>
            <button
              type="submit"
              onClick={handleFormSubmit}
              disabled={!value || requestSent}
              style={{ cursor: !value ? "not-allowed" : "pointer" }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>

      {isHappyEatingPopupVisible2 && (
        <div className="food-popup-background-custom">
          <div className="food-popup-custom">
            <div className="inner-custom">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="61"
                height="60"
                viewBox="0 0 61 60"
                fill="none"
              >
                <path
                  d="M43.8975 42.0797L29.1246 27.3067L27.4852 29.9971L25.6923 23.8744L19.1314 17.3135L15.4203 26.443L13.3532 25.3064L7.59726 40.6595V40.6607L7.76483 45.3479L5.64855 45.8553V45.8565L1.23669 57.6203C0.973029 58.3234 1.18513 59.0229 1.64096 59.4788C2.09562 59.9346 2.79285 60.1491 3.49945 59.8831L12.8071 56.3934L13.1645 54.215L16.4843 55.0142L23.7061 52.3061V49.6051L27.4852 50.8894L34.4879 48.2634L35.2238 46.1682L38.1381 46.8936H38.1393L43.2718 44.9694C44.4706 44.5195 44.7987 42.9808 43.8975 42.0797Z"
                  fill="#FF7D43"
                />
                <path
                  d="M43.2715 44.9702L38.1389 46.8943C38.1389 46.8943 38.139 46.8943 38.1378 46.8943L35.2235 46.169L34.4876 48.2642L27.4849 50.8902L23.7058 49.6059V52.3069L16.4839 55.015L13.1642 54.2158L12.8068 56.3942L3.49911 59.8838C2.79251 60.1498 2.09529 59.9354 1.64062 59.4796L31.469 29.6523L43.8972 42.0805C44.7983 42.9817 44.4702 44.5204 43.2715 44.9702Z"
                  fill="#E63950"
                />
                <path
                  d="M38.1378 46.894L34.4876 48.2639C30.7613 44.4145 28.0708 39.6429 26.681 34.4389C25.7776 31.0558 25.4237 27.4889 25.692 23.875L29.1243 27.3072C29.1758 28.7298 29.3352 30.1383 29.5988 31.5211C30.7156 37.3836 33.6908 42.7833 38.1378 46.894Z"
                  fill="#F0F7FF"
                />
                <path
                  d="M38.1379 46.8944L34.4877 48.2643C30.7614 44.4149 28.0709 39.6433 26.6812 34.4393L29.599 31.5215C30.7157 37.384 33.6909 42.7837 38.1379 46.8944Z"
                  fill="#DFE7F4"
                />
                <path
                  d="M27.4849 50.8892L23.7058 52.3059C21.0493 49.9271 18.8194 47.1242 17.1038 44.0154C13.9554 38.313 12.6445 31.7382 13.3529 25.3063L16.15 17.8477C16.5988 16.6536 18.135 16.3173 19.0397 17.222L19.1311 17.3134C15.5879 25.306 16.0122 34.1119 19.7053 41.4139C21.5626 45.0911 24.2144 48.3277 27.4849 50.8892Z"
                  fill="#F0F7FF"
                />
                <path
                  d="M27.4851 50.8903L23.706 52.307C21.0495 49.9282 18.8195 47.1253 17.104 44.0165L19.7054 41.415C21.5627 45.0922 24.2145 48.3288 27.4851 50.8903Z"
                  fill="#DFE7F4"
                />
                <path
                  d="M16.4842 55.0156L12.807 56.3948C11.436 55.0648 10.1869 53.6106 9.07598 52.0451C7.81523 50.2924 6.59374 48.1385 5.64844 45.8579V45.8567L7.59716 40.6621C8.39984 43.8705 9.75211 46.8657 11.5778 49.5432C12.9535 51.5633 14.5999 53.4019 16.4842 55.0156Z"
                  fill="#F0F7FF"
                />
                <path
                  d="M16.4843 55.0143L12.8072 56.3936C11.4362 55.0636 10.187 53.6093 9.07617 52.0438L11.578 49.542C12.9537 51.5621 14.6001 53.4006 16.4843 55.0143Z"
                  fill="#DFE7F4"
                />
                <path
                  d="M28.2912 19.1581C27.6048 18.4717 27.6048 17.3588 28.2912 16.6724C34.904 10.0596 32.8595 3.62064 32.8382 3.55631C32.5313 2.63538 33.0289 1.63993 33.9499 1.33292C34.8706 1.02579 35.8662 1.52369 36.1733 2.44461C36.2887 2.79123 38.9091 11.026 30.777 19.158C30.0907 19.8444 28.9776 19.8446 28.2912 19.1581Z"
                  fill="#8CD96B"
                />
                <path
                  d="M41.963 32.8295C42.6495 33.516 43.7623 33.516 44.4488 32.8295C51.0615 26.2168 57.5005 28.2613 57.5648 28.2826C58.4858 28.5895 59.4812 28.0918 59.7882 27.1709C60.0953 26.2502 59.5974 25.2545 58.6765 24.9475C58.3299 24.8321 50.0951 22.2117 41.9631 30.3438C41.2767 31.0301 41.2766 32.1432 41.963 32.8295Z"
                  fill="#5AB267"
                />
                <path
                  d="M59.0523 8.28205C57.5032 9.83118 54.74 8.18596 53.7733 8.59727C53.362 9.56752 55.0084 12.3271 53.4592 13.8751C51.9101 15.4242 49.1482 13.779 48.1802 14.1903C47.7678 15.1606 49.4153 17.919 47.8662 19.4681C46.3171 21.0173 43.5528 19.372 42.5872 19.7834C42.1747 20.7536 43.8223 23.512 42.2732 25.0612C40.6924 26.6419 37.8976 24.9252 36.9801 25.3823C36.5219 25.8334 35.8751 25.9834 35.2927 25.8275C35.0044 25.7514 34.7314 25.599 34.5052 25.3729C33.8186 24.6862 33.8186 23.573 34.5052 22.8863C35.847 21.5446 37.7254 21.7567 39.1116 22.0086C39.1562 22.0168 39.2007 22.025 39.244 22.0332C39.2358 21.9899 39.2276 21.9453 39.2194 21.9008C38.9687 20.5146 38.7554 18.6362 40.0983 17.2933C41.44 15.9515 43.3184 16.1636 44.7047 16.4156C44.7492 16.4238 44.7937 16.432 44.8371 16.4402C44.8289 16.3968 44.8207 16.3523 44.8125 16.3078C44.5617 14.9215 44.3484 13.0431 45.6913 11.7002C47.033 10.3585 48.9115 10.5706 50.2977 10.8225C50.3422 10.8307 50.3868 10.8389 50.4301 10.8471C50.4219 10.8038 50.4137 10.7593 50.4055 10.7147C50.1547 9.32847 49.9415 7.45007 51.2844 6.10717C52.6261 4.76545 54.5045 4.97755 55.8908 5.22949C55.9353 5.23769 55.9798 5.24589 56.0232 5.2541C56.015 5.21074 56.0068 5.16621 55.9986 5.12168C55.7478 3.73543 55.5345 1.85702 56.8774 0.514131C57.5629 -0.171377 58.6761 -0.171377 59.3628 0.514131C59.589 0.74029 59.7413 1.01332 59.8175 1.30276C59.9733 1.88398 59.8257 2.53081 59.3734 2.99016C58.921 3.89937 60.6178 6.7164 59.0523 8.28205Z"
                  fill="#FF7D43"
                />
                <path
                  d="M59.0526 8.28203C57.5034 9.83116 54.7403 8.18594 53.7736 8.59724C53.3623 9.5675 55.0087 12.3271 53.4595 13.8751C51.9104 15.4242 49.1484 13.779 48.1805 14.1903C47.768 15.1605 49.4156 17.919 47.8665 19.4681C46.3173 21.0172 43.553 19.372 42.5875 19.7833C42.175 20.7536 43.8226 23.512 42.2734 25.0612C40.6927 26.6419 37.8979 24.9252 36.9804 25.3822C36.5222 25.8334 35.8754 25.9834 35.293 25.8275L39.1119 22.0086C39.1564 22.0168 39.201 22.025 39.2443 22.0332C39.2361 21.9898 39.2279 21.9453 39.2197 21.9008L44.7049 16.4155C44.7495 16.4238 44.794 16.432 44.8374 16.4402C44.8291 16.3968 44.8209 16.3523 44.8127 16.3077L50.298 10.8225C50.3425 10.8307 50.387 10.8389 50.4304 10.8471C50.4222 10.8038 50.414 10.7592 50.4058 10.7147L55.891 5.22946C55.9356 5.23767 55.9801 5.24587 56.0234 5.25407C56.0152 5.21072 56.007 5.16619 55.9988 5.12166L59.8178 1.30273C59.9736 1.88395 59.826 2.53079 59.3736 2.99014C58.9213 3.89934 60.6181 6.71637 59.0526 8.28203Z"
                  fill="#E63950"
                />
                <path
                  d="M43.206 8.48808C44.1767 8.48808 44.9637 7.70113 44.9637 6.73037C44.9637 5.75961 44.1767 4.97266 43.206 4.97266C42.2352 4.97266 41.4482 5.75961 41.4482 6.73037C41.4482 7.70113 42.2352 8.48808 43.206 8.48808Z"
                  fill="#FFE470"
                />
                <path
                  d="M55.6342 20.9158C56.6049 20.9158 57.3919 20.1289 57.3919 19.1581C57.3919 18.1873 56.6049 17.4004 55.6342 17.4004C54.6634 17.4004 53.8765 18.1873 53.8765 19.1581C53.8765 20.1289 54.6634 20.9158 55.6342 20.9158Z"
                  fill="#FABE2C"
                />
                <path
                  d="M53.1483 38.3152C54.1191 38.3152 54.9061 37.5283 54.9061 36.5575C54.9061 35.5868 54.1191 34.7998 53.1483 34.7998C52.1776 34.7998 51.3906 35.5868 51.3906 36.5575C51.3906 37.5283 52.1776 38.3152 53.1483 38.3152Z"
                  fill="#FABE2C"
                />
                <path
                  d="M25.8051 10.9725C26.7758 10.9725 27.5628 10.1855 27.5628 9.21474C27.5628 8.24399 26.7758 7.45703 25.8051 7.45703C24.8343 7.45703 24.0474 8.24399 24.0474 9.21474C24.0474 10.1855 24.8343 10.9725 25.8051 10.9725Z"
                  fill="#FFE470"
                />
              </svg>

              <h3>Thank You</h3>
              <h1>We appreciate your reservation with us.</h1>
            </div>
          </div>
        </div>
      )}
    </div>
    <Footer />
    </>
  );
};

export default ValentineReservation;
