import { React, useEffect, useState } from "react";
import "../Styles/MobileDate.css";
import { Link, useLocation,useNavigate } from "react-router-dom";
import { Navbar2 } from "./NavbarTwo";
import DateModal from "./DateModal";
import TimeSlotModal from "./TimeSlotModal";
import { setEstateCodeForTheme, useTheme } from "./ColorContext";
const MobileDate = () => {
  const location = useLocation();
  const [selectedPeople, setSelectedPeople] = useState(0);
  const estateCode = localStorage.getItem("estateCode");
    const { themeColor } = useTheme();
    setEstateCodeForTheme(estateCode);
  const [discountedPrice, setDiscountedPrice] = useState();
  const [moneyToBeSaved, setMoneyToBeSaved] = useState();
  const [youSavedFixed, setYouSavedFixed] = useState();
  const [validationErrors, setValidationErrors] = useState({
    date: "",
    timeSlot: "",
    selectedPeople: "",
  });

  let reservationDatafromDateAndTime2 =
    location.state?.reservationDatafromDateAndTime || {};
  const initialPrice = reservationDatafromDateAndTime2.initialPrice;
  const [price, setPrice] = useState(initialPrice);
  const duration = reservationDatafromDateAndTime2.duration;
  const pax = reservationDatafromDateAndTime2.pax;
  const initialDiscountedPrice =
  reservationDatafromDateAndTime2.initialDiscountedPrice;
  const packageName = reservationDatafromDateAndTime2.packageName;
  const choiceA = reservationDatafromDateAndTime2.choiceA;
  const choiceD = reservationDatafromDateAndTime2.choiceD;
  const choiceM = reservationDatafromDateAndTime2.choiceM;
  const bestValue = reservationDatafromDateAndTime2.bestValue;
  const category = reservationDatafromDateAndTime2.category;
  const selectedPackage = reservationDatafromDateAndTime2.selectedPackage;
  const totalCost = price * selectedPeople;
  const totalDiscount = initialPrice * selectedPeople;
  const moneysaved = totalDiscount - totalCost;
  const [isDateOpen, setIsDateOpen] = useState(false);
  const [isTimeOpen, setIsTimeOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [savedDate, setSavedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [displayedTime, setDisplayedTime] = useState(null)
  const [savedTime, setSavedTime] = useState(null);

  useEffect(() => {
    const storedDate = sessionStorage.getItem("selectedDate");
    const storedTime = sessionStorage.getItem("selectedTime")
    const selectedPeople = sessionStorage.getItem("selectedPeople");
    const displayedTime = sessionStorage.getItem("displayedTime");
    if (storedDate) setSavedDate(new Date(storedDate));
    if (storedTime) setSavedTime(storedTime);
    if (selectedPeople) setSelectedPeople(selectedPeople);
    if (displayedTime) setDisplayedTime(displayedTime);
  }, []);

  useEffect(() => {
    if (selectedDate) {
      sessionStorage.setItem("selectedDate", selectedDate.toISOString());
      setSavedDate(selectedDate);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (selectedTime) {
      sessionStorage.setItem("selectedTime", selectedTime);
      setSavedTime(selectedTime);
    }
  }, [selectedTime]);

  useEffect(() => {
    if (displayedTime) {
      sessionStorage.setItem("displayedTime", displayedTime);
      setDisplayedTime(displayedTime);
    }
  }, [selectedTime]);
  useEffect(() => {
    if (selectedPeople) {
      sessionStorage.setItem("selectedPeople", selectedPeople);
      setSelectedPeople(selectedPeople);
    }
  }, [selectedPeople]);

  const handleSaveDate = () => {
    setSavedDate(selectedDate);
    if (selectedDate) {
      sessionStorage.setItem("selectedDate", selectedDate.toISOString());
    }
    setIsDateOpen(false);
  };

  
  const formattedSavedDate = savedDate
    ? savedDate.toLocaleDateString("en-US", { month: "long", day: "numeric" })
    : "Select a date";

  const handleSaveTime = () => {
    setSavedTime(selectedTime);
    if (selectedTime) {
      sessionStorage.setItem("selectedTime", selectedTime);
    }
    setIsTimeOpen(false);
  };

  const updatePrices = (value) => {
    if (category === "Regular") {
      if (value >= 250) {
        setPrice(199);
        setDiscountedPrice(219);
      } else if (value >= 150) {
        setPrice(219);
        setDiscountedPrice(234);
      } else if (value >= 50) {
        setPrice(234);
        setDiscountedPrice(249);
      } else {
        setPrice(initialPrice);
        setDiscountedPrice(initialDiscountedPrice);
      }
    } else if (category === "Premium") {
      if (value >= 250) {
        setPrice(299);
        setDiscountedPrice(319);
      } else if (value >= 150) {
        setPrice(319);
        setDiscountedPrice(334);
      } else if (value >= 50) {
        setPrice(334);
        setDiscountedPrice(349);
      } else {
        setPrice(initialPrice);
        setDiscountedPrice(initialDiscountedPrice);
      }
    } else if (category === "Exclusive") {
      if (value >= 250) {
        setPrice(399);
        setDiscountedPrice(419);
      } else if (value >= 150) {
        setPrice(419);
        setDiscountedPrice(434);
      } else if (value >= 50) {
        setPrice(434);
        setDiscountedPrice(449);
      } else {
        setPrice(initialPrice);
        setDiscountedPrice(initialDiscountedPrice);
      }
    } else if (category === "Ultra Exclusive") {
      if (value >= 250) {
        setPrice(399);
        setDiscountedPrice(419);
      } else if (value >= 150) {
        setPrice(419);
        setDiscountedPrice(434);
      } else if (value >= 50) {
        setPrice(434);
        setDiscountedPrice(449);
      } else {
        setPrice(initialPrice);
        setDiscountedPrice(initialDiscountedPrice);
      }
    }
    if (value >= 20 && value <= 49) {
      setMoneyToBeSaved(750);
    } else if (value >= 51 && value <= 149) {
      setMoneyToBeSaved(4500);
    } else if (value >= 151 && value <= 249) {
      setMoneyToBeSaved(7470);
    } else {
      setMoneyToBeSaved();
    }

    if (value >= 50 && value <= 149) {
      setYouSavedFixed(1000);
    } else if (value >= 150 && value <= 249) {
      setYouSavedFixed(6750);
    } else if (value >= 250 && value <= 350) {
      setYouSavedFixed(19750);
    } else {
      setYouSavedFixed();
    }
  };
  const clearSelectedPeopleError = () => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      selectedPeople: "",
    }));
  };

  const handleInputChange = (value) => {
    if (!isNaN(value) && value >= 1 && value <= 350) {
      setSelectedPeople(value);
      updatePrices(value);
      clearSelectedPeopleError();
    } else {
      const clampedValue = value >= 1 ? Math.min(Math.max(value, 10), 350) : 0;
      setSelectedPeople(clampedValue);
      updatePrices(clampedValue);
      clearSelectedPeopleError();
    }
  };

  const handleInputNumberChange = (event) => {
    const value = parseInt(event.target.value, 10);
    handleInputChange(value);
  };
  const handleSliderChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setSelectedPeople(value);
    updatePrices(value);
    clearSelectedPeopleError();
  };


  const divStyle = {
    backgroundColor: themeColor === '#060807' ? '#E6E6E6' : '#CDE1E7',
    color: themeColor === '#060807' ? '#060807' : '#056A88',
  };
  const renderDynamicNumber = () => {
    if (
      (selectedPeople >= 15 && selectedPeople <= 49) ||
      (selectedPeople >= 51 && selectedPeople <= 149) ||
      (selectedPeople >= 151 && selectedPeople <= 249)
    ) {
      const upperLimit =
        selectedPeople >= 15 && selectedPeople <= 50
          ? 50
          : selectedPeople >= 51 && selectedPeople <= 150
          ? 150
          : selectedPeople >= 151 && selectedPeople <= 250
          ? 250
          : 0;
      return (
        <>
          {moneyToBeSaved !== undefined && (
            <div className="moneySavedDiv2"style={divStyle} >
              Add {upperLimit - selectedPeople} more guests to enjoy AED{" "}
              {moneyToBeSaved.toLocaleString()} OFF
            </div>
          )}
        </>
      );
    } else {
      return null;
    }
  };

  const renderDynamicSaved = () => {
    if (
      (selectedPeople >= 50 && selectedPeople <= 149) ||
      (selectedPeople >= 150 && selectedPeople <= 249) ||
      (selectedPeople >= 100 && selectedPeople <= 110) ||
      selectedPeople >= 350 ||
      selectedPeople > 0
    ) {
      return (
        <>
          {moneysaved !== undefined && (
            <div className="moneySavedDiv">
              🥳 You saved AED {moneysaved.toLocaleString()}
            </div>
          )}
        </>
      );
    } else {
      return null;
    }
  };

  const validateForm = () => {
    let isValid = true;
    const errors = {
      date: "",
      timeSlot: "",
      selectedPeople: "",
    };
    if (!savedDate) {
      errors.date = "Please select a date";
      isValid = false;
    }
    if (!savedTime) {
      errors.timeSlot = "Please select a time slot";
      isValid = false;
    }
    if (selectedPeople < 20 || selectedPeople > 350) {
      errors.selectedPeople = "Please select a valid number of guests";
      isValid = false;
    }
    setValidationErrors(errors);
    return isValid;
  };

  const navigate = useNavigate();

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  
  

  const handleContinueClick = () => {
    if (!validateForm()) {
      return;
    }
  
    const formattedDate = formatDate(savedDate);
  
    const reservationDatafromDateAndTime = {
      date: formattedDate,
      timeSlot: selectedTime,
      displayTime: displayedTime,
      price,
      totalCost,
      category,
      initialPrice,
      initialDiscountedPrice,
      pax,
      selectedPeople,
      packageName,
      duration,
      moneysaved,
      selectedPackage,
      displayDate: formattedSavedDate,
      choiceA,
      choiceD,
      choiceM,
      bestValue,
      totalDiscount,
      youSavedFixed,
    };
  
    setSavedDate(null);
    setSavedTime(null);
    setDisplayedTime(null);
    setSelectedPeople(0);
    setPrice(initialPrice);
    setDiscountedPrice(initialDiscountedPrice);
  
    sessionStorage.setItem(
      "reservationDatafromDateAndTime",
      JSON.stringify(reservationDatafromDateAndTime)
    );
    navigate("/FandB/janjaes", { state: { reservationDatafromDateAndTime } });
  };
  

    let svgPath = "";
    let allCapsCategory = "";
    switch (category) {
      case "Regular":
        svgPath = (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M6.66602 12H25.3327"
              stroke="#4A6AFE"
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M9.00359 13.3005C8.98973 13.1341 9.01612 12.9671 9.08113 12.8099C9.14613 12.6526 9.24835 12.5085 9.3814 12.3865C9.51446 12.2645 9.6755 12.1672 9.8545 12.1007C10.0335 12.0342 10.2266 11.9999 10.4218 12H21.5797C21.7747 12.0001 21.9677 12.0345 22.1465 12.101C22.3253 12.1676 22.4862 12.2649 22.6191 12.3869C22.752 12.5089 22.8541 12.653 22.919 12.8102C22.9839 12.9673 23.0103 13.1342 22.9964 13.3005L21.7786 27.8719C21.7531 28.1782 21.5928 28.4648 21.3299 28.6741C21.067 28.8834 20.7211 28.9999 20.3618 29H11.6382C11.2789 28.9999 10.933 28.8834 10.6701 28.6741C10.4072 28.4648 10.2469 28.1782 10.2214 27.8719L9.00216 13.3005H9.00359Z"
              stroke="#4A6AFE"
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M16 8.00008V4.44453C16 3.97303 16.1686 3.52085 16.4686 3.18745C16.7687 2.85405 17.1757 2.66675 17.6 2.66675H20"
              stroke="#4A6AFE"
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M9.81398 9.11467C9.86582 8.80288 10.0267 8.51965 10.268 8.31548C10.5093 8.11132 10.8153 7.99952 11.1313 8H20.8727C21.1883 8.00015 21.4936 8.11224 21.7343 8.31635C21.975 8.52045 22.1356 8.80333 22.1873 9.11467L22.6673 12H9.33398L9.81398 9.11467Z"
              stroke="#4A6AFE"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>
        );
        allCapsCategory = "REGULAR";
        break;
      case "Premium":
        svgPath = (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M10.6665 28H21.3332"
              stroke="#4A6AFE"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16 20V28"
              stroke="#4A6AFE"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M22.6667 4L24 13.3333C24 17.3493 20.4187 20 16 20C11.5813 20 8 17.3493 8 13.3333L9.33333 4H22.6667Z"
              stroke="#4A6AFE"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 13.3333C9.15397 12.4679 10.5575 12 12 12C13.4425 12 14.846 12.4679 16 13.3333C17.154 14.1988 18.5575 14.6667 20 14.6667C21.4425 14.6667 22.846 14.1988 24 13.3333"
              stroke="#4A6AFE"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        );
        allCapsCategory = "PREMIUM";
        break;
      case "Exclusive":
        svgPath = (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M14.4229 19.3335L5.3335 7.3335H23.5123L14.4229 19.3335Z"
              stroke="#4A6AFE"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.4224 18.6667V29.3334"
              stroke="#4A6AFE"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.9248 29.3335H17.922"
              stroke="#4A6AFE"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.3413 14H18.5047"
              stroke="#4A6AFE"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.3337 10.6667L22.6683 10.6668C23.4879 10.6665 24.2876 10.4145 24.9593 9.94475C25.631 9.47504 26.1422 8.81034 26.4237 8.04059C26.7052 7.27083 26.7434 6.43318 26.5332 5.64097C26.323 4.84876 25.8746 4.14025 25.2485 3.61129C24.6224 3.08233 23.849 2.75846 23.0328 2.68351C22.2166 2.60856 21.3971 2.78614 20.6852 3.19223C19.9732 3.59832 19.4032 4.21331 19.0522 4.95398C18.7013 5.69466 18.5863 6.52527 18.7229 7.33342"
              stroke="#4A6AFE"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.4248 10.0957L12.4022 16.6677"
              stroke="#4A6AFE"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.4199 10.0945L16.2944 16.8611"
              stroke="#4A6AFE"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        );
        allCapsCategory = "EXCLUSIVE";
        break;
      case "Ultra Exclusive":
        svgPath = (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M14.4229 19.3335L5.3335 7.3335H23.5123L14.4229 19.3335Z"
              stroke="#4A6AFE"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M14.4224 18.6667V29.3334"
              stroke="#4A6AFE"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.9248 29.3335H17.922"
              stroke="#4A6AFE"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M10.3413 14H18.5047"
              stroke="#4A6AFE"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.3337 10.6667L22.6683 10.6668C23.4879 10.6665 24.2876 10.4145 24.9593 9.94475C25.631 9.47504 26.1422 8.81034 26.4237 8.04059C26.7052 7.27083 26.7434 6.43318 26.5332 5.64097C26.323 4.84876 25.8746 4.14025 25.2485 3.61129C24.6224 3.08233 23.849 2.75846 23.0328 2.68351C22.2166 2.60856 21.3971 2.78614 20.6852 3.19223C19.9732 3.59832 19.4032 4.21331 19.0522 4.95398C18.7013 5.69466 18.5863 6.52527 18.7229 7.33342"
              stroke="#4A6AFE"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M7.4248 10.0957L12.4022 16.6677"
              stroke="#4A6AFE"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M21.4199 10.0945L16.2944 16.8611"
              stroke="#4A6AFE"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        );
        allCapsCategory = "ULTRA EXCLUSIVE";
        break;
      default:
        svgPath = (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="32"
            viewBox="0 0 32 32"
            fill="none"
          >
            <path
              d="M6.66602 12H25.3327"
              stroke="#4A6AFE"
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M9.00359 13.3005C8.98973 13.1341 9.01612 12.9671 9.08113 12.8099C9.14613 12.6526 9.24835 12.5085 9.3814 12.3865C9.51446 12.2645 9.6755 12.1672 9.8545 12.1007C10.0335 12.0342 10.2266 11.9999 10.4218 12H21.5797C21.7747 12.0001 21.9677 12.0345 22.1465 12.101C22.3253 12.1676 22.4862 12.2649 22.6191 12.3869C22.752 12.5089 22.8541 12.653 22.919 12.8102C22.9839 12.9673 23.0103 13.1342 22.9964 13.3005L21.7786 27.8719C21.7531 28.1782 21.5928 28.4648 21.3299 28.6741C21.067 28.8834 20.7211 28.9999 20.3618 29H11.6382C11.2789 28.9999 10.933 28.8834 10.6701 28.6741C10.4072 28.4648 10.2469 28.1782 10.2214 27.8719L9.00216 13.3005H9.00359Z"
              stroke="#4A6AFE"
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M16 8.00008V4.44453C16 3.97303 16.1686 3.52085 16.4686 3.18745C16.7687 2.85405 17.1757 2.66675 17.6 2.66675H20"
              stroke="#4A6AFE"
              stroke-width="1.5"
              stroke-linecap="round"
            />
            <path
              d="M9.81398 9.11467C9.86582 8.80288 10.0267 8.51965 10.268 8.31548C10.5093 8.11132 10.8153 7.99952 11.1313 8H20.8727C21.1883 8.00015 21.4936 8.11224 21.7343 8.31635C21.975 8.52045 22.1356 8.80333 22.1873 9.11467L22.6673 12H9.33398L9.81398 9.11467Z"
              stroke="#4A6AFE"
              stroke-width="1.5"
              stroke-linecap="round"
            />
          </svg>
        );
        allCapsCategory = category;
    }


    // slider functionality mobile
    const [rangeProgress, setRangeProgress] = useState(((selectedPeople - 20) / (350 - 20)) * 100);

  const handleSliderChangeWithProgress = (event) => {
    const value = event.target.value;
    const min = event.target.min;
    const max = event.target.max;
    const progress = ((value - min) / (max - min)) * 100;
    setRangeProgress(progress);
    handleSliderChange(event);
  };

  useEffect(() => {
    const initialProgress = ((selectedPeople - 20) / (350 - 20)) * 100;
    setRangeProgress(initialProgress);

    const slider = document.querySelector('.slider-input');
    if (slider) {
      slider.style.setProperty('--range-progress', `${initialProgress}%`);
    }
  }, [selectedPeople]);

  useEffect(() => {
    const slider = document.querySelector('.slider-input');
    if (slider) {
      slider.style.setProperty('--range-progress', `${rangeProgress}%`);
    }
  }, [rangeProgress]);

  
  return (
    <>
      <Navbar2 />
      <div className="PackageInfoMain2">
        {/* **** Left Side Content ***** */}
        <div className="PackageInfoInnerLeft2">
          <div className="packageLeftOne2">
            <div className="packageNameDiv2">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M11.082 2.33301H2.91536C2.76065 2.33301 2.61228 2.38217 2.50289 2.46969C2.39349 2.55721 2.33203 2.67591 2.33203 2.79967V11.1997C2.33203 11.3234 2.39349 11.4421 2.50289 11.5297C2.61228 11.6172 2.76065 11.6663 2.91536 11.6663H11.082C11.2367 11.6663 11.3851 11.6172 11.4945 11.5297C11.6039 11.4421 11.6654 11.3234 11.6654 11.1997V2.79967C11.6654 2.67591 11.6039 2.55721 11.4945 2.46969C11.3851 2.38217 11.2367 2.33301 11.082 2.33301Z"
                    fill="#4A6AFE"
                  />
                  <path
                    d="M5.83203 2.33301H8.16536V6.99967L6.9987 5.83301L5.83203 6.99967V2.33301Z"
                    fill="#DBE1FF"
                  />
                </svg>
                {allCapsCategory}
              </div>
              {bestValue && (
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="12"
                    viewBox="0 0 14 12"
                    fill="none"
                  >
                    <path
                      d="M6.99935 0.458496L5.21552 4.09791L1.16602 4.68533L4.10018 7.55299L3.39785 11.5418L6.99935 9.62266L10.6008 11.5418L9.90435 7.55299L12.8327 4.68533L8.80535 4.09791L6.99876 0.458496H6.99935Z"
                      fill="#EE4622"
                      stroke="#EE4622"
                      stroke-width="0.875"
                      stroke-linejoin="round"
                    />
                  </svg>
                  BEST VALUE
                </div>
              )}
            </div>
            <h1>{packageName}</h1>
            <p>International, Indo Global</p>
            <div className="inside-nonAlcoholic">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 15 15"
                  fill="none"
                >
                  <path
                    d="M11 6C11 8.76 8.76 11 6 11C3.24 11 1 8.76 1 6C1 3.24 3.24 1 6 1C8.76 1 11 3.24 11 6Z"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.85494 7.58988L6.30494 6.66488C6.03494 6.50488 5.81494 6.11988 5.81494 5.80488V3.75488"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {duration} hours
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2"
                  height="2"
                  viewBox="0 0 2 2"
                  fill="none"
                >
                  <circle cx="1" cy="1" r="1" fill="#8E8E93" />
                </svg>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 15 15"
                  fill="none"
                >
                  <path
                    d="M4.57979 5.435C4.52979 5.43 4.46979 5.43 4.41479 5.435C3.22479 5.395 2.27979 4.42 2.27979 3.22C2.27979 1.995 3.26979 1 4.49979 1C5.72479 1 6.71978 1.995 6.71978 3.22C6.71478 4.42 5.76979 5.395 4.57979 5.435Z"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.20514 2C9.17514 2 9.95514 2.785 9.95514 3.75C9.95514 4.695 9.20514 5.465 8.27014 5.5C8.23014 5.495 8.18514 5.495 8.14014 5.5"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.07986 7.28C0.869863 8.09 0.869863 9.41 2.07986 10.215C3.45486 11.135 5.70986 11.135 7.08486 10.215C8.29486 9.405 8.29486 8.085 7.08486 7.28C5.71486 6.365 3.45986 6.365 2.07986 7.28Z"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.16992 10C9.52992 9.925 9.86992 9.78 10.1499 9.565C10.9299 8.98 10.9299 8.015 10.1499 7.43C9.87492 7.22 9.53992 7.08 9.18492 7"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {pax} pax min
              </div>
            </div>
          </div>
        </div>

        <div className="curved-line">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="350"
            height="18"
            viewBox="0 0 390 18"
            fill="none"
          >
            <path
              d="M1 1C99.9997 22.3663 289.999 22.3003 389 1"
              stroke="url(#paint0_linear_2112_1851)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2112_1851"
                x1="389"
                y1="9.00026"
                x2="0.999997"
                y2="9.0001"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#B37E7D" stop-opacity="0.1" />
                <stop offset="0.510417" stop-color="#B37E7D" />
                <stop offset="1" stop-color="#B37E7D" stop-opacity="0.1" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        {/* Date component */}

        <div className="mobile-comp">
          <div>
          <div className="inner-mobile-comp">
            <div onClick={() => setIsDateOpen(true)}>
              <div className="text-neutral-800 text-[13px] font-normal font-['Gilroy-Bold'] tracking-tight">
                DATE
              </div>
              <div className="text-neutral-800 text-base font-normal font-['Gilroy-Regular'] tracking-tight">
                {formattedSavedDate}
              </div>
            </div>
            <div onClick={() => setIsTimeOpen(true)}>
              <div className="text-neutral-800 text-[13px] font-normal font-['Gilroy-Bold'] tracking-tight">
                TIME SLOT
              </div>
              <div className="text-neutral-800 text-base font-normal font-['Gilroy-Regular'] tracking-tight">
                {displayedTime || "Select a time slot"}
              </div>
            </div>
            <DateModal
              isOpen={isDateOpen}
              onClose={() => setIsDateOpen(false)}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              handleSaveDate={handleSaveDate}
            />
            <TimeSlotModal
              isOpen={isTimeOpen}
              onClose={() => setIsTimeOpen(false)}
              selectedTime={selectedTime}
              setDisplayedTime={setDisplayedTime}
              setSelectedTime={setSelectedTime}
              handleSaveTime={handleSaveTime}
            />
          </div>

          {/* *** slider div ****  */}
          <div className="guest-div">
            {selectedPeople ? (
              <span className="Selected-place">GROUP SIZE</span>
            ) : (
              ""
            )}
            <input
              placeholder={selectedPeople ? "" : "GROUP SIZE"}
              type="number"
              min="20"
              max="350"
              value={selectedPeople > 0 ? selectedPeople : ""}
              className="selectedPeopleInput"
              onChange={handleInputNumberChange}
              style={{
                cursor: "pointer",
                padding: selectedPeople ? "1.45rem 1rem 0.55rem 1rem" : "1rem",
              }}
            />
            {validationErrors.selectedPeople && (
              <span className="error-message">
                {validationErrors.selectedPeople}
              </span>
            )}
            <div
              className="slider-wrapper"
              style={{ border: "none", padding: "0rem" }}
            >
              <div
                className="slider-markers"
                style={{ border: "none", padding: "0rem" }}
              >
                <div className="slider-marker" style={{ left: "1%" }}>
                  20
                </div>
                <div className="slider-marker" style={{ left: "48.3%" }}>
                  180
                </div>
                <div className="slider-marker" style={{ left: "97%" }}>
                  350
                </div>
              </div>
              <div
                className="slider-markers2"
                style={{ border: "none", padding: "0rem" }}
              >
                <div
                  className="slider-marker2"
                  style={{ left: "10.6%", fontSize: "0.5rem" }}
                ></div>
                <div
                  className="slider-marker2"
                  style={{ left: "39.8%", fontSize: "0.5rem" }}
                ></div>
                <div
                  className="slider-marker2"
                  style={{ left: "69%", fontSize: "0.5rem" }}
                ></div>
              </div>
              <input
                type="range"
                min="20"
                max="350"
                step="1"
                value={selectedPeople}
                // onChange={handleSliderChange}
                onChange={handleSliderChangeWithProgress}
                className="slider-input"
                style={{ cursor: "pointer" }}
              />
            </div>
            {/* <div
              className="priceParentDiv"
              style={{ border: "none", padding: "0rem" }}
            >
              <div>
                {selectedPeople >= 50 && (
                  <p className="DiscountedPrice">AED{initialPrice}</p>
                )}
                <p className="ActualPrice">AED{price}</p>
                <p className="personXpax">
                  /person × <span>{selectedPeople}</span>
                </p>
              </div>
              {totalCost !== undefined && (
                <p className="TotalPrice">AED{totalCost.toLocaleString()}</p>
              )}
            </div> */}
          </div>

          {/* after slecting people section */}

          {selectedPeople > 0 && (
            <>
              {/* no of people */}
              <div
                className="priceParentDiv2"
                style={{ border: "none", padding: "0rem", marginTop: "1rem" }}
              >
                <div>
                  <div className="ActualPrice">AED {initialPrice}</div>
                  <div className="ActualPrice">
                    × <span className="ActualPrice">{selectedPeople}</span>
                  </div>
                </div>

                {totalDiscount !== undefined && (
                  <div className="TotalPrice4">
                    AED {totalDiscount.toLocaleString()}
                  </div>
                )}
              </div>

              {/* discount */}
              <div
                className="total-cost-parent3"
                style={{ border: "none", padding: "0rem" }}
              >
                <div className="total-cost-text3">Discount</div>

                {moneysaved !== undefined && (
                  <div className="total-cost3">
                    {" "}
                    - AED {moneysaved.toLocaleString()}
                  </div>
                )}
              </div>

              {/* total price */}
              <div
                style={{
                  border: "none",
                  padding: "1.12rem 0rem 1rem 0rem",
                  width: "100%",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="380"
                  height="1"
                  viewBox="0 0 380 1"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.58333 1H0V0H1.58333V1ZM7.91667 1H4.75V0H7.91667V1ZM14.25 1H11.0833V0H14.25V1ZM20.5833 1H17.4167V0H20.5833V1ZM26.9167 1H23.75V0H26.9167V1ZM33.25 1H30.0833V0H33.25V1ZM39.5833 1H36.4167V0H39.5833V1ZM45.9167 1H42.75V0H45.9167V1ZM52.25 1H49.0833V0H52.25V1ZM58.5833 1H55.4167V0H58.5833V1ZM64.9167 1H61.75V0H64.9167V1ZM71.25 1H68.0833V0H71.25V1ZM77.5833 1H74.4167V0H77.5833V1ZM83.9167 1H80.75V0H83.9167V1ZM90.25 1H87.0833V0H90.25V1ZM96.5833 1H93.4167V0H96.5833V1ZM102.917 1H99.75V0H102.917V1ZM109.25 1H106.083V0H109.25V1ZM115.583 1H112.417V0H115.583V1ZM121.917 1H118.75V0H121.917V1ZM128.25 1H125.083V0H128.25V1ZM134.583 1H131.417V0H134.583V1ZM140.917 1H137.75V0H140.917V1ZM147.25 1H144.083V0H147.25V1ZM153.583 1H150.417V0H153.583V1ZM159.917 1H156.75V0H159.917V1ZM166.25 1H163.083V0H166.25V1ZM172.583 1H169.417V0H172.583V1ZM178.917 1H175.75V0H178.917V1ZM185.25 1H182.083V0H185.25V1ZM191.583 1H188.417V0H191.583V1ZM197.917 1H194.75V0H197.917V1ZM204.25 1H201.083V0H204.25V1ZM210.583 1H207.417V0H210.583V1ZM216.917 1H213.75V0H216.917V1ZM223.25 1H220.083V0H223.25V1ZM229.583 1H226.417V0H229.583V1ZM235.917 1H232.75V0H235.917V1ZM242.25 1H239.083V0H242.25V1ZM248.583 1H245.417V0H248.583V1ZM254.917 1H251.75V0H254.917V1ZM261.25 1H258.083V0H261.25V1ZM267.583 1H264.417V0H267.583V1ZM273.917 1H270.75V0H273.917V1ZM280.25 1H277.083V0H280.25V1ZM286.583 1H283.417V0H286.583V1ZM292.917 1H289.75V0H292.917V1ZM299.25 1H296.083V0H299.25V1ZM305.583 1H302.417V0H305.583V1ZM311.917 1H308.75V0H311.917V1ZM318.25 1H315.083V0H318.25V1ZM324.583 1H321.417V0H324.583V1ZM330.917 1H327.75V0H330.917V1ZM337.25 1H334.083V0H337.25V1ZM343.583 1H340.417V0H343.583V1ZM349.917 1H346.75V0H349.917V1ZM356.25 1H353.083V0H356.25V1ZM362.583 1H359.417V0H362.583V1ZM368.917 1H365.75V0H368.917V1ZM375.25 1H372.083V0H375.25V1ZM380 1H378.417V0H380V1Z"
                    fill="#E5E5EA"
                  />
                </svg>
              </div>
              <div
                className="total-cost-parent"
                style={{
                  border: "none",
                  padding: "0rem",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <div className="total-cost-text">
                  Total <span>(incl.VAT)</span>
                </div>

                {totalCost !== undefined && (
                  <div className="total-cost">
                    {" "}
                    AED {totalCost.toLocaleString()}{" "}
                  </div>
                )}
              </div>
              {selectedPeople >= 50 && <div>{renderDynamicSaved()}</div>}
            </>
          )}
          </div>
         

          <div>{renderDynamicNumber()}</div>
            <div onClick={handleContinueClick} className="continue-bttn" >
              <button style={{backgroundColor:themeColor }}>Continue</button>
            </div>
        </div>
      </div>
    </>
  );
};

export default MobileDate;
