import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import "../Styles/Request.css";
// import rectangle from "../Images/ReservationImage1.png";
import rectangle from "../Images/mokshabook.png"
import rectangle2 from "../Images/pahadibook.png"
import { Link, useParams } from "react-router-dom";
import logo from "../Images/barbaar logo.png";
import { useLocation } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import LoadingAnimation from "../Images/LOADINGaNIMATION.gif";
import { ChevronRightIcon } from "@heroicons/react/20/solid";
const Merchant = () => {
  const location = useLocation();
  const choiceA = new URLSearchParams(location.search).get("choiceA");
  const choiceM = new URLSearchParams(location.search).get("choiceM");
  const choiceD = new URLSearchParams(location.search).get("choiceD");
  const { randomReservationID, type } = useParams();
  const [bookingData, setbookingData] = useState(null);

  const [isFoodPopupOpen, setIsFoodPopupOpen] = useState(false);
  const [isBevPopupOpen, setIsBevPopupOpen] = useState(false);

  const openPopupFood = () => {
    setIsFoodPopupOpen(true);
  };

  const openPopupBev = () => {
    setIsBevPopupOpen(true);
  };
  const closePopupFood = () => {
    setIsFoodPopupOpen(false);
  };

  const closePopupBev = () => {
    setIsBevPopupOpen(false);
  };

  const foodCategoryOrder = ["appetizer", "main_course", "dessert", "sides"];
  useEffect(() => {
    if (randomReservationID) {
      const hostname = window.location.hostname;
      let apiUrl;
      if (
        hostname.includes("testgrouple.com") ||
        hostname.includes("localhost")
      ) {
        apiUrl = `https://server.testgrouple.com/api/v1/beta/enduser/viewReservation/${type}/${randomReservationID}`;
      }
      if (hostname.includes("grouple.tech")) {
        apiUrl = `https://production.grouple.tech/api/v1/beta/enduser/viewReservation/${type}/${randomReservationID}`;
      }

      fetch(apiUrl)
        .then((res) => res.json())
        .then((data) => {
          setbookingData(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [randomReservationID]);
  if (!bookingData) {
    return (
      <p
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "80vh",
        }}
      >
        <img src={LoadingAnimation} alt="Loading......" />
      </p>
    );
  }


  const renderCategoryItems = (category) => {
    const foodData = bookingData.data.bookingFoodItemSelected.food;

    if (!foodData || !foodData[category]) {
      console.log(`No data for category: ${category}`);
      return null;
    }

    const categoryItems = foodData[category];

    return (
      <div key={category}>
        <div className="menuCategoryHeading">
          {capitalizeAndReplace(category)}
        </div>
        {categoryItems.map((item) => (
          <div key={item.item_code} className="menuItemNameWrapper">
            <div>
              {item.item_category === "Non Vegetarian" ? (
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="15"
                    height="15"
                    rx="1.5"
                    stroke="#963415"
                  />
                  <path
                    d="M4.38833 12C4.20992 12 4.09388 11.8122 4.17367 11.6527L7.78534 4.42932C7.87378 4.25243 8.12622 4.25243 8.21466 4.42933L11.8263 11.6527C11.9061 11.8122 11.7901 12 11.6117 12H4.38833Z"
                    fill="#963415"
                  />
                </svg>
              ) : (
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="15"
                    height="15"
                    rx="1.5"
                    stroke="#009600"
                  />
                  <circle cx="8" cy="8" r="4" fill="#009600" />
                </svg>
              )}
            </div>
            <div>{item.item_name}</div>
          </div>
        ))}
      </div>
    );
  };
  const statusValue = bookingData.data.record_status;
  let beverages = bookingData.data.bookingFoodItemSelected.beverage;
  const keysArray = Object.keys(beverages);

  function capitalizeAndReplace(str) {
    const words = str.split("_");
    const capitalized = words.map(
      (word) => word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalized.join(" ");
  }

  const getCategoriesForPackage = (bookingPackage) => {
    switch (bookingPackage) {
      case "Non-Alcohol Beverage Package":
        return "Non-Alcohol";
      case "Regular Beverage Package":
        return "Bottle Beers, Wines, Spirits, Non-Alcohol";
      case "Premium Beverage Package":
        return "Bottle Beers, Wines, Spirits, Cocktails, Non-Alcohol";
      case "Ultra Premium Beverage Package":
        return "Bottle Beers, Wines, Spirits, Cocktails, Non-Alcohol";
      default:
        return "All Categories";
    }
  };

  // ******** Date ************ //
  const displayDate = bookingData.data.bookingDate;
  const date = new Date(displayDate);
  let showDate;
  if (
    Object.prototype.toString.call(date) === "[object Date]" &&
    !isNaN(date)
  ) {
    const options = { weekday: "short", day: "numeric", month: "short" };
    showDate = date.toLocaleDateString("en-GB", options);
  }

  return (
    <div>
      <nav className="navbar-div2">
        <div
            className="inner-navdiv2">
          <Link to="/landingPage/janjaes" style={{ textDecoration: "none" }}>
            <img src={bookingData.data.logo} alt="" className="barbaar-logo" />
          </Link>
        </div>
      </nav>

      <div className="RequestWrapper">
        {/* LEFT */}
        <div className="RequestWrapperLeft">
          <div className="RequestStatusWrapper">
            <div className="RequestSuccess">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="40"
                  height="40"
                  viewBox="0 0 40 40"
                  fill="none"
                >
                  <g clip-path="url(#clip0_1622_3036)">
                    <path
                      d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
                      fill="#C9E7CB"
                    />
                    <path
                      d="M20 35C28.2843 35 35 28.2843 35 20C35 11.7157 28.2843 5 20 5C11.7157 5 5 11.7157 5 20C5 28.2843 11.7157 35 20 35Z"
                      fill="#4CAF50"
                    />
                    <path
                      d="M26 15.5L17.75 23.75L14 20"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1622_3036">
                      <rect width="40" height="40" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </div>
              <div>You got a new Reservation</div>
            </div>

            <p className="RequestStatusDetails">
              We are pleased to inform you that you got a new reservation.
            </p>
            <p className="RequestStatusDetails">Happy Servicing !</p>
          </div>

          <div className="svgLine">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="1"
              viewBox="0 0 610 1"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M610 1H0V0H610V1Z"
                fill="#E5E5EA"
              />
            </svg>
          </div>

          {/* personal details  */}
          <div className="CustomerForMerchantWrapper">
            <div className="CustomerFoodMenuHead">Personal Details</div>
            <div className="CustomerForMerchantDeatil">
              <div>Name -</div>
              <div className="RequestSummaryChild2">
                {bookingData.data.bookerFirstName}{" "}
                {bookingData.data.bookerLastName}
              </div>
            </div>
            <div className="CustomerForMerchantDeatil">
              <div>Phone number -</div>
              <div className="RequestSummaryChild2">
                {bookingData.data.bookerInternationalCode}{" "}
                {bookingData.data.bookerPhone}
              </div>
            </div>
            <div className="CustomerForMerchantDeatil">
              <div>Email -</div>
              <div className="RequestSummaryChild2">{bookingData.data.bookerEmail}</div>
            </div>
            <div className="CustomerForMerchantDeatil">
              <div>
                Reservation notes <span>(Optional)</span>{" "}
              </div>
              <div className="RequestSummaryChild2">{bookingData.data.bookingNotes}</div>
            </div>
          </div>

          <div className="svgLine">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="1"
              viewBox="0 0 610 1"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M610 1H0V0H610V1Z"
                fill="#E5E5EA"
              />
            </svg>
          </div>

          {statusValue === 0 ? (
            <>
              <div className="svgLine">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="1"
                  viewBox="0 0 610 1"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M610 1H0V0H610V1Z"
                    fill="#E5E5EA"
                  />
                </svg>
              </div>
              <div className="desktopMenuParent">
                <div className="CustomerFoodMenuHead">
                  Selected Food & Beverages menu
                </div>

                {/* Food menu */}
                <div className="food-menu-div">
                  <h4>Food Menu</h4>
                </div>
                <div className="w-full">
                  <div className="w-full rounded-2xl bg-white">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={`foodMenuDropdownButton flex w-full justify-between ${
                              open ? "border-2 border-black" : ""
                            }`}
                          >
                            <span>
                              {choiceA} Appetisers, {choiceM} Main Course,{" "}
                              {choiceD} Desserts, Sides
                            </span>
                            <ChevronDownIcon
                              className={`${
                                open ? "rotate-180 transform" : ""
                              } h-5 w-5 text-black-500`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="dropdownMenuWrapper">
                            {foodCategoryOrder.map((category) => (
                              <div key={category}>
                                {renderCategoryItems(category)}
                              </div>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>

                {/* Beverage menu */}
                <div className="food-menu-div">
                  <h4>Beverage Menu</h4>
                </div>
                <div className="w-full">
                  <div className="w-full rounded-2xl bg-white ">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={`foodMenuDropdownButton flex w-full justify-between ${
                              open ? "border-2 border-black" : ""
                            }`}
                          >
                            <span>
                              {getCategoriesForPackage(
                                bookingData.data.bookingPackage
                              )}
                            </span>
                            <ChevronDownIcon
                              className={`${
                                open ? "rotate-180 transform" : ""
                              } h-5 w-5 text-black-500`}
                            />
                          </Disclosure.Button>

                          <Disclosure.Panel className="dropdownMenuWrapper">
                            {bookingData.data.bookingFoodItemSelected
                              .beverage &&
                              Object.keys(
                                bookingData.data.bookingFoodItemSelected
                                  .beverage
                              ).map((category) => (
                                <div key={category}>
                                  <div className="menuCategoryHeading">
                                    {capitalizeAndReplace(category)}
                                  </div>
                                  {bookingData.data.bookingFoodItemSelected.beverage[
                                    category
                                  ].map((item) => (
                                    <div
                                      key={item.item_code}
                                      className="menuItemNameWrapper"
                                    >
                                      <div>
                                        <svg
                                          width="13"
                                          height="13"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <rect
                                            x="0.5"
                                            y="0.5"
                                            width="15"
                                            height="15"
                                            rx="1.5"
                                            stroke="#009600"
                                          />
                                          <circle
                                            cx="8"
                                            cy="8"
                                            r="4"
                                            fill="#009600"
                                          />
                                        </svg>
                                      </div>
                                      <div>{item.item_name}</div>
                                    </div>
                                  ))}
                                </div>
                              ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>

              <div className="fandbmenuMobile2 pb-8 border-b border-gray-200">
                <div className="pt-4 pb-5">
                  <div className="flex justify-between">
                    <h4 className="pb-1 foodmenuHeadertwo">Food Menu</h4>
                  </div>
                  <div
                    onClick={openPopupFood}
                    className="px-3 py-3 bg-white rounded-lg border border-zinc-400 flex justify-between items-center"
                  >
                    <div className="text-neutral-800 text-base font-normal font-['Gilroy-Medium'] leading-normal tracking-tight">
                      {choiceA} Appetisers, {choiceM} Main Course, {choiceD}{" "}
                      desserts, Sides
                    </div>
                    <div>
                      <ChevronRightIcon className="h-6 w-6 text-black-500" />
                    </div>
                  </div>
                  {isFoodPopupOpen && (
                    <div className="menu-popup-background-custom">
                      <div className="menu-popup-custom">
                        <div className="flex justify-between">
                          <h2>Food Menu</h2>
                          <button
                            className="MenuPopupCloseBtn"
                            onClick={closePopupFood}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M7 7L17 17"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M7 17L17 7"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>

                        <div className="menu-content">
                          {bookingData &&
                            bookingData.data.bookingFoodItemSelected &&
                            foodCategoryOrder.map((category) => (
                              <div key={category}>
                                <div className="w-full h-[42px] pl-5 pr-5 py-3 border-b border-gray-200 flex items-center gap-2.5">
                                  <div className="w-full text-neutral-800 text-base font-normal font-['Gilroy-SemiBold'] tracking-tight whitespace-nowrap">
                                    {capitalizeAndReplace(category)}
                                  </div>
                                </div>

                                {bookingData.data.bookingFoodItemSelected.food[
                                  category
                                ].map((item) => (
                                  <div
                                    key={item.item_code}
                                    className="pl-5 py-2.5 flex items-center gap-2.5"
                                  >
                                    <div>
                                      {item.item_category ===
                                      "Non Vegetarian" ? (
                                        <svg
                                          width="13"
                                          height="13"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <rect
                                            x="0.5"
                                            y="0.5"
                                            width="15"
                                            height="15"
                                            rx="1.5"
                                            stroke="#963415"
                                          />
                                          <path
                                            d="M4.38833 12C4.20992 12 4.09388 11.8122 4.17367 11.6527L7.78534 4.42932C7.87378 4.25243 8.12622 4.25243 8.21466 4.42933L11.8263 11.6527C11.9061 11.8122 11.7901 12 11.6117 12H4.38833Z"
                                            fill="#963415"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          width="13"
                                          height="13"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <rect
                                            x="0.5"
                                            y="0.5"
                                            width="15"
                                            height="15"
                                            rx="1.5"
                                            stroke="#009600"
                                          />
                                          <circle
                                            cx="8"
                                            cy="8"
                                            r="4"
                                            fill="#009600"
                                          />
                                        </svg>
                                      )}
                                    </div>
                                    <div className="text-neutral-700 text-base font-normal">
                                      {item.item_name}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div>
                  {/* Beverage dropdown */}
                  <h4 className="pb-1 foodmenuHeadertwo">Beverage menu</h4>
                  <div
                    onClick={openPopupBev}
                    className="px-3 py-3 bg-white rounded-lg border border-zinc-400 flex justify-between items-center"
                  >
                    <div className="text-neutral-800 text-base font-normal font-['Gilroy-Medium'] leading-normal tracking-tight">
                      Bottle Beers, Wines, Spirits, Cocktai...
                    </div>
                    <div>
                      <ChevronRightIcon className="h-6 w-6 text-black-500" />
                    </div>
                  </div>
                  {isBevPopupOpen && (
                    <div className="menu-popup-background-custom">
                      <div className="menu-popup-custom">
                        <div className="flex justify-between">
                          <h2>Beverage menu</h2>
                          <button
                            className="MenuPopupCloseBtn"
                            onClick={closePopupBev}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M7 7L17 17"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M7 17L17 7"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>

                        <div className="menu-content">
                          {bookingData &&
                            bookingData.data.bookingFoodItemSelected.beverage &&
                            Object.keys(
                              bookingData.data.bookingFoodItemSelected.beverage
                            ).map((category) => (
                              <div key={category}>
                               
                                <div className="w-full h-[42px] pl-5 pr-5 py-3 border-b border-gray-200 flex items-center gap-2.5">
                                  <div className="w-full text-neutral-800 text-base font-normal font-['Gilroy-SemiBold'] tracking-tight whitespace-nowrap">
                                    {capitalizeAndReplace(category)}
                                  </div>
                                </div>

                                {bookingData.data.bookingFoodItemSelected.beverage[
                                  category
                                ].map((item) => (
                                  <div
                                    key={item.item_code}
                                    className="pl-5 py-2.5 flex items-center gap-2.5"
                                  >
                                    <svg
                                      width="13"
                                      height="13"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        x="0.5"
                                        y="0.5"
                                        width="15"
                                        height="15"
                                        rx="1.5"
                                        stroke="#009600"
                                      />
                                      <circle
                                        cx="8"
                                        cy="8"
                                        r="4"
                                        fill="#009600"
                                      />
                                    </svg>
                                    <div>{item.item_name}</div>
                                  </div>
                                ))}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : statusValue === 1 ? (
            <>
              <div className="svgLine">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100%"
                  height="1"
                  viewBox="0 0 610 1"
                  fill="none"
                >
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M610 1H0V0H610V1Z"
                    fill="#E5E5EA"
                  />
                </svg>
              </div>
              <div className="desktopMenuParent">
                <div className="CustomerFoodMenuHead">
                  Selected Food & Beverages menu
                </div>

                {/* Food menu */}
                <div className="food-menu-div">
                  <h4>Food Menu</h4>
                </div>
                <div className="w-full">
                  <div className="w-full rounded-2xl bg-white">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={`foodMenuDropdownButton flex w-full justify-between ${
                              open ? "border-2 border-black" : ""
                            }`}
                          >
                            <span>
                              {choiceA} Appetisers, {choiceM} Main Course,{" "}
                              {choiceD} Desserts, Sides
                            </span>
                            <ChevronDownIcon
                              className={`${
                                open ? "rotate-180 transform" : ""
                              } h-5 w-5 text-black-500`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="dropdownMenuWrapper">
                            {foodCategoryOrder.map((category) => (
                              <div key={category}>
                                {renderCategoryItems(category)}
                              </div>
                            ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>

                {/* Beverage menu */}
                <div className="food-menu-div">
                  <h4>Beverage Menu</h4>
                </div>
                <div className="w-full">
                  <div className="w-full rounded-2xl bg-white ">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className={`foodMenuDropdownButton flex w-full justify-between ${
                              open ? "border-2 border-black" : ""
                            }`}
                          >
                            <span>
                              {getCategoriesForPackage(
                                bookingData.data.bookingPackage
                              )}
                            </span>
                            <ChevronDownIcon
                              className={`${
                                open ? "rotate-180 transform" : ""
                              } h-5 w-5 text-black-500`}
                            />
                          </Disclosure.Button>

                          <Disclosure.Panel className="dropdownMenuWrapper">
                            {bookingData.data.bookingFoodItemSelected
                              .beverage &&
                              Object.keys(
                                bookingData.data.bookingFoodItemSelected
                                  .beverage
                              ).map((category) => (
                                <div key={category}>
                                  <div className="menuCategoryHeading">
                                    {capitalizeAndReplace(category)}
                                  </div>
                                  {bookingData.data.bookingFoodItemSelected.beverage[
                                    category
                                  ].map((item) => (
                                    <div
                                      key={item.item_code}
                                      className="menuItemNameWrapper"
                                    >
                                      <div>
                                        <svg
                                          width="13"
                                          height="13"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <rect
                                            x="0.5"
                                            y="0.5"
                                            width="15"
                                            height="15"
                                            rx="1.5"
                                            stroke="#009600"
                                          />
                                          <circle
                                            cx="8"
                                            cy="8"
                                            r="4"
                                            fill="#009600"
                                          />
                                        </svg>
                                      </div>
                                      <div>{item.item_name}</div>
                                    </div>
                                  ))}
                                </div>
                              ))}
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>

              <div className="fandbmenuMobile2 pb-8 border-b border-gray-200">
                <div className="pt-4 pb-5">
                  <div className="flex justify-between">
                    <h4 className="pb-1 foodmenuHeadertwo">Food Menu</h4>
                  </div>
                  <div
                    onClick={openPopupFood}
                    className="px-3 py-3 bg-white rounded-lg border border-zinc-400 flex justify-between items-center"
                  >
                    <div className="text-neutral-800 text-base font-normal font-['Gilroy-Medium'] leading-normal tracking-tight">
                      {choiceA} Appetisers, {choiceM} Main Course, {choiceD}{" "}
                      desserts, Sides
                    </div>
                    <div>
                      <ChevronRightIcon className="h-6 w-6 text-black-500" />
                    </div>
                  </div>
                  {isFoodPopupOpen && (
                    <div className="menu-popup-background-custom">
                      <div className="menu-popup-custom">
                        <div className="flex justify-between">
                          <h2>Food Menu</h2>
                          <button
                            className="MenuPopupCloseBtn"
                            onClick={closePopupFood}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M7 7L17 17"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M7 17L17 7"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>

                        <div className="menu-content">
                          {bookingData &&
                            bookingData.data.bookingFoodItemSelected &&
                            foodCategoryOrder.map((category) => (
                              <div key={category}>
                                <div className="w-full h-[42px] pl-5 pr-5 py-3 border-b border-gray-200 flex items-center gap-2.5">
                                  <div className="w-full text-neutral-800 text-base font-normal font-['Gilroy-SemiBold'] tracking-tight whitespace-nowrap">
                                    {capitalizeAndReplace(category)}
                                  </div>
                                </div>

                                {bookingData.data.bookingFoodItemSelected.food[
                                  category
                                ].map((item) => (
                                  <div
                                    key={item.item_code}
                                    className="pl-5 py-2.5 flex items-center gap-2.5"
                                  >
                                    <div>
                                      {item.item_category ===
                                      "Non Vegetarian" ? (
                                        <svg
                                          width="13"
                                          height="13"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <rect
                                            x="0.5"
                                            y="0.5"
                                            width="15"
                                            height="15"
                                            rx="1.5"
                                            stroke="#963415"
                                          />
                                          <path
                                            d="M4.38833 12C4.20992 12 4.09388 11.8122 4.17367 11.6527L7.78534 4.42932C7.87378 4.25243 8.12622 4.25243 8.21466 4.42933L11.8263 11.6527C11.9061 11.8122 11.7901 12 11.6117 12H4.38833Z"
                                            fill="#963415"
                                          />
                                        </svg>
                                      ) : (
                                        <svg
                                          width="13"
                                          height="13"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <rect
                                            x="0.5"
                                            y="0.5"
                                            width="15"
                                            height="15"
                                            rx="1.5"
                                            stroke="#009600"
                                          />
                                          <circle
                                            cx="8"
                                            cy="8"
                                            r="4"
                                            fill="#009600"
                                          />
                                        </svg>
                                      )}
                                    </div>
                                    <div className="text-neutral-700 text-base font-normal">
                                      {item.item_name}
                                    </div>
                                  </div>
                                ))}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div>
                  {/* Beverage dropdown */}
                  <h4 className="pb-1 foodmenuHeadertwo">Beverage menu</h4>
                  <div
                    onClick={openPopupBev}
                    className="px-3 py-3 bg-white rounded-lg border border-zinc-400 flex justify-between items-center"
                  >
                    <div className="text-neutral-800 text-base font-normal font-['Gilroy-Medium'] leading-normal tracking-tight">
                      Bottle Beers, Wines, Spirits, Cocktai...
                    </div>
                    <div>
                      <ChevronRightIcon className="h-6 w-6 text-black-500" />
                    </div>
                  </div>
                  {isBevPopupOpen && (
                    <div className="menu-popup-background-custom">
                      <div className="menu-popup-custom">
                        <div className="flex justify-between">
                          <h2>Beverage menu</h2>
                          <button
                            className="MenuPopupCloseBtn"
                            onClick={closePopupBev}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M7 7L17 17"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                              <path
                                d="M7 17L17 7"
                                stroke="black"
                                strokeWidth="1.5"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </button>
                        </div>

                        <div className="menu-content">
                          {bookingData &&
                            bookingData.data.bookingFoodItemSelected.beverage &&
                            Object.keys(
                              bookingData.data.bookingFoodItemSelected.beverage
                            ).map((category) => (
                              <div key={category}>
                               
                                <div className="w-full h-[42px] pl-5 pr-5 py-3 border-b border-gray-200 flex items-center gap-2.5">
                                  <div className="w-full text-neutral-800 text-base font-normal font-['Gilroy-SemiBold'] tracking-tight whitespace-nowrap">
                                    {capitalizeAndReplace(category)}
                                  </div>
                                </div>

                                {bookingData.data.bookingFoodItemSelected.beverage[
                                  category
                                ].map((item) => (
                                  <div
                                    key={item.item_code}
                                    className="pl-5 py-2.5 flex items-center gap-2.5"
                                  >
                                    <svg
                                      width="13"
                                      height="13"
                                      viewBox="0 0 16 16"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <rect
                                        x="0.5"
                                        y="0.5"
                                        width="15"
                                        height="15"
                                        rx="1.5"
                                        stroke="#009600"
                                      />
                                      <circle
                                        cx="8"
                                        cy="8"
                                        r="4"
                                        fill="#009600"
                                      />
                                    </svg>
                                    <div>{item.item_name}</div>
                                  </div>
                                ))}
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          ) : statusValue === 2 ? (
            <></>
          ) : (
            <>
            <div className="svgLine">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="1"
                viewBox="0 0 610 1"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M610 1H0V0H610V1Z"
                  fill="#E5E5EA"
                />
              </svg>
            </div>
            <div className="desktopMenuParent">
              <div className="CustomerFoodMenuHead">
                Selected Food & Beverages menu
              </div>

              {/* Food menu */}
              <div className="food-menu-div">
                <h4>Food Menu</h4>
              </div>
              <div className="w-full">
                <div className="w-full rounded-2xl bg-white">
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className={`foodMenuDropdownButton flex w-full justify-between ${
                            open ? "border-2 border-black" : ""
                          }`}
                        >
                          <span>
                            {choiceA} Appetisers, {choiceM} Main Course,{" "}
                            {choiceD} Desserts, Sides
                          </span>
                          <ChevronDownIcon
                            className={`${
                              open ? "rotate-180 transform" : ""
                            } h-5 w-5 text-black-500`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="dropdownMenuWrapper">
                          {foodCategoryOrder.map((category) => (
                            <div key={category}>
                              {renderCategoryItems(category)}
                            </div>
                          ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
              </div>

              {/* Beverage menu */}
              <div className="food-menu-div">
                <h4>Beverage Menu</h4>
              </div>
              <div className="w-full">
                <div className="w-full rounded-2xl bg-white ">
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className={`foodMenuDropdownButton flex w-full justify-between ${
                            open ? "border-2 border-black" : ""
                          }`}
                        >
                          <span>
                            {getCategoriesForPackage(
                              bookingData.data.bookingPackage
                            )}
                          </span>
                          <ChevronDownIcon
                            className={`${
                              open ? "rotate-180 transform" : ""
                            } h-5 w-5 text-black-500`}
                          />
                        </Disclosure.Button>

                        <Disclosure.Panel className="dropdownMenuWrapper">
                          {bookingData.data.bookingFoodItemSelected
                            .beverage &&
                            Object.keys(
                              bookingData.data.bookingFoodItemSelected
                                .beverage
                            ).map((category) => (
                              <div key={category}>
                                <div className="menuCategoryHeading">
                                  {capitalizeAndReplace(category)}
                                </div>
                                {bookingData.data.bookingFoodItemSelected.beverage[
                                  category
                                ].map((item) => (
                                  <div
                                    key={item.item_code}
                                    className="menuItemNameWrapper"
                                  >
                                    <div>
                                      <svg
                                        width="13"
                                        height="13"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          x="0.5"
                                          y="0.5"
                                          width="15"
                                          height="15"
                                          rx="1.5"
                                          stroke="#009600"
                                        />
                                        <circle
                                          cx="8"
                                          cy="8"
                                          r="4"
                                          fill="#009600"
                                        />
                                      </svg>
                                    </div>
                                    <div>{item.item_name}</div>
                                  </div>
                                ))}
                              </div>
                            ))}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
              </div>
            </div>

            <div className="fandbmenuMobile2 pb-8 border-b border-gray-200">
              <div className="pt-4 pb-5">
                <div className="flex justify-between">
                  <h4 className="pb-1 foodmenuHeadertwo">Food Menu</h4>
                </div>
                <div
                  onClick={openPopupFood}
                  className="px-3 py-3 bg-white rounded-lg border border-zinc-400 flex justify-between items-center"
                >
                  <div className="text-neutral-800 text-base font-normal font-['Gilroy-Medium'] leading-normal tracking-tight">
                    {choiceA} Appetisers, {choiceM} Main Course, {choiceD}{" "}
                    desserts, Sides
                  </div>
                  <div>
                    <ChevronRightIcon className="h-6 w-6 text-black-500" />
                  </div>
                </div>
                {isFoodPopupOpen && (
                  <div className="menu-popup-background-custom">
                    <div className="menu-popup-custom">
                      <div className="flex justify-between">
                        <h2>Food Menu</h2>
                        <button
                          className="MenuPopupCloseBtn"
                          onClick={closePopupFood}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M7 7L17 17"
                              stroke="black"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7 17L17 7"
                              stroke="black"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>

                      <div className="menu-content">
                        {bookingData &&
                          bookingData.data.bookingFoodItemSelected &&
                          foodCategoryOrder.map((category) => (
                            <div key={category}>
                              <div className="w-full h-[42px] pl-5 pr-5 py-3 border-b border-gray-200 flex items-center gap-2.5">
                                <div className="w-full text-neutral-800 text-base font-normal font-['Gilroy-SemiBold'] tracking-tight whitespace-nowrap">
                                  {capitalizeAndReplace(category)}
                                </div>
                              </div>

                              {bookingData.data.bookingFoodItemSelected.food[
                                category
                              ].map((item) => (
                                <div
                                  key={item.item_code}
                                  className="pl-5 py-2.5 flex items-center gap-2.5"
                                >
                                  <div>
                                    {item.item_category ===
                                    "Non Vegetarian" ? (
                                      <svg
                                        width="13"
                                        height="13"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          x="0.5"
                                          y="0.5"
                                          width="15"
                                          height="15"
                                          rx="1.5"
                                          stroke="#963415"
                                        />
                                        <path
                                          d="M4.38833 12C4.20992 12 4.09388 11.8122 4.17367 11.6527L7.78534 4.42932C7.87378 4.25243 8.12622 4.25243 8.21466 4.42933L11.8263 11.6527C11.9061 11.8122 11.7901 12 11.6117 12H4.38833Z"
                                          fill="#963415"
                                        />
                                      </svg>
                                    ) : (
                                      <svg
                                        width="13"
                                        height="13"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          x="0.5"
                                          y="0.5"
                                          width="15"
                                          height="15"
                                          rx="1.5"
                                          stroke="#009600"
                                        />
                                        <circle
                                          cx="8"
                                          cy="8"
                                          r="4"
                                          fill="#009600"
                                        />
                                      </svg>
                                    )}
                                  </div>
                                  <div className="text-neutral-700 text-base font-normal">
                                    {item.item_name}
                                  </div>
                                </div>
                              ))}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div>
                {/* Beverage dropdown */}
                <h4 className="pb-1 foodmenuHeadertwo">Beverage menu</h4>
                <div
                  onClick={openPopupBev}
                  className="px-3 py-3 bg-white rounded-lg border border-zinc-400 flex justify-between items-center"
                >
                  <div className="text-neutral-800 text-base font-normal font-['Gilroy-Medium'] leading-normal tracking-tight">
                    Bottle Beers, Wines, Spirits, Cocktai...
                  </div>
                  <div>
                    <ChevronRightIcon className="h-6 w-6 text-black-500" />
                  </div>
                </div>
                {isBevPopupOpen && (
                  <div className="menu-popup-background-custom">
                    <div className="menu-popup-custom">
                      <div className="flex justify-between">
                        <h2>Beverage menu</h2>
                        <button
                          className="MenuPopupCloseBtn"
                          onClick={closePopupBev}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M7 7L17 17"
                              stroke="black"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M7 17L17 7"
                              stroke="black"
                              strokeWidth="1.5"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                        </button>
                      </div>

                      <div className="menu-content">
                        {bookingData &&
                          bookingData.data.bookingFoodItemSelected.beverage &&
                          Object.keys(
                            bookingData.data.bookingFoodItemSelected.beverage
                          ).map((category) => (
                            <div key={category}>
                             
                              <div className="w-full h-[42px] pl-5 pr-5 py-3 border-b border-gray-200 flex items-center gap-2.5">
                                <div className="w-full text-neutral-800 text-base font-normal font-['Gilroy-SemiBold'] tracking-tight whitespace-nowrap">
                                  {capitalizeAndReplace(category)}
                                </div>
                              </div>

                              {bookingData.data.bookingFoodItemSelected.beverage[
                                category
                              ].map((item) => (
                                <div
                                  key={item.item_code}
                                  className="pl-5 py-2.5 flex items-center gap-2.5"
                                >
                                  <svg
                                    width="13"
                                    height="13"
                                    viewBox="0 0 16 16"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <rect
                                      x="0.5"
                                      y="0.5"
                                      width="15"
                                      height="15"
                                      rx="1.5"
                                      stroke="#009600"
                                    />
                                    <circle
                                      cx="8"
                                      cy="8"
                                      r="4"
                                      fill="#009600"
                                    />
                                  </svg>
                                  <div>{item.item_name}</div>
                                </div>
                              ))}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </>
          )}
        </div>

        {/* RIGHT */}
        <div className="RequestWrapperRight">
          <div className="RequestWrapperChild">
          <img className="resCardImgOne" src={bookingData.data.bookingOutlet === "Moksha" ? rectangle : rectangle2} alt="" />

            {/* outlet addres */}
            <div className="everything-wrapper">
              <div className="RequestDateAndTimeWrapper">
                {bookingData.data.bookingOutlet}
              </div>

              <div className="RequestSummaryChild">
                {bookingData.data.bookingOutletAddress}
              </div>

              <div className="RequestSummaryChild2">
                {bookingData.data.bookingPackage}
              </div>
            </div>

            {/* bookin date/time/guest */}
            <div className="RequestSummaryChild3">
              <div>{showDate}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2"
                height="2"
                viewBox="0 0 2 2"
                fill="none"
              >
                <circle cx="1" cy="1" r="1" fill="#242426" />
              </svg>
              <div>{bookingData.data.bookingSlotStartTime} -</div>
              <div>{bookingData.data.bookingSlotEndTime}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2"
                height="2"
                viewBox="0 0 2 2"
                fill="none"
              >
                <circle cx="1" cy="1" r="1" fill="#242426" />
              </svg>
              <div>{bookingData.data.bookingTotalGuest} Guests</div>
            </div>

            {/* total price */}
            <div className="RequestSummaryWrapper">
              <div className="RequestSummaryChild4">
                Total
                <div>
                  {" "}
                  AED{" "}
                  {Number(
                    bookingData.data.bookingTotalCost
                  ).toLocaleString()}{" "}
                  /-
                </div>
              </div>

              <div className="RequestStatusHeading3">
                <div>Reservation ID </div>
                <div>#{bookingData.data.reservationCode}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Merchant;
