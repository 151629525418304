import React, { useState } from "react";
import "../Styles/NewReservation.css";
import { Link, useLocation } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/20/solid";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CountryFlag from "react-country-flag";
import axios from "axios";
import { useNavigate } from "react-router-dom";
// import restaurant from "../Images/Rectangle 6272.png";
// import { useEffect } from "react";
import restaurant1 from "../Images/moksha reserve.png";
import restaurant2 from "../Images/pahadi reserve.png";
import { setEstateCodeForTheme, useTheme } from "./ColorContext";

const NewReservation = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const estateCode = localStorage.getItem("estateCode");
  const hostname = window.location.hostname;
  let apiUrl;
  if (hostname.includes("testgrouple.com") || hostname.includes("localhost")) {
    apiUrl = "https://server.testgrouple.com/api/v1/beta/enduser/submitDetails";
  }
  if (hostname.includes("grouple.tech")) {
    apiUrl =
      "https://production.grouple.tech/api/v1/beta/enduser/submitDetails";
  }

  const handleEditClick = () => {
    navigate(`/FandB/janjaes/?edit=true`);
  };

  const { reservationData } = location.state || {};
  // console.log(reservationData)
  // const [isHappyEatingPopupVisible2, setIsHappyEatingPopupVisible2] =
  //   useState(false);



  const storedCompanyData = JSON.parse(localStorage.getItem("companyData"));
  const outletCode2 = localStorage.getItem("estateCode").toString();


  let allCapsCategory = "";
  switch (reservationData.packageCategory) {
    case "Regular":
      allCapsCategory = "REGULAR";
      break;
    case "Premium":
      allCapsCategory = "PREMIUM";
      break;
    case "Exclusive":
      allCapsCategory = "EXCLUSIVE";
      break;
    case "Ultra Exclusive":
      allCapsCategory = "ULTRA EXCLUSIVE";
      break;
    default:
      allCapsCategory = reservationData.packageCategory;
  }

  //   form functionality

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dialCode: "",
    phoneNumber: "",
    note: "",
    confirmEmail: "",
  });

  const [errorMessage, setErrorMessage] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    confirmEmail: "",
  });

  let foodData = reservationData.selectedItems;
  let newUpdatedFoodData = {};
  for (let category in foodData) {
    newUpdatedFoodData[category] = foodData[category].map((item) => ({
      item_code: item.id,
      item_name: item.item,
      item_category: item.category,
    }));
  }

  const isValidEmail = (email) => {
    return (
      /\S+@\S+\.\S+/.test(email) &&
      (email.endsWith("@gmail.com") ||
        email.endsWith("@yahoo.com") ||
        email.endsWith(".in"))
    );
  };

  const [value, setValue] = useState("");

  const validateForm = () => {
    let valid = true;
    const errors = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      confirmEmail: "",
      note: "",
    };
    const nameRegex = /^\S+$/;

    if (
      formData.firstName.trim() === "" ||
      !nameRegex.test(formData.firstName)
    ) {
      valid = false;
      errors.firstName =
        "Please enter a valid first name (one word, no spaces).";
    }
    if (formData.lastName.trim() === "" || !nameRegex.test(formData.lastName)) {
      valid = false;
      errors.lastName = "Please enter a valid last name (one word, no spaces).";
    }
    if (formData.email.trim() === "" || !isValidEmail(formData.email)) {
      valid = false;
      errors.email =
        "Please enter a valid Gmail address (e.g., mailto:example@gmail.com).";
    }
    if (formData.phoneNumber.trim() === "") {
      valid = false;
      errors.phoneNumber = "Please enter your phone number.";
    }
    if (formData.confirmEmail !== formData.email) {
      valid = false;
      errors.confirmEmail = "Emails does not match";
    }
    setErrorMessage(errors);
    return valid;
  };

  const getCategoriesForPackage = (packageCategory) => {
    switch (packageCategory) {
      case "Regular":
        return "Non-Alcohol";
      case "Premium":
        return "Bottle Beers, Wines, Spirits, Non-Alcohol";
      case "Exclusive":
        return "Bottle Beers, Wines, Spirits, Cocktails, Non-Alcohol";
      case "Ultra Exclusive":
        return "Bottle Beers, Wines, Spirits, Cocktails, Non-Alcohol";
      default:
        return "All Categories";
    }
  };

  const [requestSent, setRequestSent] = useState(false);
  let beverage = reservationData.selectedBeverages;
  let newUpdatedBeverageData = {};

  for (let category in beverage) {
    if (category === "NonAlcohol") {
      newUpdatedBeverageData["non_alcoholic"] = beverage["NonAlcohol"].map(
        (item) => ({
          item_code: item.id,
          item_name: item.item,
          item_category: item.category,
        })
      );
    } else if (category === "BottleBeer") {
      newUpdatedBeverageData["bottle_beers"] = beverage["BottleBeer"].map(
        (item) => ({
          item_code: item.id,
          item_name: item.item,
          item_category: item.category,
        })
      );
    } else if (category === "SingleMoult") {
      newUpdatedBeverageData["single_moult"] = beverage["SingleMoult"].map(
        (item) => ({
          item_code: item.id,
          item_name: item.item,
          item_category: item.category,
        })
      );
    } else if (category === "Cocktail") {
      newUpdatedBeverageData["cocktails"] = beverage["Cocktail"].map(
        (item) => ({
          item_code: item.id,
          item_name: item.item,
          item_category: item.category,
        })
      );
    } else if (category === "Gin") {
      newUpdatedBeverageData["gin"] = beverage["Gin"].map((item) => ({
        item_code: item.id,
        item_name: item.item,
        item_category: item.category,
      }));
    } else if (category === "Rum") {
      newUpdatedBeverageData["rum"] = beverage["Rum"].map((item) => ({
        item_code: item.id,
        item_name: item.item,
        item_category: item.category,
      }));
    } else if (category === "Tequila") {
      newUpdatedBeverageData["tequila"] = beverage["Tequila"].map((item) => ({
        item_code: item.id,
        item_name: item.item,
        item_category: item.category,
      }));
    } else if (category === "Vodka") {
      newUpdatedBeverageData["vodka"] = beverage["Vodka"].map((item) => ({
        item_code: item.id,
        item_name: item.item,
        item_category: item.category,
      }));
    } else if (category === "Whiskey") {
      newUpdatedBeverageData["whiskey"] = beverage["Whiskey"].map((item) => ({
        item_code: item.id,
        item_name: item.item,
        item_category: item.category,
      }));
    } else if (category === "Wine") {
      newUpdatedBeverageData["wines"] = beverage["Wine"].map((item) => ({
        item_code: item.id,
        item_name: item.item,
        item_category: item.category,
      }));
    } else {
      newUpdatedBeverageData[category] = beverage[category].map((item) => ({
        item_code: item.id,
        item_name: item.item,
        item_category: item.category,
      }));
    }
  }

  const estateCodeFromLocalStorage = localStorage.getItem("estateCode");

  const selectedCompany = storedCompanyData[0];

  const selectedEstate = selectedCompany.estates.find(
    (estate) => estate.estatecode === estateCodeFromLocalStorage
  );

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const isFormValid = validateForm();
      if (!isFormValid) {
        return;
      }
      if (!requestSent) {
        setRequestSent(true);
        setTimeout(() => {
          setRequestSent(false);
        }, 8000);
      }

      const time = reservationData.timeSlot.split(" ");
      const startTime = time[0];
      const endTime = time[2];
      const d = formData.dialCode.split("+");
      const phoneNumberWithoutDialCode = formData.phoneNumber.replace(d[1], "");
      const apiData = {
        bookerFirstName: formData.firstName,
        bookerLastName: formData.lastName,
        bookerEmail: formData.email,
        bookerInternationalCode: formData.dialCode,
        bookerPhone: phoneNumberWithoutDialCode,
        bookingCompany: selectedCompany.company_name,
        bookingOutlet: selectedEstate.estate_name,
        bookingOutletAddress: selectedEstate.address,
        bookingOutletCuisine: selectedEstate.cuisine,
        bookingPackageCategory: "Group Experiences",
        bookingPackage: reservationData.packageName,
        bookingPackageDuration: reservationData.duration,
        bookingDate: reservationData.date,
        bookingSlotStartTime: startTime,
        bookingSlotEndTime: endTime,
        bookingTotalGuest: reservationData.selectedPeople,
        bookingCurrency: "AED",
        bookingPerPersonCost: reservationData.price,
        bookingPerPersonCostWithoutDiscount: reservationData.initialPrice,
        bookingTotalCost: reservationData.totalCost,
        bookingDiscountedAmount: reservationData.moneysaved,
        bookingGrouplePackageCategory: reservationData.packageCategory,
        bookingNotes: formData.note || null,
        maxCapacity: 180,
        floatingCapacity: 180,
        companyCode: storedCompanyData[0].cmpcode,
        outletCode: outletCode2,
        foodItemSelected: {
          food: {
            appetizer: newUpdatedFoodData.Appetizer,
            main_course: newUpdatedFoodData.MainCourse,
            dessert: newUpdatedFoodData.Dessert,
            sides: newUpdatedFoodData.Sides,
          },
          beverage: newUpdatedBeverageData,
        },
      };

      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        note: "",
        confirmEmail: "",
      });
      let response = await axios.post(apiUrl, apiData);
      // setIsHappyEatingPopupVisible2(true);
      sessionStorage.clear();
      localStorage.clear();
      setTimeout(() => {
        // setIsHappyEatingPopupVisible2(false);
        let baseUrl = "/groupReservation/7gK1Yn8pQF";
        baseUrl += `/${response.data.data.random_reservation_code}?reservation_code=${response.data.data.reservation_code}&choiceA=${reservationData.choiceA}&choiceM=${reservationData.choiceM}&choiceD=${reservationData.choiceD}`;
        window.open(baseUrl, "_blank");
        window.location.href = "/landingPage/janjaes";
      }, 100);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const [isFoodPopupOpen, setIsFoodPopupOpen] = useState(false);
  const [isBevPopupOpen, setIsBevPopupOpen] = useState(false);
  const [isPricePopupOpen, setIsPricePopupOpen] = useState(false);

  const openPriceDetailPopup = () => {
    setIsPricePopupOpen(true);
  };

  const openPopupFood = () => {
    setIsFoodPopupOpen(true);
  };
  const openPopupBev = () => {
    setIsBevPopupOpen(true);
  };

  const closePopupFood = () => {
    setIsFoodPopupOpen(false);
  };
  const closePriceDetailPopup = () => {
    setIsPricePopupOpen(false);
  };
  const closePopupBev = () => {
    setIsBevPopupOpen(false);
  };

  const companyData = JSON.parse(localStorage.getItem("companyData"));

  const getEstateName = (estateCode) => {
    const estate = companyData[0].estates.find(
      (estate) => estate.estatecode === estateCode
    );
    return estate ? estate.estate_name : null;
  };

  const getEstateAddress = (estateCode) => {
    const estate = companyData[0].estates.find(
      (estate) => estate.estatecode === estateCode
    );
    return estate ? estate.address : null;
  };

  const getEstateCuisine = (estateCode) => {
    const estate = companyData[0].estates.find(
      (estate) => estate.estatecode === estateCode
    );
    return estate ? estate.cuisine : null;
  };

  const estateName = getEstateName(estateCode);
  const estateAddress = getEstateAddress(estateCode);
  const estateCuisine = getEstateCuisine(estateCode);


  const handleContinueMobile = () => {
   
    sessionStorage.setItem(
      "reservationDatafromDateAndTime2",
      JSON.stringify(reservationData)
    );
    navigate("/groupRes/janjaes", { state: { reservationData } });
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short' };
    return date.toLocaleDateString('en-GB', options);
  };

  const DisplayDate = formatDate(reservationData.date)

  const { themeColor } = useTheme();
  setEstateCodeForTheme(estateCode)

  return (
    <>
      <div className="newReservation">
        <h1>Confirm and reserve</h1>

        <div className="outer-new">
          {/* left side div */}
          <div className="inner-new1">
            <h2>Your experience</h2>
            <div className="AllCapsFlexWrapper">
              {/* <h3>Package</h3> */}
              <div className="packageNameDiv2">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                  >
                    <path
                      d="M11.082 2.33301H2.91536C2.76065 2.33301 2.61228 2.38217 2.50289 2.46969C2.39349 2.55721 2.33203 2.67591 2.33203 2.79967V11.1997C2.33203 11.3234 2.39349 11.4421 2.50289 11.5297C2.61228 11.6172 2.76065 11.6663 2.91536 11.6663H11.082C11.2367 11.6663 11.3851 11.6172 11.4945 11.5297C11.6039 11.4421 11.6654 11.3234 11.6654 11.1997V2.79967C11.6654 2.67591 11.6039 2.55721 11.4945 2.46969C11.3851 2.38217 11.2367 2.33301 11.082 2.33301Z"
                      fill="#4A6AFE"
                    />
                    <path
                      d="M5.83203 2.33301H8.16536V6.99967L6.9987 5.83301L5.83203 6.99967V2.33301Z"
                      fill="#DBE1FF"
                    />
                  </svg>
                  {allCapsCategory}
                </div>
                {reservationData.bestValue && (
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="12"
                      viewBox="0 0 14 12"
                      fill="none"
                    >
                      <path
                        d="M6.99935 0.458496L5.21552 4.09791L1.16602 4.68533L4.10018 7.55299L3.39785 11.5418L6.99935 9.62266L10.6008 11.5418L9.90435 7.55299L12.8327 4.68533L8.80535 4.09791L6.99876 0.458496H6.99935Z"
                        fill="#EE4622"
                        stroke="#EE4622"
                        stroke-width="0.875"
                        stroke-linejoin="round"
                      />
                    </svg>
                    {reservationData.bestValue}
                  </div>
                )}
              </div>
              <p>{reservationData.packageName}</p>
            </div>
            <div className="date-infodiv">
              <div>
                <p>Date</p>
                <h5>{DisplayDate}</h5>
              </div>
              <div>
                <p>Time</p>
                <h5>{reservationData.displayedTimeSot}</h5>
              </div>
              <div>
                <p>Group Size</p>
                <h5>{reservationData.selectedPeople} guests</h5>
              </div>
            </div>

            {/* Food dropdown */}
            <div className="fandbmenuDesktop">
              <div className="food-menu-div">
                <h4>Food menu</h4>
                <h4
                  onClick={handleEditClick}
                  style={{ cursor: "pointer", textDecoration: "underline" }}
                >
                  Edit
                </h4>
              </div>
              <div className="w-full">
                <div className="w-full rounded-2xl bg-white">
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className={`foodMenuDropdownButton flex w-full justify-between ${
                            open ? "border-2 border-black" : ""
                          }`}
                        >
                          <span>
                            {reservationData.choiceA} Appetisers,{" "}
                            {reservationData.choiceM} Main Course,{" "}
                            {reservationData.choiceD} desserts, Sides
                          </span>
                          <ChevronDownIcon
                            className={`${
                              open ? "rotate-180 transform" : ""
                            } h-5 w-5 text-black-500`}
                          />
                        </Disclosure.Button>
                        <Disclosure.Panel className="dropdownMenuWrapper">
                          {reservationData &&
                            reservationData.selectedItems &&
                            Object.keys(reservationData.selectedItems).map(
                              (category) => (
                                <div key={category}>
                                  <div className="menuCategoryHeading">
                                    {category}
                                  </div>
                                  {reservationData.selectedItems[category].map(
                                    (item) => (
                                      <div
                                        key={item.id}
                                        className="menuItemNameWrapper"
                                      >
                                        {" "}
                                        <div>
                                          {item.category ===
                                          "Non Vegetarian" ? (
                                            <svg
                                              width="13"
                                              height="13"
                                              viewBox="0 0 16 16"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <rect
                                                x="0.5"
                                                y="0.5"
                                                width="15"
                                                height="15"
                                                rx="1.5"
                                                stroke="#963415"
                                              />
                                              <path
                                                d="M4.38833 12C4.20992 12 4.09388 11.8122 4.17367 11.6527L7.78534 4.42932C7.87378 4.25243 8.12622 4.25243 8.21466 4.42933L11.8263 11.6527C11.9061 11.8122 11.7901 12 11.6117 12H4.38833Z"
                                                fill="#963415"
                                              />
                                            </svg>
                                          ) : (
                                            <svg
                                              width="13"
                                              height="13"
                                              viewBox="0 0 16 16"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <rect
                                                x="0.5"
                                                y="0.5"
                                                width="15"
                                                height="15"
                                                rx="1.5"
                                                stroke="#009600"
                                              />
                                              <circle
                                                cx="8"
                                                cy="8"
                                                r="4"
                                                fill="#009600"
                                              />
                                            </svg>
                                          )}
                                        </div>
                                        <div>{item.item}</div>
                                      </div>
                                    )
                                  )}
                                </div>
                              )
                            )}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
              </div>

              {/* Beverage dropdown */}
              <div className="food-menu-div">
                <h4>Beverage Menu</h4>
              </div>
              <div className="w-full">
                <div className="w-full rounded-2xl bg-white ">
                  <Disclosure>
                    {({ open }) => (
                      <>
                        <Disclosure.Button
                          className={`foodMenuDropdownButton flex w-full justify-between ${
                            open ? "border-2 border-black" : ""
                          }`}
                        >
                          <span>
                            {getCategoriesForPackage(
                              reservationData.packageCategory
                            )}
                          </span>
                          <ChevronDownIcon
                            className={`${
                              open ? "rotate-180 transform" : ""
                            } h-5 w-5 text-black-500`}
                          />
                        </Disclosure.Button>

                        <Disclosure.Panel className="dropdownMenuWrapper">
                          {reservationData &&
                            reservationData.selectedBeverages &&
                            Object.keys(reservationData.selectedBeverages).map(
                              (category) => (
                                <div key={category}>
                                  <div className="menuCategoryHeading">
                                    {category}
                                  </div>

                                  {Array.isArray(
                                    reservationData.selectedBeverages[category]
                                  ) ? (
                                    reservationData.selectedBeverages[
                                      category
                                    ].map((item) => (
                                      <div
                                        key={item.id}
                                        className="menuItemNameWrapper"
                                      >
                                        {" "}
                                        <svg
                                          width="13"
                                          height="13"
                                          viewBox="0 0 16 16"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <rect
                                            x="0.5"
                                            y="0.5"
                                            width="15"
                                            height="15"
                                            rx="1.5"
                                            stroke="#009600"
                                          />
                                          <circle
                                            cx="8"
                                            cy="8"
                                            r="4"
                                            fill="#009600"
                                          />
                                        </svg>
                                        <div>{item.item}</div>
                                      </div>
                                    ))
                                  ) : (
                                    <p
                                      key={
                                        reservationData.selectedBeverages[
                                          category
                                        ].id
                                      }
                                    >
                                      {
                                        reservationData.selectedBeverages[
                                          category
                                        ].item
                                      }
                                    </p>
                                  )}
                                </div>
                              )
                            )}
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>
                </div>
              </div>
            </div>

            <div className="fandbmenuMobile pb-8 border-b border-gray-200">
              <div className="pt-4 pb-5">
                <div className="flex justify-between">
                  <h4 className="pb-1 foodmenuHeadertwo">Food menu</h4>
                  <h4
                    onClick={handleEditClick}
                    style={{ cursor: "pointer", textDecoration: "underline" }}
                    className="foodmenuHeadertwo"
                  >
                    Edit
                  </h4>
                </div>
                <div
                  onClick={openPopupFood}
                  class="px-3 py-3 bg-white rounded-lg border border-zinc-400 flex justify-between items-center"
                >
                  <div class="text-neutral-800 text-base font-normal font-['Gilroy-Medium'] leading-normal tracking-tight">
                    {reservationData.choiceA} Appetisers,{" "}
                    {reservationData.choiceM} Main Course,{" "}
                    {reservationData.choiceD} desserts, Sides
                  </div>
                  <div class="">
                    <ChevronRightIcon className=" h-6 w-6 text-black-500" />
                  </div>
                </div>
                {isFoodPopupOpen && (
                  <div className="menu-popup-background-custom">
                    <div className="menu-popup-custom">
                      <div className="flex justify-between">
                        <h2>Food Menu</h2>
                        <button
                          className="MenuPopupCloseBtn"
                          onClick={closePopupFood}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M7 7L17 17"
                              stroke="black"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7 17L17 7"
                              stroke="black"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                      </div>

                      <div className="menu-content">
                        {reservationData &&
                          reservationData.selectedItems &&
                          Object.keys(reservationData.selectedItems).map(
                            (category) => (
                              <div key={category}>
                                <div className=" pl-5  py-3 border-b border-gray-200 justify-center items-center gap-2.5 inline-flex">
                                  <div className="text-neutral-800 text-base font-normal font-['Gilroy-SemiBold'] tracking-tight">
                                    {category}
                                  </div>
                                </div>
                                {reservationData.selectedItems[category].map(
                                  (item) => (
                                    <div
                                      key={item.id}
                                      className="pl-5 py-2.5 flex items-center gap-2.5"
                                    >
                                      {" "}
                                      <div>
                                        {item.category === "Non Vegetarian" ? (
                                          <svg
                                            width="13"
                                            height="13"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <rect
                                              x="0.5"
                                              y="0.5"
                                              width="15"
                                              height="15"
                                              rx="1.5"
                                              stroke="#963415"
                                            />
                                            <path
                                              d="M4.38833 12C4.20992 12 4.09388 11.8122 4.17367 11.6527L7.78534 4.42932C7.87378 4.25243 8.12622 4.25243 8.21466 4.42933L11.8263 11.6527C11.9061 11.8122 11.7901 12 11.6117 12H4.38833Z"
                                              fill="#963415"
                                            />
                                          </svg>
                                        ) : (
                                          <svg
                                            width="13"
                                            height="13"
                                            viewBox="0 0 16 16"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <rect
                                              x="0.5"
                                              y="0.5"
                                              width="15"
                                              height="15"
                                              rx="1.5"
                                              stroke="#009600"
                                            />
                                            <circle
                                              cx="8"
                                              cy="8"
                                              r="4"
                                              fill="#009600"
                                            />
                                          </svg>
                                        )}
                                      </div>
                                      <div className="text-neutral-700 text-base font-normal">
                                        {item.item}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )
                          )}
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div>
                {/* Beverage dropdown */}
                <h4 className="pb-1 foodmenuHeadertwo">Beverage menu</h4>
                <div
                  onClick={openPopupBev}
                  class="px-3 py-3 bg-white rounded-lg border border-zinc-400 flex justify-between items-center"
                >
                  <div class="text-neutral-800 text-base font-normal font-['Gilroy-Medium'] leading-normal tracking-tight">
                    Bottle Beers, Wines, Spirits, Cocktai...
                  </div>
                  <div class="">
                    <ChevronRightIcon className=" h-6 w-6 text-black-500" />
                  </div>
                </div>
                {isBevPopupOpen && (
                  <div className="menu-popup-background-custom">
                    <div className="menu-popup-custom">
                      <div className="flex justify-between">
                        <h2>Beverage menu</h2>
                        <button
                          className="MenuPopupCloseBtn"
                          onClick={closePopupBev}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                          >
                            <path
                              d="M7 7L17 17"
                              stroke="black"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M7 17L17 7"
                              stroke="black"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </button>
                      </div>

                      <div className="menu-content">
                        {reservationData &&
                          reservationData.selectedBeverages &&
                          Object.keys(reservationData.selectedBeverages).map(
                            (category) => (
                              <div key={category}>
                                <div className=" pl-5  py-3 border-b border-gray-200 justify-center items-center gap-2.5 inline-flex">
                                  <div className=" text-neutral-800 text-base font-normal font-['Gilroy-SemiBold'] tracking-tight">
                                    {category}
                                  </div>
                                </div>

                                {Array.isArray(
                                  reservationData.selectedBeverages[category]
                                ) ? (
                                  reservationData.selectedBeverages[
                                    category
                                  ].map((item) => (
                                    <div
                                      key={item.id}
                                      className="pl-5 py-2.5 flex items-center gap-2.5"
                                    >
                                      {" "}
                                      <svg
                                        width="13"
                                        height="13"
                                        viewBox="0 0 16 16"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <rect
                                          x="0.5"
                                          y="0.5"
                                          width="15"
                                          height="15"
                                          rx="1.5"
                                          stroke="#009600"
                                        />
                                        <circle
                                          cx="8"
                                          cy="8"
                                          r="4"
                                          fill="#009600"
                                        />
                                      </svg>
                                      <div>{item.item}</div>
                                    </div>
                                  ))
                                ) : (
                                  <div
                                    key={
                                      reservationData.selectedBeverages[
                                        category
                                      ].id
                                    }
                                    className="text-neutral-700 text-base font-normal"
                                  >
                                    {
                                      reservationData.selectedBeverages[
                                        category
                                      ].item
                                    }
                                  </div>
                                )}
                              </div>
                            )
                          )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>

            <div className="fandbmenuMobile pb-2 border-b border-gray-200">
            <div className="pt-1 pb-2">
              <div className="flex justify-between">
                <h4 className="pb-1 foodmenuHeadertwo">Price details</h4>
              </div>
              <div
                onClick={openPriceDetailPopup}
                class="px-3 py-3 bg-white rounded-lg border border-zinc-400 flex justify-between items-center"
              >
                <div class="text-neutral-800 flex gap-2 text-base font-normal font-['Gilroy-Medium'] leading-normal tracking-tight">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="22"
                      height="22"
                      viewBox="0 0 22 22"
                      fill="none"
                    >
                      <path
                        d="M11.9154 13.75H13.7487M8.2487 6.41667H13.7487M8.2487 10.0833H13.7487M4.58203 19.25V4.58333C4.58203 4.0971 4.77519 3.63079 5.119 3.28697C5.46282 2.94315 5.92913 2.75 6.41536 2.75H15.582C16.0683 2.75 16.5346 2.94315 16.8784 3.28697C17.2222 3.63079 17.4154 4.0971 17.4154 4.58333V19.25L14.6654 17.4167L12.832 19.25L10.9987 17.4167L9.16536 19.25L7.33203 17.4167L4.58203 19.25Z"
                        stroke="#4A6AFE"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="font-normal font-['Gilroy-Medium']  flex gap-3 justify-center text-sm ">
                    Total: AED {Number(reservationData.totalCost).toLocaleString()}
                    <div className="font-normal font-['Gilroy-Medium'] text-[#4A6AFE] border rounded-md bg-[#DBE1FF] px-2 py-0.5">
                      You saved AED {Number(reservationData.moneysaved).toLocaleString()}
                    </div>{" "}
                  </div>
                </div>
                <div class="">
                  <ChevronRightIcon className=" h-6 w-6 text-black-500" />
                </div>
              </div>
              {isPricePopupOpen && (
                <div className="menu-popup-background-custom">
                  <div className="menu-popup-custom2">
                    <div className="flex justify-between">
                      <h2>Price details</h2>
                      <button
                        className="MenuPopupCloseBtn"
                        onClick={closePriceDetailPopup}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            d="M7 7L17 17"
                            stroke="black"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7 17L17 7"
                            stroke="black"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </button>
                    </div>

                    <div className="menu-content">
                      <div className="w-full justify-between items-center gap-[211px] inline-flex">
                        <div className=" text-neutral-800 text-base font-normal font-['Gilroy-Medium'] tracking-tight">
                          AED {reservationData.initialPrice} × {reservationData.selectedPeople}
                        </div>
                        <div className=" text-neutral-800 text-base font-normal font-['Gilroy-Medium'] tracking-tight">
                          AED {Number(reservationData.priceBeforeDiscount).toLocaleString()}
                        </div>
                      </div>
                      <div className="w-full py-4 justify-between items-center gap-[248px] inline-flex">
                        <div className="text-indigo-500 text-base font-normal font-['Gilroy-Medium'] tracking-tight">
                          Discount
                        </div>
                        <div className="text-indigo-500 text-base font-normal font-['Gilroy-Medium'] tracking-tight">
                          -AED {Number(reservationData.moneysaved).toLocaleString()}
                        </div>
                      </div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="388"
                        height="1"
                        viewBox="0 0 388 1"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M1.61667 1H0V0H1.61667V1ZM8.08333 1H4.85V0H8.08333V1ZM14.55 1H11.3167V0H14.55V1ZM21.0167 1H17.7833V0H21.0167V1ZM27.4833 1H24.25V0H27.4833V1ZM33.95 1H30.7167V0H33.95V1ZM40.4167 1H37.1833V0H40.4167V1ZM46.8833 1H43.65V0H46.8833V1ZM53.35 1H50.1167V0H53.35V1ZM59.8167 1H56.5833V0H59.8167V1ZM66.2833 1H63.05V0H66.2833V1ZM72.75 1H69.5167V0H72.75V1ZM79.2167 1H75.9833V0H79.2167V1ZM85.6833 1H82.45V0H85.6833V1ZM92.15 1H88.9167V0H92.15V1ZM98.6167 1H95.3833V0H98.6167V1ZM105.083 1H101.85V0H105.083V1ZM111.55 1H108.317V0H111.55V1ZM118.017 1H114.783V0H118.017V1ZM124.483 1H121.25V0H124.483V1ZM130.95 1H127.717V0H130.95V1ZM137.417 1H134.183V0H137.417V1ZM143.883 1H140.65V0H143.883V1ZM150.35 1H147.117V0H150.35V1ZM156.817 1H153.583V0H156.817V1ZM163.283 1H160.05V0H163.283V1ZM169.75 1H166.517V0H169.75V1ZM176.217 1H172.983V0H176.217V1ZM182.683 1H179.45V0H182.683V1ZM189.15 1H185.917V0H189.15V1ZM195.617 1H192.383V0H195.617V1ZM202.083 1H198.85V0H202.083V1ZM208.55 1H205.317V0H208.55V1ZM215.017 1H211.783V0H215.017V1ZM221.483 1H218.25V0H221.483V1ZM227.95 1H224.717V0H227.95V1ZM234.417 1H231.183V0H234.417V1ZM240.883 1H237.65V0H240.883V1ZM247.35 1H244.117V0H247.35V1ZM253.817 1H250.583V0H253.817V1ZM260.283 1H257.05V0H260.283V1ZM266.75 1H263.517V0H266.75V1ZM273.217 1H269.983V0H273.217V1ZM279.683 1H276.45V0H279.683V1ZM286.15 1H282.917V0H286.15V1ZM292.617 1H289.383V0H292.617V1ZM299.083 1H295.85V0H299.083V1ZM305.55 1H302.317V0H305.55V1ZM312.017 1H308.783V0H312.017V1ZM318.483 1H315.25V0H318.483V1ZM324.95 1H321.717V0H324.95V1ZM331.417 1H328.183V0H331.417V1ZM337.883 1H334.65V0H337.883V1ZM344.35 1H341.117V0H344.35V1ZM350.817 1H347.583V0H350.817V1ZM357.283 1H354.05V0H357.283V1ZM363.75 1H360.517V0H363.75V1ZM370.217 1H366.983V0H370.217V1ZM376.683 1H373.45V0H376.683V1ZM383.15 1H379.917V0H383.15V1ZM388 1H386.383V0H388V1Z"
                          fill="#D8D8DC"
                        />
                      </svg>
                      <div className="w-full py-4 justify-between items-center gap-[86px] inline-flex">
                        <div>
                          <span className="text-neutral-800 text-lg font-normal font-['Gilroy-SemiBold'] tracking-tight">
                            Total{" "}
                          </span>
                          <span className="text-neutral-400 text-sm font-normal font-['Gilroy-Medium'] tracking-tight">
                            (Incl. taxes and charges)
                          </span>
                        </div>
                        <div className="text-neutral-800 text-lg font-normal font-['Gilroy-SemiBold'] tracking-tight">
                          AED {Number(reservationData.totalCost).toLocaleString()}
                        </div>
                      </div>
                      <div className="w-full h-10 pl-[104px] pr-[105px] py-3.5 bg-indigo-100 rounded-lg justify-center items-center gap-2.5 inline-flex">
                        <div className="text-indigo-500 text-[17px] font-normal font-['Gilroy-SemiBold'] tracking-tight">
                          🥳 You saved AED {Number(reservationData.moneysaved).toLocaleString()}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            </div>

           
            <div className="mobile-cnfrm"  onClick={handleContinueMobile}>
             
                <button style={{backgroundColor:themeColor }}>Continue</button>
           
            </div>
         
            {/* reservation form */}

            <div className="mobile-none-div">
              <form className="reservationForm">
                <h1>Personal Details</h1>

                <div className="nameWrapper">
                  <div className="firstname-wrapper">
                    <label htmlFor="firstName">First Name</label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      value={formData.firstName}
                      onChange={(e) => {
                        const capitalizedValue =
                          e.target.value.charAt(0).toUpperCase() +
                          e.target.value.slice(1);

                        setErrorMessage({ ...errorMessage, firstName: "" });
                        setFormData({
                          ...formData,
                          firstName: capitalizedValue,
                        });
                      }}
                      required
                    />
                    {errorMessage.firstName && (
                      <p style={{ color: "red" }}>{errorMessage.firstName}</p>
                    )}
                  </div>

                  <div className="lastname-wrapper">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      value={formData.lastName}
                      onChange={(e) => {
                        const capitalizedValueTwo =
                          e.target.value.charAt(0).toUpperCase() +
                          e.target.value.slice(1);

                        setErrorMessage({ ...errorMessage, lastName: "" });
                        setFormData({
                          ...formData,
                          lastName: capitalizedValueTwo,
                        });
                      }}
                      required
                    />
                    {errorMessage.lastName && (
                      <p style={{ color: "red" }}>{errorMessage.lastName}</p>
                    )}
                  </div>
                </div>

                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={(e) => {
                    setErrorMessage({ ...errorMessage, email: "" });
                    setFormData({ ...formData, email: e.target.value });
                  }}
                  required
                />
                {errorMessage.email && (
                  <p style={{ color: "red" }}>{errorMessage.email}</p>
                )}

                <label htmlFor="email">Confirm Email Address</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.confirmEmail}
                  onChange={(e) => {
                    setErrorMessage({ ...errorMessage, confirmEmail: "" });
                    setFormData({ ...formData, confirmEmail: e.target.value });
                  }}
                  required
                />
                {errorMessage.confirmEmail && (
                  <p style={{ color: "red" }}>{errorMessage.confirmEmail}</p>
                )}

                <label htmlFor="phoneNumber">Phone Number</label>
                <PhoneInput
                  country={"ae"}
                  inputStyle={{
                    width: "100%",
                    height: "3rem",
                    borderRadius: "0.5rem",
                    border: "1px solid #a6a6a6",
                  }}
                  dropdownStyle={{
                    padding: "1rem",
                    height: "80rem",
                    width: "39rem",
                    borderRadius: "0.5rem",
                  }}
                  buttonStyle={{
                    marginTop: "0.3rem",
                    marginBottom: "0.3rem",
                    borderRight: "2px solid #293897",
                    background: "#fff",
                    borderTop: "none",
                    borderBottom: "none",
                  }}
                  name="phoneNumber"
                  placeholder="Enter your phone number"
                  value={formData.phoneNumber}
                  onChange={(value, country) => {
                    setErrorMessage({ ...errorMessage, phoneNumber: "" });
                    setFormData({
                      ...formData,
                      dialCode: country ? `+${country.dialCode}` : `+971`,
                      phoneNumber: value || "",
                    });
                  }}
                  maxLength={10}
                  buttonComponent={
                    <CountryFlag dialCode={formData.dialCode} svg />
                  }
                />
                {errorMessage.phoneNumber && (
                  <p style={{ color: "red" }}>{errorMessage.phoneNumber}</p>
                )}

                <label htmlFor="notes"> Reservation notes (Optional)</label>

                <textarea
                  onChange={(e) => {
                    setFormData({ ...formData, note: e.target.value });
                  }}
                  value={formData.note}
                  className="reservationInput3"
                  type="text"
                  name="note"
                  id="note"
                ></textarea>
              </form>
              <div className="ConfirmButtondiv">
                <h4>
                  <label
                    style={{
                      marginRight: "1rem",
                      position: "relative",
                      display: "inline-block",
                    }}
                  >
                    <input
                      type="checkbox"
                      name="agreement"
                      checked={value}
                      onChange={(e) => setValue(e.target.checked)}
                      style={{ display: "none" }}
                    />
                    <span
                      style={{
                        display: "inline-block",
                        width: "20px",
                        height: "20px",
                        backgroundColor: value ? "#4A6AFE" : "#e5e5ea",
                        border: `1px solid ${value ? "#4A6AFE" : "#CCCCCC"}`,
                        borderRadius: "3px",
                        cursor: "pointer",
                      }}
                    />
                    {value && (
                      <span
                        style={{
                          position: "absolute",
                          top: "10%",
                          left: "30%",
                          transform: "rotate(45deg)",
                          width: "8px",
                          height: "12px",
                          borderStyle: "solid",
                          borderWidth: "0 2px 2px 0",
                          borderColor: "#FFFFFF",
                        }}
                      />
                    )}
                  </label>
                  By clicking ”confirm” you agree to{" "}
                  <a
                    href="https://grouple.in/privacy"
                    target="blank"
                    style={{ margin: " 0rem 0.3rem" }}
                  >
                    Terms and Condition
                  </a>
                </h4>
                <button
                  type="submit"
                  onClick={handleFormSubmit}
                  disabled={!value || requestSent}
                  style={{ cursor: !value ? "not-allowed" : "pointer" , backgroundColor:themeColor}}
                >
                  Confirm
                </button>
              </div>
            </div>
          </div>

          {/* right side div */}
          <div className="inner-new2">
            <div className="inside-right">
              {estateName === "Moksha" ? (
                <img src={restaurant1} alt="" />
              ) : (
                <img src={restaurant2} alt="" />
              )}
              <div className="text-rightwrapper">
                <div className="text-rightwrapperH2">{estateName}</div>
                <div className="text-rightwrapperP">{estateAddress}</div>

                <div className="outer-textwrapper">
                  <div>3 hours</div>
                  <div>.</div>
                  <div>{estateCuisine}</div>
                </div>
                <div className="packageNameDiv2">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                    >
                      <path
                        d="M11.082 2.33301H2.91536C2.76065 2.33301 2.61228 2.38217 2.50289 2.46969C2.39349 2.55721 2.33203 2.67591 2.33203 2.79967V11.1997C2.33203 11.3234 2.39349 11.4421 2.50289 11.5297C2.61228 11.6172 2.76065 11.6663 2.91536 11.6663H11.082C11.2367 11.6663 11.3851 11.6172 11.4945 11.5297C11.6039 11.4421 11.6654 11.3234 11.6654 11.1997V2.79967C11.6654 2.67591 11.6039 2.55721 11.4945 2.46969C11.3851 2.38217 11.2367 2.33301 11.082 2.33301Z"
                        fill="#4A6AFE"
                      />
                      <path
                        d="M5.83203 2.33301H8.16536V6.99967L6.9987 5.83301L5.83203 6.99967V2.33301Z"
                        fill="#DBE1FF"
                      />
                    </svg>
                    {allCapsCategory}
                  </div>
                  {reservationData.bestValue && (
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="12"
                        viewBox="0 0 14 12"
                        fill="none"
                      >
                        <path
                          d="M6.99935 0.458496L5.21552 4.09791L1.16602 4.68533L4.10018 7.55299L3.39785 11.5418L6.99935 9.62266L10.6008 11.5418L9.90435 7.55299L12.8327 4.68533L8.80535 4.09791L6.99876 0.458496H6.99935Z"
                          fill="#EE4622"
                          stroke="#EE4622"
                          stroke-width="0.875"
                          stroke-linejoin="round"
                        />
                      </svg>
                      {reservationData.bestValue}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="outside-right">
              <h2>Price details</h2>
              <div className="priceRight">
                <div>
                  AED {reservationData.initialPrice} ×{" "}
                  {reservationData.selectedPeople}
                </div>
                <div>
                  AED{" "}
                  {reservationData.priceBeforeDiscount &&
                    reservationData.priceBeforeDiscount.toLocaleString()}
                </div>
              </div>
              {/* <div className="priceRight">
                <div>Platform fee</div>
                <div>AED {reservationData.ServiceFee}</div>
              </div> */}
              {reservationData.selectedPeople > 49 && (
                <div className="priceRight">
                  <div className="priceRight-color">Discount</div>

                  <div className="priceRight-color">
                    -AED{" "}
                    {reservationData.moneysaved &&
                      reservationData.moneysaved.toLocaleString()}
                  </div>
                </div>
              )}
            </div>

            <div className="priceRight">
              <div className="total-price">Total</div>
              <div className="total-price">
                AED{" "}
                {reservationData.totalCost &&
                  reservationData.totalCost.toLocaleString()}
              </div>
            </div>
            {reservationData.selectedPeople > 49 && (
              <div className="moneySavedDivReservation">
                🥳 You saved AED{" "}
                {reservationData.moneysaved !== undefined &&
                  reservationData.moneysaved.toLocaleString()}
              </div>
            )}
          </div>

          {/* popup */}
          {/* {isHappyEatingPopupVisible2 && (
            <div className="food-popup-background-custom">
              <div className="food-popup-custom">
                <div className="inner-custom">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="61"
                    height="60"
                    viewBox="0 0 61 60"
                    fill="none"
                  >
                    <path
                      d="M43.8975 42.0797L29.1246 27.3067L27.4852 29.9971L25.6923 23.8744L19.1314 17.3135L15.4203 26.443L13.3532 25.3064L7.59726 40.6595V40.6607L7.76483 45.3479L5.64855 45.8553V45.8565L1.23669 57.6203C0.973029 58.3234 1.18513 59.0229 1.64096 59.4788C2.09562 59.9346 2.79285 60.1491 3.49945 59.8831L12.8071 56.3934L13.1645 54.215L16.4843 55.0142L23.7061 52.3061V49.6051L27.4852 50.8894L34.4879 48.2634L35.2238 46.1682L38.1381 46.8936H38.1393L43.2718 44.9694C44.4706 44.5195 44.7987 42.9808 43.8975 42.0797Z"
                      fill="#FF7D43"
                    />
                    <path
                      d="M43.2715 44.9702L38.1389 46.8943C38.1389 46.8943 38.139 46.8943 38.1378 46.8943L35.2235 46.169L34.4876 48.2642L27.4849 50.8902L23.7058 49.6059V52.3069L16.4839 55.015L13.1642 54.2158L12.8068 56.3942L3.49911 59.8838C2.79251 60.1498 2.09529 59.9354 1.64062 59.4796L31.469 29.6523L43.8972 42.0805C44.7983 42.9817 44.4702 44.5204 43.2715 44.9702Z"
                      fill="#E63950"
                    />
                    <path
                      d="M38.1378 46.894L34.4876 48.2639C30.7613 44.4145 28.0708 39.6429 26.681 34.4389C25.7776 31.0558 25.4237 27.4889 25.692 23.875L29.1243 27.3072C29.1758 28.7298 29.3352 30.1383 29.5988 31.5211C30.7156 37.3836 33.6908 42.7833 38.1378 46.894Z"
                      fill="#F0F7FF"
                    />
                    <path
                      d="M38.1379 46.8944L34.4877 48.2643C30.7614 44.4149 28.0709 39.6433 26.6812 34.4393L29.599 31.5215C30.7157 37.384 33.6909 42.7837 38.1379 46.8944Z"
                      fill="#DFE7F4"
                    />
                    <path
                      d="M27.4849 50.8892L23.7058 52.3059C21.0493 49.9271 18.8194 47.1242 17.1038 44.0154C13.9554 38.313 12.6445 31.7382 13.3529 25.3063L16.15 17.8477C16.5988 16.6536 18.135 16.3173 19.0397 17.222L19.1311 17.3134C15.5879 25.306 16.0122 34.1119 19.7053 41.4139C21.5626 45.0911 24.2144 48.3277 27.4849 50.8892Z"
                      fill="#F0F7FF"
                    />
                    <path
                      d="M27.4851 50.8903L23.706 52.307C21.0495 49.9282 18.8195 47.1253 17.104 44.0165L19.7054 41.415C21.5627 45.0922 24.2145 48.3288 27.4851 50.8903Z"
                      fill="#DFE7F4"
                    />
                    <path
                      d="M16.4842 55.0156L12.807 56.3948C11.436 55.0648 10.1869 53.6106 9.07598 52.0451C7.81523 50.2924 6.59374 48.1385 5.64844 45.8579V45.8567L7.59716 40.6621C8.39984 43.8705 9.75211 46.8657 11.5778 49.5432C12.9535 51.5633 14.5999 53.4019 16.4842 55.0156Z"
                      fill="#F0F7FF"
                    />
                    <path
                      d="M16.4843 55.0143L12.8072 56.3936C11.4362 55.0636 10.187 53.6093 9.07617 52.0438L11.578 49.542C12.9537 51.5621 14.6001 53.4006 16.4843 55.0143Z"
                      fill="#DFE7F4"
                    />
                    <path
                      d="M28.2912 19.1581C27.6048 18.4717 27.6048 17.3588 28.2912 16.6724C34.904 10.0596 32.8595 3.62064 32.8382 3.55631C32.5313 2.63538 33.0289 1.63993 33.9499 1.33292C34.8706 1.02579 35.8662 1.52369 36.1733 2.44461C36.2887 2.79123 38.9091 11.026 30.777 19.158C30.0907 19.8444 28.9776 19.8446 28.2912 19.1581Z"
                      fill="#8CD96B"
                    />
                    <path
                      d="M41.963 32.8295C42.6495 33.516 43.7623 33.516 44.4488 32.8295C51.0615 26.2168 57.5005 28.2613 57.5648 28.2826C58.4858 28.5895 59.4812 28.0918 59.7882 27.1709C60.0953 26.2502 59.5974 25.2545 58.6765 24.9475C58.3299 24.8321 50.0951 22.2117 41.9631 30.3438C41.2767 31.0301 41.2766 32.1432 41.963 32.8295Z"
                      fill="#5AB267"
                    />
                    <path
                      d="M59.0523 8.28205C57.5032 9.83118 54.74 8.18596 53.7733 8.59727C53.362 9.56752 55.0084 12.3271 53.4592 13.8751C51.9101 15.4242 49.1482 13.779 48.1802 14.1903C47.7678 15.1606 49.4153 17.919 47.8662 19.4681C46.3171 21.0173 43.5528 19.372 42.5872 19.7834C42.1747 20.7536 43.8223 23.512 42.2732 25.0612C40.6924 26.6419 37.8976 24.9252 36.9801 25.3823C36.5219 25.8334 35.8751 25.9834 35.2927 25.8275C35.0044 25.7514 34.7314 25.599 34.5052 25.3729C33.8186 24.6862 33.8186 23.573 34.5052 22.8863C35.847 21.5446 37.7254 21.7567 39.1116 22.0086C39.1562 22.0168 39.2007 22.025 39.244 22.0332C39.2358 21.9899 39.2276 21.9453 39.2194 21.9008C38.9687 20.5146 38.7554 18.6362 40.0983 17.2933C41.44 15.9515 43.3184 16.1636 44.7047 16.4156C44.7492 16.4238 44.7937 16.432 44.8371 16.4402C44.8289 16.3968 44.8207 16.3523 44.8125 16.3078C44.5617 14.9215 44.3484 13.0431 45.6913 11.7002C47.033 10.3585 48.9115 10.5706 50.2977 10.8225C50.3422 10.8307 50.3868 10.8389 50.4301 10.8471C50.4219 10.8038 50.4137 10.7593 50.4055 10.7147C50.1547 9.32847 49.9415 7.45007 51.2844 6.10717C52.6261 4.76545 54.5045 4.97755 55.8908 5.22949C55.9353 5.23769 55.9798 5.24589 56.0232 5.2541C56.015 5.21074 56.0068 5.16621 55.9986 5.12168C55.7478 3.73543 55.5345 1.85702 56.8774 0.514131C57.5629 -0.171377 58.6761 -0.171377 59.3628 0.514131C59.589 0.74029 59.7413 1.01332 59.8175 1.30276C59.9733 1.88398 59.8257 2.53081 59.3734 2.99016C58.921 3.89937 60.6178 6.7164 59.0523 8.28205Z"
                      fill="#FF7D43"
                    />
                    <path
                      d="M59.0526 8.28203C57.5034 9.83116 54.7403 8.18594 53.7736 8.59724C53.3623 9.5675 55.0087 12.3271 53.4595 13.8751C51.9104 15.4242 49.1484 13.779 48.1805 14.1903C47.768 15.1605 49.4156 17.919 47.8665 19.4681C46.3173 21.0172 43.553 19.372 42.5875 19.7833C42.175 20.7536 43.8226 23.512 42.2734 25.0612C40.6927 26.6419 37.8979 24.9252 36.9804 25.3822C36.5222 25.8334 35.8754 25.9834 35.293 25.8275L39.1119 22.0086C39.1564 22.0168 39.201 22.025 39.2443 22.0332C39.2361 21.9898 39.2279 21.9453 39.2197 21.9008L44.7049 16.4155C44.7495 16.4238 44.794 16.432 44.8374 16.4402C44.8291 16.3968 44.8209 16.3523 44.8127 16.3077L50.298 10.8225C50.3425 10.8307 50.387 10.8389 50.4304 10.8471C50.4222 10.8038 50.414 10.7592 50.4058 10.7147L55.891 5.22946C55.9356 5.23767 55.9801 5.24587 56.0234 5.25407C56.0152 5.21072 56.007 5.16619 55.9988 5.12166L59.8178 1.30273C59.9736 1.88395 59.826 2.53079 59.3736 2.99014C58.9213 3.89934 60.6181 6.71637 59.0526 8.28203Z"
                      fill="#E63950"
                    />
                    <path
                      d="M43.206 8.48808C44.1767 8.48808 44.9637 7.70113 44.9637 6.73037C44.9637 5.75961 44.1767 4.97266 43.206 4.97266C42.2352 4.97266 41.4482 5.75961 41.4482 6.73037C41.4482 7.70113 42.2352 8.48808 43.206 8.48808Z"
                      fill="#FFE470"
                    />
                    <path
                      d="M55.6342 20.9158C56.6049 20.9158 57.3919 20.1289 57.3919 19.1581C57.3919 18.1873 56.6049 17.4004 55.6342 17.4004C54.6634 17.4004 53.8765 18.1873 53.8765 19.1581C53.8765 20.1289 54.6634 20.9158 55.6342 20.9158Z"
                      fill="#FABE2C"
                    />
                    <path
                      d="M53.1483 38.3152C54.1191 38.3152 54.9061 37.5283 54.9061 36.5575C54.9061 35.5868 54.1191 34.7998 53.1483 34.7998C52.1776 34.7998 51.3906 35.5868 51.3906 36.5575C51.3906 37.5283 52.1776 38.3152 53.1483 38.3152Z"
                      fill="#FABE2C"
                    />
                    <path
                      d="M25.8051 10.9725C26.7758 10.9725 27.5628 10.1855 27.5628 9.21474C27.5628 8.24399 26.7758 7.45703 25.8051 7.45703C24.8343 7.45703 24.0474 8.24399 24.0474 9.21474C24.0474 10.1855 24.8343 10.9725 25.8051 10.9725Z"
                      fill="#FFE470"
                    />
                  </svg>

                  <h3>Thank You</h3>
                  <h1>We appreciate your reservation with us.</h1>
                </div>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export default NewReservation;
