import "./MobileScroll/MobileScroll.css";
import React from "react";

const TextCarousel = () => {
  return (
    <div className="carousel2">
      <h1>
        Connect with your <span>favourite </span>
        people, whenever you want{" "}
      </h1>
    </div>
  );
};

export default TextCarousel;
