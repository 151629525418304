import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import mobileImage1 from "../Images/MobileImage1.png";
import mobileImage2 from "../Images/MobileImage2.png";
import mobileImage3 from "../Images/MobileImage3.png";
import rectangle0 from "../compressed/carauselimg1.png"
import rectangle1 from "../compressed/carauselimg2.png"
import rectangle2 from "../compressed/carauselimg3.png"
import rectangle3 from "../compressed/carauselimg4.png"
import rectangle4 from "../compressed/carauselimg5.png"
import "../Styles/Carousel.css";
import { Link } from "react-router-dom";
import { FaArrowLeftLong } from "react-icons/fa6";
import { FaArrowRightLong } from "react-icons/fa6";

function IndividualIntervalsExample() {
  return (
    <>
      <Carousel
        className="carouselStyle"
        prevIcon={
          <div
            style={{
              padding: "1rem",
              borderRadius: "6.5rem",
              background: "rgba(0, 0, 0, 0.70)",
            }}
          >
            <FaArrowLeftLong
              style={{
                width: "1.5rem",
                color: "white",
                height: "1.5rem",
                
              }}
            />
          </div>
        }
        nextIcon={
          <div
          style={{
            padding: "1rem",
            borderRadius: "6.5rem",
            background: "rgba(0, 0, 0, 0.70)",
          }}
        >
          <FaArrowRightLong
            style={{
              width: "1.5rem",
              color: "white",
              height: "1.5rem",
              
            }}
          />
        </div>
        }
      >
         <Carousel.Item interval={1000} className="caruselItem">
          <img src={rectangle0} alt="First slide" className="imageStyle" />
        </Carousel.Item>
        <Carousel.Item interval={1000} className="caruselItem">
          <img src={rectangle1} alt="First slide" className="imageStyle" />
        </Carousel.Item>
        <Carousel.Item interval={1000} className="caruselItem">
          <img src={rectangle2} alt="Second slide" className="imageStyle" />
        </Carousel.Item>
        <Carousel.Item interval={1000} className="caruselItem">
          <img src={rectangle3} alt="Third slide" className="imageStyle" />
        </Carousel.Item>
        <Carousel.Item interval={1000} className="caruselItem">
          <img src={rectangle4} alt="Third slide" className="imageStyle" />
        </Carousel.Item>
      </Carousel>

      {/* *********Mobile Version******** */}

      <Carousel className="carouselStyle2">
        <Carousel.Item interval={1000} className="caruselItem2">
          <img src={mobileImage1} alt="First slide" className="imageStyle2" />
        </Carousel.Item>
        <Carousel.Item interval={1000} className="caruselItem2">
          <img src={mobileImage2} alt="Second slide" className="imageStyle2" />
        </Carousel.Item>
        <Carousel.Item interval={1000} className="caruselItem2">
          <img src={mobileImage3} alt="Third slide" className="imageStyle2" />
        </Carousel.Item>
      </Carousel>
      <Link to="/location">
        <button className="reserveNowCarousel">Reserve Now</button>
      </Link>
    </>
  );
}

export default IndividualIntervalsExample;
