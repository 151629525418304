import { React, useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import Reservation from "./Pages/Reservation";
import NotFound from "./Pages/NotFound";
import Outlet from "./Pages/Outlet";
import Redirector from "./Components/Redirector";
import Package from "./Pages/Package";
import Profile from "./Pages/Profile";
import Customer from "./Components/Customer";
import BookingHistory from "./Components/BookingHistory";
import RulesAndTime from "./Pages/RulesAndTime";
import Merchant from "./Components/Merchant";
import { PrismaneProvider } from "@prismane/core";
import Invitee from "./Components/Grouple";
// import IndividualInfo from "./Pages/IndividualInfo";
import ReservationPageIndividual from "./Pages/ReservationPageIndividual";
import CustomerIndividual from "./Components/CustomerIndividual";
import MerchantIndividual from "./Components/MerchantIndividual";
import InviteeIndividual from "./Components/GroupleIndividual";
import ValentineInfo from "./Components/Valentine/ValentineInfo";
import ValentineDate from "./Components/Valentine/ValentineDate";
import ValentineReservation from "./Components/Valentine/ValentineReservation";
import ValentineCustomer from "./Components/Valentine/ValentineIndividual";
import ValentineMerchant from "./Components/Valentine/ValentineMerchant";
import ValentineInvitee from "./Components/Valentine/ValentineInvitee";
import PopupMessage from "./Components/ScreenSize";
import { Provider } from "react-redux";
import { store } from "./Redux/store";
import Individualdate from "./Components/IndividualBooking/IndividualDate";
import IndividualFood from "./Components/IndividualBooking/IndividualFood";
import MobileDate from "./Components/MobileDate";
import SwitchOutlet from "./Components/SwitchOutlet";
import { OutletProvider } from "./Components/OutletContext";
import PersonalInfo from "./Components/PersonalInfo";
import MobileDateIndividual from "./Components/IndividualBooking/MobileDateIndividual";
import PersonalInfoIndividual from "./Components/IndividualBooking/PersonalInfoIndividual";
import ScrollToTop from "./Components/Scroll";
import { ThemeProvider } from "./Components/ColorContext";

function App({ children }) {
  const [gifPlayed, setGifPlayed] = useState(false);
  const [open, setOpen] = useState(window.innerWidth < 1024);

  useEffect(() => {
    const gifDuration = 3500;

    const timeout = setTimeout(() => {
      setGifPlayed(true);
    }, gifDuration);

    const handleResize = () => {
      setOpen(window.innerWidth < 1024);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Provider store={store}>
      <OutletProvider>
        <ThemeProvider>
        <PrismaneProvider>
          <div className="App">
            <Router>
            <ScrollToTop />
              {open && <PopupMessage />}
              <Routes>
                <Route path="/" element={<Redirector />} />
                <Route path="/landingPage/Janjaes" element={<HomePage />} />
                <Route path="/package/:outlet" element={<Outlet />} />
                <Route path="/FandB/janjaes" element={<Package />} />
                <Route path="/groupSize/janjaes" element={<RulesAndTime />} />
                <Route path="/datemobile" element={<MobileDate />} />
                <Route path="/groupSummary/janjaes" element={<Reservation />} />
                <Route path="/groupRes/janjaes" element={<PersonalInfo />} />
                <Route
                  path="/groupReservation/:type/:randomReservationID"
                  element={<Customer />}
                />
                <Route
                  path="/groupMerchant/:type/:randomReservationID"
                  element={<Merchant />}
                />
                <Route
                  path="/groupInvitee/:type/:randomReservationID"
                  element={<Invitee />}
                />
                <Route path="/bookingHistory" element={<BookingHistory />} />
                <Route path="/profile" element={<Profile />} />

                {/* Special Package */}
                <Route path="/valentineFandB" element={<ValentineInfo />} />
                <Route
                  path="/valentineDate/janjaes"
                  element={<ValentineDate />}
                />
                <Route
                  path="/valentineReservation/janjaes"
                  element={<ValentineReservation />}
                />
                <Route
                  path="/valentineReservation/:type/:randomReservationID"
                  element={<ValentineCustomer />}
                />
                <Route
                  path="/valentineMerchant/:type/:randomReservationID"
                  element={<ValentineMerchant />}
                />
                <Route
                  path="/valentineInvitee/:type/:randomReservationID"
                  element={<ValentineInvitee />}
                />

                {/* Individual */}
                <Route
                  path="/personalInfo/janjaes"
                  element={<Individualdate />}
                />

                <Route
                  path="/personalInfo/foodmenu"
                  element={<IndividualFood />}
                />

                <Route
                  path="/personalGuestSize"
                  element={<MobileDateIndividual />}
                />

                <Route
                  path="/PersonalRes/janjaes"
                  element={<PersonalInfoIndividual />}
                />

                <Route
                  path="/personalSummary/janjaes"
                  element={<ReservationPageIndividual />}
                />

                <Route
                  path="/personalReservation/:type/:randomReservationID"
                  element={<CustomerIndividual />}
                />
                <Route
                  path="/personalMerchant/:type/:randomReservationID"
                  element={<MerchantIndividual />}
                />
                <Route
                  path="/personalInvitee/:type/:randomReservationID"
                  element={<InviteeIndividual />}
                />

                <Route path="/*" element={<NotFound />} />

                <Route path="/location" element={<SwitchOutlet />} />
              </Routes>
            </Router>
          </div>
        </PrismaneProvider>
        </ThemeProvider>
      </OutletProvider>
    </Provider>
  );
}

export default App;
