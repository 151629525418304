import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "../Styles/DateModal.css";

const DateModal = ({
  isOpen,
  onClose,
  selectedDate,
  setSelectedDate,
  handleSaveDate,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const handleDateChange = (date) => {
    setSelectedDate(date);
    handleSaveDate();
  };

  const today = new Date();
  
  const customNextIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M9 18L15 12L9 6"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const customPrevIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="24"
      viewBox="0 0 44 24"
      fill="none"
    >
      <path
        d="M15 6L9 12L15 18"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  return ReactDOM.createPortal(
    <div className="modal-overlay2">
      <div className="modal-content2">
        <div className="pr-5 py-2 border-b border-gray-200 justify-between flex">
          <div className="w-11/12 text-center text-black text-xl font-normal font-['Gilroy-SemiBold']">
            Date
          </div>
          <div className="w-6 h-6 relative" onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M7 7L17 17"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M7 17L17 7"
                stroke="black"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="datePickerForMobile">
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            inline
            dateFormat="dd-MM-yyyy"
            minDate={today}
            nextMonthButtonLabel={customNextIcon}
            previousMonthButtonLabel={customPrevIcon}
          />
        </div>
      </div>
    </div>,
    document.body
  );
};

export default DateModal;
