import React, { useState, useEffect } from "react";
import "../Styles/Package.css";
import { MdOutlineArrowForwardIos } from "react-icons/md";

const Rules = () => {
  const [isReqPopupOpen, setIsReqPopupOpen] = useState(false);
  const [isRulePopupOpen, setIsRulePopupOpen] = useState(false);

  const openPopupReq = () => {
    setIsReqPopupOpen(true);
  };
  const openPopupRule = () => {
    setIsRulePopupOpen(true);
  };

  const closePopupReq = () => {
    setIsReqPopupOpen(false);
  };
  const closePopupRule = () => {
    setIsRulePopupOpen(false);
  };



  return (
    <>
      <div className="RulesForDesktop">
        <div className="things">
          <h2>Things to know</h2>

          <div className="outer-flex-div">
            <div className="requirements">
              <h2>Requirements</h2>
              <div className="div-1">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <path
                      d="M15.75 3H2.25C2.05109 3 1.86032 3.07902 1.71967 3.21967C1.57902 3.36032 1.5 3.55109 1.5 3.75V14.25C1.5 14.4489 1.57902 14.6397 1.71967 14.7803C1.86032 14.921 2.05109 15 2.25 15H15.75C15.9489 15 16.1397 14.921 16.2803 14.7803C16.421 14.6397 16.5 14.4489 16.5 14.25V3.75C16.5 3.55109 16.421 3.36032 16.2803 3.21967C16.1397 3.07902 15.9489 3 15.75 3Z"
                      stroke="#4A6AFE"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7.5 7.5C7.5 6.67157 6.82843 6 6 6V6C5.17157 6 4.5 6.67157 4.5 7.5V7.5C4.5 8.32843 5.17157 9 6 9V9C6.82843 9 7.5 8.32843 7.5 7.5V7.5Z"
                      stroke="#4A6AFE"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M4.5 12H13.5"
                      stroke="#4A6AFE"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.5 6H13.5"
                      stroke="#4A6AFE"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M10.5 9H13.5"
                      stroke="#4A6AFE"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>

                <div>
                  {" "}
                  You’ll need a photo that matches the one on your ID to confirm
                  who’s actually going on this experience.
                </div>
              </div>
              <div className="div-1">
                <div style={{marginLeft:"-0.35rem"}}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M8 21H16"
                      stroke="#4A6AFE"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M11.9998 15V21"
                      stroke="#4A6AFE"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17 3L18 10C18 13.012 15.314 15 12 15C8.686 15 6 13.012 6 10L7 3H17Z"
                      stroke="#4A6AFE"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M6 10C6.86548 9.35089 7.91815 9 9 9C10.0819 9 11.1345 9.35089 12 10C12.8655 10.6491 13.9181 11 15 11C16.0819 11 17.1345 10.6491 18 10"
                      stroke="#4A6AFE"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>

                <div>
                  Guest must be of legal drinking age to be served alcohol.
                </div>
              </div>

              <div className="div-1">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_1162_768)">
                      <path
                        d="M6.74316 4.488L11.9189 1.5L13.6499 4.4955L6.74316 4.488Z"
                        stroke="#4A6AFE"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M1.5 5.25C1.5 5.05109 1.57902 4.86032 1.71967 4.71967C1.86032 4.57902 2.05109 4.5 2.25 4.5H15.75C15.9489 4.5 16.1397 4.57902 16.2803 4.71967C16.421 4.86032 16.5 5.05109 16.5 5.25V15.75C16.5 15.9489 16.421 16.1397 16.2803 16.2803C16.1397 16.421 15.9489 16.5 15.75 16.5H2.25C2.05109 16.5 1.86032 16.421 1.71967 16.2803C1.57902 16.1397 1.5 15.9489 1.5 15.75V5.25Z"
                        stroke="#4A6AFE"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M13.2187 12.375H16.5V8.625H13.2187C12.1312 8.625 11.25 9.465 11.25 10.5C11.25 11.535 12.1312 12.375 13.2187 12.375Z"
                        stroke="#4A6AFE"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M16.5 6.1875V15.1875"
                        stroke="#4A6AFE"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1162_768">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>

                <div>
                  Confirmation will require 50% advance to confirm the venue at
                  least a month before the party and guest list is fine on same
                  day of party.
                </div>
              </div>
            </div>

            <div className="rules">
              <h2>Rules</h2>
              <div className="div-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11.999 22C17.499 22 21.999 17.5 21.999 12C21.999 6.5 17.499 2 11.999 2C6.49902 2 1.99902 6.5 1.99902 12C1.99902 17.5 6.49902 22 11.999 22Z"
                    fill="#4A6AFE"
                  />
                  <path
                    d="M7.75049 11.9999L10.5805 14.8299L16.2505 9.16992"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div>The charges will be based on Per Person basis.</div>
              </div>

              <div className="div-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11.999 22C17.499 22 21.999 17.5 21.999 12C21.999 6.5 17.499 2 11.999 2C6.49902 2 1.99902 6.5 1.99902 12C1.99902 17.5 6.49902 22 11.999 22Z"
                    fill="#4A6AFE"
                  />
                  <path
                    d="M7.75049 11.9999L10.5805 14.8299L16.2505 9.16992"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>

                <div>
                  Canned beverages like Diet coke, Tonic water, Package water
                  and Energy drinks will be charged on actual.
                </div>
              </div>

              <div className="div-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11.999 22C17.499 22 21.999 17.5 21.999 12C21.999 6.5 17.499 2 11.999 2C6.49902 2 1.99902 6.5 1.99902 12C1.99902 17.5 6.49902 22 11.999 22Z"
                    fill="#4A6AFE"
                  />
                  <path
                    d="M7.75049 11.9999L10.5805 14.8299L16.2505 9.16992"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div>Outside liquor is strictly prohibited.</div>
              </div>

              <div className="div-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M11.999 22C17.499 22 21.999 17.5 21.999 12C21.999 6.5 17.499 2 11.999 2C6.49902 2 1.99902 6.5 1.99902 12C1.99902 17.5 6.49902 22 11.999 22Z"
                    fill="#4A6AFE"
                  />
                  <path
                    d="M7.75049 11.9999L10.5805 14.8299L16.2505 9.16992"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <div>
                  Please note that some of the food may/ may not be suitable for
                  certain person(s). One must check the dish or read its
                  description (if provided) prior to placing any order. Barbaar
                  shall not be liable in the event food ordered by you does not
                  meet your dietary or any other requirements and restrictions.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="RulesForPhone">
        <div className="ReqPhone" onClick={openPopupReq}>
          <div >
            <h3>Requirement</h3>
            <p>
              You’ll need a photo that matches the one on your ID to confirm
              who’s actually going on this experience.
            </p>
          </div>
          <div className="arrow-icon" >
            <MdOutlineArrowForwardIos />
          </div>
        </div>
        <div className="RulePhone" onClick={openPopupRule}>
          <div>
            <h3>Rules</h3>
            <p>
            The charges will be based on Per Person basis.
            </p>
          </div>
          <div className="arrow-icon" >
            <MdOutlineArrowForwardIos />
          </div>
        </div>

        {isReqPopupOpen && (
          <div className="Req-popup-background-custom">
            <div className="Req-popup-custom">
              <button className="ReqPopupCloseBtn" onClick={closePopupReq}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7 7L17 17"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7 17L17 7"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>

              <div className="requirements">
                <h2>Requirements</h2>
                <div className="div-1">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <path
                        d="M15.75 3H2.25C2.05109 3 1.86032 3.07902 1.71967 3.21967C1.57902 3.36032 1.5 3.55109 1.5 3.75V14.25C1.5 14.4489 1.57902 14.6397 1.71967 14.7803C1.86032 14.921 2.05109 15 2.25 15H15.75C15.9489 15 16.1397 14.921 16.2803 14.7803C16.421 14.6397 16.5 14.4489 16.5 14.25V3.75C16.5 3.55109 16.421 3.36032 16.2803 3.21967C16.1397 3.07902 15.9489 3 15.75 3Z"
                        stroke="#4A6AFE"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.5 7.5C7.5 6.67157 6.82843 6 6 6V6C5.17157 6 4.5 6.67157 4.5 7.5V7.5C4.5 8.32843 5.17157 9 6 9V9C6.82843 9 7.5 8.32843 7.5 7.5V7.5Z"
                        stroke="#4A6AFE"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M4.5 12H13.5"
                        stroke="#4A6AFE"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.5 6H13.5"
                        stroke="#4A6AFE"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M10.5 9H13.5"
                        stroke="#4A6AFE"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>

                  <div>
                    {" "}
                    You’ll need a photo that matches the one on your ID to
                    confirm who’s actually going on this experience.
                  </div>
                </div>
                <div className="div-1">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M8 21H16"
                        stroke="#4A6AFE"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M11.9998 15V21"
                        stroke="#4A6AFE"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M17 3L18 10C18 13.012 15.314 15 12 15C8.686 15 6 13.012 6 10L7 3H17Z"
                        stroke="#4A6AFE"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M6 10C6.86548 9.35089 7.91815 9 9 9C10.0819 9 11.1345 9.35089 12 10C12.8655 10.6491 13.9181 11 15 11C16.0819 11 17.1345 10.6491 18 10"
                        stroke="#4A6AFE"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </div>

                  <div>
                    Guest must be of legal drinking age to be served alcohol.
                  </div>
                </div>

                <div className="div-1">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="18"
                      height="18"
                      viewBox="0 0 18 18"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_1162_768)">
                        <path
                          d="M6.74316 4.488L11.9189 1.5L13.6499 4.4955L6.74316 4.488Z"
                          stroke="#4A6AFE"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M1.5 5.25C1.5 5.05109 1.57902 4.86032 1.71967 4.71967C1.86032 4.57902 2.05109 4.5 2.25 4.5H15.75C15.9489 4.5 16.1397 4.57902 16.2803 4.71967C16.421 4.86032 16.5 5.05109 16.5 5.25V15.75C16.5 15.9489 16.421 16.1397 16.2803 16.2803C16.1397 16.421 15.9489 16.5 15.75 16.5H2.25C2.05109 16.5 1.86032 16.421 1.71967 16.2803C1.57902 16.1397 1.5 15.9489 1.5 15.75V5.25Z"
                          stroke="#4A6AFE"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M13.2187 12.375H16.5V8.625H13.2187C12.1312 8.625 11.25 9.465 11.25 10.5C11.25 11.535 12.1312 12.375 13.2187 12.375Z"
                          stroke="#4A6AFE"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M16.5 6.1875V15.1875"
                          stroke="#4A6AFE"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_1162_768">
                          <rect width="18" height="18" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>

                  <div>
                    Confirmation will require 50% advance to confirm the venue
                    at least a month before the party and guest list is fine on
                    same day of party.
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {isRulePopupOpen && (
          <div className="Req-popup-background-custom">
            <div className="Req-popup-custom">
              <button className="ReqPopupCloseBtn" onClick={closePopupRule}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M7 7L17 17"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7 17L17 7"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
              <div className="rules">
                <h2>Rules</h2>
                <div className="div-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11.999 22C17.499 22 21.999 17.5 21.999 12C21.999 6.5 17.499 2 11.999 2C6.49902 2 1.99902 6.5 1.99902 12C1.99902 17.5 6.49902 22 11.999 22Z"
                      fill="#4A6AFE"
                    />
                    <path
                      d="M7.75049 11.9999L10.5805 14.8299L16.2505 9.16992"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div>The charges will be based on Per Person basis.</div>
                </div>

                <div className="div-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11.999 22C17.499 22 21.999 17.5 21.999 12C21.999 6.5 17.499 2 11.999 2C6.49902 2 1.99902 6.5 1.99902 12C1.99902 17.5 6.49902 22 11.999 22Z"
                      fill="#4A6AFE"
                    />
                    <path
                      d="M7.75049 11.9999L10.5805 14.8299L16.2505 9.16992"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <div>
                    Canned beverages like Diet coke, Tonic water, Package water
                    and Energy drinks will be charged on actual.
                  </div>
                </div>

                <div className="div-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11.999 22C17.499 22 21.999 17.5 21.999 12C21.999 6.5 17.499 2 11.999 2C6.49902 2 1.99902 6.5 1.99902 12C1.99902 17.5 6.49902 22 11.999 22Z"
                      fill="#4A6AFE"
                    />
                    <path
                      d="M7.75049 11.9999L10.5805 14.8299L16.2505 9.16992"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div>Outside liquor is strictly prohibited.</div>
                </div>

                <div className="div-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M11.999 22C17.499 22 21.999 17.5 21.999 12C21.999 6.5 17.499 2 11.999 2C6.49902 2 1.99902 6.5 1.99902 12C1.99902 17.5 6.49902 22 11.999 22Z"
                      fill="#4A6AFE"
                    />
                    <path
                      d="M7.75049 11.9999L10.5805 14.8299L16.2505 9.16992"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <div>
                    Please note that some of the food may/ may not be suitable
                    for certain person(s). One must check the dish or read its
                    description (if provided) prior to placing any order.
                    Barbaar shall not be liable in the event food ordered by you
                    does not meet your dietary or any other requirements and
                    restrictions.
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Rules;
