import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./ValentineDate.css";
import { Disclosure } from "@headlessui/react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { FormControl, TextField } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import Rules from "../../Components/Rules";
import { Navbar2 } from "../../Components/NavbarTwo";
import Footer from "../../Components/Footer";

const ValentineDate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const {
    category,
    duration,
    initialPrice,
    initialDiscountedPrice,
    pax,
    packageName,
    selectedPackage,
    Wines,
    selectedItems,
  } = location.state;

  const foodOption = [
    { id: 1, name: "Appetizer" },
    { id: 2, name: "Main Course" },
    { id: 3, name: "Dessert" },
  ];

  const beverageOptions = [{ id: 1, name: "Wines" }];

  const renderFoodItems = (categoryName) => {
    let renderItems = [];
    switch (categoryName) {
      case "Appetizer":
        renderItems = selectedItems.appetizers;
        break;
      case "Main Course":
        renderItems = selectedItems.mainCourses;
        break;
      case "Dessert":
        renderItems = selectedItems.desserts;
        break;
      case "Wines":
        renderItems = Wines;
        break;
      default:
        break;
    }
    return renderItems.map((item, index) => (
      <div key={index} className="selected-food3">
        <div className="ItemDetailLogo3">
          {item.category === "Vegetarian" ? (
            <div className="veg-div">
              <svg
                width="13"
                height="13"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="15"
                  height="15"
                  rx="1.5"
                  stroke="#009600"
                />
                <circle cx="8" cy="8" r="4" fill="#009600" />
              </svg>
            </div>
          ) : item.category === "Non Vegetarian" ? (
            <div className="nonveg-div">
              <svg
                width="13"
                height="13"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="15"
                  height="15"
                  rx="1.5"
                  stroke="#963415"
                />
                <path
                  d="M4.38833 12C4.20992 12 4.09388 11.8122 4.17367 11.6527L7.78534 4.42932C7.87378 4.25243 8.12622 4.25243 8.21466 4.42933L11.8263 11.6527C11.9061 11.8122 11.7901 12 11.6117 12H4.38833Z"
                  fill="#963415"
                />
              </svg>
            </div>
          ) : item.category === "Wines" ? (
            <div className="veg-div">
              <svg
                width="13"
                height="13"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="15"
                  height="15"
                  rx="1.5"
                  stroke="#009600"
                />
                <circle cx="8" cy="8" r="4" fill="#009600" />
              </svg>
            </div>
          ) : null}
        </div>
        <div className="ItemDetailName3">{item.item}</div>
      </div>
    ));
  };

  const renderBeverageItems = (options) => {
    return options.map((option) => {
      return (
        <div key={option.id} className="w-full">
          <div className="w-full rounded-2xl bg-white pb-2 pt-2">
            <Disclosure defaultOpen={option.name === "Wines"}>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className="flex w-full justify-between rounded-lg"
                    style={{
                      background: "white",
                      padding: "0.75rem 0.5rem",
                      borderRadius: open ? " 0.5rem 0.5rem 0 0" : " 0.5rem",
                      border: "1px solid #E5E5EA",
                      borderBottom: open ? "none" : "1px solid #E5E5EA",
                      boxShadow: "0px 4px 12px 0px rgba(34, 34, 34, 0.06)",
                    }}
                  >
                    <span
                      style={{
                        fontWeight: "400",
                        paddingLeft: "0.5rem",
                      }}
                    >
                      {option.name}
                    </span>
                    <ChevronDownIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-black-700`}
                    />
                  </Disclosure.Button>
                  <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                    <div className="selected-food-list2">
                      {renderFoodItems(option.name)}
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
          </div>
        </div>
      );
    });
  };

  let svgPath = "";
  let allCapsCategory = "";
  switch (category) {
    case "Valentine":
      allCapsCategory = "VALENTINE SPECIAL";
      break;
    default:
      allCapsCategory = category;
  }

  // *************** Time Slot *************
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [displayedTimeSlot, setDisplayedTimeSlot] =
    useState("Select a time slot");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleSelectTimeSlot = (timeSlot) => {
    setSelectedTimeSlot(timeSlot);
    setDisplayedTimeSlot(formatTimeTo12hr(timeSlot));
    setIsDropdownOpen(false);
  };

  const formatTimeTo12hr = (time) => {
    let [hours, minutes] = time.split(":");
    let period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${period}`;
  };

  // ************SLIDER LOGIC*******
  const [selectedPeople, setSelectedPeople] = useState(2);
  const [price, setPrice] = useState(initialPrice);
  const [discountedPrice, setDiscountedPrice] = useState();
  const totalCost = price * selectedPeople;
  const handleInputChange = (value) => {
    if (!isNaN(value) && value >= 1 && value <= 180) {
      setSelectedPeople(value);
    } else {
      const clampedValue = value >= 1 ? Math.min(Math.max(value, 10), 180) : 0;
      setSelectedPeople(clampedValue);
    }
  };
  const handleInputNumberChange = (event) => {
    const value = parseInt(event.target.value, 10);
    handleInputChange(value);
  };
  const handleSliderChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setSelectedPeople(value);
  };
  // /**** Validation ******* */
  const [validationErrors, setValidationErrors] = useState({
    timeSlot: "",
    selectedPeople: "",
  });

  const clearTimeSlotError = () => {
    setValidationErrors((prevErrors) => ({ ...prevErrors, timeSlot: "" }));
  };
  const clearSelectedPeopleError = () => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      selectedPeople: "",
    }));
  };
  const validateForm = () => {
    let isValid = true;
    const errors = {
      timeSlot: "",
      selectedPeople: "",
    };

    if (!selectedTimeSlot) {
      errors.timeSlot = "Please select a time slot";
      isValid = false;
    }
    if (selectedPeople <= 0 || selectedPeople > 180) {
      errors.selectedPeople = "Please select a valid number of guests";
      isValid = false;
    }
    setValidationErrors(errors);
    return isValid;
  };
  useEffect(() => {
    const storedReservationData = sessionStorage.getItem("reservationData");
    if (storedReservationData) {
      const parsedReservationData = JSON.parse(storedReservationData);

      setSelectedTimeSlot(parsedReservationData.timeSlot);
      setPrice(parsedReservationData.price);
      setDiscountedPrice(parsedReservationData.discountedPrice);
      setSelectedPeople(parsedReservationData.selectedPeople);
    }
  }, []);

  const handleReserve = () => {
    if (!validateForm()) {
      return;
    }

    const valentineReservationData = {
      date: "2024-02-14",
      timeSlot: selectedTimeSlot,
      price,
      totalCost: totalCost / 2,
      category,
      initialPrice,
      selectedPeople,
      packageName,
      duration,
      selectedPackage,
      displayDate: "14 February 2024",
      selectedItems,
      Wines,
    };

    setSelectedTimeSlot(null);
    setSelectedPeople(pax);
    setPrice(initialPrice);
    setDiscountedPrice(initialDiscountedPrice);
    navigate("/valentineReservation/barbaar", {
      state: { valentineReservationData },
    });
  };
  let borderColor;
  switch (category) {
    case "Regular":
      borderColor = "#9E9E9E";
      break;
    case "Premium":
      borderColor = "#D0A86A";
      break;
    case "Exclusive":
      borderColor = "#8A9AD1";
      break;
    case "Ultra Exclusive":
      borderColor = "#B37E7D";
      break;
    case "Valentine":
      borderColor = "#B51A3A";
      break;
    default:
      borderColor = "#767676";
      break;
  }
  return (
    <>
      <Navbar2 />
      <div className="PackageInfoMain2">
        {/* **** Left Side Content ***** */}
        <div className="PackageInfoInnerLeft2">
          <div className="packageLeftOne2">
            <div className="packageNameDiv2">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M11.082 2.33301H2.91536C2.76065 2.33301 2.61228 2.38217 2.50289 2.46969C2.39349 2.55721 2.33203 2.67591 2.33203 2.79967V11.1997C2.33203 11.3234 2.39349 11.4421 2.50289 11.5297C2.61228 11.6172 2.76065 11.6663 2.91536 11.6663H11.082C11.2367 11.6663 11.3851 11.6172 11.4945 11.5297C11.6039 11.4421 11.6654 11.3234 11.6654 11.1997V2.79967C11.6654 2.67591 11.6039 2.55721 11.4945 2.46969C11.3851 2.38217 11.2367 2.33301 11.082 2.33301Z"
                    fill="#4A6AFE"
                  />
                  <path
                    d="M5.83203 2.33301H8.16536V6.99967L6.9987 5.83301L5.83203 6.99967V2.33301Z"
                    fill="#DBE1FF"
                  />
                </svg>
                {allCapsCategory}
              </div>
            </div>
            <h1> Valentine's Day Package</h1>
            <p>International</p>
            <div className="inside-nonAlcoholic">
              <div
                style={{
                  fontSize: "1.1rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "end",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 15 15"
                  fill="none"
                >
                  <path
                    d="M11 6C11 8.76 8.76 11 6 11C3.24 11 1 8.76 1 6C1 3.24 3.24 1 6 1C8.76 1 11 3.24 11 6Z"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.85494 7.58988L6.30494 6.66488C6.03494 6.50488 5.81494 6.11988 5.81494 5.80488V3.75488"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {duration} hours
              </div>
              <div
                style={{
                  fontSize: "1.1rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "end",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2"
                  height="2"
                  viewBox="0 0 2 2"
                  fill="none"
                >
                  <circle cx="1" cy="1" r="1" fill="#8E8E93" />
                </svg>
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 15 15"
                  fill="none"
                >
                  <path
                    d="M4.57979 5.435C4.52979 5.43 4.46979 5.43 4.41479 5.435C3.22479 5.395 2.27979 4.42 2.27979 3.22C2.27979 1.995 3.26979 1 4.49979 1C5.72479 1 6.71978 1.995 6.71978 3.22C6.71478 4.42 5.76979 5.395 4.57979 5.435Z"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.20514 2C9.17514 2 9.95514 2.785 9.95514 3.75C9.95514 4.695 9.20514 5.465 8.27014 5.5C8.23014 5.495 8.18514 5.495 8.14014 5.5"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.07986 7.28C0.869863 8.09 0.869863 9.41 2.07986 10.215C3.45486 11.135 5.70986 11.135 7.08486 10.215C8.29486 9.405 8.29486 8.085 7.08486 7.28C5.71486 6.365 3.45986 6.365 2.07986 7.28Z"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.16992 10C9.52992 9.925 9.86992 9.78 10.1499 9.565C10.9299 8.98 10.9299 8.015 10.1499 7.43C9.87492 7.22 9.53992 7.08 9.18492 7"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {pax} pax min
              </div>
            </div>
          </div>
          <div className="PackageInDetailWrapper">
            <h1 className="PackageDeatailsHeader">What you will experience</h1>
            <div className="PackageInDeatilChild">
              <div className="PackageInDetailChildHeading">
                💖 Romantic Dinner with glass of sparkling for two
              </div>
              <div className="PackageInDetailChildPara">
                Indulge in a delectable three-course gourmet dinner carefully
                curated by our renowned chefs. Each dish is crafted with love
                and passion, promising a culinary journey that will tantalize
                your taste buds.
              </div>
            </div>
            <div className="PackageInDeatilChildTwo">
              <div className="PackageDeatailsHeaderTwo">
                🍷 GLASS OF RED WINE FOR TWO
              </div>
              <div className="PackageDeatailsHeaderTwo">
                🍝 APPETIZER{" "}
                <span>(Spicy avocado maki roll/Chicken dumpling)</span>
              </div>
              <div className="PackageDeatailsHeaderTwo">
                🥘 MAIN COURSE 
                <span>(Grilled chicken pink sauce pasta/Penne Pasta Mix)</span>
              </div>
              <div className="PackageDeatailsHeaderTwo">
                🍨 DESSERTS <span>(Red Velvet Cake Mix berry compote)</span>
              </div>
            </div>
            <div className="PackageInDeatilChild">
              <div className="PackageInDetailChildHeading">
                💕 Private Table for Two:
              </div>
              <div className="PackageInDetailChildPara">
                Indulge in a delectable three-course gourmet dinner carefully
                curated by our renowned chefs. Each dish is crafted with love
                and passion, promising a culinary journey that will tantalize
                your taste buds.
              </div>
            </div>
            <div className="PackageInDeatilChild">
              <div className="PackageInDetailChildHeading">
                🕯️ Soft Candlelight Ambiance:
              </div>
              <div className="PackageInDetailChildPara">
                Your evening begins at a beautifully adorned private table, set
                exclusively for you and your partner. Enjoy the luxury of
                undivided attention in a cozy and intimate space.
              </div>
            </div>
            <div className="PackageInDeatilChild">
              <div className="PackageInDetailChildHeading">
                🌹 Floral Elegance:
              </div>
              <div className="PackageInDetailChildPara">
                Adorned with fresh flowers and elegant table settings, your
                table will be a masterpiece of romantic aesthetics. The floral
                arrangements will add a touch of natural beauty to your dining
                experience.
              </div>
            </div>
            <div className="PackageInDeatilChild">
              <div className="PackageInDetailChildHeading">
                🎶 Live Band Performance:
              </div>
              <div className="PackageInDetailChildPara">
                Immerse yourself in the soulful tunes of our live music
                performance by Malang Band, creating the perfect backdrop for an
                evening filled with love. Let the melodies enhance the romantic
                atmosphere as you and your partner share meaningful moments.
              </div>
            </div>
            <div className="PackageInDeatilChild">
              <div className="PackageInDetailChildHeading">
                📸 Memorable Photo Session:
              </div>
              <div className="PackageInDetailChildPara">
                Capture the magic of the evening with a complimentary photo
                session. Professional photographers will be available to
                immortalize your special moments, ensuring you have tangible
                memories to cherish forever.
              </div>
            </div>
          </div>
          <Rules />
        </div>

        {/* **** Right Side Content **** */}
        <div
          className="PackageInfoInnerRight"
          style={{ borderTop: `8px solid ${borderColor}` }}
        >
          <h1>Book your Reservation</h1>
          {/* /*** Date div *****  */}
          <div className="date-div">
            <h2>Pick a date</h2>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormControl fullWidth>
                <DatePicker
                  value={dayjs("2024-02-14")}
                  readOnly
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      InputProps={{
                        style: { color: "black" },
                      }}
                    />
                  )}
                  format="DD-MM-YYYY"
                />
              </FormControl>
            </LocalizationProvider>
          </div>
          {/* *** Time div ***  */}
          <div
            className="slot-div"
            onClick={clearTimeSlotError}
            style={{ position: "relative" }}
          >
            <h2>Pick a slot</h2>
            <Disclosure>
              {({ open }) => (
                <>
                  <Disclosure.Button
                    className="flex w-full justify-between rounded-lg"
                    style={{
                      background: "white",
                      padding: "1rem 0.7rem",
                      borderRadius: "0.2rem",
                      marginBottom: "0.5rem",
                      border: "1px solid #c4c4c4",
                      cursor: "pointer",
                    }}
                    onClick={() => setIsDropdownOpen(true)}
                  >
                    <span className="time-slot-header2">
                      {displayedTimeSlot}
                    </span>
                    <ChevronDownIcon
                      className={`${
                        isDropdownOpen ? "rotate-180 transform" : ""
                      } h-5 w-5 text-black-700`}
                    />
                  </Disclosure.Button>

                  {isDropdownOpen && (
                    <Disclosure.Panel className="pb-2 w-full">
                      <div className="selected-timeSlot2">
                        <div
                          onClick={() => {
                            handleSelectTimeSlot("19:00");
                            setIsDropdownOpen(false);
                          }}
                          className={
                            selectedTimeSlot === "19:00" ? "selected" : ""
                          }
                        >
                          7:00 PM
                        </div>
                        <div
                          onClick={() => {
                            handleSelectTimeSlot("19:30");
                          }}
                          className={`${
                            selectedTimeSlot === "19:30" ? "selected" : ""
                          }`}
                        >
                          7:30 PM
                        </div>
                        <div
                          onClick={() => {
                            handleSelectTimeSlot("20:00");
                          }}
                          className={`${
                            selectedTimeSlot === "20:00" ? "selected" : ""
                          }`}
                        >
                          8:00 PM
                        </div>
                        <div
                          onClick={() => {
                            handleSelectTimeSlot("20:30");
                          }}
                          className={`${
                            selectedTimeSlot === "20:30" ? "selected" : ""
                          }`}
                        >
                          8:30 PM
                        </div>
                        <div
                          onClick={() => {
                            handleSelectTimeSlot("21:00");
                          }}
                          className={`${
                            selectedTimeSlot === "21:00" ? "selected" : ""
                          }`}
                        >
                          9:00 PM
                        </div>
                        <div
                          onClick={() => {
                            handleSelectTimeSlot("21:30");
                          }}
                          className={`${
                            selectedTimeSlot === "21:30" ? "selected" : ""
                          }`}
                        >
                          9:30 PM
                        </div>
                        <div
                          onClick={() => {
                            handleSelectTimeSlot("22:00");
                          }}
                          className={`${
                            selectedTimeSlot === "22:00" ? "selected" : ""
                          }`}
                        >
                          10:00 PM
                        </div>
                        <div
                          onClick={() => {
                            handleSelectTimeSlot("22:30");
                          }}
                          className={`${
                            selectedTimeSlot === "22:30" ? "selected" : ""
                          }`}
                        >
                          10:30 PM
                        </div>
                        <div
                          onClick={() => {
                            handleSelectTimeSlot("23:00");
                          }}
                          className={`${
                            selectedTimeSlot === "23:00" ? "selected" : ""
                          }`}
                        >
                          11:00 PM
                        </div>
                        <div
                          onClick={() => {
                            handleSelectTimeSlot("23:30");
                          }}
                          className={`${
                            selectedTimeSlot === "23:30" ? "selected" : ""
                          }`}
                        >
                          11:30 PM
                        </div>
                        <div
                          onClick={() => {
                            handleSelectTimeSlot("00:00");
                          }}
                          className={`${
                            selectedTimeSlot === "00:00" ? "selected" : ""
                          }`}
                        >
                          12:00 AM
                        </div>
                        <div
                          onClick={() => {
                            handleSelectTimeSlot("00:30");
                          }}
                          className={`${
                            selectedTimeSlot === "00:30" ? "selected" : ""
                          }`}
                        >
                          12:30 AM
                        </div>
                        <div
                          onClick={() => {
                            handleSelectTimeSlot("01:00");
                          }}
                          className={`${
                            selectedTimeSlot === "01:00" ? "selected" : ""
                          }`}
                        >
                          01:00 AM
                        </div>
                        <div
                          onClick={() => {
                            handleSelectTimeSlot("01:30");
                          }}
                          className={`${
                            selectedTimeSlot === "01:30" ? "selected" : ""
                          }`}
                        >
                          01:30 AM
                        </div>
                        <div
                          onClick={() => {
                            handleSelectTimeSlot("02:00");
                          }}
                          className={`${
                            selectedTimeSlot === "02:00" ? "selected" : ""
                          }`}
                        >
                          02:00 AM
                        </div>
                      </div>
                    </Disclosure.Panel>
                  )}
                </>
              )}
            </Disclosure>
            <p style={{ color: "red" }}>{validationErrors.timeSlot}</p>
          </div>
          {/* *** slider div ****  */}
          <div className="guest-div" onClick={clearSelectedPeopleError}>
            <h2>No. of Guest</h2>
            <input
              placeholder="0"
              type="number"
              min="10"
              max="180"
              value={selectedPeople > 0 ? selectedPeople : ""}
              className="selectedPeopleInput"
              onChange={handleInputNumberChange}
              disabled={!selectedTimeSlot}
              style={{ cursor: selectedTimeSlot ? "pointer" : "not-allowed" }}
            />
            <div
              className="slider-wrapper"
              style={{ border: "none", padding: "0rem" }}
            >
              <div
                className="slider-markers"
                style={{ border: "none", padding: "0rem" }}
              >
                <div className="slider-marker" style={{ left: "1%" }}>
                  2
                </div>
                <div className="slider-marker" style={{ left: "25.3%" }}>
                  4
                </div>
                <div className="slider-marker" style={{ left: "49.7%" }}>
                  6
                </div>
                <div className="slider-marker" style={{ left: "73.7%" }}>
                  8
                </div>
                <div className="slider-marker" style={{ left: "98%" }}>
                  10
                </div>
              </div>
              <input
                type="range"
                min="2"
                max="10"
                step="2"
                value={selectedPeople}
                onChange={handleSliderChange}
                disabled={!selectedTimeSlot}
                className="slider-input"
                style={{ cursor: selectedTimeSlot ? "pointer" : "not-allowed" }}
              />
            </div>
            <p style={{ color: "red" }}>{validationErrors.selectedPeople}</p>
            <div
              className="priceParentDiv"
              style={{ border: "none", padding: "0rem" }}
            >
              <div>
                <p className="ActualPrice">AED{price}</p>
                <p className="personXpax">
                  × <span>{selectedPeople} Guests</span>
                </p>
              </div>
              <p className="TotalPrice">AED{totalCost / 2}</p>
            </div>
            <div
              style={{
                border: "none",
                padding: "0rem 0rem 1rem 0rem",
                width: "100%",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="1"
                viewBox="0 0 380 1"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M1.58333 1H0V0H1.58333V1ZM7.91667 1H4.75V0H7.91667V1ZM14.25 1H11.0833V0H14.25V1ZM20.5833 1H17.4167V0H20.5833V1ZM26.9167 1H23.75V0H26.9167V1ZM33.25 1H30.0833V0H33.25V1ZM39.5833 1H36.4167V0H39.5833V1ZM45.9167 1H42.75V0H45.9167V1ZM52.25 1H49.0833V0H52.25V1ZM58.5833 1H55.4167V0H58.5833V1ZM64.9167 1H61.75V0H64.9167V1ZM71.25 1H68.0833V0H71.25V1ZM77.5833 1H74.4167V0H77.5833V1ZM83.9167 1H80.75V0H83.9167V1ZM90.25 1H87.0833V0H90.25V1ZM96.5833 1H93.4167V0H96.5833V1ZM102.917 1H99.75V0H102.917V1ZM109.25 1H106.083V0H109.25V1ZM115.583 1H112.417V0H115.583V1ZM121.917 1H118.75V0H121.917V1ZM128.25 1H125.083V0H128.25V1ZM134.583 1H131.417V0H134.583V1ZM140.917 1H137.75V0H140.917V1ZM147.25 1H144.083V0H147.25V1ZM153.583 1H150.417V0H153.583V1ZM159.917 1H156.75V0H159.917V1ZM166.25 1H163.083V0H166.25V1ZM172.583 1H169.417V0H172.583V1ZM178.917 1H175.75V0H178.917V1ZM185.25 1H182.083V0H185.25V1ZM191.583 1H188.417V0H191.583V1ZM197.917 1H194.75V0H197.917V1ZM204.25 1H201.083V0H204.25V1ZM210.583 1H207.417V0H210.583V1ZM216.917 1H213.75V0H216.917V1ZM223.25 1H220.083V0H223.25V1ZM229.583 1H226.417V0H229.583V1ZM235.917 1H232.75V0H235.917V1ZM242.25 1H239.083V0H242.25V1ZM248.583 1H245.417V0H248.583V1ZM254.917 1H251.75V0H254.917V1ZM261.25 1H258.083V0H261.25V1ZM267.583 1H264.417V0H267.583V1ZM273.917 1H270.75V0H273.917V1ZM280.25 1H277.083V0H280.25V1ZM286.583 1H283.417V0H286.583V1ZM292.917 1H289.75V0H292.917V1ZM299.25 1H296.083V0H299.25V1ZM305.583 1H302.417V0H305.583V1ZM311.917 1H308.75V0H311.917V1ZM318.25 1H315.083V0H318.25V1ZM324.583 1H321.417V0H324.583V1ZM330.917 1H327.75V0H330.917V1ZM337.25 1H334.083V0H337.25V1ZM343.583 1H340.417V0H343.583V1ZM349.917 1H346.75V0H349.917V1ZM356.25 1H353.083V0H356.25V1ZM362.583 1H359.417V0H362.583V1ZM368.917 1H365.75V0H368.917V1ZM375.25 1H372.083V0H375.25V1ZM380 1H378.417V0H380V1Z"
                  fill="#D8D8DC"
                />
              </svg>
            </div>
            <div
              className="total-cost-parent"
              style={{ border: "none", padding: "0rem" }}
            >
              <p className="total-cost-text">
                Total <span>(excl.VAT)</span>
              </p>
              <p className="total-cost"> AED {totalCost / 2}</p>
            </div>
          </div>
          {/* *** reserve button *** */}
          <button
            className="ReserveButton"
            onClick={handleReserve}
            disabled={!selectedPeople}
            style={{ cursor: !selectedPeople ? "not-allowed" : "pointer" }}
          >
            Reserve
          </button>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default ValentineDate;
