import React, { useState, useEffect } from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

function PopupMessage() {
  const [open, setOpen] = useState(window.innerWidth < 1024 && window.innerWidth > 640);

  useEffect(() => {
    const handleResize = () => {
      setOpen(window.innerWidth < 1024 && window.innerWidth > 640);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (window.innerWidth < 1024 && window.innerWidth > 640) {
      setOpen(true);
    } else {
      setOpen(false);
    }
  }, [window.innerWidth]);


  return (
    open && (
      <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 flex items-center justify-center z-10"
        onClose={() => {}}
        static={true}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-100 transition-opacity" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enterTo="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100 translate-y-0 sm:scale-100"
          leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div className="relative overflow-hidden rounded-lg bg-white p-6 mx-auto mt-10 max-w-xs text-center shadow-xxl">
            <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100">
              <ExclamationTriangleIcon className="h-7 w-7 text-red-600" aria-hidden="true" />
            </div>
            <div className="mt-3">
              <p className="text-sm text-gray-600">
              " 
              Our platform is currently being optimized for mobile and tablet screens.
               In the meantime, we recommend using a desktop screen for an improved user experience.
                Thank you for your understanding and co-operation."
              </p>
            </div>
          </div>
        </Transition.Child>
      </Dialog>
    </Transition.Root>
    )
  );
}

export default PopupMessage;
