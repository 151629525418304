import React, { useEffect, useState } from "react";
import "../Styles/Package.css";
import { Navbar2 } from "../Components/NavbarTwo";
import Footer from "../Components/Footer";
import PackageInfo from "../Components/PackageInfo";
import Rules from "../Components/Rules";

const Package = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Navbar2 />
      <PackageInfo />
      {/* <Rules /> */}
      {/* {windowWidth >= 1024 && <Footer />} */}
    </>
  );
};

export default Package;
