import { ADD_ITEM, EDIT_ITEM, REMOVE_ITEM } from "./action";
import header from "../Images/Image.png";
import VegDumplingImg from "../Images/VegDumpling.png";
import KandaBhajiya from "../foodImages/kandaBhajiya.png"
import friedIdli from "../foodImages/babyFriedidli.png"
import chaknabox from "../foodImages/CakhnaBox.png"
import crispycorn from "../foodImages/Crispy corn bhel.png"
import ChickenGheeRoastImg from "../Images/ChickenGheeRoast.png";
import ChilliPaneerImg from "../Images/ChilliPaneer.png";
import CrispyCornImg from "../Images/CrispyCorn.png";
import AsparagusChickenImg from "../Images/AsparagusChicken.png";
import GarlicButterPrawnsImg from "../Images/GarlicButterPrawns.png";
import FishTikkaImg from "../Images/FishTikka.png";
import SweetPotatoFries from "../Images/SweetPotatoFries.png";
import PeriPeriChickenImg from "../Images/PeriPeriChicken.png";
import BBQChickenImg from "../Images/BBQ Chiken.png";
import WokFriedVegsNoodleImg from "../Images/Wok Fried Vegetables Noodles.png";
import WokFriedSichuanImg from "../Images/Wok Fried Sichuan Chicken.png";
import ChickenTikkaMasalaImg from "../Images/Chicken Tikka Masala.png";
import VegRisottoImg from "../Images/Vegetable Risotto.png";
import KaraageImg from "../Images/Karaage Fried Chicken Sliders.png";
import KeemaPavImg from "../Images/Keema Pav.png";
import PenneWPrawnsImg from "../Images/Penne With Prawns.png";
import VegFriedRiceImg from "../Images/Vegetable Fried rice.png";
import ChilliChickenImg from "../Images/Chilli Chicken.png";
import GulabJamunImg from "../Images/Gulab Jamun.png";
import CheeseCakeImg from "../Images/Cheese Cake.png";
import MixedFruitImg from "../Images/Mix Fruit Platter.png";
import PapadImg from "../Images/Papad.png";
import SaladImg from "../Images/Salad.png";
import PickelImg from "../Images/Pickel.png";
import SteamedRiceImg from "../Images/Steamed Rice.png";
import BreadImg from "../Images/Indian Breadbasket.png";
import CoffeeImg from "../Images/Coffee.png";
import SoftDrinksImg from "../Images/Soft Drinks.png";
import TetraPackImg from "../Images/Tetra Packet Juice.png";
import WaterImg from "../Images/Water.png";
import HeinekenImg from "../Images/Heineken.png";
import BudwiserImg from "../Images/Budweiser.png";
import HoegaardenImg from "../Images/Hoegaarden.png";
import CoronaExtraImg from "../Images/Corona Extra.png";
import DraughtBeer from "../Images/Draught Beer.png";
import RoseWineImg from "../Images/Rose Wine.png";
import WhiteWineImg from "../Images/White Wine.png";
import RedWineImg from "../Images/Red Wine.png";
import GlenfiddichImg from "../Images/Glenfiddich 12 yrs.png";
import Blacklabel from "../Images/Black Label.png";
import Chivas from "../Images/Chivas.png";
import redlabel from "../Images/Red Label.png";
import bullfrog from "../Images/bullfrog.png";
import tequillasunrise from "../Images/tequillasunrise.png";
import mojito from "../Images/mojito.png";
import whiskeysour from "../Images/whiskey sour.png";
import cosmo from "../Images/cosmo.png";
import gimlet from "../Images/gimlet.png";
import morgan from "../Images/Morgan.png";
import havana from "../Images/havana.png";
import darkrum from "../Images/darkrum.png";
import bacardi from "../Images/bacardi.png";
import monkey from "../Images/monkey47.png";
import ginmare from "../Images/ginmare.png";
import hendriks from "../Images/hendriks.png";
import Tanqueray from "../Images/tanquerey.png";
import crystal from "../Images/crystalhead.png";
import grey from "../Images/greygoose.png";
import beluga from "../Images/beluga.png";
import stolich from "../Images/stolich.png";
import patron from "../Images/patron.png";
import jose from "../Images/jose.png";

import vadapao from "../foodImages/DadarVadaPav.png"
import behlpuri from "../foodImages/juhubeachBhel.png"
import cheesechillytoast from "../foodImages/CheeseChilliToast.png"
import papad from "../foodImages/AplamChaplamPapadum.png"
import chineesebhel from "../foodImages/ChieneseBhel.png"
import shakarkand from "../foodImages/Shakarkand ki chaat.png"
import rajkachori from '../foodImages/Raj Kachori.png'
import crispycorn2 from "../foodImages/Crispy corn bhel.png"
import seafoodbhel from "../foodImages/Seafood bhel.png"
import chandnichowk from "../foodImages/Chandni chowk aloo chaat.png"
import chickencutlet from "../foodImages/Chicken cutlet.png"
import keemasamosa from "../foodImages/Bohri kheema samosa.png"
import kejriwal from "../foodImages/Egg kejriwal.png"
import chickenfry from "../foodImages/Bar baar chicken fry.png"
import chickentikka from "../foodImages/Peri peri chicekn tikka.png"
import Muttonburrah from "../foodImages/Mutton burrah.png" 
import peshawari from "../foodImages/Peshawari chapli kebab.png"
import baidaroti from "../foodImages/Chicken baida roti.png"
import bohrikeemapav from "../foodImages/Bohri Kheema pav.png"
import chooza69 from "../foodImages/Chooza 69.png"
import nadenchicken from "../foodImages/Naden chicken fry.png"
import Fauladipahadikebab from "../foodImages/Fauladi pahadi kebab.png"
import bhendi from "../foodImages/Bhendi bazaar seekh paratha.png"
import prawn from "../foodImages/Prawn kolbi fry.png"
import pratap from "../foodImages/Chef Pratap’s ghar ki bhendi.png"
import parsi from "../foodImages/Mushroom patio with PAV.png"
import dalmakhani from "../foodImages/Daal Makhani with butter naan.png"
import baap from "../foodImages/Baap ki dal with jeera rice.png"
import paneerlababdar from "../foodImages/Paneer lababdar with butter garlic naan.png"
import rajasthani from "../foodImages/Rajasthani curry pakora bowl with basmati rice.png"
import khichdi from "../foodImages/Dal Khichdi.png"
import parleG from "../foodImages/Parle G cheese cake.png"
import gajjar from "../foodImages/Gajjar halwa with kulfi ice cream.png"
import custard from "../foodImages/Super caramel custard.png"
import fruit from "../foodImages/Fruit platter.png"
import murghMussallam from "../foodImages/Murgh Mussallam 2.0.png"
import salli from "../foodImages/Salli chicken.png"

// const initialState = Object.freeze({
//   Premium: {
//     "Food menu": [],
//     Appetizer: [
//       {
//         id: 1,
//         item: "Vegetable Dumpling",
//         category: "Vegetarian",
//         description:
//           "A cozy Platter of Dumplings Cream Cheese & Mushroom, Truffle Edamame, Corn Basil Water Chestnut",
//         foodimg: VegDumplingImg,
//       },
//       {
//         id: 2,
//         item: "Peri Peri Chicken",
//         category: "Non Vegetarian",
//         description:
//           "Fiery chicken tikka cooked in tandoor coated with our signature peri peri cream sauce",
//         foodimg: PeriPeriChickenImg,
//       },
//       {
//         id: 3,
//         item: "Asparagus Lemon Grass Chicken",
//         category: "Non Vegetarian",
//         description:
//           "Succulent grilled chicken paired with vibrant asparagus & blend of flavors",
//         foodimg: AsparagusChickenImg,
//       },
//       {
//         id: 4,
//         item: "Chicken Ghee Roast",
//         category: "Non Vegetarian",
//         description:
//           "Succulent chicken, fiery spices, and rich ghee create a tantalizing culinary delight",
//         foodimg: ChickenGheeRoastImg,
//       },
//       {
//         id: 5,
//         item: "Fish Tikka",
//         category: "Non Vegetarian",
//         description:
//           "Fish Tikka Marinated In A Lebanese Spice Mix, Served With Green Chutney And Onions",
//         foodimg: FishTikkaImg,
//       },
//       {
//         id: 6,
//         item: "Garlic Butter Prawns",
//         category: "Non Vegetarian",
//         description:
//           "Richly seasoned prawns bathed in savory garlic butter perfection.",
//         foodimg: GarlicButterPrawnsImg,
//       },
//       {
//         id: 7,
//         item: "Chili Paneer",
//         category: "Vegetarian",
//         description:
//           "Zesty paneer cubes with fiery chili-infused sauce, a tantalizing blend of spice and flavor.",
//         foodimg: ChilliPaneerImg,
//       },
//       {
//         id: 8,
//         item: "Crispy Corn",
//         category: "Vegetarian",
//         description:
//           "Golden-brown, crunchy corn bites, seasoned to perfection for a delightful snack experience",
//         foodimg: CrispyCornImg,
//       },
//       {
//         id: 9,
//         item: "Sweet Potato Fries",
//         category: "Vegetarian",
//         description:
//           "Irresistibly crisp, golden sweet potato fries, seasoned to perfection for a delightful flavor experience.",
//         foodimg: SweetPotatoFries,
//       },
//     ],
//     MainCourse: [
//       {
//         id: 10,
//         item: "Wok Fried Vegetables Noodles",
//         category: "Vegetarian",
//         description:
//           "Bursting with vibrant stir-fried veggies, our noodles are a symphony of flavors and textures.",
//         foodimg: WokFriedVegsNoodleImg,
//       },
//       {
//         id: 11,
//         item: "Wok Fried Sichuan Chicken",
//         category: "Non Vegetarian",
//         description:
//           "Fiery Sichuan stir-fry ignites your palate with bold flavors, leaving you craving more spice.",
//         foodimg: WokFriedSichuanImg,
//       },
//       {
//         id: 12,
//         item: "Penne with Prawns",
//         category: "Non Vegetarian",
//         description:
//           "Al dente penne embraces succulent prawns in a symphony of garlic, herbs, and olive oil.",
//         foodimg: PenneWPrawnsImg,
//       },
//       {
//         id: 13,
//         item: "BBQ Chicken",
//         category: "Non Vegetarian",
//         description:
//           "Char-grilled perfection, our BBQ chicken boasts smoky richness and a caramelized exterior",
//         foodimg: BBQChickenImg,
//       },
//       {
//         id: 14,
//         item: "Chicken Tikka Masala",
//         category: "Non Vegetarian",
//         description:
//           "Tender, spice-kissed chicken dances in a velvety tomato masala, a curry lover's dream.",
//         foodimg: ChickenTikkaMasalaImg,
//       },
//       {
//         id: 15,
//         item: "Vegetable Fried Rice",
//         category: "Vegetarian",
//         description:
//           "Fragrant rice embraces a medley of fresh veggies, wok-tossed to perfection, simplicity elevated.",
//         foodimg: VegFriedRiceImg,
//       },
//       {
//         id: 16,
//         item: "Vegetable Risotto",
//         category: "Vegetarian",
//         description:
//           "Creamy risotto unveils a garden of vegetables, marrying comfort and refined flavors harmoniously.",
//         foodimg: VegRisottoImg,
//       },
//       {
//         id: 17,
//         item: "Karaage Fried Chicken Sliders",
//         category: "Non Vegetarian",
//         description:
//           "fried chicken sliders, Japanese fried chicken sandwich, karaage chicken.",
//         foodimg: KaraageImg,
//       },
//       {
//         id: 18,
//         item: "Chili Chicken",
//         category: "Non Vegetarian",
//         description:
//           "Zesty, stir-fried chicken, drenched in chili-infused glaze, promises a fiery flavor explosion.",
//         foodimg: ChilliChickenImg,
//       },
//       {
//         id: 19,
//         item: "Keema Pav",
//         category: "Non Vegetarian",
//         description:
//           "Savory minced meat, aromatic spices, and fluffy pao buns create a hearty, satisfying delight.",
//         foodimg: KeemaPavImg,
//       },
//     ],
//     Dessert: [
//       {
//         id: 20,
//         item: "Gulab Jamun",
//         category: "Vegetarian",
//         description:
//           "Fried dumplings made of dried milk & dipped in rose cardamom flavoured sugar syrup.",
//         foodimg: GulabJamunImg,
//       },
//       {
//         id: 21,
//         item: "Cheese Cake",
//         category: "Vegetarian",
//         description: "Cheesecake Finished With A Sweet Blueberry Topping",
//         foodimg: CheeseCakeImg,
//       },
//       {
//         id: 22,
//         item: "Mix Fruit Platter (Seasonal)",
//         category: "Vegetarian",
//         description:
//           "Enjoy a medley of seasonal fruits, a refreshing plate that captures the essence of nature's bounty.",
//         foodimg: MixedFruitImg,
//       },
//     ],
//     Sides: [
//       {
//         id: 23,
//         item: "Indian Breadbasket",
//         category: "Vegetarian",
//         description:
//           "Assorted Indian bread, a delightful mix of rotis, naans, and parathas, offering diverse flavors",
//         foodimg: BreadImg,
//       },
//       {
//         id: 24,
//         item: "Steamed Rice",
//         category: "Vegetarian",
//         description: "Basmati rice",
//         foodimg: SteamedRiceImg,
//       },
//       {
//         id: 25,
//         item: "Papad",
//         category: "Vegetarian",
//         description:
//           "Crunchy, thin papad - a crispy appetizer, a versatile accompaniment to complement Indian cuisine",
//         foodimg: PapadImg,
//       },
//       {
//         id: 26,
//         item: "Pickle",
//         category: "Vegetarian",
//         description:
//           "Zesty, tangy pickle bursting with spice, a traditional Indian condiment, elevating every meal",
//         foodimg: PickelImg,
//       },
//       {
//         id: 27,
//         item: "Salad",
//         category: "Vegetarian",
//         description: "Freshly sliced green vegetable salad.",
//         foodimg: SaladImg,
//       },
//     ],
//     "Beverage menu": [],
//     BottleBeer: [
//       {
//         id: 35,
//         item: "Budweiser",
//         category: "bottleBeers",
//         description: "330ml Budweiser Bottle Beer",
//         foodimg: BudwiserImg,
//       },
//     ],
//     Wine: [
//       {
//         id: 33,
//         item: "Red Wine",
//         category: "Wines",
//         description:
//           "Bold and robust, a velvety blend of dark fruits and subtle oak undertones",
//         foodimg: RedWineImg,
//       },
//       {
//         id: 34,
//         item: "White Wine",
//         category: "Wines",
//         description:
//           "Delicate and crisp, a symphony of citrus and floral notes in each pour",
//         foodimg: WhiteWineImg,
//       },
//     ],
//     Spirits: {
//       Whiskey: [
//         {
//           id: 88,
//           item: "JW Red Label",
//           category: "Whiskey",
//           description:
//             "Bold and iconic, Red Label whiskey offers a rich, smooth taste with a hint of smokiness",
//           foodimg: redlabel,
//         },
//       ],
//       Rum: [
//         {
//           id: 86,
//           item: "Bacardi",
//           category: "Rum",
//           description:
//             "Offers a subtle sweetness and refreshing character, ideal for mixing vibrant cocktails",
//           foodimg: bacardi,
//         },
//       ],
//       Gin: [
//         {
//           id: 87,
//           item: "Tanqueray",
//           category: "Gin",
//           description:
//             "Crisp and juniper-forward, Tanqueray gin boasts a well-balanced botanical profile",
//           foodimg: Tanqueray,
//         },
//       ],
//       Vodka: [
//         {
//           id: 99,
//           item: "Stolichnaya",
//           category: "Vodka",
//           description:
//             "Delivers a clean, smooth flavor, perfect for crafting versatile cocktails",
//           foodimg: stolich,
//         },
//       ],
//     },
//     NonAlcohol: [
//       {
//         id: 28,
//         item: "Soft Drinks",
//         category: "non-alcoholic",
//         description: "150ml",
//         foodimg: SoftDrinksImg,
//       },
//       {
//         id: 29,
//         item: "Tetra Packet Juice",
//         category: "non-alcoholic",
//         description: "210ml Fresh Juice",
//         foodimg: TetraPackImg,
//       },
//       {
//         id: 30,
//         item: "Tea/Coffee",
//         category: "non-alcoholic",
//         description:
//           "A robust brew awakening your senses, delivering rich flavor in every sip",
//         foodimg: CoffeeImg,
//       },
//       {
//         id: 31,
//         item: "Water",
//         category: "non-alcoholic",
//         description: "1 L fresh watert",
//         foodimg: WaterImg,
//       },
//     ],
//   },
//   Regular: {
//     "Food menu": [],
//     Appetizer: [
//       {
//         id: 1,
//         item: "Vegetable Dumpling",
//         category: "Vegetarian",
//         description:
//           "A cozy Platter of Dumplings Cream Cheese & Mushroom, Truffle Edamame, Corn Basil Water Chestnut",
//         foodimg: VegDumplingImg,
//       },
//       {
//         id: 2,
//         item: "Peri Peri Chicken",
//         category: "Non Vegetarian",
//         description:
//           "Fiery chicken tikka cooked in tandoor coated with our signature peri peri cream sauce",
//         foodimg: PeriPeriChickenImg,
//       },
//       {
//         id: 3,
//         item: "Asparagus Lemon Grass Chicken",
//         category: "Non Vegetarian",
//         description:
//           "Succulent grilled chicken paired with vibrant asparagus & blend of flavors",
//         foodimg: AsparagusChickenImg,
//       },
//       {
//         id: 4,
//         item: "Chicken Ghee Roast",
//         category: "Non Vegetarian",
//         description:
//           "Succulent chicken, fiery spices, and rich ghee create a tantalizing culinary delight",
//         foodimg: ChickenGheeRoastImg,
//       },
//       {
//         id: 5,
//         item: "Fish Tikka",
//         category: "Non Vegetarian",
//         description:
//           "Fish Tikka Marinated In A Lebanese Spice Mix, Served With Green Chutney And Onions",
//         foodimg: FishTikkaImg,
//       },
//       {
//         id: 6,
//         item: "Garlic Butter Prawns",
//         category: "Non Vegetarian",
//         description:
//           "Richly seasoned prawns bathed in savory garlic butter perfection.",
//         foodimg: GarlicButterPrawnsImg,
//       },
//       {
//         id: 7,
//         item: "Chili Paneer",
//         category: "Vegetarian",
//         description:
//           "Zesty paneer cubes with fiery chili-infused sauce, a tantalizing blend of spice and flavor.",
//         foodimg: ChilliPaneerImg,
//       },
//       {
//         id: 8,
//         item: "Crispy Corn",
//         category: "Vegetarian",
//         description:
//           "Golden-brown, crunchy corn bites, seasoned to perfection for a delightful snack experience",
//         foodimg: CrispyCornImg,
//       },
//       {
//         id: 9,
//         item: "Sweet Potato Fries",
//         category: "Vegetarian",
//         description:
//           "Irresistibly crisp, golden sweet potato fries, seasoned to perfection for a delightful flavor experience.",
//         foodimg: SweetPotatoFries,
//       },
//     ],
//     MainCourse: [
//       {
//         id: 10,
//         item: "Wok Fried Vegetables Noodles",
//         category: "Vegetarian",
//         description:
//           "Bursting with vibrant stir-fried veggies, our noodles are a symphony of flavors and textures.",
//         foodimg: WokFriedVegsNoodleImg,
//       },
//       {
//         id: 11,
//         item: "Wok Fried Sichuan Chicken",
//         category: "Non Vegetarian",
//         description:
//           "Fiery Sichuan stir-fry ignites your palate with bold flavors, leaving you craving more spice.",
//         foodimg: WokFriedSichuanImg,
//       },
//       {
//         id: 12,
//         item: "Penne with Prawns",
//         category: "Non Vegetarian",
//         description:
//           "Al dente penne embraces succulent prawns in a symphony of garlic, herbs, and olive oil.",
//         foodimg: PenneWPrawnsImg,
//       },
//       {
//         id: 13,
//         item: "BBQ Chicken",
//         category: "Non Vegetarian",
//         description:
//           "Char-grilled perfection, our BBQ chicken boasts smoky richness and a caramelized exterior",
//         foodimg: BBQChickenImg,
//       },
//       {
//         id: 14,
//         item: "Chicken Tikka Masala",
//         category: "Non Vegetarian",
//         description:
//           "Tender, spice-kissed chicken dances in a velvety tomato masala, a curry lover's dream.",
//         foodimg: ChickenTikkaMasalaImg,
//       },
//       {
//         id: 15,
//         item: "Vegetable Fried Rice",
//         category: "Vegetarian",
//         description:
//           "Fragrant rice embraces a medley of fresh veggies, wok-tossed to perfection, simplicity elevated.",
//         foodimg: VegFriedRiceImg,
//       },
//       {
//         id: 16,
//         item: "Vegetable Risotto",
//         category: "Vegetarian",
//         description:
//           "Creamy risotto unveils a garden of vegetables, marrying comfort and refined flavors harmoniously.",
//         foodimg: VegRisottoImg,
//       },
//       {
//         id: 17,
//         item: "Karaage Fried Chicken Sliders",
//         category: "Non Vegetarian",
//         description:
//           "fried chicken sliders, Japanese fried chicken sandwich, karaage chicken.",
//         foodimg: KaraageImg,
//       },
//       {
//         id: 18,
//         item: "Chili Chicken",
//         category: "Non Vegetarian",
//         description:
//           "Zesty, stir-fried chicken, drenched in chili-infused glaze, promises a fiery flavor explosion.",
//         foodimg: ChilliChickenImg,
//       },
//       {
//         id: 19,
//         item: "Keema Pav",
//         category: "Non Vegetarian",
//         description:
//           "Savory minced meat, aromatic spices, and fluffy pao buns create a hearty, satisfying delight.",
//         foodimg: KeemaPavImg,
//       },
//     ],
//     Dessert: [
//       {
//         id: 20,
//         item: "Gulab Jamun",
//         category: "Vegetarian",
//         description:
//           "Fried dumplings made of dried milk & dipped in rose cardamom flavoured sugar syrup.",
//         foodimg: GulabJamunImg,
//       },
//       {
//         id: 21,
//         item: "Cheese Cake",
//         category: "Vegetarian",
//         description: "Cheesecake Finished With A Sweet Blueberry Topping",
//         foodimg: CheeseCakeImg,
//       },
//       {
//         id: 22,
//         item: "Mix Fruit Platter (Seasonal)",
//         category: "Vegetarian",
//         description:
//           "Enjoy a medley of seasonal fruits, a refreshing plate that captures the essence of nature's bounty.",
//         foodimg: MixedFruitImg,
//       },
//     ],
//     Sides: [
//       {
//         id: 23,
//         item: "Indian Breadbasket",
//         category: "Vegetarian",
//         description:
//           "Assorted Indian bread, a delightful mix of rotis, naans, and parathas, offering diverse flavors",
//         foodimg: BreadImg,
//       },
//       {
//         id: 24,
//         item: "Steamed Rice",
//         category: "Vegetarian",
//         description: "Basmati rice",
//         foodimg: SteamedRiceImg,
//       },
//       {
//         id: 25,
//         item: "Papad",
//         category: "Vegetarian",
//         description:
//           "Crunchy, thin papad - a crispy appetizer, a versatile accompaniment to complement Indian cuisine",
//         foodimg: PapadImg,
//       },
//       {
//         id: 26,
//         item: "Pickle",
//         category: "Vegetarian",
//         description:
//           "Zesty, tangy pickle bursting with spice, a traditional Indian condiment, elevating every meal",
//         foodimg: PickelImg,
//       },
//       {
//         id: 27,
//         item: "Salad",
//         category: "Vegetarian",
//         description: "Freshly sliced green vegetable salad.",
//         foodimg: SaladImg,
//       },
//     ],
//     "Beverage menu": [],
//     NonAlcohol: [
//       {
//         id: 28,
//         item: "Soft Drinks",
//         category: "non-alcoholic",
//         description: "150ml",
//         foodimg: SoftDrinksImg,
//       },
//       {
//         id: 29,
//         item: "Tetra Packet Juice",
//         category: "non-alcoholic",
//         description: "210ml Fresh Juice",
//         foodimg: TetraPackImg,
//       },
//       {
//         id: 30,
//         item: "Tea/Coffee",
//         category: "non-alcoholic",
//         description:
//           "A robust brew awakening your senses, delivering rich flavor in every sip",
//         foodimg: CoffeeImg,
//       },
//       {
//         id: 31,
//         item: "Water",
//         category: "non-alcoholic",
//         description: "1 L fresh watert",
//         foodimg: WaterImg,
//       },
//     ],
//   },
//   Exclusive: {
//     "Food menu": [],
//     Appetizer: [
//       {
//         id: 1,
//         item: "Vegetable Dumpling",
//         category: "Vegetarian",
//         description:
//           "A cozy Platter of Dumplings Cream Cheese & Mushroom, Truffle Edamame, Corn Basil Water Chestnut",
//         foodimg: VegDumplingImg,
//       },
//       {
//         id: 2,
//         item: "Peri Peri Chicken",
//         category: "Non Vegetarian",
//         description:
//           "Fiery chicken tikka cooked in tandoor coated with our signature peri peri cream sauce",
//         foodimg: PeriPeriChickenImg,
//       },
//       {
//         id: 3,
//         item: "Asparagus Lemon Grass Chicken",
//         category: "Non Vegetarian",
//         description:
//           "Succulent grilled chicken paired with vibrant asparagus & blend of flavors",
//         foodimg: AsparagusChickenImg,
//       },
//       {
//         id: 4,
//         item: "Chicken Ghee Roast",
//         category: "Non Vegetarian",
//         description:
//           "Succulent chicken, fiery spices, and rich ghee create a tantalizing culinary delight",
//         foodimg: ChickenGheeRoastImg,
//       },
//       {
//         id: 5,
//         item: "Fish Tikka",
//         category: "Non Vegetarian",
//         description:
//           "Fish Tikka Marinated In A Lebanese Spice Mix, Served With Green Chutney And Onions",
//         foodimg: FishTikkaImg,
//       },
//       {
//         id: 6,
//         item: "Garlic Butter Prawns",
//         category: "Non Vegetarian",
//         description:
//           "Richly seasoned prawns bathed in savory garlic butter perfection.",
//         foodimg: GarlicButterPrawnsImg,
//       },
//       {
//         id: 7,
//         item: "Chili Paneer",
//         category: "Vegetarian",
//         description:
//           "Zesty paneer cubes with fiery chili-infused sauce, a tantalizing blend of spice and flavor.",
//         foodimg: ChilliPaneerImg,
//       },
//       {
//         id: 8,
//         item: "Crispy Corn",
//         category: "Vegetarian",
//         description:
//           "Golden-brown, crunchy corn bites, seasoned to perfection for a delightful snack experience",
//         foodimg: CrispyCornImg,
//       },
//       {
//         id: 9,
//         item: "Sweet Potato Fries",
//         category: "Vegetarian",
//         description:
//           "Irresistibly crisp, golden sweet potato fries, seasoned to perfection for a delightful flavor experience.",
//         foodimg: SweetPotatoFries,
//       },
//     ],
//     MainCourse: [
//       {
//         id: 10,
//         item: "Wok Fried Vegetables Noodles",
//         category: "Vegetarian",
//         description:
//           "Bursting with vibrant stir-fried veggies, our noodles are a symphony of flavors and textures.",
//         foodimg: WokFriedVegsNoodleImg,
//       },
//       {
//         id: 11,
//         item: "Wok Fried Sichuan Chicken",
//         category: "Non Vegetarian",
//         description:
//           "Fiery Sichuan stir-fry ignites your palate with bold flavors, leaving you craving more spice.",
//         foodimg: WokFriedSichuanImg,
//       },
//       {
//         id: 12,
//         item: "Penne with Prawns",
//         category: "Non Vegetarian",
//         description:
//           "Al dente penne embraces succulent prawns in a symphony of garlic, herbs, and olive oil.",
//         foodimg: PenneWPrawnsImg,
//       },
//       {
//         id: 13,
//         item: "BBQ Chicken",
//         category: "Non Vegetarian",
//         description:
//           "Char-grilled perfection, our BBQ chicken boasts smoky richness and a caramelized exterior",
//         foodimg: BBQChickenImg,
//       },
//       {
//         id: 14,
//         item: "Chicken Tikka Masala",
//         category: "Non Vegetarian",
//         description:
//           "Tender, spice-kissed chicken dances in a velvety tomato masala, a curry lover's dream.",
//         foodimg: ChickenTikkaMasalaImg,
//       },
//       {
//         id: 15,
//         item: "Vegetable Fried Rice",
//         category: "Vegetarian",
//         description:
//           "Fragrant rice embraces a medley of fresh veggies, wok-tossed to perfection, simplicity elevated.",
//         foodimg: VegFriedRiceImg,
//       },
//       {
//         id: 16,
//         item: "Vegetable Risotto",
//         category: "Vegetarian",
//         description:
//           "Creamy risotto unveils a garden of vegetables, marrying comfort and refined flavors harmoniously.",
//         foodimg: VegRisottoImg,
//       },
//       {
//         id: 17,
//         item: "Karaage Fried Chicken Sliders",
//         category: "Non Vegetarian",
//         description:
//           "fried chicken sliders, Japanese fried chicken sandwich, karaage chicken.",
//         foodimg: KaraageImg,
//       },
//       {
//         id: 18,
//         item: "Chili Chicken",
//         category: "Non Vegetarian",
//         description:
//           "Zesty, stir-fried chicken, drenched in chili-infused glaze, promises a fiery flavor explosion.",
//         foodimg: ChilliChickenImg,
//       },
//       {
//         id: 19,
//         item: "Keema Pav",
//         category: "Non Vegetarian",
//         description:
//           "Savory minced meat, aromatic spices, and fluffy pao buns create a hearty, satisfying delight.",
//         foodimg: KeemaPavImg,
//       },
//     ],
//     Dessert: [
//       {
//         id: 20,
//         item: "Gulab Jamun",
//         category: "Vegetarian",
//         description:
//           "Fried dumplings made of dried milk & dipped in rose cardamom flavoured sugar syrup.",
//         foodimg: GulabJamunImg,
//       },
//       {
//         id: 21,
//         item: "Cheese Cake",
//         category: "Vegetarian",
//         description: "Cheesecake Finished With A Sweet Blueberry Topping",
//         foodimg: CheeseCakeImg,
//       },
//       {
//         id: 22,
//         item: "Mix Fruit Platter (Seasonal)",
//         category: "Vegetarian",
//         description:
//           "Enjoy a medley of seasonal fruits, a refreshing plate that captures the essence of nature's bounty.",
//         foodimg: MixedFruitImg,
//       },
//     ],
//     Sides: [
//       {
//         id: 23,
//         item: "Indian Breadbasket",
//         category: "Vegetarian",
//         description:
//           "Assorted Indian bread, a delightful mix of rotis, naans, and parathas, offering diverse flavors",
//         foodimg: BreadImg,
//       },
//       {
//         id: 24,
//         item: "Steamed Rice",
//         category: "Vegetarian",
//         description: "Basmati rice",
//         foodimg: SteamedRiceImg,
//       },
//       {
//         id: 25,
//         item: "Papad",
//         category: "Vegetarian",
//         description:
//           "Crunchy, thin papad - a crispy appetizer, a versatile accompaniment to complement Indian cuisine",
//         foodimg: PapadImg,
//       },
//       {
//         id: 26,
//         item: "Pickle",
//         category: "Vegetarian",
//         description:
//           "Zesty, tangy pickle bursting with spice, a traditional Indian condiment, elevating every meal",
//         foodimg: PickelImg,
//       },
//       {
//         id: 27,
//         item: "Salad",
//         category: "Vegetarian",
//         description: "Freshly sliced green vegetable salad.",
//         foodimg: SaladImg,
//       },
//     ],
//     "Beverage menu": [],
//     BottleBeer: [
//       {
//         id: 35,
//         item: "Budweiser",
//         category: "bottleBeers",
//         description: "330ml Budweiser Bottle Beer",
//         foodimg: BudwiserImg,
//       },
//       {
//         id: 36,
//         item: "Heineken",
//         category: "bottleBeers",
//         description:
//           "A renowned Dutch lager, balancing a mild bitterness with a smooth finish",
//         foodimg: HeinekenImg,
//       },
//       {
//         id: 37,
//         item: "Corona Extra",
//         category: "bottleBeers",
//         description: "750ml refreshing lager with a hint of citrus",
//         foodimg: CoronaExtraImg,
//       },
//     ],
//     Wine: [
//       {
//         id: 32,
//         item: "Rose Wine",
//         category: "Wines",
//         description: "750ml Grenache, Syrah, Cinsault",
//         foodimg: RoseWineImg,
//       },
//       {
//         id: 33,
//         item: "Red Wine",
//         category: "Wines",
//         description:
//           "Bold and robust, a velvety blend of dark fruits and subtle oak undertones",
//         foodimg: RedWineImg,
//       },
//       {
//         id: 34,
//         item: "White Wine",
//         category: "Wines",
//         description:
//           "Delicate and crisp, a symphony of citrus and floral notes in each pour",
//         foodimg: WhiteWineImg,
//       },
//     ],
//     Spirits: {
//       Whiskey: [
//         {
//           id: 47,
//           item: "JW Black Label",
//           category: "Whiskey",
//           description: "750ml Johnnie Walker Black Label Whisky",
//           foodimg: Blacklabel,
//         },
//         {
//           id: 47,
//           item: "Chivas Regal 12yrs",
//           category: "Whiskey",
//           description:
//             "A luxurious blend, balancing richness, smoothness, and a hint of smokiness",
//           foodimg: Chivas,
//         },
//       ],
//       Rum: [
//         {
//           id: 48,
//           item: "Capt Morgan Spiced gold",
//           category: "Rum",
//           description:
//             "Caribbean rum with a warm, spiced character and a touch of vanilla",
//           foodimg: morgan,
//         },
//         {
//           id: 99,
//           item: "Havana 07",
//           category: "Rum",
//           description: "750ml Grenache, Syrah, Cinsault",
//           foodimg: havana,
//         },
//         {
//           id: 85,
//           item: "Bacardi Dark Rum",
//           category: "Rum",
//           description:
//             "375ml Bacardi Dark Rum tropical fruit & molasses, with a smoky finish.",
//           foodimg: darkrum,
//         },
//       ],
//       Gin: [
//         {
//           id: 49,
//           item: "Monkey 47",
//           category: "Gin",
//           description:
//             "German gin, an intricate mix of botanicals, delivering a harmonious and exotic flavor",
//           foodimg: monkey,
//         },
//         {
//           id: 84,
//           item: "Gin Mare",
//           category: "Gin",
//           description: "700ml herbal and aromatic palate.",
//           foodimg: ginmare,
//         },
//         {
//           id: 88,
//           item: "Hendriks",
//           category: "Gin",
//           description:
//             "A Scottish gin, infused with cucumber and rose petals, offering a unique floral twist",
//           foodimg: hendriks,
//         },
//       ],
//       Vodka: [
//         {
//           id: 50,
//           item: "Crystal Head",
//           category: "Vodka",
//           description:
//             "Pure vodka, crafted with crystal clarity, providing a clean and smooth drinking experience",
//           foodimg: crystal,
//         },
//         {
//           id: 92,
//           item: "Grey Goose",
//           category: "Vodka",
//           description:
//             "750ml Fresh & bright throughout, sweet & creamy with a richness of almond.",
//           foodimg: grey,
//         },
//         {
//           id: 95,
//           item: "Beluga",
//           category: "Vodka",
//           description:
//             "Russian vodka, distinguished by its velvety texture and subtle hints of honey.",
//           foodimg: beluga,
//         },
//       ],
//       Tequila: [
//         {
//           id: 51,
//           item: "Patron Silver",
//           category: "Tequila",
//           description: "Almost earthy nose with soft spices and zesty citrus",
//           foodimg: patron,
//         },
//         {
//           id: 59,
//           item: "Jose Cuervo",
//           category: "Tequila",
//           description:
//             "750ml Black pepper, caramel, finishing off with toffee and Vanilla.",
//           foodimg: jose,
//         },
//       ],
//     },

//     Cocktail: [
//       {
//         id: 41,
//         item: "Tequila Sunrise",
//         category: "Cocktails",
//         description:
//           "A sunrise in a glass, fusing tequila, orange juice, and a grenadine sunrise—a burst of vibrant citrus hues",
//         foodimg: tequillasunrise,
//       },
//       {
//         id: 42,
//         item: "Mojito",
//         category: "Cocktails",
//         description:
//           "Refreshing Cuban classic, marrying lime zest, mint, and white rum, a symphony of citrus and herbal bliss",
//         foodimg: mojito,
//       },
//       {
//         id: 43,
//         item: "Whisky Sour",
//         category: "Cocktails",
//         description:
//           "Timeless and tangy, uniting bourbon, fresh lemon juice, and a touch of sweetness—a zesty, spirited classic",
//         foodimg: whiskeysour,
//       },
//       {
//         id: 44,
//         item: "Cosmopolitan",
//         category: "Cocktails",
//         description:
//           "Elegant and sophisticated, blending vodka, triple sec, cranberry, and lime—a cosmopolitan symphony in a glass",
//         foodimg: cosmo,
//       },
//       {
//         id: 45,
//         item: "Gimlet",
//         category: "Cocktails",
//         description: "Gin, simple syrup, lime juice",
//         foodimg: gimlet,
//       },
//     ],
//     NonAlcohol: [
//       {
//         id: 28,
//         item: "Soft Drinks",
//         category: "non-alcoholic",
//         description: "150ml",
//         foodimg: SoftDrinksImg,
//       },
//       {
//         id: 29,
//         item: "Tetra Packet Juice",
//         category: "non-alcoholic",
//         description: "210ml Fresh Juice",
//         foodimg: TetraPackImg,
//       },
//       {
//         id: 30,
//         item: "Tea/Coffee",
//         category: "non-alcoholic",
//         description:
//           "A robust brew awakening your senses, delivering rich flavor in every sip",
//         foodimg: CoffeeImg,
//       },
//       {
//         id: 31,
//         item: "Water",
//         category: "non-alcoholic",
//         description: "1 L fresh watert",
//         foodimg: WaterImg,
//       },
//     ],
//   },
//   "Ultra Exclusive": {
//     "Food menu": [],
//     Appetizer: [
//       {
//         id: 1,
//         item: "Vegetable Dumpling",
//         category: "Vegetarian",
//         description:
//           "A cozy Platter of Dumplings Cream Cheese & Mushroom, Truffle Edamame, Corn Basil Water Chestnut",
//         foodimg: VegDumplingImg,
//       },
//       {
//         id: 2,
//         item: "Peri Peri Chicken",
//         category: "Non Vegetarian",
//         description:
//           "Fiery chicken tikka cooked in tandoor coated with our signature peri peri cream sauce",
//         foodimg: PeriPeriChickenImg,
//       },
//       {
//         id: 3,
//         item: "Asparagus Lemon Grass Chicken",
//         category: "Non Vegetarian",
//         description:
//           "Succulent grilled chicken paired with vibrant asparagus & blend of flavors",
//         foodimg: AsparagusChickenImg,
//       },
//       {
//         id: 4,
//         item: "Chicken Ghee Roast",
//         category: "Non Vegetarian",
//         description:
//           "Succulent chicken, fiery spices, and rich ghee create a tantalizing culinary delight",
//         foodimg: ChickenGheeRoastImg,
//       },
//       {
//         id: 5,
//         item: "Fish Tikka",
//         category: "Non Vegetarian",
//         description:
//           "Fish Tikka Marinated In A Lebanese Spice Mix, Served With Green Chutney And Onions",
//         foodimg: FishTikkaImg,
//       },
//       {
//         id: 6,
//         item: "Garlic Butter Prawns",
//         category: "Non Vegetarian",
//         description:
//           "Richly seasoned prawns bathed in savory garlic butter perfection.",
//         foodimg: GarlicButterPrawnsImg,
//       },
//       {
//         id: 7,
//         item: "Chili Paneer",
//         category: "Vegetarian",
//         description:
//           "Zesty paneer cubes with fiery chili-infused sauce, a tantalizing blend of spice and flavor.",
//         foodimg: ChilliPaneerImg,
//       },
//       {
//         id: 8,
//         item: "Crispy Corn",
//         category: "Vegetarian",
//         description:
//           "Golden-brown, crunchy corn bites, seasoned to perfection for a delightful snack experience",
//         foodimg: CrispyCornImg,
//       },
//       {
//         id: 9,
//         item: "Sweet Potato Fries",
//         category: "Vegetarian",
//         description:
//           "Irresistibly crisp, golden sweet potato fries, seasoned to perfection for a delightful flavor experience.",
//         foodimg: SweetPotatoFries,
//       },
//     ],
//     MainCourse: [
//       {
//         id: 10,
//         item: "Wok Fried Vegetables Noodles",
//         category: "Vegetarian",
//         description:
//           "Bursting with vibrant stir-fried veggies, our noodles are a symphony of flavors and textures.",
//         foodimg: WokFriedVegsNoodleImg,
//       },
//       {
//         id: 11,
//         item: "Wok Fried Sichuan Chicken",
//         category: "Non Vegetarian",
//         description:
//           "Fiery Sichuan stir-fry ignites your palate with bold flavors, leaving you craving more spice.",
//         foodimg: WokFriedSichuanImg,
//       },
//       {
//         id: 12,
//         item: "Penne with Prawns",
//         category: "Non Vegetarian",
//         description:
//           "Al dente penne embraces succulent prawns in a symphony of garlic, herbs, and olive oil.",
//         foodimg: PenneWPrawnsImg,
//       },
//       {
//         id: 13,
//         item: "BBQ Chicken",
//         category: "Non Vegetarian",
//         description:
//           "Char-grilled perfection, our BBQ chicken boasts smoky richness and a caramelized exterior",
//         foodimg: BBQChickenImg,
//       },
//       {
//         id: 14,
//         item: "Chicken Tikka Masala",
//         category: "Non Vegetarian",
//         description:
//           "Tender, spice-kissed chicken dances in a velvety tomato masala, a curry lover's dream.",
//         foodimg: ChickenTikkaMasalaImg,
//       },
//       {
//         id: 15,
//         item: "Vegetable Fried Rice",
//         category: "Vegetarian",
//         description:
//           "Fragrant rice embraces a medley of fresh veggies, wok-tossed to perfection, simplicity elevated.",
//         foodimg: VegFriedRiceImg,
//       },
//       {
//         id: 16,
//         item: "Vegetable Risotto",
//         category: "Vegetarian",
//         description:
//           "Creamy risotto unveils a garden of vegetables, marrying comfort and refined flavors harmoniously.",
//         foodimg: VegRisottoImg,
//       },
//       {
//         id: 17,
//         item: "Karaage Fried Chicken Sliders",
//         category: "Non Vegetarian",
//         description:
//           "fried chicken sliders, Japanese fried chicken sandwich, karaage chicken.",
//         foodimg: KaraageImg,
//       },
//       {
//         id: 18,
//         item: "Chili Chicken",
//         category: "Non Vegetarian",
//         description:
//           "Zesty, stir-fried chicken, drenched in chili-infused glaze, promises a fiery flavor explosion.",
//         foodimg: ChilliChickenImg,
//       },
//       {
//         id: 19,
//         item: "Keema Pav",
//         category: "Non Vegetarian",
//         description:
//           "Savory minced meat, aromatic spices, and fluffy pao buns create a hearty, satisfying delight.",
//         foodimg: KeemaPavImg,
//       },
//     ],
//     Dessert: [
//       {
//         id: 20,
//         item: "Gulab Jamun",
//         category: "Vegetarian",
//         description:
//           "Fried dumplings made of dried milk & dipped in rose cardamom flavoured sugar syrup.",
//         foodimg: GulabJamunImg,
//       },
//       {
//         id: 21,
//         item: "Cheese Cake",
//         category: "Vegetarian",
//         description: "Cheesecake Finished With A Sweet Blueberry Topping",
//         foodimg: CheeseCakeImg,
//       },
//       {
//         id: 22,
//         item: "Mix Fruit Platter (Seasonal)",
//         category: "Vegetarian",
//         description:
//           "Enjoy a medley of seasonal fruits, a refreshing plate that captures the essence of nature's bounty.",
//         foodimg: MixedFruitImg,
//       },
//     ],
//     Sides: [
//       {
//         id: 23,
//         item: "Indian Breadbasket",
//         category: "Vegetarian",
//         description:
//           "Assorted Indian bread, a delightful mix of rotis, naans, and parathas, offering diverse flavors",
//         foodimg: BreadImg,
//       },
//       {
//         id: 24,
//         item: "Steamed Rice",
//         category: "Vegetarian",
//         description: "Basmati rice",
//         foodimg: SteamedRiceImg,
//       },
//       {
//         id: 25,
//         item: "Papad",
//         category: "Vegetarian",
//         description:
//           "Crunchy, thin papad - a crispy appetizer, a versatile accompaniment to complement Indian cuisine",
//         foodimg: PapadImg,
//       },
//       {
//         id: 26,
//         item: "Pickle",
//         category: "Vegetarian",
//         description:
//           "Zesty, tangy pickle bursting with spice, a traditional Indian condiment, elevating every meal",
//         foodimg: PickelImg,
//       },
//       {
//         id: 27,
//         item: "Salad",
//         category: "Vegetarian",
//         description: "Freshly sliced green vegetable salad.",
//         foodimg: SaladImg,
//       },
//     ],
//     "Beverage menu": [],
//     BottleBeer: [
//       {
//         id: 35,
//         item: "Budweiser",
//         category: "bottleBeers",
//         description: "330ml Budweiser Bottle Beer",
//         foodimg: BudwiserImg,
//       },
//       {
//         id: 36,
//         item: "Heineken",
//         category: "bottleBeers",
//         description:
//           "A renowned Dutch lager, balancing a mild bitterness with a smooth finish",
//         foodimg: HeinekenImg,
//       },
//       {
//         id: 37,
//         item: "Corona Extra",
//         category: "bottleBeers",
//         description: "750ml refreshing lager with a hint of citrus",
//         foodimg: CoronaExtraImg,
//       },
//       {
//         id: 38,
//         item: "Hoegaarden",
//         category: "bottleBeers",
//         description:
//           "Belgian wheat beer, unfiltered and aromatic, boasting a distinctive citrusy and spicy profile",
//         foodimg: HoegaardenImg,
//       },
//       {
//         id: 39,
//         item: "Draught Beer",
//         category: "bottleBeers",
//         description:
//           "On tap, the embodiment of beer perfection, delivering freshness and smoothness",
//         foodimg: DraughtBeer,
//       },
//     ],
//     Wine: [
//       {
//         id: 32,
//         item: "Rose Wine",
//         category: "Wines",
//         description: "750ml Grenache, Syrah, Cinsault",
//         foodimg: RoseWineImg,
//       },
//       {
//         id: 33,
//         item: "Red Wine",
//         category: "Wines",
//         description:
//           "Bold and robust, a velvety blend of dark fruits and subtle oak undertones",
//         foodimg: RedWineImg,
//       },
//       {
//         id: 34,
//         item: "White Wine",
//         category: "Wines",
//         description:
//           "Delicate and crisp, a symphony of citrus and floral notes in each pour",
//         foodimg: WhiteWineImg,
//       },
//     ],
//     Spirits: {
//       SingleMoult: [
//         {
//           id: 46,
//           item: "Glenfiddich 12 yrs",
//           category: "SingleMoult",
//           description:
//             "A single moult Scotch whisky, with a distinctive pear and apple fragrance",
//           foodimg: GlenfiddichImg,
//         },
//       ],
//       Whiskey: [
//         {
//           id: 47,
//           item: "JW Black Label",
//           category: "Whiskey",
//           description: "750ml Johnnie Walker Black Label Whisky",
//           foodimg: Blacklabel,
//         },
//         {
//           id: 47,
//           item: "Chivas Regal 12yrs",
//           category: "Whiskey",
//           description:
//             "A luxurious blend, balancing richness, smoothness, and a hint of smokiness",
//           foodimg: Chivas,
//         },
//       ],
//       Rum: [
//         {
//           id: 48,
//           item: "Capt Morgan Spiced gold",
//           category: "Rum",
//           description:
//             "Caribbean rum with a warm, spiced character and a touch of vanilla",
//           foodimg: morgan,
//         },
//         {
//           id: 99,
//           item: "Havana 07",
//           category: "Rum",
//           description: "750ml Grenache, Syrah, Cinsault",
//           foodimg: havana,
//         },
//         {
//           id: 85,
//           item: "Bacardi Dark Rum",
//           category: "Rum",
//           description:
//             "375ml Bacardi Dark Rum tropical fruit & molasses, with a smoky finish.",
//           foodimg: darkrum,
//         },
//       ],
//       Gin: [
//         {
//           id: 49,
//           item: "Monkey 47",
//           category: "Gin",
//           description:
//             "German gin, an intricate mix of botanicals, delivering a harmonious and exotic flavor",
//           foodimg: monkey,
//         },
//         {
//           id: 84,
//           item: "Gin Mare",
//           category: "Gin",
//           description: "700ml herbal and aromatic palate.",
//           foodimg: ginmare,
//         },
//         {
//           id: 88,
//           item: "Hendriks",
//           category: "Gin",
//           description:
//             "A Scottish gin, infused with cucumber and rose petals, offering a unique floral twist",
//           foodimg: hendriks,
//         },
//       ],
//       Vodka: [
//         {
//           id: 50,
//           item: "Crystal Head",
//           category: "Vodka",
//           description:
//             "Pure vodka, crafted with crystal clarity, providing a clean and smooth drinking experience",
//           foodimg: crystal,
//         },
//         {
//           id: 92,
//           item: "Grey Goose",
//           category: "Vodka",
//           description:
//             "750ml Fresh & bright throughout, sweet & creamy with a richness of almond.",
//           foodimg: grey,
//         },
//         {
//           id: 95,
//           item: "Beluga",
//           category: "Vodka",
//           description:
//             "Russian vodka, distinguished by its velvety texture and subtle hints of honey.",
//           foodimg: beluga,
//         },
//       ],
//       Tequila: [
//         {
//           id: 51,
//           item: "Patron Silver",
//           category: "Tequila",
//           description: "Almost earthy nose with soft spices and zesty citrus",
//           foodimg: patron,
//         },
//         {
//           id: 59,
//           item: "Jose Cuervo",
//           category: "Tequila",
//           description:
//             "750ml Black pepper, caramel, finishing off with toffee and Vanilla.",
//           foodimg: jose,
//         },
//       ],
//     },
//     Cocktail: [
//       {
//         id: 40,
//         item: "Bull Frog",
//         category: "Cocktails",
//         description:
//           "A vibrant concoction, merging citrus vodka, peach schnapps, and energy drink—an electrifying burst of flavors",
//         foodimg: bullfrog,
//       },
//       {
//         id: 41,
//         item: "Tequila Sunrise",
//         category: "Cocktails",
//         description:
//           "A sunrise in a glass, fusing tequila, orange juice, and a grenadine sunrise—a burst of vibrant citrus hues",
//         foodimg: tequillasunrise,
//       },
//       {
//         id: 42,
//         item: "Mojito",
//         category: "Cocktails",
//         description:
//           "Refreshing Cuban classic, marrying lime zest, mint, and white rum, a symphony of citrus and herbal bliss",
//         foodimg: mojito,
//       },
//       {
//         id: 43,
//         item: "Whisky Sour",
//         category: "Cocktails",
//         description:
//           "Timeless and tangy, uniting bourbon, fresh lemon juice, and a touch of sweetness—a zesty, spirited classic",
//         foodimg: whiskeysour,
//       },
//       {
//         id: 44,
//         item: "Cosmopolitan",
//         category: "Cocktails",
//         description:
//           "Elegant and sophisticated, blending vodka, triple sec, cranberry, and lime—a cosmopolitan symphony in a glass",
//         foodimg: cosmo,
//       },
//       {
//         id: 45,
//         item: "Gimlet",
//         category: "Cocktails",
//         description: "Gin, simple syrup, lime juice",
//         foodimg: gimlet,
//       },
//     ],
//     NonAlcohol: [
//       {
//         id: 28,
//         item: "Soft Drinks",
//         category: "non-alcoholic",
//         description: "150ml",
//         foodimg: SoftDrinksImg,
//       },
//       {
//         id: 29,
//         item: "Tetra Packet Juice",
//         category: "non-alcoholic",
//         description: "210ml Fresh Juice",
//         foodimg: TetraPackImg,
//       },
//       {
//         id: 30,
//         item: "Tea/Coffee",
//         category: "non-alcoholic",
//         description:
//           "A robust brew awakening your senses, delivering rich flavor in every sip",
//         foodimg: CoffeeImg,
//       },
//       {
//         id: 31,
//         item: "Water",
//         category: "non-alcoholic",
//         description: "1 L fresh watert",
//         foodimg: WaterImg,
//       },
//     ],
//   },
//   "Gerneral Reservations": {
//     "Food menu": [],
//     Appetizer: [
//       {
//         id: 1,
//         item: "Vegetable Dumpling",
//         category: "Vegetarian",
//         description:
//           "A cozy Platter of Dumplings Cream Cheese & Mushroom, Truffle Edamame, Corn Basil Water Chestnut",
//         foodimg: VegDumplingImg,
//       },
//       {
//         id: 2,
//         item: "Peri Peri Chicken",
//         category: "Non Vegetarian",
//         description:
//           "Fiery chicken tikka cooked in tandoor coated with our signature peri peri cream sauce",
//         foodimg: PeriPeriChickenImg,
//       },
//       {
//         id: 3,
//         item: "Asparagus Lemon Grass Chicken",
//         category: "Non Vegetarian",
//         description:
//           "Succulent grilled chicken paired with vibrant asparagus & blend of flavors",
//         foodimg: AsparagusChickenImg,
//       },
//       {
//         id: 4,
//         item: "Chicken Ghee Roast",
//         category: "Non Vegetarian",
//         description:
//           "Succulent chicken, fiery spices, and rich ghee create a tantalizing culinary delight",
//         foodimg: ChickenGheeRoastImg,
//       },
//       {
//         id: 5,
//         item: "Fish Tikka",
//         category: "Non Vegetarian",
//         description:
//           "Fish Tikka Marinated In A Lebanese Spice Mix, Served With Green Chutney And Onions",
//         foodimg: FishTikkaImg,
//       },
//       {
//         id: 6,
//         item: "Garlic Butter Prawns",
//         category: "Non Vegetarian",
//         description:
//           "Richly seasoned prawns bathed in savory garlic butter perfection.",
//         foodimg: GarlicButterPrawnsImg,
//       },
//       {
//         id: 7,
//         item: "Chili Paneer",
//         category: "Vegetarian",
//         description:
//           "Zesty paneer cubes with fiery chili-infused sauce, a tantalizing blend of spice and flavor.",
//         foodimg: ChilliPaneerImg,
//       },
//       {
//         id: 8,
//         item: "Crispy Corn",
//         category: "Vegetarian",
//         description:
//           "Golden-brown, crunchy corn bites, seasoned to perfection for a delightful snack experience",
//         foodimg: CrispyCornImg,
//       },
//       {
//         id: 9,
//         item: "Sweet Potato Fries",
//         category: "Vegetarian",
//         description:
//           "Irresistibly crisp, golden sweet potato fries, seasoned to perfection for a delightful flavor experience.",
//         foodimg: SweetPotatoFries,
//       },
//     ],
//     MainCourse: [
//       {
//         id: 10,
//         item: "Wok Fried Vegetables Noodles",
//         category: "Vegetarian",
//         description:
//           "Bursting with vibrant stir-fried veggies, our noodles are a symphony of flavors and textures.",
//         foodimg: WokFriedVegsNoodleImg,
//       },
//       {
//         id: 11,
//         item: "Wok Fried Sichuan Chicken",
//         category: "Non Vegetarian",
//         description:
//           "Fiery Sichuan stir-fry ignites your palate with bold flavors, leaving you craving more spice.",
//         foodimg: WokFriedSichuanImg,
//       },
//       {
//         id: 12,
//         item: "Penne with Prawns",
//         category: "Non Vegetarian",
//         description:
//           "Al dente penne embraces succulent prawns in a symphony of garlic, herbs, and olive oil.",
//         foodimg: PenneWPrawnsImg,
//       },
//       {
//         id: 13,
//         item: "BBQ Chicken",
//         category: "Non Vegetarian",
//         description:
//           "Char-grilled perfection, our BBQ chicken boasts smoky richness and a caramelized exterior",
//         foodimg: BBQChickenImg,
//       },
//       {
//         id: 14,
//         item: "Chicken Tikka Masala",
//         category: "Non Vegetarian",
//         description:
//           "Tender, spice-kissed chicken dances in a velvety tomato masala, a curry lover's dream.",
//         foodimg: ChickenTikkaMasalaImg,
//       },
//       {
//         id: 15,
//         item: "Vegetable Fried Rice",
//         category: "Vegetarian",
//         description:
//           "Fragrant rice embraces a medley of fresh veggies, wok-tossed to perfection, simplicity elevated.",
//         foodimg: VegFriedRiceImg,
//       },
//       {
//         id: 16,
//         item: "Vegetable Risotto",
//         category: "Vegetarian",
//         description:
//           "Creamy risotto unveils a garden of vegetables, marrying comfort and refined flavors harmoniously.",
//         foodimg: VegRisottoImg,
//       },
//       {
//         id: 17,
//         item: "Karaage Fried Chicken Sliders",
//         category: "Non Vegetarian",
//         description:
//           "fried chicken sliders, Japanese fried chicken sandwich, karaage chicken.",
//         foodimg: KaraageImg,
//       },
//       {
//         id: 18,
//         item: "Chili Chicken",
//         category: "Non Vegetarian",
//         description:
//           "Zesty, stir-fried chicken, drenched in chili-infused glaze, promises a fiery flavor explosion.",
//         foodimg: ChilliChickenImg,
//       },
//       {
//         id: 19,
//         item: "Keema Pav",
//         category: "Non Vegetarian",
//         description:
//           "Savory minced meat, aromatic spices, and fluffy pao buns create a hearty, satisfying delight.",
//         foodimg: KeemaPavImg,
//       },
//     ],
//     Dessert: [
//       {
//         id: 20,
//         item: "Gulab Jamun",
//         category: "Vegetarian",
//         description:
//           "Fried dumplings made of dried milk & dipped in rose cardamom flavoured sugar syrup.",
//         foodimg: GulabJamunImg,
//       },
//       {
//         id: 21,
//         item: "Cheese Cake",
//         category: "Vegetarian",
//         description: "Cheesecake Finished With A Sweet Blueberry Topping",
//         foodimg: CheeseCakeImg,
//       },
//       {
//         id: 22,
//         item: "Mix Fruit Platter (Seasonal)",
//         category: "Vegetarian",
//         description:
//           "Enjoy a medley of seasonal fruits, a refreshing plate that captures the essence of nature's bounty.",
//         foodimg: MixedFruitImg,
//       },
//     ],
//     Sides: [
//       {
//         id: 23,
//         item: "Indian Breadbasket",
//         category: "Vegetarian",
//         description:
//           "Assorted Indian bread, a delightful mix of rotis, naans, and parathas, offering diverse flavors",
//         foodimg: BreadImg,
//       },
//       {
//         id: 24,
//         item: "Steamed Rice",
//         category: "Vegetarian",
//         description: "Basmati rice",
//         foodimg: SteamedRiceImg,
//       },
//       {
//         id: 25,
//         item: "Papad",
//         category: "Vegetarian",
//         description:
//           "Crunchy, thin papad - a crispy appetizer, a versatile accompaniment to complement Indian cuisine",
//         foodimg: PapadImg,
//       },
//       {
//         id: 26,
//         item: "Pickle",
//         category: "Vegetarian",
//         description:
//           "Zesty, tangy pickle bursting with spice, a traditional Indian condiment, elevating every meal",
//         foodimg: PickelImg,
//       },
//       {
//         id: 27,
//         item: "Salad",
//         category: "Vegetarian",
//         description: "Freshly sliced green vegetable salad.",
//         foodimg: SaladImg,
//       },
//     ],
//     "Beverage menu": [],
//     BottleBeer: [
//       {
//         id: 35,
//         item: "Budweiser",
//         category: "bottleBeers",
//         description: "330ml Budweiser Bottle Beer",
//         foodimg: BudwiserImg,
//       },
//       {
//         id: 36,
//         item: "Heineken",
//         category: "bottleBeers",
//         description:
//           "A renowned Dutch lager, balancing a mild bitterness with a smooth finish",
//         foodimg: HeinekenImg,
//       },
//       {
//         id: 37,
//         item: "Corona Extra",
//         category: "bottleBeers",
//         description: "750ml refreshing lager with a hint of citrus",
//         foodimg: CoronaExtraImg,
//       },
//       {
//         id: 38,
//         item: "Hoegaarden",
//         category: "bottleBeers",
//         description:
//           "Belgian wheat beer, unfiltered and aromatic, boasting a distinctive citrusy and spicy profile",
//         foodimg: HoegaardenImg,
//       },
//       {
//         id: 39,
//         item: "Draught Beer",
//         category: "bottleBeers",
//         description:
//           "On tap, the embodiment of beer perfection, delivering freshness and smoothness",
//         foodimg: DraughtBeer,
//       },
//     ],
//     Wine: [
//       {
//         id: 32,
//         item: "Rose Wine",
//         category: "Wines",
//         description: "750ml Grenache, Syrah, Cinsault",
//         foodimg: RoseWineImg,
//       },
//       {
//         id: 33,
//         item: "Red Wine",
//         category: "Wines",
//         description:
//           "Bold and robust, a velvety blend of dark fruits and subtle oak undertones",
//         foodimg: RedWineImg,
//       },
//       {
//         id: 34,
//         item: "White Wine",
//         category: "Wines",
//         description:
//           "Delicate and crisp, a symphony of citrus and floral notes in each pour",
//         foodimg: WhiteWineImg,
//       },
//     ],
//     Spirits: {
//       SingleMoult: [
//         {
//           id: 46,
//           item: "Glenfiddich 12 yrs",
//           category: "SingleMoult",
//           description:
//             "A single moult Scotch whisky, with a distinctive pear and apple fragrance",
//           foodimg: GlenfiddichImg,
//         },
//       ],
//       Whiskey: [
//         {
//           id: 47,
//           item: "JW Black Label",
//           category: "Whiskey",
//           description: "750ml Johnnie Walker Black Label Whisky",
//           foodimg: Blacklabel,
//         },
//         {
//           id: 47,
//           item: "Chivas Regal 12yrs",
//           category: "Whiskey",
//           description:
//             "A luxurious blend, balancing richness, smoothness, and a hint of smokiness",
//           foodimg: Chivas,
//         },
//       ],
//       Rum: [
//         {
//           id: 48,
//           item: "Capt Morgan Spiced gold",
//           category: "Rum",
//           description:
//             "Caribbean rum with a warm, spiced character and a touch of vanilla",
//           foodimg: morgan,
//         },
//         {
//           id: 99,
//           item: "Havana 07",
//           category: "Rum",
//           description: "750ml Grenache, Syrah, Cinsault",
//           foodimg: havana,
//         },
//         {
//           id: 85,
//           item: "Bacardi Dark Rum",
//           category: "Rum",
//           description:
//             "375ml Bacardi Dark Rum tropical fruit & molasses, with a smoky finish.",
//           foodimg: darkrum,
//         },
//       ],
//       Gin: [
//         {
//           id: 49,
//           item: "Monkey 47",
//           category: "Gin",
//           description:
//             "German gin, an intricate mix of botanicals, delivering a harmonious and exotic flavor",
//           foodimg: monkey,
//         },
//         {
//           id: 84,
//           item: "Gin Mare",
//           category: "Gin",
//           description: "700ml herbal and aromatic palate.",
//           foodimg: ginmare,
//         },
//         {
//           id: 88,
//           item: "Hendriks",
//           category: "Gin",
//           description:
//             "A Scottish gin, infused with cucumber and rose petals, offering a unique floral twist",
//           foodimg: hendriks,
//         },
//       ],
//       Vodka: [
//         {
//           id: 50,
//           item: "Crystal Head",
//           category: "Vodka",
//           description:
//             "Pure vodka, crafted with crystal clarity, providing a clean and smooth drinking experience",
//           foodimg: crystal,
//         },
//         {
//           id: 92,
//           item: "Grey Goose",
//           category: "Vodka",
//           description:
//             "750ml Fresh & bright throughout, sweet & creamy with a richness of almond.",
//           foodimg: grey,
//         },
//         {
//           id: 95,
//           item: "Beluga",
//           category: "Vodka",
//           description:
//             "Russian vodka, distinguished by its velvety texture and subtle hints of honey.",
//           foodimg: beluga,
//         },
//       ],
//       Tequila: [
//         {
//           id: 51,
//           item: "Patron Silver",
//           category: "Tequila",
//           description: "Almost earthy nose with soft spices and zesty citrus",
//           foodimg: patron,
//         },
//         {
//           id: 59,
//           item: "Jose Cuervo",
//           category: "Tequila",
//           description:
//             "750ml Black pepper, caramel, finishing off with toffee and Vanilla.",
//           foodimg: jose,
//         },
//       ],
//     },
//     Cocktail: [
//       {
//         id: 40,
//         item: "Bull Frog",
//         category: "Cocktails",
//         description:
//           "A vibrant concoction, merging citrus vodka, peach schnapps, and energy drink—an electrifying burst of flavors",
//         foodimg: bullfrog,
//       },
//       {
//         id: 41,
//         item: "Tequila Sunrise",
//         category: "Cocktails",
//         description:
//           "A sunrise in a glass, fusing tequila, orange juice, and a grenadine sunrise—a burst of vibrant citrus hues",
//         foodimg: tequillasunrise,
//       },
//       {
//         id: 42,
//         item: "Mojito",
//         category: "Cocktails",
//         description:
//           "Refreshing Cuban classic, marrying lime zest, mint, and white rum, a symphony of citrus and herbal bliss",
//         foodimg: mojito,
//       },
//       {
//         id: 43,
//         item: "Whisky Sour",
//         category: "Cocktails",
//         description:
//           "Timeless and tangy, uniting bourbon, fresh lemon juice, and a touch of sweetness—a zesty, spirited classic",
//         foodimg: whiskeysour,
//       },
//       {
//         id: 44,
//         item: "Cosmopolitan",
//         category: "Cocktails",
//         description:
//           "Elegant and sophisticated, blending vodka, triple sec, cranberry, and lime—a cosmopolitan symphony in a glass",
//         foodimg: cosmo,
//       },
//       {
//         id: 45,
//         item: "Gimlet",
//         category: "Cocktails",
//         description: "Gin, simple syrup, lime juice",
//         foodimg: gimlet,
//       },
//     ],
//     NonAlcohol: [
//       {
//         id: 28,
//         item: "Soft Drinks",
//         category: "non-alcoholic",
//         description: "150ml",
//         foodimg: SoftDrinksImg,
//       },
//       {
//         id: 29,
//         item: "Tetra Packet Juice",
//         category: "non-alcoholic",
//         description: "210ml Fresh Juice",
//         foodimg: TetraPackImg,
//       },
//       {
//         id: 30,
//         item: "Tea/Coffee",
//         category: "non-alcoholic",
//         description:
//           "A robust brew awakening your senses, delivering rich flavor in every sip",
//         foodimg: CoffeeImg,
//       },
//       {
//         id: 31,
//         item: "Water",
//         category: "non-alcoholic",
//         description: "1 L fresh watert",
//         foodimg: WaterImg,
//       },
//     ],
//   },
// });



const initialState = Object.freeze({
  Premium: {
    "Food menu": [],
    Appetizer: [
      {
        id: 1,
        item: "Kanda bhajiya",
        category: "Vegetarian",
        description:
          "Crispy onion fritters, spiced to perfection.",
        foodimg: KandaBhajiya,
      },
      {
        id: 2,
        item: "Baby fried idli",
        category: "Vegetarian",
        description:
          "Miniature idlis, deep-fried to golden perfection",
        foodimg: friedIdli,
      },
      {
        id: 3,
        item: "Chakna box",
        category: "Vegetarian",
        description:
          "A savory assortment of crunchy snacks, perfect for pairing with your favorite beverage, offering a satisfying munching experience.",
        foodimg: chaknabox,
      },
      {
        id: 4,
        item: "Crispy corn chakna",
        category: "Vegetarian",
        description:
          "Crunchy corn kernels seasoned with aromatic spices, offering a delightful snack that's both addictive and satisfying.",
        foodimg:  crispycorn,
      },
      {
        id: 5,
        item: "Dadar station ka vada pav",
        category: "Vegetarian",
        description:
          "Mumbai's iconic snack, a spicy potato patty sandwiched in a bun.",
        foodimg: vadapao,
      },
      {
        id: 6,
        item: "Bhel puri juhu beach ishtyle",
        category: "Vegetarian",
        description:
          "A flavor-packed delight with layers of grilled veggies, cheese, and tangy sauces, crafted with love and tradition from Tardeo's famous eatery.",
        foodimg: behlpuri,
      },
      {
        id: 7,
        item: "Cheese chilli toast",
        category: "Vegetarian",
        description:
          "Irresistible toast topped with melted cheese and spicy chillies, a flavorful fusion snack loved by all",
        foodimg:  cheesechillytoast,
      },
      {
        id: 8,
        item: "Aplam chaplam papadum",
        category: "Vegetarian",
        description:
          "Thin, crispy papadum seasoned with tangy spices, an ideal accompaniment to any Indian meal, adding a burst of flavor and crunch.",
        foodimg: papad,
      },
      {
        id: 9,
        item: "Chinese bhel",
        category: "Vegetarian",
        description:
          "Crispy noodles, vegetables, and sauces, creating a unique and addictive dish with a spicy and tangy kick.",
        foodimg: chineesebhel,
      },
      {
        id: 10,
        item: "Shakarkand ki chaat",
        category: "Vegetarian",
        description:
          "sweet potatoes seasoned with spices, herbs, and tangy chutneys, creating a flavorful and nutritious chaat option bursting with both sweetness and tanginess.",
        foodimg: shakarkand,
      },
      {
        id: 11,
        item: "Raj Kachori",
        category: "Vegetarian",
        description:
          "Crispy, hollow pastry shell filled with a medley of savory ingredients like chickpeas, potatoes, yogurt, and chutneys, offering a burst of flavors and textures in every bite.",
        foodimg: rajkachori,
      },
      {
        id: 12,
        item: "Crispy corn bhel",
        category: "Vegetarian",
        description:
          "Crispy Corn Bhel combines crispy fried corn kernels with onions, tomatoes, and spices, tossed in tangy chutneys, resulting in a crunchy and flavorful snack perfect for any occasion.",
        foodimg: crispycorn2,
      },
      {
        id: 13,
        item: "Seafood bhel",
        category: "Non Vegetarian",
        description:
          "Shrimp, fish, or squid along with traditional Bhel ingredients, offering a delightful seafood-infused snack bursting with flavor.",
        foodimg: seafoodbhel,
      },
      {
        id: 14,
        item: "Chandni chowk aloo chaat",
        category: "Vegetarian",
        description:
          "Aloo Chaat from Chandni Chowk is a famous street food delicacy featuring crispy fried potatoes tossed in spices, yogurt, chutneys, and garnished with fresh herbs, offering a savory and indulgent treat",
        foodimg: chandnichowk,
      },


      {
        id: 15,
        item: "Chicken cutlet",
        category: "Non Vegetarian",
        description:
          "Deep fried cutlet of ground chicken mince, served with green chutney",
        foodimg: chickencutlet,
      },
      {
        id: 16,
        item: "Bohri kheema samosa",
        category: "Non Vegetarian",
        description:
          "The famous bohri mutton kheema samosa, lacha pyaz and chutney",
        foodimg: keemasamosa,
      },
      {
        id: 17,
        item: "Egg kejriwal",
        category: "Non Vegetarian",
        description:
          "Two sunny side up eggs on toast. Served with mushroom & green chilli- must try",
        foodimg: kejriwal,
      },
      {
        id: 18,
        item: "Bar baar chicken fry",
        category: "Non Vegetarian",
        description:
          "Our style of fried chicken the boneless way",
        foodimg: chickenfry,
      },
      {
        id: 19,
        item: "Peri peri chicekn tikka",
        category: "Non Vegetarian",
        description:
          "Boneless chicken in blend of youghurt  and peri peri sauce",
        foodimg: chickentikka,
      },
      {
        id: 20,
        item: "Mutton burrah",
        category: "Non Vegetarian",
        description:
          "A very classic mughlai dish of lamb chop and boti",
        foodimg: Muttonburrah,
      },
      {
        id: 21,
        item: "Peshawari chapli kebab",
        category: "Non Vegetarian",
        description:
          "Peshwari’s famous mutton minced patties with ground spices and youghurt chutney",
        foodimg: peshawari,
      },
      {
        id: 22,
        item: "Chicken baida roti",
        category: "Non Vegetarian",
        description:
          "Typical mumbai street food- skilled omelet stuffed with minced chicken served with green chutney",
        foodimg: baidaroti,
      },
      {
        id: 23,
        item: "Bohri Kheema pav",
        category: "Non Vegetarian",
        description:
          "Light on spices, delicious, minced meat, served with pav",
        foodimg: bohrikeemapav,
      },
      {
        id: 24,
        item: "Chooza 69",
        category: "Non Vegetarian",
        description:
          "Hyderabadi style chicken lolipop",
        foodimg: chooza69,
      },
      {
        id: 25,
        item: "Naden chicken fry",
        category: "Non Vegetarian",
        description:
          "Kerela styled fried chicken",
        foodimg: nadenchicken,
      },
      {
        id: 26,
        item: "Fauladi pahadi kebab",
        category: "Non Vegetarian",
        description:
          "Boneless pieces of chicken marinated with pahadi masala",
        foodimg: Fauladipahadikebab,
      },
      {
        id: 27,
        item: "Bhendi bazaar seekh paratha",
        category: "Non Vegetarian",
        description:
          "Mutton seekh kebab served with crispy paratha inspired from the street vendors of bhendi bazar in bombay",
        foodimg: bhendi,
      },
      {
        id: 28,
        item: "Prawn kolbi fry",
        category: "Non Vegetarian",
        description:
          "Mutton seekh kebab served with crispy paratha inspired from the street vendors of bhendi bazar in bombay",
        foodimg: prawn,
      },



    ],
    MainCourse: [
      {
        id: 10,
        item: "Chef Pratap’s ghar ki bhendi",
        category: "Vegetarian",
        description:
          "Our chefs soul recipe of okra served with butter tandoori roti",
        foodimg: pratap,
      },
      {
        id: 11,
        item: "Mushroom patio with PAV",
        category: "Vegetarian",
        description:
          "Common parsi house hold dish #Bawaspeciality",
        foodimg: parsi,
      },
      {
        id: 12,
        item: "Daal Makhani with butter naan",
        category: "Vegetarian",
        description:
          "",
        foodimg: dalmakhani,
      },
      {
        id: 13,
        item: "Baap ki dal with jeera rice",
        category: "Vegetarian",
        description:
          "This time #Baapkidal kha ke dekho",
        foodimg: baap,
      },
      {
        id: 14,
        item: "Paneer lababdar with butter garlic naan",
        category: "Vegetarian",
        description:
          "The best paneer dish.",
        foodimg: paneerlababdar,
      },
      {
        id: 15,
        item: "Rajasthani curry pakora bowl with basmati rice",
        category: "Vegetarian",
        description:
          "#RajasthaniMealInBowl",
        foodimg: rajasthani,
      },
      {
        id: 16,
        item: "Dal Khichdi",
        category: "Vegetarian",
        description:
          "",
        foodimg: khichdi,
      },
      {
        id: 17,
        item: "Murgh Mussallam 2.0",
        category: "Non Vegetarian",
        description:
          "Isko nahin khaaya toh kya khaaya? If you loved it call chef pratap",
        foodimg: murghMussallam,
      },
      {
        id: 18,
        item: "Flambe butter chicken",
        category: "Non Vegetarian",
        description:
          "Traditional chicken spiked with JD shot #MustTry",
        foodimg: ChilliChickenImg,
      },
      {
        id: 19,
        item: "Salli chicken",
        category: "Non Vegetarian",
        description:
          "Parsi style chicken curry topped with potato salli served with pav",
        foodimg: salli,
      },
    ],
    Dessert: [
      {
        id: 20,
        item: "Parle G cheese cake",
        category: "Vegetarian",
        description:
          "",
        foodimg: parleG,
      },
      {
        id: 21,
        item: "Gajjar halwa with kulfi ice creame",
        category: "Vegetarian",
        description: "",
        foodimg: gajjar,
      },
      {
        id: 22,
        item: "Super caramel custard",
        category: "Vegetarian",
        description:
          "",
        foodimg: custard,
      },
      {
        id: 23,
        item: "Fruit platter",
        category: "Vegetarian",
        description:
          "",
        foodimg: fruit,
      },
    ],
    Sides: [
      {
        id: 23,
        item: "Indian Breadbasket",
        category: "Vegetarian",
        description:
          "Assorted Indian bread, a delightful mix of rotis, naans, and parathas, offering diverse flavors",
        foodimg: BreadImg,
      },
      {
        id: 24,
        item: "Steamed Rice",
        category: "Vegetarian",
        description: "Basmati rice",
        foodimg: SteamedRiceImg,
      },
      {
        id: 25,
        item: "Papad",
        category: "Vegetarian",
        description:
          "Crunchy, thin papad - a crispy appetizer, a versatile accompaniment to complement Indian cuisine",
        foodimg: PapadImg,
      },
      {
        id: 26,
        item: "Pickle",
        category: "Vegetarian",
        description:
          "Zesty, tangy pickle bursting with spice, a traditional Indian condiment, elevating every meal",
        foodimg: PickelImg,
      },
      {
        id: 27,
        item: "Salad",
        category: "Vegetarian",
        description: "Freshly sliced green vegetable salad.",
        foodimg: SaladImg,
      },
    ],
    "Beverage menu": [],
    BottleBeer: [
      {
        id: 35,
        item: "Budweiser",
        category: "bottleBeers",
        description: "330ml Budweiser Bottle Beer",
        foodimg: BudwiserImg,
      },
    ],
    Wine: [
      {
        id: 33,
        item: "Red Wine",
        category: "Wines",
        description:
          "Bold and robust, a velvety blend of dark fruits and subtle oak undertones",
        foodimg: RedWineImg,
      },
      {
        id: 34,
        item: "White Wine",
        category: "Wines",
        description:
          "Delicate and crisp, a symphony of citrus and floral notes in each pour",
        foodimg: WhiteWineImg,
      },
    ],
    Spirits: {
      Whiskey: [
        {
          id: 88,
          item: "JW Red Label",
          category: "Whiskey",
          description:
            "Bold and iconic, Red Label whiskey offers a rich, smooth taste with a hint of smokiness",
          foodimg: redlabel,
        },
      ],
      Rum: [
        {
          id: 86,
          item: "Bacardi",
          category: "Rum",
          description:
            "Offers a subtle sweetness and refreshing character, ideal for mixing vibrant cocktails",
          foodimg: bacardi,
        },
      ],
      Gin: [
        {
          id: 87,
          item: "Tanqueray",
          category: "Gin",
          description:
            "Crisp and juniper-forward, Tanqueray gin boasts a well-balanced botanical profile",
          foodimg: Tanqueray,
        },
      ],
      Vodka: [
        {
          id: 99,
          item: "Stolichnaya",
          category: "Vodka",
          description:
            "Delivers a clean, smooth flavor, perfect for crafting versatile cocktails",
          foodimg: stolich,
        },
      ],
    },
    NonAlcohol: [
      {
        id: 28,
        item: "Soft Drinks",
        category: "non-alcoholic",
        description: "150ml",
        foodimg: SoftDrinksImg,
      },
      {
        id: 29,
        item: "Tetra Packet Juice",
        category: "non-alcoholic",
        description: "210ml Fresh Juice",
        foodimg: TetraPackImg,
      },
      {
        id: 30,
        item: "Tea/Coffee",
        category: "non-alcoholic",
        description:
          "A robust brew awakening your senses, delivering rich flavor in every sip",
        foodimg: CoffeeImg,
      },
      {
        id: 31,
        item: "Water",
        category: "non-alcoholic",
        description: "1 L fresh watert",
        foodimg: WaterImg,
      },
    ],
  },
  Regular: {
    "Food menu": [],
    Appetizer: [
      {
        id: 1,
        item: "Kanda bhajiya",
        category: "Vegetarian",
        description:
          "Crispy onion fritters, spiced to perfection.",
        foodimg: KandaBhajiya,
      },
      {
        id: 2,
        item: "Baby fried idli",
        category: "Vegetarian",
        description:
          "Miniature idlis, deep-fried to golden perfection",
        foodimg: friedIdli,
      },
      {
        id: 3,
        item: "Chakna box",
        category: "Vegetarian",
        description:
          "A savory assortment of crunchy snacks, perfect for pairing with your favorite beverage, offering a satisfying munching experience.",
        foodimg: chaknabox,
      },
      {
        id: 4,
        item: "Crispy corn chakna",
        category: "Vegetarian",
        description:
          "Crunchy corn kernels seasoned with aromatic spices, offering a delightful snack that's both addictive and satisfying.",
        foodimg:  crispycorn,
      },
      {
        id: 5,
        item: "Dadar station ka vada pav",
        category: "Vegetarian",
        description:
          "Mumbai's iconic snack, a spicy potato patty sandwiched in a bun.",
        foodimg: vadapao,
      },
      {
        id: 6,
        item: "Bhel puri juhu beach ishtyle",
        category: "Vegetarian",
        description:
          "A flavor-packed delight with layers of grilled veggies, cheese, and tangy sauces, crafted with love and tradition from Tardeo's famous eatery.",
        foodimg: behlpuri,
      },
      {
        id: 7,
        item: "Cheese chilli toast",
        category: "Vegetarian",
        description:
          "Irresistible toast topped with melted cheese and spicy chillies, a flavorful fusion snack loved by all",
        foodimg:  cheesechillytoast,
      },
      {
        id: 8,
        item: "Aplam chaplam papadum",
        category: "Vegetarian",
        description:
          "Thin, crispy papadum seasoned with tangy spices, an ideal accompaniment to any Indian meal, adding a burst of flavor and crunch.",
        foodimg: papad,
      },
      {
        id: 9,
        item: "Chinese bhel",
        category: "Vegetarian",
        description:
          "Crispy noodles, vegetables, and sauces, creating a unique and addictive dish with a spicy and tangy kick.",
        foodimg: chineesebhel,
      },
      {
        id: 10,
        item: "Shakarkand ki chaat",
        category: "Vegetarian",
        description:
          "sweet potatoes seasoned with spices, herbs, and tangy chutneys, creating a flavorful and nutritious chaat option bursting with both sweetness and tanginess.",
        foodimg: shakarkand,
      },
      {
        id: 11,
        item: "Raj Kachori",
        category: "Vegetarian",
        description:
          "Crispy, hollow pastry shell filled with a medley of savory ingredients like chickpeas, potatoes, yogurt, and chutneys, offering a burst of flavors and textures in every bite.",
        foodimg: rajkachori,
      },
      {
        id: 12,
        item: "Crispy corn bhel",
        category: "Vegetarian",
        description:
          "Crispy Corn Bhel combines crispy fried corn kernels with onions, tomatoes, and spices, tossed in tangy chutneys, resulting in a crunchy and flavorful snack perfect for any occasion.",
        foodimg: crispycorn2,
      },
      {
        id: 13,
        item: "Seafood bhel",
        category: "Non Vegetarian",
        description:
          "Shrimp, fish, or squid along with traditional Bhel ingredients, offering a delightful seafood-infused snack bursting with flavor.",
        foodimg: seafoodbhel,
      },
      {
        id: 14,
        item: "Chandni chowk aloo chaat",
        category: "Vegetarian",
        description:
          "Aloo Chaat from Chandni Chowk is a famous street food delicacy featuring crispy fried potatoes tossed in spices, yogurt, chutneys, and garnished with fresh herbs, offering a savory and indulgent treat",
        foodimg: chandnichowk,
      },


      {
        id: 15,
        item: "Chicken cutlet",
        category: "Non Vegetarian",
        description:
          "Deep fried cutlet of ground chicken mince, served with green chutney",
        foodimg: chickencutlet,
      },
      {
        id: 16,
        item: "Bohri kheema samosa",
        category: "Non Vegetarian",
        description:
          "The famous bohri mutton kheema samosa, lacha pyaz and chutney",
        foodimg: keemasamosa,
      },
      {
        id: 17,
        item: "Egg kejriwal",
        category: "Non Vegetarian",
        description:
          "Two sunny side up eggs on toast. Served with mushroom & green chilli- must try",
        foodimg: kejriwal,
      },
      {
        id: 18,
        item: "Bar baar chicken fry",
        category: "Non Vegetarian",
        description:
          "Our style of fried chicken the boneless way",
        foodimg: chickenfry,
      },
      {
        id: 19,
        item: "Peri peri chicekn tikka",
        category: "Non Vegetarian",
        description:
          "Boneless chicken in blend of youghurt  and peri peri sauce",
        foodimg: chickentikka,
      },
      {
        id: 20,
        item: "Mutton burrah",
        category: "Non Vegetarian",
        description:
          "A very classic mughlai dish of lamb chop and boti",
        foodimg: Muttonburrah,
      },
      {
        id: 21,
        item: "Peshawari chapli kebab",
        category: "Non Vegetarian",
        description:
          "Peshwari’s famous mutton minced patties with ground spices and youghurt chutney",
        foodimg: peshawari,
      },
      {
        id: 22,
        item: "Chicken baida roti",
        category: "Non Vegetarian",
        description:
          "Typical mumbai street food- skilled omelet stuffed with minced chicken served with green chutney",
        foodimg: baidaroti,
      },
      {
        id: 23,
        item: "Bohri Kheema pav",
        category: "Non Vegetarian",
        description:
          "Light on spices, delicious, minced meat, served with pav",
        foodimg: bohrikeemapav,
      },
      {
        id: 24,
        item: "Chooza 69",
        category: "Non Vegetarian",
        description:
          "Hyderabadi style chicken lolipop",
        foodimg: chooza69,
      },
      {
        id: 25,
        item: "Naden chicken fry",
        category: "Non Vegetarian",
        description:
          "Kerela styled fried chicken",
        foodimg: nadenchicken,
      },
      {
        id: 26,
        item: "Fauladi pahadi kebab",
        category: "Non Vegetarian",
        description:
          "Boneless pieces of chicken marinated with pahadi masala",
        foodimg: Fauladipahadikebab,
      },
      {
        id: 27,
        item: "Bhendi bazaar seekh paratha",
        category: "Non Vegetarian",
        description:
          "Mutton seekh kebab served with crispy paratha inspired from the street vendors of bhendi bazar in bombay",
        foodimg: bhendi,
      },


      {
        id: 28,
        item: "Prawn kolbi fry",
        category: "Non Vegetarian",
        description:
          "Mutton seekh kebab served with crispy paratha inspired from the street vendors of bhendi bazar in bombay",
        foodimg: prawn,
      },



    ],
    MainCourse: [
      {
        id: 10,
        item: "Chef Pratap’s ghar ki bhendi",
        category: "Vegetarian",
        description:
          "Our chefs soul recipe of okra served with butter tandoori roti",
        foodimg: pratap,
      },
      {
        id: 11,
        item: "Mushroom patio with PAV",
        category: "Vegetarian",
        description:
          "Common parsi house hold dish #Bawaspeciality",
        foodimg: parsi,
      },
      {
        id: 12,
        item: "Daal Makhani with butter naan",
        category: "Vegetarian",
        description:
          "",
        foodimg: dalmakhani,
      },
      {
        id: 13,
        item: "Baap ki dal with jeera rice",
        category: "Vegetarian",
        description:
          "This time #Baapkidal kha ke dekho",
        foodimg: baap,
      },
      {
        id: 14,
        item: "Paneer lababdar with butter garlic naan",
        category: "Vegetarian",
        description:
          "The best paneer dish.",
        foodimg: paneerlababdar,
      },
      {
        id: 15,
        item: "Rajasthani curry pakora bowl with basmati rice",
        category: "Vegetarian",
        description:
          "#RajasthaniMealInBowl",
        foodimg: rajasthani,
      },
      {
        id: 16,
        item: "Dal Khichdi",
        category: "Vegetarian",
        description:
          "",
        foodimg: khichdi,
      },
      {
        id: 17,
        item: "Murgh Mussallam 2.0",
        category: "Non Vegetarian",
        description:
          "Isko nahin khaaya toh kya khaaya? If you loved it call chef pratap",
        foodimg: murghMussallam,
      },
      {
        id: 18,
        item: "Flambe butter chicken",
        category: "Non Vegetarian",
        description:
          "Traditional chicken spiked with JD shot #MustTry",
        foodimg: ChilliChickenImg,
      },
      {
        id: 19,
        item: "Salli chicken",
        category: "Non Vegetarian",
        description:
          "Parsi style chicken curry topped with potato salli served with pav",
        foodimg: salli,
      },
    ],
    Dessert: [
      {
        id: 20,
        item: "Parle G cheese cake",
        category: "Vegetarian",
        description:
          "",
        foodimg: parleG,
      },
      {
        id: 21,
        item: "Gajjar halwa with kulfi ice creame",
        category: "Vegetarian",
        description: "",
        foodimg: gajjar,
      },
      {
        id: 22,
        item: "Super caramel custard",
        category: "Vegetarian",
        description:
          "",
        foodimg: custard,
      },
      {
        id: 23,
        item: "Fruit platter",
        category: "Vegetarian",
        description:
          "",
        foodimg: fruit,
      },
    ],
    Sides: [
      {
        id: 23,
        item: "Indian Breadbasket",
        category: "Vegetarian",
        description:
          "Assorted Indian bread, a delightful mix of rotis, naans, and parathas, offering diverse flavors",
        foodimg: BreadImg,
      },
      {
        id: 24,
        item: "Steamed Rice",
        category: "Vegetarian",
        description: "Basmati rice",
        foodimg: SteamedRiceImg,
      },
      {
        id: 25,
        item: "Papad",
        category: "Vegetarian",
        description:
          "Crunchy, thin papad - a crispy appetizer, a versatile accompaniment to complement Indian cuisine",
        foodimg: PapadImg,
      },
      {
        id: 26,
        item: "Pickle",
        category: "Vegetarian",
        description:
          "Zesty, tangy pickle bursting with spice, a traditional Indian condiment, elevating every meal",
        foodimg: PickelImg,
      },
      {
        id: 27,
        item: "Salad",
        category: "Vegetarian",
        description: "Freshly sliced green vegetable salad.",
        foodimg: SaladImg,
      },
    ],
    "Beverage menu": [],
    BottleBeer: [
      {
        id: 35,
        item: "Budweiser",
        category: "bottleBeers",
        description: "330ml Budweiser Bottle Beer",
        foodimg: BudwiserImg,
      },
    ],
    Wine: [
      {
        id: 33,
        item: "Red Wine",
        category: "Wines",
        description:
          "Bold and robust, a velvety blend of dark fruits and subtle oak undertones",
        foodimg: RedWineImg,
      },
      {
        id: 34,
        item: "White Wine",
        category: "Wines",
        description:
          "Delicate and crisp, a symphony of citrus and floral notes in each pour",
        foodimg: WhiteWineImg,
      },
    ],
    Spirits: {
      Whiskey: [
        {
          id: 88,
          item: "JW Red Label",
          category: "Whiskey",
          description:
            "Bold and iconic, Red Label whiskey offers a rich, smooth taste with a hint of smokiness",
          foodimg: redlabel,
        },
      ],
      Rum: [
        {
          id: 86,
          item: "Bacardi",
          category: "Rum",
          description:
            "Offers a subtle sweetness and refreshing character, ideal for mixing vibrant cocktails",
          foodimg: bacardi,
        },
      ],
      Gin: [
        {
          id: 87,
          item: "Tanqueray",
          category: "Gin",
          description:
            "Crisp and juniper-forward, Tanqueray gin boasts a well-balanced botanical profile",
          foodimg: Tanqueray,
        },
      ],
      Vodka: [
        {
          id: 99,
          item: "Stolichnaya",
          category: "Vodka",
          description:
            "Delivers a clean, smooth flavor, perfect for crafting versatile cocktails",
          foodimg: stolich,
        },
      ],
    },
    NonAlcohol: [
      {
        id: 28,
        item: "Soft Drinks",
        category: "non-alcoholic",
        description: "150ml",
        foodimg: SoftDrinksImg,
      },
      {
        id: 29,
        item: "Tetra Packet Juice",
        category: "non-alcoholic",
        description: "210ml Fresh Juice",
        foodimg: TetraPackImg,
      },
      {
        id: 30,
        item: "Tea/Coffee",
        category: "non-alcoholic",
        description:
          "A robust brew awakening your senses, delivering rich flavor in every sip",
        foodimg: CoffeeImg,
      },
      {
        id: 31,
        item: "Water",
        category: "non-alcoholic",
        description: "1 L fresh watert",
        foodimg: WaterImg,
      },
    ],
  },
  Exclusive: {
    "Food menu": [],
    Appetizer: [
      {
        id: 1,
        item: "Kanda bhajiya",
        category: "Vegetarian",
        description:
          "Crispy onion fritters, spiced to perfection.",
        foodimg: KandaBhajiya,
      },
      {
        id: 2,
        item: "Baby fried idli",
        category: "Vegetarian",
        description:
          "Miniature idlis, deep-fried to golden perfection",
        foodimg: friedIdli,
      },
      {
        id: 3,
        item: "Chakna box",
        category: "Vegetarian",
        description:
          "A savory assortment of crunchy snacks, perfect for pairing with your favorite beverage, offering a satisfying munching experience.",
        foodimg: chaknabox,
      },
      {
        id: 4,
        item: "Crispy corn chakna",
        category: "Vegetarian",
        description:
          "Crunchy corn kernels seasoned with aromatic spices, offering a delightful snack that's both addictive and satisfying.",
        foodimg:  crispycorn,
      },
      {
        id: 5,
        item: "Dadar station ka vada pav",
        category: "Vegetarian",
        description:
          "Mumbai's iconic snack, a spicy potato patty sandwiched in a bun.",
        foodimg: vadapao,
      },
      {
        id: 6,
        item: "Bhel puri juhu beach ishtyle",
        category: "Vegetarian",
        description:
          "A flavor-packed delight with layers of grilled veggies, cheese, and tangy sauces, crafted with love and tradition from Tardeo's famous eatery.",
        foodimg: behlpuri,
      },
      {
        id: 7,
        item: "Cheese chilli toast",
        category: "Vegetarian",
        description:
          "Irresistible toast topped with melted cheese and spicy chillies, a flavorful fusion snack loved by all",
        foodimg:  cheesechillytoast,
      },
      {
        id: 8,
        item: "Aplam chaplam papadum",
        category: "Vegetarian",
        description:
          "Thin, crispy papadum seasoned with tangy spices, an ideal accompaniment to any Indian meal, adding a burst of flavor and crunch.",
        foodimg: papad,
      },
      {
        id: 9,
        item: "Chinese bhel",
        category: "Vegetarian",
        description:
          "Crispy noodles, vegetables, and sauces, creating a unique and addictive dish with a spicy and tangy kick.",
        foodimg: chineesebhel,
      },
      {
        id: 10,
        item: "Shakarkand ki chaat",
        category: "Vegetarian",
        description:
          "sweet potatoes seasoned with spices, herbs, and tangy chutneys, creating a flavorful and nutritious chaat option bursting with both sweetness and tanginess.",
        foodimg: shakarkand,
      },
      {
        id: 11,
        item: "Raj Kachori",
        category: "Vegetarian",
        description:
          "Crispy, hollow pastry shell filled with a medley of savory ingredients like chickpeas, potatoes, yogurt, and chutneys, offering a burst of flavors and textures in every bite.",
        foodimg: rajkachori,
      },
      {
        id: 12,
        item: "Crispy corn bhel",
        category: "Vegetarian",
        description:
          "Crispy Corn Bhel combines crispy fried corn kernels with onions, tomatoes, and spices, tossed in tangy chutneys, resulting in a crunchy and flavorful snack perfect for any occasion.",
        foodimg: crispycorn2,
      },
      {
        id: 13,
        item: "Seafood bhel",
        category: "Non Vegetarian",
        description:
          "Shrimp, fish, or squid along with traditional Bhel ingredients, offering a delightful seafood-infused snack bursting with flavor.",
        foodimg: seafoodbhel,
      },
      {
        id: 14,
        item: "Chandni chowk aloo chaat",
        category: "Vegetarian",
        description:
          "Aloo Chaat from Chandni Chowk is a famous street food delicacy featuring crispy fried potatoes tossed in spices, yogurt, chutneys, and garnished with fresh herbs, offering a savory and indulgent treat",
        foodimg: chandnichowk,
      },


      {
        id: 15,
        item: "Chicken cutlet",
        category: "Non Vegetarian",
        description:
          "Deep fried cutlet of ground chicken mince, served with green chutney",
        foodimg: chickencutlet,
      },
      {
        id: 16,
        item: "Bohri kheema samosa",
        category: "Non Vegetarian",
        description:
          "The famous bohri mutton kheema samosa, lacha pyaz and chutney",
        foodimg: keemasamosa,
      },
      {
        id: 17,
        item: "Egg kejriwal",
        category: "Non Vegetarian",
        description:
          "Two sunny side up eggs on toast. Served with mushroom & green chilli- must try",
        foodimg: kejriwal,
      },
      {
        id: 18,
        item: "Bar baar chicken fry",
        category: "Non Vegetarian",
        description:
          "Our style of fried chicken the boneless way",
        foodimg: chickenfry,
      },
      {
        id: 19,
        item: "Peri peri chicekn tikka",
        category: "Non Vegetarian",
        description:
          "Boneless chicken in blend of youghurt  and peri peri sauce",
        foodimg: chickentikka,
      },
      {
        id: 20,
        item: "Mutton burrah",
        category: "Non Vegetarian",
        description:
          "A very classic mughlai dish of lamb chop and boti",
        foodimg: Muttonburrah,
      },
      {
        id: 21,
        item: "Peshawari chapli kebab",
        category: "Non Vegetarian",
        description:
          "Peshwari’s famous mutton minced patties with ground spices and youghurt chutney",
        foodimg: peshawari,
      },
      {
        id: 22,
        item: "Chicken baida roti",
        category: "Non Vegetarian",
        description:
          "Typical mumbai street food- skilled omelet stuffed with minced chicken served with green chutney",
        foodimg: baidaroti,
      },
      {
        id: 23,
        item: "Bohri Kheema pav",
        category: "Non Vegetarian",
        description:
          "Light on spices, delicious, minced meat, served with pav",
        foodimg: bohrikeemapav,
      },
      {
        id: 24,
        item: "Chooza 69",
        category: "Non Vegetarian",
        description:
          "Hyderabadi style chicken lolipop",
        foodimg: chooza69,
      },
      {
        id: 25,
        item: "Naden chicken fry",
        category: "Non Vegetarian",
        description:
          "Kerela styled fried chicken",
        foodimg: nadenchicken,
      },
      {
        id: 26,
        item: "Fauladi pahadi kebab",
        category: "Non Vegetarian",
        description:
          "Boneless pieces of chicken marinated with pahadi masala",
        foodimg: Fauladipahadikebab,
      },
      {
        id: 27,
        item: "Bhendi bazaar seekh paratha",
        category: "Non Vegetarian",
        description:
          "Mutton seekh kebab served with crispy paratha inspired from the street vendors of bhendi bazar in bombay",
        foodimg: bhendi,
      },
      {
        id: 28,
        item: "Prawn kolbi fry",
        category: "Non Vegetarian",
        description:
          "Mutton seekh kebab served with crispy paratha inspired from the street vendors of bhendi bazar in bombay",
        foodimg: prawn,
      },



    ],
    MainCourse: [
      {
        id: 10,
        item: "Chef Pratap’s ghar ki bhendi",
        category: "Vegetarian",
        description:
          "Our chefs soul recipe of okra served with butter tandoori roti",
        foodimg: pratap,
      },
      {
        id: 11,
        item: "Mushroom patio with PAV",
        category: "Vegetarian",
        description:
          "Common parsi house hold dish #Bawaspeciality",
        foodimg: parsi,
      },
      {
        id: 12,
        item: "Daal Makhani with butter naan",
        category: "Vegetarian",
        description:
          "",
        foodimg: dalmakhani,
      },
      {
        id: 13,
        item: "Baap ki dal with jeera rice",
        category: "Vegetarian",
        description:
          "This time #Baapkidal kha ke dekho",
        foodimg: baap,
      },
      {
        id: 14,
        item: "Paneer lababdar with butter garlic naan",
        category: "Vegetarian",
        description:
          "The best paneer dish.",
        foodimg: paneerlababdar,
      },
      {
        id: 15,
        item: "Rajasthani curry pakora bowl with basmati rice",
        category: "Vegetarian",
        description:
          "#RajasthaniMealInBowl",
        foodimg: rajasthani,
      },
      {
        id: 16,
        item: "Dal Khichdi",
        category: "Vegetarian",
        description:
          "",
        foodimg: khichdi,
      },
      {
        id: 17,
        item: "Murgh Mussallam 2.0",
        category: "Non Vegetarian",
        description:
          "Isko nahin khaaya toh kya khaaya? If you loved it call chef pratap",
        foodimg: murghMussallam,
      },
      {
        id: 18,
        item: "Flambe butter chicken",
        category: "Non Vegetarian",
        description:
          "Traditional chicken spiked with JD shot #MustTry",
        foodimg: ChilliChickenImg,
      },
      {
        id: 19,
        item: "Salli chicken",
        category: "Non Vegetarian",
        description:
          "Parsi style chicken curry topped with potato salli served with pav",
        foodimg: salli,
      },
    ],
    Dessert: [
      {
        id: 20,
        item: "Parle G cheese cake",
        category: "Vegetarian",
        description:
          "",
        foodimg: parleG,
      },
      {
        id: 21,
        item: "Gajjar halwa with kulfi ice creame",
        category: "Vegetarian",
        description: "",
        foodimg: gajjar,
      },
      {
        id: 22,
        item: "Super caramel custard",
        category: "Vegetarian",
        description:
          "",
        foodimg: custard,
      },
      {
        id: 23,
        item: "Fruit platter",
        category: "Vegetarian",
        description:
          "",
        foodimg: fruit,
      },
    ],
    Sides: [
      {
        id: 23,
        item: "Indian Breadbasket",
        category: "Vegetarian",
        description:
          "Assorted Indian bread, a delightful mix of rotis, naans, and parathas, offering diverse flavors",
        foodimg: BreadImg,
      },
      {
        id: 24,
        item: "Steamed Rice",
        category: "Vegetarian",
        description: "Basmati rice",
        foodimg: SteamedRiceImg,
      },
      {
        id: 25,
        item: "Papad",
        category: "Vegetarian",
        description:
          "Crunchy, thin papad - a crispy appetizer, a versatile accompaniment to complement Indian cuisine",
        foodimg: PapadImg,
      },
      {
        id: 26,
        item: "Pickle",
        category: "Vegetarian",
        description:
          "Zesty, tangy pickle bursting with spice, a traditional Indian condiment, elevating every meal",
        foodimg: PickelImg,
      },
      {
        id: 27,
        item: "Salad",
        category: "Vegetarian",
        description: "Freshly sliced green vegetable salad.",
        foodimg: SaladImg,
      },
    ],
    "Beverage menu": [],
    BottleBeer: [
      {
        id: 35,
        item: "Budweiser",
        category: "bottleBeers",
        description: "330ml Budweiser Bottle Beer",
        foodimg: BudwiserImg,
      },
    ],
    Wine: [
      {
        id: 33,
        item: "Red Wine",
        category: "Wines",
        description:
          "Bold and robust, a velvety blend of dark fruits and subtle oak undertones",
        foodimg: RedWineImg,
      },
      {
        id: 34,
        item: "White Wine",
        category: "Wines",
        description:
          "Delicate and crisp, a symphony of citrus and floral notes in each pour",
        foodimg: WhiteWineImg,
      },
    ],
    Spirits: {
      Whiskey: [
        {
          id: 88,
          item: "JW Red Label",
          category: "Whiskey",
          description:
            "Bold and iconic, Red Label whiskey offers a rich, smooth taste with a hint of smokiness",
          foodimg: redlabel,
        },
      ],
      Rum: [
        {
          id: 86,
          item: "Bacardi",
          category: "Rum",
          description:
            "Offers a subtle sweetness and refreshing character, ideal for mixing vibrant cocktails",
          foodimg: bacardi,
        },
      ],
      Gin: [
        {
          id: 87,
          item: "Tanqueray",
          category: "Gin",
          description:
            "Crisp and juniper-forward, Tanqueray gin boasts a well-balanced botanical profile",
          foodimg: Tanqueray,
        },
      ],
      Vodka: [
        {
          id: 99,
          item: "Stolichnaya",
          category: "Vodka",
          description:
            "Delivers a clean, smooth flavor, perfect for crafting versatile cocktails",
          foodimg: stolich,
        },
      ],
    },
    NonAlcohol: [
      {
        id: 28,
        item: "Soft Drinks",
        category: "non-alcoholic",
        description: "150ml",
        foodimg: SoftDrinksImg,
      },
      {
        id: 29,
        item: "Tetra Packet Juice",
        category: "non-alcoholic",
        description: "210ml Fresh Juice",
        foodimg: TetraPackImg,
      },
      {
        id: 30,
        item: "Tea/Coffee",
        category: "non-alcoholic",
        description:
          "A robust brew awakening your senses, delivering rich flavor in every sip",
        foodimg: CoffeeImg,
      },
      {
        id: 31,
        item: "Water",
        category: "non-alcoholic",
        description: "1 L fresh watert",
        foodimg: WaterImg,
      },
    ],
  },
  "Ultra Exclusive": {
    "Food menu": [],
    Appetizer: [
      {
        id: 1,
        item: "Kanda bhajiya",
        category: "Vegetarian",
        description:
          "Crispy onion fritters, spiced to perfection.",
        foodimg: KandaBhajiya,
      },
      {
        id: 2,
        item: "Baby fried idli",
        category: "Vegetarian",
        description:
          "Miniature idlis, deep-fried to golden perfection",
        foodimg: friedIdli,
      },
      {
        id: 3,
        item: "Chakna box",
        category: "Vegetarian",
        description:
          "A savory assortment of crunchy snacks, perfect for pairing with your favorite beverage, offering a satisfying munching experience.",
        foodimg: chaknabox,
      },
      {
        id: 4,
        item: "Crispy corn chakna",
        category: "Vegetarian",
        description:
          "Crunchy corn kernels seasoned with aromatic spices, offering a delightful snack that's both addictive and satisfying.",
        foodimg:  crispycorn,
      },
      {
        id: 5,
        item: "Dadar station ka vada pav",
        category: "Vegetarian",
        description:
          "Mumbai's iconic snack, a spicy potato patty sandwiched in a bun.",
        foodimg: vadapao,
      },
      {
        id: 6,
        item: "Bhel puri juhu beach ishtyle",
        category: "Vegetarian",
        description:
          "A flavor-packed delight with layers of grilled veggies, cheese, and tangy sauces, crafted with love and tradition from Tardeo's famous eatery.",
        foodimg: behlpuri,
      },
      {
        id: 7,
        item: "Cheese chilli toast",
        category: "Vegetarian",
        description:
          "Irresistible toast topped with melted cheese and spicy chillies, a flavorful fusion snack loved by all",
        foodimg:  cheesechillytoast,
      },
      {
        id: 8,
        item: "Aplam chaplam papadum",
        category: "Vegetarian",
        description:
          "Thin, crispy papadum seasoned with tangy spices, an ideal accompaniment to any Indian meal, adding a burst of flavor and crunch.",
        foodimg: papad,
      },
      {
        id: 9,
        item: "Chinese bhel",
        category: "Vegetarian",
        description:
          "Crispy noodles, vegetables, and sauces, creating a unique and addictive dish with a spicy and tangy kick.",
        foodimg: chineesebhel,
      },
      {
        id: 10,
        item: "Shakarkand ki chaat",
        category: "Vegetarian",
        description:
          "sweet potatoes seasoned with spices, herbs, and tangy chutneys, creating a flavorful and nutritious chaat option bursting with both sweetness and tanginess.",
        foodimg: shakarkand,
      },
      {
        id: 11,
        item: "Raj Kachori",
        category: "Vegetarian",
        description:
          "Crispy, hollow pastry shell filled with a medley of savory ingredients like chickpeas, potatoes, yogurt, and chutneys, offering a burst of flavors and textures in every bite.",
        foodimg: rajkachori,
      },
      {
        id: 12,
        item: "Crispy corn bhel",
        category: "Vegetarian",
        description:
          "Crispy Corn Bhel combines crispy fried corn kernels with onions, tomatoes, and spices, tossed in tangy chutneys, resulting in a crunchy and flavorful snack perfect for any occasion.",
        foodimg: crispycorn2,
      },
      {
        id: 13,
        item: "Seafood bhel",
        category: "Non Vegetarian",
        description:
          "Shrimp, fish, or squid along with traditional Bhel ingredients, offering a delightful seafood-infused snack bursting with flavor.",
        foodimg: seafoodbhel,
      },
      {
        id: 14,
        item: "Chandni chowk aloo chaat",
        category: "Vegetarian",
        description:
          "Aloo Chaat from Chandni Chowk is a famous street food delicacy featuring crispy fried potatoes tossed in spices, yogurt, chutneys, and garnished with fresh herbs, offering a savory and indulgent treat",
        foodimg: chandnichowk,
      },


      {
        id: 15,
        item: "Chicken cutlet",
        category: "Non Vegetarian",
        description:
          "Deep fried cutlet of ground chicken mince, served with green chutney",
        foodimg: chickencutlet,
      },
      {
        id: 16,
        item: "Bohri kheema samosa",
        category: "Non Vegetarian",
        description:
          "The famous bohri mutton kheema samosa, lacha pyaz and chutney",
        foodimg: keemasamosa,
      },
      {
        id: 17,
        item: "Egg kejriwal",
        category: "Non Vegetarian",
        description:
          "Two sunny side up eggs on toast. Served with mushroom & green chilli- must try",
        foodimg: kejriwal,
      },
      {
        id: 18,
        item: "Bar baar chicken fry",
        category: "Non Vegetarian",
        description:
          "Our style of fried chicken the boneless way",
        foodimg: chickenfry,
      },
      {
        id: 19,
        item: "Peri peri chicekn tikka",
        category: "Non Vegetarian",
        description:
          "Boneless chicken in blend of youghurt  and peri peri sauce",
        foodimg: chickentikka,
      },
      {
        id: 20,
        item: "Mutton burrah",
        category: "Non Vegetarian",
        description:
          "A very classic mughlai dish of lamb chop and boti",
        foodimg: Muttonburrah,
      },
      {
        id: 21,
        item: "Peshawari chapli kebab",
        category: "Non Vegetarian",
        description:
          "Peshwari’s famous mutton minced patties with ground spices and youghurt chutney",
        foodimg: peshawari,
      },
      {
        id: 22,
        item: "Chicken baida roti",
        category: "Non Vegetarian",
        description:
          "Typical mumbai street food- skilled omelet stuffed with minced chicken served with green chutney",
        foodimg: baidaroti,
      },
      {
        id: 23,
        item: "Bohri Kheema pav",
        category: "Non Vegetarian",
        description:
          "Light on spices, delicious, minced meat, served with pav",
        foodimg: bohrikeemapav,
      },
      {
        id: 24,
        item: "Chooza 69",
        category: "Non Vegetarian",
        description:
          "Hyderabadi style chicken lolipop",
        foodimg: chooza69,
      },
      {
        id: 25,
        item: "Naden chicken fry",
        category: "Non Vegetarian",
        description:
          "Kerela styled fried chicken",
        foodimg: nadenchicken,
      },
      {
        id: 26,
        item: "Fauladi pahadi kebab",
        category: "Non Vegetarian",
        description:
          "Boneless pieces of chicken marinated with pahadi masala",
        foodimg: Fauladipahadikebab,
      },
      {
        id: 27,
        item: "Bhendi bazaar seekh paratha",
        category: "Non Vegetarian",
        description:
          "Mutton seekh kebab served with crispy paratha inspired from the street vendors of bhendi bazar in bombay",
        foodimg: bhendi,
      },
      {
        id: 28,
        item: "Prawn kolbi fry",
        category: "Non Vegetarian",
        description:
          "Mutton seekh kebab served with crispy paratha inspired from the street vendors of bhendi bazar in bombay",
        foodimg: prawn,
      },



    ],
    MainCourse: [
      {
        id: 10,
        item: "Chef Pratap’s ghar ki bhendi",
        category: "Vegetarian",
        description:
          "Our chefs soul recipe of okra served with butter tandoori roti",
        foodimg: pratap,
      },
      {
        id: 11,
        item: "Mushroom patio with PAV",
        category: "Vegetarian",
        description:
          "Common parsi house hold dish #Bawaspeciality",
        foodimg: parsi,
      },
      {
        id: 12,
        item: "Daal Makhani with butter naan",
        category: "Vegetarian",
        description:
          "",
        foodimg: dalmakhani,
      },
      {
        id: 13,
        item: "Baap ki dal with jeera rice",
        category: "Vegetarian",
        description:
          "This time #Baapkidal kha ke dekho",
        foodimg: baap,
      },
      {
        id: 14,
        item: "Paneer lababdar with butter garlic naan",
        category: "Vegetarian",
        description:
          "The best paneer dish.",
        foodimg: paneerlababdar,
      },
      {
        id: 15,
        item: "Rajasthani curry pakora bowl with basmati rice",
        category: "Vegetarian",
        description:
          "#RajasthaniMealInBowl",
        foodimg: rajasthani,
      },
      {
        id: 16,
        item: "Dal Khichdi",
        category: "Vegetarian",
        description:
          "",
        foodimg: khichdi,
      },
      {
        id: 17,
        item: "Murgh Mussallam 2.0",
        category: "Non Vegetarian",
        description:
          "Isko nahin khaaya toh kya khaaya? If you loved it call chef pratap",
        foodimg: murghMussallam,
      },
      {
        id: 18,
        item: "Flambe butter chicken",
        category: "Non Vegetarian",
        description:
          "Traditional chicken spiked with JD shot #MustTry",
        foodimg: ChilliChickenImg,
      },
      {
        id: 19,
        item: "Salli chicken",
        category: "Non Vegetarian",
        description:
          "Parsi style chicken curry topped with potato salli served with pav",
        foodimg: salli,
      },
    ],
    Dessert: [
      {
        id: 20,
        item: "Parle G cheese cake",
        category: "Vegetarian",
        description:
          "",
        foodimg: parleG,
      },
      {
        id: 21,
        item: "Gajjar halwa with kulfi ice creame",
        category: "Vegetarian",
        description: "",
        foodimg: gajjar,
      },
      {
        id: 22,
        item: "Super caramel custard",
        category: "Vegetarian",
        description:
          "",
        foodimg: custard,
      },
      {
        id: 23,
        item: "Fruit platter",
        category: "Vegetarian",
        description:
          "",
        foodimg: fruit,
      },
    ],
    Sides: [
      {
        id: 23,
        item: "Indian Breadbasket",
        category: "Vegetarian",
        description:
          "Assorted Indian bread, a delightful mix of rotis, naans, and parathas, offering diverse flavors",
        foodimg: BreadImg,
      },
      {
        id: 24,
        item: "Steamed Rice",
        category: "Vegetarian",
        description: "Basmati rice",
        foodimg: SteamedRiceImg,
      },
      {
        id: 25,
        item: "Papad",
        category: "Vegetarian",
        description:
          "Crunchy, thin papad - a crispy appetizer, a versatile accompaniment to complement Indian cuisine",
        foodimg: PapadImg,
      },
      {
        id: 26,
        item: "Pickle",
        category: "Vegetarian",
        description:
          "Zesty, tangy pickle bursting with spice, a traditional Indian condiment, elevating every meal",
        foodimg: PickelImg,
      },
      {
        id: 27,
        item: "Salad",
        category: "Vegetarian",
        description: "Freshly sliced green vegetable salad.",
        foodimg: SaladImg,
      },
    ],
    "Beverage menu": [],
    BottleBeer: [
      {
        id: 35,
        item: "Budweiser",
        category: "bottleBeers",
        description: "330ml Budweiser Bottle Beer",
        foodimg: BudwiserImg,
      },
    ],
    Wine: [
      {
        id: 33,
        item: "Red Wine",
        category: "Wines",
        description:
          "Bold and robust, a velvety blend of dark fruits and subtle oak undertones",
        foodimg: RedWineImg,
      },
      {
        id: 34,
        item: "White Wine",
        category: "Wines",
        description:
          "Delicate and crisp, a symphony of citrus and floral notes in each pour",
        foodimg: WhiteWineImg,
      },
    ],
    Spirits: {
      Whiskey: [
        {
          id: 88,
          item: "JW Red Label",
          category: "Whiskey",
          description:
            "Bold and iconic, Red Label whiskey offers a rich, smooth taste with a hint of smokiness",
          foodimg: redlabel,
        },
      ],
      Rum: [
        {
          id: 86,
          item: "Bacardi",
          category: "Rum",
          description:
            "Offers a subtle sweetness and refreshing character, ideal for mixing vibrant cocktails",
          foodimg: bacardi,
        },
      ],
      Gin: [
        {
          id: 87,
          item: "Tanqueray",
          category: "Gin",
          description:
            "Crisp and juniper-forward, Tanqueray gin boasts a well-balanced botanical profile",
          foodimg: Tanqueray,
        },
      ],
      Vodka: [
        {
          id: 99,
          item: "Stolichnaya",
          category: "Vodka",
          description:
            "Delivers a clean, smooth flavor, perfect for crafting versatile cocktails",
          foodimg: stolich,
        },
      ],
    },
    NonAlcohol: [
      {
        id: 28,
        item: "Soft Drinks",
        category: "non-alcoholic",
        description: "150ml",
        foodimg: SoftDrinksImg,
      },
      {
        id: 29,
        item: "Tetra Packet Juice",
        category: "non-alcoholic",
        description: "210ml Fresh Juice",
        foodimg: TetraPackImg,
      },
      {
        id: 30,
        item: "Tea/Coffee",
        category: "non-alcoholic",
        description:
          "A robust brew awakening your senses, delivering rich flavor in every sip",
        foodimg: CoffeeImg,
      },
      {
        id: 31,
        item: "Water",
        category: "non-alcoholic",
        description: "1 L fresh watert",
        foodimg: WaterImg,
      },
    ],
  },
  "Gerneral Reservations": {
    "Food menu": [],
    Appetizer: [
      {
        id: 1,
        item: "Kanda bhajiya",
        category: "Vegetarian",
        description:
          "Crispy onion fritters, spiced to perfection.",
        foodimg: KandaBhajiya,
      },
      {
        id: 2,
        item: "Baby fried idli",
        category: "Vegetarian",
        description:
          "Miniature idlis, deep-fried to golden perfection",
        foodimg: friedIdli,
      },
      {
        id: 3,
        item: "Chakna box",
        category: "Vegetarian",
        description:
          "A savory assortment of crunchy snacks, perfect for pairing with your favorite beverage, offering a satisfying munching experience.",
        foodimg: chaknabox,
      },
      {
        id: 4,
        item: "Crispy corn chakna",
        category: "Vegetarian",
        description:
          "Crunchy corn kernels seasoned with aromatic spices, offering a delightful snack that's both addictive and satisfying.",
        foodimg:  crispycorn,
      },
      {
        id: 5,
        item: "Dadar station ka vada pav",
        category: "Vegetarian",
        description:
          "Mumbai's iconic snack, a spicy potato patty sandwiched in a bun.",
        foodimg: vadapao,
      },
      {
        id: 6,
        item: "Bhel puri juhu beach ishtyle",
        category: "Vegetarian",
        description:
          "A flavor-packed delight with layers of grilled veggies, cheese, and tangy sauces, crafted with love and tradition from Tardeo's famous eatery.",
        foodimg: behlpuri,
      },
      {
        id: 7,
        item: "Cheese chilli toast",
        category: "Vegetarian",
        description:
          "Irresistible toast topped with melted cheese and spicy chillies, a flavorful fusion snack loved by all",
        foodimg:  cheesechillytoast,
      },
      {
        id: 8,
        item: "Aplam chaplam papadum",
        category: "Vegetarian",
        description:
          "Thin, crispy papadum seasoned with tangy spices, an ideal accompaniment to any Indian meal, adding a burst of flavor and crunch.",
        foodimg: papad,
      },
      {
        id: 9,
        item: "Chinese bhel",
        category: "Vegetarian",
        description:
          "Crispy noodles, vegetables, and sauces, creating a unique and addictive dish with a spicy and tangy kick.",
        foodimg: chineesebhel,
      },
      {
        id: 10,
        item: "Shakarkand ki chaat",
        category: "Vegetarian",
        description:
          "sweet potatoes seasoned with spices, herbs, and tangy chutneys, creating a flavorful and nutritious chaat option bursting with both sweetness and tanginess.",
        foodimg: shakarkand,
      },
      {
        id: 11,
        item: "Raj Kachori",
        category: "Vegetarian",
        description:
          "Crispy, hollow pastry shell filled with a medley of savory ingredients like chickpeas, potatoes, yogurt, and chutneys, offering a burst of flavors and textures in every bite.",
        foodimg: rajkachori,
      },
      {
        id: 12,
        item: "Crispy corn bhel",
        category: "Vegetarian",
        description:
          "Crispy Corn Bhel combines crispy fried corn kernels with onions, tomatoes, and spices, tossed in tangy chutneys, resulting in a crunchy and flavorful snack perfect for any occasion.",
        foodimg: crispycorn2,
      },
      {
        id: 13,
        item: "Seafood bhel",
        category: "Non Vegetarian",
        description:
          "Shrimp, fish, or squid along with traditional Bhel ingredients, offering a delightful seafood-infused snack bursting with flavor.",
        foodimg: seafoodbhel,
      },
      {
        id: 14,
        item: "Chandni chowk aloo chaat",
        category: "Vegetarian",
        description:
          "Aloo Chaat from Chandni Chowk is a famous street food delicacy featuring crispy fried potatoes tossed in spices, yogurt, chutneys, and garnished with fresh herbs, offering a savory and indulgent treat",
        foodimg: chandnichowk,
      },


      {
        id: 15,
        item: "Chicken cutlet",
        category: "Non Vegetarian",
        description:
          "Deep fried cutlet of ground chicken mince, served with green chutney",
        foodimg: chickencutlet,
      },
      {
        id: 16,
        item: "Bohri kheema samosa",
        category: "Non Vegetarian",
        description:
          "The famous bohri mutton kheema samosa, lacha pyaz and chutney",
        foodimg: keemasamosa,
      },
      {
        id: 17,
        item: "Egg kejriwal",
        category: "Non Vegetarian",
        description:
          "Two sunny side up eggs on toast. Served with mushroom & green chilli- must try",
        foodimg: kejriwal,
      },
      {
        id: 18,
        item: "Bar baar chicken fry",
        category: "Non Vegetarian",
        description:
          "Our style of fried chicken the boneless way",
        foodimg: chickenfry,
      },
      {
        id: 19,
        item: "Peri peri chicekn tikka",
        category: "Non Vegetarian",
        description:
          "Boneless chicken in blend of youghurt  and peri peri sauce",
        foodimg: chickentikka,
      },
      {
        id: 20,
        item: "Mutton burrah",
        category: "Non Vegetarian",
        description:
          "A very classic mughlai dish of lamb chop and boti",
        foodimg: Muttonburrah,
      },
      {
        id: 21,
        item: "Peshawari chapli kebab",
        category: "Non Vegetarian",
        description:
          "Peshwari’s famous mutton minced patties with ground spices and youghurt chutney",
        foodimg: peshawari,
      },
      {
        id: 22,
        item: "Chicken baida roti",
        category: "Non Vegetarian",
        description:
          "Typical mumbai street food- skilled omelet stuffed with minced chicken served with green chutney",
        foodimg: baidaroti,
      },
      {
        id: 23,
        item: "Bohri Kheema pav",
        category: "Non Vegetarian",
        description:
          "Light on spices, delicious, minced meat, served with pav",
        foodimg: bohrikeemapav,
      },
      {
        id: 24,
        item: "Chooza 69",
        category: "Non Vegetarian",
        description:
          "Hyderabadi style chicken lolipop",
        foodimg: chooza69,
      },
      {
        id: 25,
        item: "Naden chicken fry",
        category: "Non Vegetarian",
        description:
          "Kerela styled fried chicken",
        foodimg: nadenchicken,
      },
      {
        id: 26,
        item: "Fauladi pahadi kebab",
        category: "Non Vegetarian",
        description:
          "Boneless pieces of chicken marinated with pahadi masala",
        foodimg: Fauladipahadikebab,
      },
      {
        id: 27,
        item: "Bhendi bazaar seekh paratha",
        category: "Non Vegetarian",
        description:
          "Mutton seekh kebab served with crispy paratha inspired from the street vendors of bhendi bazar in bombay",
        foodimg: bhendi,
      },
      {
        id: 28,
        item: "Prawn kolbi fry",
        category: "Non Vegetarian",
        description:
          "Mutton seekh kebab served with crispy paratha inspired from the street vendors of bhendi bazar in bombay",
        foodimg: prawn,
      },



    ],
    MainCourse: [
      {
        id: 10,
        item: "Chef Pratap’s ghar ki bhendi",
        category: "Vegetarian",
        description:
          "Our chefs soul recipe of okra served with butter tandoori roti",
        foodimg: pratap,
      },
      {
        id: 11,
        item: "Mushroom patio with PAV",
        category: "Vegetarian",
        description:
          "Common parsi house hold dish #Bawaspeciality",
        foodimg: parsi,
      },
      {
        id: 12,
        item: "Daal Makhani with butter naan",
        category: "Vegetarian",
        description:
          "",
        foodimg: dalmakhani,
      },
      {
        id: 13,
        item: "Baap ki dal with jeera rice",
        category: "Vegetarian",
        description:
          "This time #Baapkidal kha ke dekho",
        foodimg: baap,
      },
      {
        id: 14,
        item: "Paneer lababdar with butter garlic naan",
        category: "Vegetarian",
        description:
          "The best paneer dish.",
        foodimg: paneerlababdar,
      },
      {
        id: 15,
        item: "Rajasthani curry pakora bowl with basmati rice",
        category: "Vegetarian",
        description:
          "#RajasthaniMealInBowl",
        foodimg: rajasthani,
      },
      {
        id: 16,
        item: "Dal Khichdi",
        category: "Vegetarian",
        description:
          "",
        foodimg: khichdi,
      },
      {
        id: 17,
        item: "Murgh Mussallam 2.0",
        category: "Non Vegetarian",
        description:
          "Isko nahin khaaya toh kya khaaya? If you loved it call chef pratap",
        foodimg: murghMussallam,
      },
      {
        id: 18,
        item: "Flambe butter chicken",
        category: "Non Vegetarian",
        description:
          "Traditional chicken spiked with JD shot #MustTry",
        foodimg: ChilliChickenImg,
      },
      {
        id: 19,
        item: "Salli chicken",
        category: "Non Vegetarian",
        description:
          "Parsi style chicken curry topped with potato salli served with pav",
        foodimg: salli,
      },
    ],
    Dessert: [
      {
        id: 20,
        item: "Parle G cheese cake",
        category: "Vegetarian",
        description:
          "",
        foodimg: parleG,
      },
      {
        id: 21,
        item: "Gajjar halwa with kulfi ice creame",
        category: "Vegetarian",
        description: "",
        foodimg: gajjar,
      },
      {
        id: 22,
        item: "Super caramel custard",
        category: "Vegetarian",
        description:
          "",
        foodimg: custard,
      },
      {
        id: 23,
        item: "Fruit platter",
        category: "Vegetarian",
        description:
          "",
        foodimg: fruit,
      },
    ],
    Sides: [
      {
        id: 23,
        item: "Indian Breadbasket",
        category: "Vegetarian",
        description:
          "Assorted Indian bread, a delightful mix of rotis, naans, and parathas, offering diverse flavors",
        foodimg: BreadImg,
      },
      {
        id: 24,
        item: "Steamed Rice",
        category: "Vegetarian",
        description: "Basmati rice",
        foodimg: SteamedRiceImg,
      },
      {
        id: 25,
        item: "Papad",
        category: "Vegetarian",
        description:
          "Crunchy, thin papad - a crispy appetizer, a versatile accompaniment to complement Indian cuisine",
        foodimg: PapadImg,
      },
      {
        id: 26,
        item: "Pickle",
        category: "Vegetarian",
        description:
          "Zesty, tangy pickle bursting with spice, a traditional Indian condiment, elevating every meal",
        foodimg: PickelImg,
      },
      {
        id: 27,
        item: "Salad",
        category: "Vegetarian",
        description: "Freshly sliced green vegetable salad.",
        foodimg: SaladImg,
      },
    ],
    "Beverage menu": [],
    BottleBeer: [
      {
        id: 35,
        item: "Budweiser",
        category: "bottleBeers",
        description: "330ml Budweiser Bottle Beer",
        foodimg: BudwiserImg,
      },
    ],
    Wine: [
      {
        id: 33,
        item: "Red Wine",
        category: "Wines",
        description:
          "Bold and robust, a velvety blend of dark fruits and subtle oak undertones",
        foodimg: RedWineImg,
      },
      {
        id: 34,
        item: "White Wine",
        category: "Wines",
        description:
          "Delicate and crisp, a symphony of citrus and floral notes in each pour",
        foodimg: WhiteWineImg,
      },
    ],
    Spirits: {
      Whiskey: [
        {
          id: 88,
          item: "JW Red Label",
          category: "Whiskey",
          description:
            "Bold and iconic, Red Label whiskey offers a rich, smooth taste with a hint of smokiness",
          foodimg: redlabel,
        },
      ],
      Rum: [
        {
          id: 86,
          item: "Bacardi",
          category: "Rum",
          description:
            "Offers a subtle sweetness and refreshing character, ideal for mixing vibrant cocktails",
          foodimg: bacardi,
        },
      ],
      Gin: [
        {
          id: 87,
          item: "Tanqueray",
          category: "Gin",
          description:
            "Crisp and juniper-forward, Tanqueray gin boasts a well-balanced botanical profile",
          foodimg: Tanqueray,
        },
      ],
      Vodka: [
        {
          id: 99,
          item: "Stolichnaya",
          category: "Vodka",
          description:
            "Delivers a clean, smooth flavor, perfect for crafting versatile cocktails",
          foodimg: stolich,
        },
      ],
    },
    NonAlcohol: [
      {
        id: 28,
        item: "Soft Drinks",
        category: "non-alcoholic",
        description: "150ml",
        foodimg: SoftDrinksImg,
      },
      {
        id: 29,
        item: "Tetra Packet Juice",
        category: "non-alcoholic",
        description: "210ml Fresh Juice",
        foodimg: TetraPackImg,
      },
      {
        id: 30,
        item: "Tea/Coffee",
        category: "non-alcoholic",
        description:
          "A robust brew awakening your senses, delivering rich flavor in every sip",
        foodimg: CoffeeImg,
      },
      {
        id: 31,
        item: "Water",
        category: "non-alcoholic",
        description: "1 L fresh watert",
        foodimg: WaterImg,
      },
    ],
  },
});

const menuReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_ITEM:
      return {
        ...state,
        [action.payload.category]: [
          ...state[action.payload.category],
          action.payload.item,
        ],
      };

    case REMOVE_ITEM:
      return {
        ...state,
        [action.payload.category]: state[action.payload.category].filter(
          (item) => item.id !== action.payload.itemId
        ),
      };

    case EDIT_ITEM:
      return {
        ...state,
        [action.payload.category]: state[action.payload.category].map((item) =>
          item.id === action.payload.itemId ? action.payload.updatedItem : item
        ),
      };

    default:
      return state;
  }
};

export default menuReducer;
