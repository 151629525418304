import React, { useState, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../Styles/PackageInfo.css";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import header from "../../Images/Image.png";
import AvocadoMakiImg from "../../Images/image 109.png";
import RedVelvetImg from "../../Images/image 113.png";
import ChickenPinkImg from "../../Images/image 112.png";
import ChickenDumplingImg from "../../Images/image 101.png";
import PennePinkSauceImg from "../../Images/image 111.png";
import RedWineImg from "../../Images/Red Wine.png";
import { Navbar2 } from "../../Components/NavbarTwo";

const ValentineInfo = () => {
  const navigate = useNavigate();
  const disclosurePanelWrapperRef = useRef(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const [initialData] = useState({
    appetizers: [
      {
        id: 1,
        item: "Spicy Avocado Maki Roll ",
        category: "Vegetarian",
        description: "Creamy avocado meets fiery spice in this sushi favorite.",
        foodimg: AvocadoMakiImg,
      },
      {
        id: 2,
        item: "Chicken Dumpling",
        category: "Non Vegetarian",
        description:
          "Tender chicken wrapped in delicate dumpling skin, bursting with flavor.",
        foodimg: ChickenDumplingImg,
      },
    ],
    mainCourses: [
      {
        id: 3,
        item: "Penne Pasta Mix Sauce",
        category: "Vegetarian",
        description:
          "Penne pasta tossed in a medley of savory sauces for a flavor explosion.",
        foodimg: ChickenPinkImg,
      },
      {
        id: 4,
        item: "Grilled Chicken with pink sauce pasta",
        category: "Non Vegetarian",
        description:
          "Tender grilled chicken paired with creamy pink sauce pasta.",
        foodimg: PennePinkSauceImg,
      },
    ],
    desserts: [
      {
        id: 5,
        item: "Red Velvet Cake Mix Berry Compote",
        category: "Vegetarian",
        description:
          "Indulge in decadent red velvet cake with tangy berry compote.",
        foodimg: RedVelvetImg,
      },
    ],
    Wines: [
      {
        id: 6,
        item: "Red Wine",
        category: "Wines",
        description:
          "Bold and robust, a velvety blend of dark fruits and subtle oak undertones",
        foodimg: RedWineImg,
      },
    ],
  });

  const foodoption = [
    { id: 1, name: "Appetizer" },
    { id: 2, name: "Main Course" },
    { id: 3, name: "Dessert" },
  ];

  const { Wines } = initialData;

  const category = params.get("category");
  const duration = params.get("duration");
  const initialPrice = parseInt(params.get("price"), 10);
  const initialDiscountedPrice = parseInt(params.get("dicsPrice"), 10);
  const pax = parseInt(params.get("pax"), 10);
  const packageName = params.get("packageName");
  const selectedPackage = params.get("selectedPackage");

  //************** Food menu *************

  const renderFoodItemsTwo = (categoryName, index) => {
    let renderItems = [];
    switch (categoryName) {
      case "Appetizer":
        renderItems = initialData.appetizers;
        break;
      case "Main Course":
        renderItems = initialData.mainCourses;
        break;
      case "Dessert":
        renderItems = initialData.desserts;
        break;
      case "Wines":
        renderItems = initialData.Wines;
        break;
      default:
        break;
    }

    return renderItems.map((item, index) => (
      <div key={index} className="whole-div">
        <div className="ItemImageWrapper5">
          <img src={item?.foodimg} alt="FoodImage" />
        </div>
        <div className="ItemDetailWrapper2">
          <div className="ItemDetailLogo">
            {item?.category === "Vegetarian" ? (
              <div className="veg-div">
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="15"
                    height="15"
                    rx="1.5"
                    stroke="#009600"
                  />
                  <circle cx="8" cy="8" r="4" fill="#009600" />
                </svg>
              </div>
            ) : item?.category === "Non Vegetarian" ? (
              <div className="nonveg-div">
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="15"
                    height="15"
                    rx="1.5"
                    stroke="#963415"
                  />
                  <path
                    d="M4.38833 12C4.20992 12 4.09388 11.8122 4.17367 11.6527L7.78534 4.42932C7.87378 4.25243 8.12622 4.25243 8.21466 4.42933L11.8263 11.6527C11.9061 11.8122 11.7901 12 11.6117 12H4.38833Z"
                    fill="#963415"
                  />
                </svg>
              </div>
            ) : item?.category === "Wines" ? (
              <div className="veg-div">
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect
                    x="0.5"
                    y="0.5"
                    width="15"
                    height="15"
                    rx="1.5"
                    stroke="#009600"
                  />
                  <circle cx="8" cy="8" r="4" fill="#009600" />
                </svg>
              </div>
            ) : null}
          </div>
          <div className="ItemDetailName">{item?.item}</div>
          <div className="ItemDetailDesc">{item?.description}</div>
        </div>
      </div>
    ));
  };

  let allCapsCategory = "";
  switch (category) {
    case "Valentine":
      allCapsCategory = "VALENTINE SPECIAL";
      break;
    default:
      allCapsCategory = category;
  }

  //************** Beverge menu *************

  const beverageOptions = [{ id: 1, name: "Wines" }];

  const renderBeverageOptions = (options) => {
    return options.map((option) => {
      return (
        <div key={option.id} className="w-full">
          <div className="w-full rounded-2xl bg-white pb-2 pt-2">
            <Disclosure defaultOpen={option.name === "Wines"}>
              {({ open }) => {
                return (
                  <>
                    <Disclosure.Button
                      className="flex w-full justify-between rounded-lg"
                      style={{
                        background: "white",
                        padding: "0.75rem 0.5rem",
                        borderRadius: open ? " 0.5rem 0.5rem 0 0" : " 0.5rem",
                        border: "1px solid #E5E5EA",
                        boxShadow: "0px 4px 12px 0px rgba(34, 34, 34, 0.06)",
                        borderBottom: open ? "none" : "1px solid #E5E5EA",
                      }}
                    >
                      <span
                        style={{ fontWeight: "400", paddingLeft: "0.5rem" }}
                      >
                        {option.name}
                      </span>
                      <ChevronDownIcon
                        className={`${
                          open ? "rotate-180 transform" : ""
                        } h-5 w-5 text-black-700`}
                      />
                    </Disclosure.Button>
                    <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                      <div className="selected-food-list5">
                        {renderFoodItemsTwo(option.name)}
                      </div>
                    </Disclosure.Panel>
                  </>
                );
              }}
            </Disclosure>
          </div>
        </div>
      );
    });
  };

  const handleContinueClick = () => {
    const dataToSend = {
      category,
      duration,
      initialPrice,
      initialDiscountedPrice,
      pax,
      packageName,
      selectedPackage,
      Wines,
      selectedItems: initialData,
    };
    navigate("/valentineDate/barbaar", { state: dataToSend });
  };

  return (
    <>
      <Navbar2 />
      <img className="headerImgPackage" src={header} alt="foodImage" />
      <div className="PackageInfoMain">
        <div className="PackageInfoInnerLeft">
          <div className="packageLeftOne">
            <div className="packageNameDiv">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M11.082 2.33301H2.91536C2.76065 2.33301 2.61228 2.38217 2.50289 2.46969C2.39349 2.55721 2.33203 2.67591 2.33203 2.79967V11.1997C2.33203 11.3234 2.39349 11.4421 2.50289 11.5297C2.61228 11.6172 2.76065 11.6663 2.91536 11.6663H11.082C11.2367 11.6663 11.3851 11.6172 11.4945 11.5297C11.6039 11.4421 11.6654 11.3234 11.6654 11.1997V2.79967C11.6654 2.67591 11.6039 2.55721 11.4945 2.46969C11.3851 2.38217 11.2367 2.33301 11.082 2.33301Z"
                    fill="#4A6AFE"
                  />
                  <path
                    d="M5.83203 2.33301H8.16536V6.99967L6.9987 5.83301L5.83203 6.99967V2.33301Z"
                    fill="#DBE1FF"
                  />
                </svg>
                {allCapsCategory}
              </div>
            </div>
            <h1> Valentine's Day Package </h1>
            <p>International , Indo Global</p>
            <div className="inside-nonAlcoholic">
              <div
                style={{
                  fontSize: "1.1rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "end",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 15 15"
                  fill="none"
                >
                  <path
                    d="M11 6C11 8.76 8.76 11 6 11C3.24 11 1 8.76 1 6C1 3.24 3.24 1 6 1C8.76 1 11 3.24 11 6Z"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.85494 7.58988L6.30494 6.66488C6.03494 6.50488 5.81494 6.11988 5.81494 5.80488V3.75488"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {duration} hours
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2"
                  height="2"
                  viewBox="0 0 2 2"
                  fill="none"
                >
                  <circle cx="1" cy="1" r="1" fill="#8E8E93" />
                </svg>
              </div>
              <div
                style={{
                  fontSize: "1.1rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "end",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 15 15"
                  fill="none"
                >
                  <path
                    d="M4.57979 5.435C4.52979 5.43 4.46979 5.43 4.41479 5.435C3.22479 5.395 2.27979 4.42 2.27979 3.22C2.27979 1.995 3.26979 1 4.49979 1C5.72479 1 6.71978 1.995 6.71978 3.22C6.71478 4.42 5.76979 5.395 4.57979 5.435Z"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.20514 2C9.17514 2 9.95514 2.785 9.95514 3.75C9.95514 4.695 9.20514 5.465 8.27014 5.5C8.23014 5.495 8.18514 5.495 8.14014 5.5"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.07986 7.28C0.869863 8.09 0.869863 9.41 2.07986 10.215C3.45486 11.135 5.70986 11.135 7.08486 10.215C8.29486 9.405 8.29486 8.085 7.08486 7.28C5.71486 6.365 3.45986 6.365 2.07986 7.28Z"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.16992 10C9.52992 9.925 9.86992 9.78 10.1499 9.565C10.9299 8.98 10.9299 8.015 10.1499 7.43C9.87492 7.22 9.53992 7.08 9.18492 7"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {pax} pax min
              </div>
            </div>
          </div>

          {/* veg only toggle */}
          <div className="packageLeftTwo">
            <div className="FoodmenuIconPar">
              <div>Food Menu</div>
            </div>

            {/* ************** Food menu Selection Required  ********** */}
            <div className="closure-div" ref={disclosurePanelWrapperRef}>
              {foodoption.map((category) => (
                <div key={category.id} className="w-full">
                  <div className="w-full rounded-2xl bg-white pb-2 pt-2">
                    <Disclosure
                      defaultOpen={
                        category.name === "Appetizer" ||
                        category.name === "Main Course" ||
                        category.name === "Dessert"
                      }
                    >
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className="flex w-full justify-between rounded-lg"
                            style={{
                              background: "white",
                              padding: "0.75rem 0.5rem",
                              borderRadius: open
                                ? " 0.5rem 0.5rem 0 0"
                                : " 0.5rem",
                              border: "1px solid #E5E5EA",
                              borderBottom: open ? "none" : "1px solid #E5E5EA",
                              boxShadow:
                                "0px 4px 12px 0px rgba(34, 34, 34, 0.06)",
                            }}
                          >
                            <span
                              style={{
                                fontWeight: "400",
                                paddingLeft: "0.5rem",
                              }}
                            >
                              {category.name}{" "}
                            </span>
                            <ChevronDownIcon
                              className={`${
                                open ? "rotate-180 transform" : ""
                              } h-5 w-5 text-black-700`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                            <div className="selected-food-list5">
                              {renderFoodItemsTwo(category.name, 0)}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              ))}
            </div>

            {/* *****************Beverage menu ****** */}
            <>
              <div className="FoodmenuIconPar">Beverage Menu</div>
              <div className="closure-div">
                {renderBeverageOptions(beverageOptions)}
              </div>
            </>
          </div>
        </div>
      </div>
      {/* {/ Sticky Footer /} */}
      <div className="PackageInfofooter">
        <button onClick={handleContinueClick}>Continue</button>
      </div>
    </>
  );
};

export default ValentineInfo;
