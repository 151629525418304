export const ADD_ITEM = 'ADD_ITEM';
export const REMOVE_ITEM = 'REMOVE_ITEM';
export const EDIT_ITEM = 'EDIT_ITEM';

export const addItem = (category, item) => ({
  type: ADD_ITEM,
  payload: { category, item },
});

export const removeItem = (category, itemId) => ({
  type: REMOVE_ITEM,
  payload: { category, itemId },
});

export const editItem = (category, itemId, updatedItem) => ({
  type: EDIT_ITEM,
  payload: { category, itemId, updatedItem },
});
