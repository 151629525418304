import React, { useEffect, useLayoutEffect, useState } from "react";
import Navbar from "../Components/Navbar";
import IndividualIntervalsExample from "../Components/Carousel";
import Body from "../Components/Body";
import Footer from "../Components/Footer";
import TextCarousel from "../Components/TextCarousel";
import Questions from "../Components/Questions";
import MobileScroll from "../Components/MobileScroll/Index";
import axios from "axios";
import CarauselForMobile from "../Components/MobileScrollTwo/CarauselForMobile";


const HomePage = () => {
  const [companyData, setCompanyData] = useState([]);
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    const currentUrl = window.location.href;
    const path = currentUrl.substring(currentUrl.lastIndexOf("/") + 1);
    fetchCompanyData(path);
  }, []);


  useEffect(() => {
    localStorage.setItem("companyData", JSON.stringify(companyData));
  }, [companyData]);


  useLayoutEffect(() => {
    setIsMobile(window.innerWidth <= 640);


    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  const fetchCompanyData = async (companyName) => {
    const hostname = window.location.hostname;
    let apiUrl;
    if (
      hostname.includes("testgrouple.com") ||
      hostname.includes("localhost")
    ) {
      apiUrl = `https://server.testgrouple.com/api/v1/enduser/comapny/merchantInformation/${companyName}`;
    }
    if (hostname.includes("grouple.tech")) {
      apiUrl = `https://production.grouple.tech/api/v1/enduser/comapny/merchantInformation/${companyName}`;
    }
    try {
      let response = await axios.get(apiUrl);
      setCompanyData(response.data.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };


  return loading ? (
    <div className="flex items-center justify-center h-screen">
      <div className="animate-spin rounded-full border-t-4 border-primary h-12 w-12"></div>
    </div>
  ) : (
    <div>
      <Navbar />
      <IndividualIntervalsExample />
      <TextCarousel />
      {isMobile ? <CarauselForMobile /> : <MobileScroll />}
      <Body />
      <Questions />
      <Footer />
    </div>
  );
};


export default HomePage;





