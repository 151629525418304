import React, { useState, useEffect } from 'react';
import { Navbar2 } from '../Components/NavbarTwo';
import Footer from '../Components/Footer';
import DateAndTime from '../Components/DateAndTime';

const RulesAndTime = () => {

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  // /////////
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isMobile]);


  return (
    <div>
      <Navbar2 />
      <DateAndTime />
      <div className={isMobile ? 'footer-hidden' : ''}>
        <Footer />
      </div>
    </div>
  );
}

export default RulesAndTime;
