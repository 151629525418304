import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "../Styles/TimeSlotModal.css";

const TimeSlotModal = ({
  isOpen,
  onClose,
  setSelectedTime,
  handleSaveTime,
  setDisplayedTime,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.classList.add('no-scroll');
    } else {
      document.body.classList.remove('no-scroll');
    }
    return () => {
      document.body.classList.remove('no-scroll');
    };
  }, [isOpen]);

  if (!isOpen) return null;

  const timeSlots = [
    { value: "19:00 to 23:00", label: "7:00 PM - 11:00 PM" },
    { value: "23:00 to 03:00", label: "11:00 PM - 3:00 AM" },
  ];

  const handleTimeSlotClick = (slot) => {
    setSelectedTime(slot.value);
    handleSaveTime();
    setDisplayedTime(slot.label);
    onClose();
  };

  return ReactDOM.createPortal(
    <div className="modal-overlay">
      <div className="modal-content">
        <div className="pr-5 py-2 border-b border-gray-200 justify-between flex">
          <div className="w-11/12 text-center text-black text-xl font-normal font-['Gilroy-SemiBold']">
            Time slot
          </div>
          <div className="w-6 h-6 relative" onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M7 7L17 17"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7 17L17 7"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        <div className="time-slots">
          {timeSlots.map((slot, index) => (
            <div
              className="py-3 bg-white rounded border border-zinc-300 justify-center items-center gap-2.5 inline-flex"
              key={index}
              onClick={() => {
                handleTimeSlotClick(slot);
              }}
            >
              <div className="text-neutral-800 text-base font-normal font-['Gilroy-Medium']">
                {slot.label}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>,
    document.body
  );
};

export default TimeSlotModal;
