import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import "../Styles/Request.css";
// import rectangle from "../Images/ReservationImage1.png";
import rectangle from "../Images/mokshabook.png";
import rectangle2 from "../Images/pahadibook.png";
import { Link, useParams } from "react-router-dom";
import logo from "../Images/barbaar logo.png";
import LoadingAnimation from "../Images/LOADINGaNIMATION.gif";
const InviteeIndividual = () => {
  const { randomReservationID, type } = useParams();
  const [bookingData, setbookingData] = useState(null);
  const [isCopied2, setIsCopied2] = useState(false);

  const copyToClipboard4 = () => {
    const hostingName = window.location.hostname;
    let url;
    if (
      hostingName.includes("testgrouple.com") ||
      hostingName.includes("localhost")
    ) {
      url = `https://maps.app.goo.gl/icrtXDSqxQfSFiia6`;
    }
    if (hostingName.includes("grouple.tech")) {
      url = `https://maps.app.goo.gl/icrtXDSqxQfSFiia6}`;
    }

    navigator.clipboard
      .writeText(url)
      .then(() => setIsCopied2(true))
      .catch((error) => console.error("Failed to copy to clipboard", error));
  };

  useEffect(() => {
    if (randomReservationID) {
      const hostname = window.location.hostname;
      let apiUrl;
      if (
        hostname.includes("testgrouple.com") ||
        hostname.includes("localhost")
      ) {
        apiUrl = `https://server.testgrouple.com/api/v1/beta/enduser/viewIndividualReservation/${type}/${randomReservationID}`;
      }
      if (hostname.includes("grouple.tech")) {
        apiUrl = `https://production.grouple.tech/api/v1/beta/enduser/viewIndividualReservation/${type}/${randomReservationID}`;
      }

      fetch(apiUrl)
        .then((res) => res.json())
        .then((data) => {
          setbookingData(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [randomReservationID]);
  if (!bookingData) {
    return (
      <p
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "80vh",
        }}
      >
        <img src={LoadingAnimation} alt="Loading......" />
      </p>
    );
  }

  // ********** date ***********//

  const displayDate = bookingData.data.bookingDate;
  const date = new Date(displayDate);
  let showDate;

  if (
    Object.prototype.toString.call(date) === "[object Date]" &&
    !isNaN(date)
  ) {
    const options = { weekday: "short", day: "numeric", month: "short" };
    showDate = date.toLocaleDateString("en-GB", options);
  }

  return (
    <div>
      <nav className="navbar-div2">
        <div className="inner-navdiv2">
          <Link to="/landingPage/janjaes" style={{ textDecoration: "none" }}>
            <img src={bookingData.data.logo} alt="" className="barbaar-logo" />
          </Link>
        </div>
      </nav>

      <div className="RequestWrapper">
        {/* LEFT */}
        <div className="RequestWrapperLeft">
          <div className="RequestStatusWrapper">
            <div className="RequestSuccess">
              <div>Your Dining Invitation 🎉</div>
            </div>

            <p className="RequestStatusDetails">
              {bookingData.data.bookerFirstName}{" "}
              {bookingData.data.bookerLastName} has booked a group reservation,
              and you've been invited to join a delightful experience, exquisite
              dining, laughter, and unforgettable memories.
            </p>
          </div>

          <div className="svgLine">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              height="1"
              viewBox="0 0 610 1"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M610 1H0V0H610V1Z"
                fill="#E5E5EA"
              />
            </svg>
          </div>

          {/* map */}
          <div style={{ paddingBottom: "2rem", marginTop: "0.5rem" }}>
            <div className="MapHeading">
              <h2>Getting there</h2>
              <p>Address</p>
              <h3>{bookingData.data.bookingOutletAddress}</h3>
            </div>

            {bookingData.data.bookingOutlet === "Moksha" && (
              <iframe
                title="Google Maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1804.1926826016163!2d55.29822731646945!3d25.257618831496888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f4375fd849943%3A0xb0a517db1521f1fe!2sMoksha%20by%20Barbaar!5e0!3m2!1sen!2sin!4v1715958283377!5m2!1sen!2sin"
                width="100%"
                height="300"
                style={{ border: "0px", borderRadius: "0.5rem" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            )}

            {bookingData.data.bookingOutlet === "Pahadi" && (
              <iframe
                title="Google Maps"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.384980470542!2d55.294873472981536!3d25.257631779924882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f430d63cabdf7%3A0x949944af77d22f2d!2sPahadi!5e0!3m2!1sen!2sin!4v1715956881355!5m2!1sen!2sin"
                width="100%"
                height="300"
                style={{ border: "0px", borderRadius: "0.5rem" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            )}

            <div className="ConciergeWrapperCall" onClick={copyToClipboard4}>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 22 22"
                  fill="none"
                >
                  <path
                    d="M16.4987 7.33301H9.16536C8.67913 7.33301 8.21282 7.52616 7.869 7.86998C7.52519 8.2138 7.33203 8.68011 7.33203 9.16634V16.4997C7.33203 16.9859 7.52519 17.4522 7.869 17.796C8.21282 18.1399 8.67913 18.333 9.16536 18.333H16.4987C16.9849 18.333 17.4512 18.1399 17.7951 17.796C18.1389 17.4522 18.332 16.9859 18.332 16.4997V9.16634C18.332 8.68011 18.1389 8.2138 17.7951 7.86998C17.4512 7.52616 16.9849 7.33301 16.4987 7.33301Z"
                    stroke="#4A6AFE"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M14.668 7.33366V5.50033C14.668 5.0141 14.4748 4.54778 14.131 4.20396C13.7872 3.86015 13.3209 3.66699 12.8346 3.66699H5.5013C5.01507 3.66699 4.54876 3.86015 4.20494 4.20396C3.86112 4.54778 3.66797 5.0141 3.66797 5.50033V12.8337C3.66797 13.3199 3.86112 13.7862 4.20494 14.13C4.54876 14.4738 5.01507 14.667 5.5013 14.667H7.33464"
                    stroke="#4A6AFE"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {isCopied2 ? "Address Copied Succesfully!" : "Copy Address"}
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                >
                  <path
                    d="M7.5 15L12.5 10L7.5 5"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>

        {/* RIGHT */}
        <div className="RequestWrapperRight1">
          <div className="RequestWrapperChild1">
            <img
              className="resCardImgOne1"
              src={
                bookingData.data.bookingOutlet === "Moksha"
                  ? rectangle
                  : rectangle2
              }
              alt=""
            />

            {/* outlet addres */}
            <div className="everything-wrapper1">
              <div className="RequestDateAndTimeWrapper1">
                {bookingData.data.bookingOutlet}
              </div>

              <div className="RequestSummaryChild1">
                {bookingData.data.bookingOutletAddress}
              </div>

              <div className="RequestSummaryChild2">Individual Package</div>
            </div>

            {/* bookin date/time/guest */}
            <div
              className="RequestSummaryChild3"
              style={{ width: "65%", border: "none" }}
            >
              <div>{showDate}</div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2"
                height="2"
                viewBox="0 0 2 2"
                fill="none"
              >
                <circle cx="1" cy="1" r="1" fill="#242426" />
              </svg>
              <div>{bookingData.data.bookingSlotStartTime} </div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="2"
                height="2"
                viewBox="0 0 2 2"
                fill="none"
              >
                <circle cx="1" cy="1" r="1" fill="#242426" />
              </svg>
              <div>{bookingData.data.bookingTotalGuest} Guests</div>
            </div>

            {/* total price */}
            <div
              className="RequestSummaryWrapper"
              style={{ borderTop: "1px solid #E5E5EA" }}
            >
              <div className="RequestStatusHeading3">
                <div>Reservation ID </div>
                <div>#{bookingData.data.reservationCode}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default InviteeIndividual;
