import React, { useEffect, useState } from "react";
import "../Styles/Navbar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";

export const Navbar2 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const estateCode = localStorage.getItem("estateCode");
  const [isLoading, setIsLoading] = useState(true);
  const [navLogo, setNavLogo] = useState("");
  const [navbarText, setNavbarText] = useState("");
  const [navUrl, setNavUrl] = useState("/landingPage/Janjaes");


  const handleBackClick = () => {
    const currentPath = window.location.pathname;
    setIsLoading(true);
    const redirectToPackageInfoPaths = ["/summary/janjaes", "/DateAndTime"];
    if (redirectToPackageInfoPaths.includes(currentPath)) {
      window.location.href = "/package/janjaes";
      setIsLoading(false);
    } else {
      window.history.back();
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = () => {
      setIsLoading(true);
      const compData = JSON.parse(localStorage.getItem("companyData"));
      const estateData = compData[0].estates.find(
        (estate) => estate.estatecode === estateCode
      );

      let logo;
      if (location.pathname === "/location") {
        logo = compData ? compData[0].compnay_logo : "";
        setNavUrl("/landingPage/Janjaes");
      } else if (estateData) {
        logo = estateData.logo;
        setNavUrl(`/package/${estateData.estate_name}`);
      } else {
        logo = compData[0].compnay_logo;
        setNavUrl("/landingPage/Janjaes");
      }

      setNavLogo(logo);

      if (location.pathname.startsWith("/package/")) {
        setTimeout(() => {
          setIsLoading(false);
        }, 2000); 
      } else {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [estateCode, location.pathname]);

    

  useEffect(() => {
    const updateNavbarText = () => {
      const compData = JSON.parse(localStorage.getItem("companyData"));
      const estateData = compData[0].estates.find(
        (estate) => estate.estatecode === estateCode
      );

      if (location.pathname === "/location") {
        setNavbarText("Outlets");
      } else if (location.pathname.startsWith("/package/")) {
        setNavbarText(estateData ? estateData.estate_name : "");
      } else if (location.pathname.startsWith("/groupSize/")) {
        setNavbarText("Experiences");
      } else if (location.pathname.startsWith("/personalInfo/foodmenu")) {
        setNavbarText("Food and beverage");
      } else if (location.pathname.startsWith("/personalInfo/")) {
        setNavbarText("Experiences");
      } else if (location.pathname === "/datemobile") {
        setNavbarText("Book a reservation");
      } else if (location.pathname.startsWith("/personalGuestSize")) {
        setNavbarText("Book a reservation");
      } else if (location.pathname.startsWith("/FandB/")) {
        setNavbarText("Food and beverage");
      } else if (location.pathname.startsWith("/groupSummary")) {
        setNavbarText("Confirm and reserve");
      } else if (location.pathname.startsWith("/groupRes/")) {
        setNavbarText("Personal details");
      } else if (location.pathname.startsWith("/PersonalRes/janjaes")) {
        setNavbarText("Personal details");
      } else {
        setNavbarText("");
      }
    };

    updateNavbarText();
  }, [location.pathname, estateCode]);

  return (
    <>
      {isLoading ? (
        <div className="flex items-center justify-center h-screen">
          <div className="animate-spin rounded-full border-t-4 border-primary border-t-primary h-12 w-12"></div>
        </div>
      ) : (
        <nav className="navbar-div2" style={{ display: "flex" }}>
          <div style={{ width: "10%" }}>
            <button
              onClick={handleBackClick}
              style={{
                fontSize: "1rem",
                textDecoration: "underline",
                fontWeight: "400",
                fontFamily: "Gilroy-Medium",
              }}
            >
              Back
            </button>
          </div>
          <div className="backArrowDiv">
            <button
              onClick={handleBackClick}
              style={{
                fontWeight: "400",
                fontFamily: "Gilroy-Medium",
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M4 12H20"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M10 18L4 12L10 6"
                  stroke="black"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>

          <div
            className="MobileTExtParent"
            style={{ width: "90%", display: "flex", justifyContent: "center" }}
          >
            <Link
             to={navUrl}
              style={{ textDecoration: "none" }}
            >
              <img src={navLogo} alt="logo" className="barbaar-logo2" />
            </Link>
            <div className="NavbarText">{navbarText}</div>
          </div>
        </nav>
      )}
    </>
  );
};
