import React, { useEffect, useState } from "react";
import "../Styles/Navbar.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { setEstateCodeForTheme, useTheme } from "./ColorContext";

const NavbarThree = () => {
  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [navLogo, setNavLogo] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const estateCode = localStorage.getItem("estateCode");
  const [estateName, setEstateName] = useState("");


  const { themeColor } = useTheme();
  setEstateCodeForTheme(estateCode)


  useEffect(() => {
    const fetchData = async () => {
      try {
        const compData = JSON.parse(localStorage.getItem("companyData"));
        const estateData = compData[0].estates.find(
          (estate) => estate.estatecode === estateCode
        );
        let logo;
        let name;
        if (location.pathname === "/location") {
          logo = compData ? compData[0].compnay_logo : "";
        } else if (estateData) {
          logo = estateData.logo;
          name = estateData.estate_name;
        } else {
          logo = compData[0].compnay_logo;
        }
        setNavLogo(logo);
        setEstateName(name);
      } catch (error) {
        console.error("Error fetching data", error);
      } finally {
        setIsLoading(false);
      }
    };


    fetchData();
  }, [estateCode, location.pathname]);


  const handleBackClick = () => {
    if (location.pathname === `/package/${estateName}`) {
      navigate("/landingPage/janjaes");
    } else {
      setPopupVisibility(true);
    }
  };


  const closePopup = () => {
    setPopupVisibility(false);
  };


  const onSureClick = () => {
    navigate(`/package/${estateName}`);
    setPopupVisibility(false);
  };


  return (
    <>
      <nav className="navbar-div2" style={{ display: "flex" }}>
        <div style={{ width: "10%" }}>
          <button
            onClick={handleBackClick}
            style={{
              fontSize: "1rem",
              textDecoration: "underline",
              fontWeight: "400",
              fontFamily: "Gilroy-Medium",
            }}
          >
            Back
          </button>
        </div>
        <div className="backArrowDiv">
          <button
            onClick={handleBackClick}
            style={{
              fontWeight: "400",
              fontFamily: "Gilroy-Medium",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
            >
              <path
                d="M4 12H20"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10 18L4 12L10 6"
                stroke="black"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </button>
        </div>


        <div
        className="MobileTExtParent"
          style={{ width: "90%", display: "flex", justifyContent: "center" }}
        >
          <Link  to={`/package/${estateName}`} style={{ textDecoration: "none" }}>
            {isLoading ? (
              <div className="flex items-center justify-center h-screen">
                <div className="animate-spin rounded-full border-t-4 border-primary border-t-primary h-12 w-12"></div>
              </div>
            ) : (
              <img src={navLogo} alt="" className="barbaar-logo3" />
            )}
          </Link>
        
              <div className="NavbarText">Confirm and reserve</div>
           
        </div>
      </nav>


      {isPopupVisible && (
        <div className="food-popup-background-navbar">
          <div className="food-popup-navbar">
            <div className="relative p-4 w-full max-w-sm max-h-full">
              <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
                <button
                  type="button"
                  className="absolute top-3 end-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={closePopup}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M7 7L17 17"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M7 17L17 7"
                      stroke="black"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </button>
                <div className="md:p-5 w-full popup-wrapper">
                  <svg
                    className="excl"
                    xmlns="http://www.w3.org/2000/svg"
                    width="52"
                    height="52"
                    viewBox="0 0 52 52"
                    fill="none"
                  >
                    <path
                      d="M26 45.5C28.5608 45.5 31.0965 44.9956 33.4623 44.0157C35.8282 43.0357 37.9778 41.5993 39.7886 39.7886C41.5993 37.9778 43.0357 35.8282 44.0157 33.4623C44.9956 31.0965 45.5 28.5608 45.5 26C45.5 23.4392 44.9956 20.9035 44.0157 18.5377C43.0357 16.1718 41.5993 14.0222 39.7886 12.2114C37.9778 10.4007 35.8282 8.96432 33.4623 7.98435C31.0965 7.00438 28.5608 6.5 26 6.5C20.8283 6.5 15.8684 8.55446 12.2114 12.2114C8.55446 15.8684 6.5 20.8283 6.5 26C6.5 31.1717 8.55446 36.1316 12.2114 39.7886C15.8684 43.4455 20.8283 45.5 26 45.5Z"
                      stroke="black"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M26 17.333V25.9997"
                      stroke="black"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M26 34.667H26.0217"
                      stroke="black"
                      stroke-width="3"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                  <h3 className="mb-3 text-lg font-normal text-black-500 dark:text-black-400">
                    Are you sure you want to leave this page?
                  </h3>
                  <button
                    onClick={onSureClick}
                    type="button"
                    style={{backgroundColor:themeColor}}
                    className="text-white w-60 py-2.5 flex justify-center text-center focus:ring-4 focus:outline-none font-medium rounded-lg text-sm items-center px-5"
                  >
                    Continue
                  </button>
                  <button
                    onClick={closePopup}
                    type="button"
                    className=" w-60 border mt-2 py-2.5 px-5 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border-gray-200 hover:bg-red-800 focus:z-10 focus:ring-4 focus:ring-red-800 dark:focus:ring-red-800 dark:bg--800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};


export default NavbarThree;



