import { useEffect } from "react";
import { useLocation } from "react-router-dom";

function Redirector() {
  const location = useLocation();

  useEffect(() => {
    const isRootURL = location.pathname === "/";

    if (isRootURL) {
      window.location.replace("https://grouple.in");
    }
  }, [location]);

  return null;
}

export default Redirector;