import React, { useEffect, useState } from "react";
import Footer from "../../Components/Footer";
import "../../Styles/Request.css";
import rectangle from "../../Images/ReservationImage1.png";
import { Link, useParams } from "react-router-dom";
import logo from "../../Images/barbaar logo.png";
import { useLocation } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Hourglass } from "react-loader-spinner";

const ValentineMerchant = () => {
  const { randomReservationID, type } = useParams();
  const [bookingData, setbookingData] = useState(null);
  useEffect(() => {
    if (randomReservationID) {

      const hostname = window.location.hostname;
      let apiUrl;
      if (hostname.includes("testgrouple.com") || hostname.includes("localhost")) {
        apiUrl =`https://server.testgrouple.com/api/v1/beta/enduser/viewValentinesReservation/${type}/${randomReservationID}`;
      }
      if (hostname.includes("grouple.tech")) {
        apiUrl =
        `https://production.grouple.tech/api/v1/beta/enduser/viewValentinesReservation/${type}/${randomReservationID}`;
      }
  
      fetch(apiUrl)
        .then((res) => res.json())
        .then((data) => {
          setbookingData(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [randomReservationID]);
  if (!bookingData) {
    return (
      <p
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "80vh",
        }}
      >
        <Hourglass
          visible={true}
          height="100"
          width="100"
          ariaLabel="hourglass-loading"
          wrapperStyle={{}}
          wrapperClass=""
          colors={["#306cce", "#72a1ed"]}
        />
      </p>
    );
  }
  const foodOption = [
    { id: 1, name: "Appetizer" },
    { id: 2, name: "Main Course" },
    { id: 3, name: "Dessert" },
  ];

  const renderFoodItemsThree = (categoryName) => {
    let renderFoodItemArray = [];
    switch (categoryName) {
      case "Appetizer":
        renderFoodItemArray =
          bookingData.data.bookingFoodItemSelected.food.appetizer;
        break;
      case "Main Course":
        renderFoodItemArray =
          bookingData.data.bookingFoodItemSelected.food.main_course;
        break;
      case "Dessert":
        renderFoodItemArray =
          bookingData.data.bookingFoodItemSelected.food.dessert;
        break;
      default:
        break;
    }
    return renderFoodItemArray.map((item, index) => (
      <div key={index} className="selected-food3">
        <div className="ItemDetailLogo3">
          {item.item_category === "Vegetarian" ? (
            <div className="veg-div">
              <svg
                width="13"
                height="13"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="15"
                  height="15"
                  rx="1.5"
                  stroke="#009600"
                />
                <circle cx="8" cy="8" r="4" fill="#009600" />
              </svg>
            </div>
          ) : item.item_category === "Non Vegetarian" ? (
            <div className="nonveg-div">
              <svg
                width="13"
                height="13"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="15"
                  height="15"
                  rx="1.5"
                  stroke="#963415"
                />
                <path
                  d="M4.38833 12C4.20992 12 4.09388 11.8122 4.17367 11.6527L7.78534 4.42932C7.87378 4.25243 8.12622 4.25243 8.21466 4.42933L11.8263 11.6527C11.9061 11.8122 11.7901 12 11.6117 12H4.38833Z"
                  fill="#963415"
                />
              </svg>
            </div>
          ) : null}
        </div>
        <div className="ItemDetailName3">{item.item_name}</div>
      </div>
    ));
  };

  let beverages = bookingData.data.bookingFoodItemSelected.beverage;
  const keysArray = Object.keys(beverages);

  const beverageOptions = keysArray.map((key, index) => {
    let modifiedName = key
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    modifiedName =
      modifiedName === "Non Alcoholic" ? "Non-Alcoholic" : modifiedName;

    return {
      id: index + 1,
      name: modifiedName,
    };
  });

  const renderBeverageItemsThree = (categoryName) => {
    let renderBeverageItemsArray = [];
    switch (categoryName) {
      case "Wines":
        renderBeverageItemsArray =
          bookingData.data.bookingFoodItemSelected.beverage.wines;
        break;
      default:
        break;
    }
    return renderBeverageItemsArray.map((item, index) => (
      <div key={index} className="selected-food3">
        <div className="ItemDetailLogo3">
          <div className="veg-div">
            <svg
              width="13"
              height="13"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="15"
                height="15"
                rx="1.5"
                stroke="#009600"
              />
              <circle cx="8" cy="8" r="4" fill="#009600" />
            </svg>
          </div>
        </div>
        <div className="ItemDetailName3">{item.item_name}</div>
      </div>
    ));
  };

  return (
    <div>
      <nav className="navbar-div2" style={{ display: "flex" }}>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Link to="/landingPage/barbaar" style={{ textDecoration: "none" }}>
            <img src={logo} alt="" className="barbaar-logo" />
          </Link>
        </div>
      </nav>

      <div className="RequestWrapper">
        {/* LEFT */}
        <div className="RequestWrapperLeft">
          <div className="RequestStatusWrapper">
            <h1 className="RequestStatusHeading2">
              You've got a new reservation
            </h1>
            <h1 className="RequestStatusHeading3">
            Reservation ID <span style={{color:"#BE360D"}}>#{bookingData.data.reservationCode}</span>
            </h1>
            <p className="RequestStatusDetails">
              We are pleased to inform you that you got a new reservation.
            </p>
            <p className="RequestEmail">Happy Servicing !</p>
          </div>

          <div className="CustomerForMerchantWrapper">
            <div className="CustomerForMerchantHeading">Personal Details</div>
            <div className="CustomerForMerchantDeatil">
              <div>Name -</div>
              <div>
                {bookingData.data.bookerFirstName}{" "}
                {bookingData.data.bookerLastName}
              </div>
            </div>
            <div className="CustomerForMerchantDeatil">
              <div>Phone number -</div>
              <div>
                {bookingData.data.bookerInternationalCode}{" "}
                {bookingData.data.bookerPhone}
              </div>
            </div>
            <div className="CustomerForMerchantDeatil">
              <div>Email -</div>
              <div>{bookingData.data.bookerEmail}</div>
            </div>
            <div className="CustomerForMerchantDeatil2">
              <div>
                Reservation notes <span>(Optional)</span>{" "}
              </div>
              <div>{bookingData.data.bookingNotes}</div>
            </div>
          </div>

          <div>
            <div className="CustomerFoodMenuHead">Food menu</div>
            {/* {/ ************* Food menu  ********* /} */}
            <div className="closure-div2">
              {foodOption.map((category, index) => (
                <div key={category.id} className="w-full">
                  <div className="w-full rounded-2xl bg-white pb-2 pt-2">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className="flex w-full justify-between rounded-lg"
                            style={{
                              background: "white",
                              padding: "0.75rem 0.5rem",
                              borderRadius: open
                                ? " 0.5rem 0.5rem 0 0"
                                : " 0.5rem",
                              border: "1px solid #E5E5EA",
                              borderBottom: open ? "none" : "1px solid #E5E5EA",
                              boxShadow:
                                "0px 4px 12px 0px rgba(34, 34, 34, 0.06)",
                            }}
                          >
                            <span
                              style={{
                                fontWeight: "400",
                                paddingLeft: "0.5rem",
                              }}
                            >
                              {category.name}
                             
                            </span>
                            <ChevronDownIcon
                              className={`${
                                open ? "rotate-180 transform" : ""
                              } h-5 w-5 text-black-700`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                            <div className="selected-food-list2">
                              {renderFoodItemsThree(category.name)}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              ))}
            </div>

            {/* {/ ****************Beverage menu ***** /} */}
            <div className="CustomerFoodMenuHead">Beverages menu</div>

            <div className="closure-div2">
              {beverageOptions.map((category, index) => (
                <div key={category.id} className="w-full">
                  <div className="w-full rounded-2xl bg-white pb-2 pt-2">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className="flex w-full justify-between rounded-lg"
                            style={{
                              background: "white",
                              padding: "0.75rem 0.5rem",
                              borderRadius: open
                                ? " 0.5rem 0.5rem 0 0"
                                : " 0.5rem",
                              border: "1px solid #E5E5EA",
                              borderBottom: open ? "none" : "1px solid #E5E5EA",
                              boxShadow:
                                "0px 4px 12px 0px rgba(34, 34, 34, 0.06)",
                            }}
                          >
                            <span
                              style={{
                                fontWeight: "400",
                                paddingLeft: "0.5rem",
                              }}
                            >
                              {category.name}{" "}
                            </span>
                            <ChevronDownIcon
                              className={`${
                                open ? "rotate-180 transform" : ""
                              } h-5 w-5 text-black-700`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                            <div className="selected-food-list2">
                              {renderBeverageItemsThree(category.name)}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* RIGHT */}
        <div className="RequestWrapperRight">
          <div className="RequestWrapperChild">
            <img className="resCardImgOne" src={rectangle} alt="" />

            <div className="RequestDateAndTimeWrapper">
              <div className="RequestDateChild">
                <div>Date of Reservation</div>
                <div>{bookingData.data.bookingDate}</div>
              </div>
              <div className="RequestTimeChild">
                <div>Time of Reservation</div>
                <div>
                  {bookingData.data.bookingSlotStartTime}
                </div>
              </div>
            </div>

            <div className="RequestSummaryWrapper">
              <div className="RequestSummaryChild">
                <div
                  className="reservationSumaryAdress"
                  style={{ alignItems: "flex-start" }}
                >
                  {" "}
                  <div>{bookingData.data.bookingOutlet}</div>
                  <div>{bookingData.data.bookingOutletAddress}</div>
                </div>
              </div>
              <div className="RequestSummaryChild">
                <div>{bookingData.data.bookingPackage}</div>
              </div>
              <div className="RequestSummaryChild">
                <div>{bookingData.data.bookingTotalGuest} Guests</div>
              </div>
              <div className="RequestSummaryChild">
                <div>
                  {" "}
                  AED{" "}
                  {Number(
                    bookingData.data.bookingTotalCost
                  ).toLocaleString()}{" "}
                  /-
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ValentineMerchant;
