import React, { useState, useEffect, useRef, Suspense } from "react";
import { Navbar2 } from "./NavbarTwo";
import Footer from "./Footer";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import "../Styles/Switch.css";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import switchImage from "../Images/mokshaOutlet.png";
import switchImage3 from "../Images/pahadiOutlet.png";

const SwitchOutlet = () => {
  const directionsRef = useRef(null);
  const [outlets, setOutlets] = useState([]);
  const { estateName } = useParams();
  const companyData = JSON.parse(localStorage.getItem("companyData"));
  const companyName = companyData[0].company_name;
  const [activeMap, setActiveMap] = useState("Moksha");
  const [loading, setLoading] = useState(true);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [imagesLoadedCount, setImagesLoadedCount] = useState(0);
  const [mapsLoadedCount, setMapsLoadedCount] = useState(0);

  const fetchOutlets = async () => {
    const hostname = window.location.hostname;
    setLoading(true);
    let apiUrl;

    if (
      hostname.includes("testgrouple.com") ||
      hostname.includes("localhost")
    ) {
      apiUrl = `https://server.testgrouple.com/api/v1/enduser/comapny/merchantInformation/${companyName}`;
    } else if (hostname.includes("grouple.tech")) {
      apiUrl = `https://production.grouple.tech/api/v1/enduser/comapny/merchantInformation/${companyName}`;
    }

    try {
      const response = await axios.get(apiUrl);
      setLoading(true);
      if (response.status === 200) {
        setOutlets(response.data.data[0].estates);
      } else {
        throw new Error("Failed to fetch outlets");
      }
    } catch (error) {
      console.error("Error fetching outlets:", error);
      setLoading(false);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchOutlets();
  }, []);

  const getEstateCode = (estateName) => {
    const estate = companyData[0].estates.find(
      (estate) => estate.estate_name === estateName
    );
    return estate ? estate.estatecode : null;
  };

  useEffect(() => {
    const estateCode = getEstateCode(estateName);
    if (estateCode) {
      localStorage.setItem("estateCode", estateCode);
    }
  }, [estateName]);

  const handleImageLoad = () => {
    setLoading(true);
    setImagesLoadedCount((prevCount) => prevCount + 1);
    setLoading(false);
  };

  const handleMapLoad = () => {
    setLoading(true);
    setMapsLoadedCount((prevCount) => prevCount + 1);
    setLoading(false);
  };

  // Check if all images and maps have loaded
  useEffect(() => {
    if (imagesLoadedCount === outlets.length) {
      setImagesLoaded(true);
    }
  }, [imagesLoadedCount, outlets.length]);

  useEffect(() => {
    if (mapsLoadedCount === outlets.length) {
      setMapLoaded(true);
    }
  }, [mapsLoadedCount, outlets.length]);
  useEffect(() => {
    if (imagesLoaded && mapLoaded) {
      setLoading(false);
    }
  }, [imagesLoaded, mapLoaded]);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 640);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  // filter functionality//
  const [searchTerm, setSearchTerm] = useState('');
  const [hoverMap, setHoverMap] = useState(null);
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const filteredOutlets = outlets.filter(outlet =>
    outlet.estate_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  useEffect(() => {
    if (filteredOutlets.length > 0) {
      setActiveMap(filteredOutlets[0].estate_name);
    }
  }, [filteredOutlets]);

  const displayedMap = hoverMap || activeMap;


  return (
    <>
      <Navbar2 />
      {loading || !(imagesLoaded && mapLoaded) ? (
        <div className="flex items-center justify-center h-screen">
          <div className="animate-spin rounded-full border-t-4 border-primary border-t-primary h-12 w-12"></div>
        </div>
      ) : (
        <div className="switchParent">
          {/* Left side outlets list */}
          <div className="leftSwitch">
            {/* Search button */}
            <div className="searchButton">
              <label htmlFor="search" className="sr-only">
                Search
              </label>
              <div className="relative text-gray-400 focus-within:text-gray-300">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                </div>
                <input
                  id="search"
                  className="block w-full rounded-md border border-gray-800 py-2.5 pl-10 pr-3 text-gray-900 sm:text-sm sm:leading-6 focus:border-none focus:color-white"
                  placeholder="Search outlets"
                  type="search"
                  name="search"
                  value={searchTerm}
                  onChange={handleSearchChange}
                />
              </div>
            </div>

            <div className="outletsMultiple">
            {filteredOutlets.length > 0 ? (
              filteredOutlets.map((outlet, index) => (
                  <Link
                    to={`/package/${outlet.estate_name}`}
                    onClick={localStorage.setItem(
                      "estateCode",
                      outlet.estatecode
                    )}
                    key={index}
                    style={{ textDecoration: "none" }}
                    onMouseEnter={() => setHoverMap(outlet.estate_name)}
                    onMouseLeave={() => setHoverMap(null)}
                  >
                    <div>
                      {outlet.estate_name === "Moksha" ? (
                        <Suspense
                          fallback={
                            <div className="flex items-center justify-center h-screen">
                              <div className="animate-spin rounded-full border-t-4 border-primary border-t-primary h-12 w-12"></div>
                            </div>
                          }
                        >
                          <img
                            src={switchImage}
                            alt={outlet.estate_name}
                            onLoad={handleImageLoad}
                          />
                        </Suspense>
                      ) : outlet.estate_name === "Pahadi" ? (
                        <Suspense
                          fallback={
                            <div className="flex items-center justify-center h-screen">
                              <div className="animate-spin rounded-full border-t-4 border-primary border-t-primary h-12 w-12"></div>
                            </div>
                          }
                        >
                          <img
                            src={switchImage3}
                            alt={outlet.estate_name}
                            onLoad={handleImageLoad}
                          />
                        </Suspense>
                      ) : (
                        <Suspense
                          fallback={
                            <div className="flex items-center justify-center h-screen">
                              <div className="animate-spin rounded-full border-t-4 border-primary border-t-primary h-12 w-12"></div>
                            </div>
                          }
                        >
                          <img
                            src={switchImage3}
                            alt={outlet.estate_name}
                            onLoad={handleImageLoad}
                          />
                        </Suspense>
                      )}
                      <h2>{outlet.estate_name}</h2>
                      <h3>{outlet.cuisine}</h3>
                      <h4>{outlet.address}</h4>
                    </div>
                  </Link>
                ))
              ) : (
                <p>No outlets available</p>
              )}
            </div>
          </div>

          {/* Right side map */}
          <div className="rightSwitch">
            <div ref={directionsRef} id="directions" className="sticky-right">
              {displayedMap === "Moksha" && (
                <iframe
                  title="Google Maps"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1804.1926826016163!2d55.29822731646945!3d25.257618831496888!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f4375fd849943%3A0xb0a517db1521f1fe!2sMoksha%20by%20Barbaar!5e0!3m2!1sen!2sin!4v1715958283377!5m2!1sen!2sin"
                  width="100%"
                  height="600"
                  style={{ border: "0px", borderRadius: "8px" }}
                  allowFullScreen=""
                  referrerPolicy="no-referrer-when-downgrade"
                  onLoad={handleMapLoad}
                ></iframe>
              )}

              {displayedMap === "Pahadi" && (
                <iframe
                  title="Google Maps"
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3608.384980470542!2d55.294873472981536!3d25.257631779924882!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f430d63cabdf7%3A0x949944af77d22f2d!2sPahadi!5e0!3m2!1sen!2sin!4v1715956881355!5m2!1sen!2sin"
                  width="100%"
                  height="600"
                  style={{ border: "0px", borderRadius: "8px" }}
                  allowFullScreen=""
                  referrerPolicy="no-referrer-when-downgrade"
                  onLoad={handleMapLoad}
                ></iframe>
              )}
            </div>
          </div>
        </div>
      )}
      <div className={isMobile ? "footer-hidden" : ""}>
        <Footer />
      </div>
    </>
  );
};

export default SwitchOutlet;
