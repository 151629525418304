import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../Styles/Navbar.css";


const Navbar = () => {
  const [companyLogo, setCompanyLogo] = useState("");
  const [loading, setLoading] = useState(true);


  const fetchData = () => {
    setTimeout(() => {
      const compData = JSON.parse(localStorage.getItem("companyData"));
      const logo = compData ? compData[0].compnay_logo : "";
      setCompanyLogo(logo);
      setLoading(false);
    }, 800);
  };


  useEffect(() => {
    fetchData();
  }, []);


  return loading ? (
    <div className="flex items-center justify-center h-screen">
      <div className="animate-spin rounded-full border-t-4 border-primary h-12 w-12"></div>
    </div>
  ) : (
    <nav className="navbar-div">
      <Link to="/landingPage/janjaes">
        <img src={companyLogo} alt="Company Logo" className="barbaar-logo1" />
      </Link>
      <div className="buttondivnav">
        <Link to="/location" style={{ textDecoration: "none" }}>
          <button className="reserveNow">Reserve Now</button>
        </Link>
      </div>
    </nav>
  );
};


export default Navbar;





