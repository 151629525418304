import React, { useEffect, useState } from "react";
import Footer from "./Footer";
import "../Styles/Request.css";
// import rectangle from "../Images/ReservationImage1.png";
import rectangle from "../Images/mokshabook.png"
import rectangle2 from "../Images/pahadibook.png"
import { Link, useParams } from "react-router-dom";
import logo from "../Images/barbaar logo.png";
import LoadingAnimation from "../Images/LOADINGaNIMATION.gif"

const MerchantIndividual = () => {
  const { randomReservationID, type } = useParams();
  const [bookingData, setbookingData] = useState(null);
  useEffect(() => {
    if (randomReservationID) {

      const hostname = window.location.hostname;
      let apiUrl;
      if (hostname.includes("testgrouple.com") || hostname.includes("localhost")) {
        apiUrl =`https://server.testgrouple.com/api/v1/beta/enduser/viewIndividualReservation/${type}/${randomReservationID}`;
      }
      if (hostname.includes("grouple.tech")) {
        apiUrl =
        `https://production.grouple.tech/api/v1/beta/enduser/viewIndividualReservation/${type}/${randomReservationID}`;
      }
  
      fetch(apiUrl)
        .then((res) => res.json())
        .then((data) => {
          setbookingData(data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [randomReservationID]);
  if (!bookingData) {
    return (
      <p
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "80vh",
      }}
    >
    <img src={LoadingAnimation} alt="Loading..." />
    </p>
    );
  }

  // ******** Date ************ //
  const displayDate = bookingData.data.bookingDate;
  const date = new Date(displayDate);
  let showDate;
  if (
    Object.prototype.toString.call(date) === "[object Date]" &&
    !isNaN(date)
  ) {
    const options = { weekday: "short", day: "numeric", month: "short" };
    showDate = date.toLocaleDateString("en-GB", options);
  }


  return (
    <div>
    <nav className="navbar-div2">
      <div
         className="inner-navdiv2">
        <Link to="/landingPage/janjaes" style={{ textDecoration: "none" }}>
          <img src={bookingData.data.logo} alt="" className="barbaar-logo" />
        </Link>
      </div>
    </nav>

    <div className="RequestWrapper">
      {/* LEFT */}
      <div className="RequestWrapperLeft">
        <div className="RequestStatusWrapper">
          <div className="RequestSuccess">
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="40"
                height="40"
                viewBox="0 0 40 40"
                fill="none"
              >
                <g clip-path="url(#clip0_1622_3036)">
                  <path
                    d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
                    fill="#C9E7CB"
                  />
                  <path
                    d="M20 35C28.2843 35 35 28.2843 35 20C35 11.7157 28.2843 5 20 5C11.7157 5 5 11.7157 5 20C5 28.2843 11.7157 35 20 35Z"
                    fill="#4CAF50"
                  />
                  <path
                    d="M26 15.5L17.75 23.75L14 20"
                    stroke="white"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_1622_3036">
                    <rect width="40" height="40" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </div>
            <div>You got a new Reservation</div>
          </div>

          <p className="RequestStatusDetails">
            We are pleased to inform you that you got a new reservation.
          </p>
          <p className="RequestStatusDetails">Happy Servicing !</p>
        </div>

        <div className="svgLine">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="1"
            viewBox="0 0 610 1"
            fill="none"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M610 1H0V0H610V1Z"
              fill="#E5E5EA"
            />
          </svg>
        </div>

        {/* personal details  */}
        <div className="CustomerForMerchantWrapper">
          <div className="CustomerFoodMenuHead">Personal Details</div>
          <div className="CustomerForMerchantDeatil">
            <div>Name -</div>
            <div className="RequestSummaryChild2">
              {bookingData.data.bookerFirstName}{" "}
              {bookingData.data.bookerLastName}
            </div>
          </div>
          <div className="CustomerForMerchantDeatil">
            <div>Phone number -</div>
            <div className="RequestSummaryChild2">
              {bookingData.data.bookerInternationalCode}{" "}
              {bookingData.data.bookerPhone}
            </div>
          </div>
          <div className="CustomerForMerchantDeatil">
            <div>Email -</div>
            <div className="RequestSummaryChild2">{bookingData.data.bookerEmail}</div>
          </div>
          <div className="CustomerForMerchantDeatil">
            <div>
              Reservation notes <span>(Optional)</span>{" "}
            </div>
            <div className="RequestSummaryChild2">{bookingData.data.bookingNotes}</div>
          </div>
        </div>
      </div>

      {/* RIGHT */}
      <div className="RequestWrapperRight">
        <div className="RequestWrapperChild">
        <img className="resCardImgOne" src={bookingData.data.bookingOutlet === "Moksha" ? rectangle : rectangle2} alt="" />

          {/* outlet addres */}
          <div className="everything-wrapper">
            <div className="RequestDateAndTimeWrapper">
              {bookingData.data.bookingOutlet}
            </div>

            <div className="RequestSummaryChild">
              {bookingData.data.bookingOutletAddress}
            </div>

            <div className="RequestSummaryChild2">
           Individual Package
            </div>
          </div>

          {/* bookin date/time/guest */}
          <div className="RequestSummaryChild3" style={{width:"66%", border:"none"}}>
            <div>{showDate}</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="2"
              height="2"
              viewBox="0 0 2 2"
              fill="none"
            >
              <circle cx="1" cy="1" r="1" fill="#242426" />
            </svg>
            <div>{bookingData.data.bookingSlotStartTime}</div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="2"
              height="2"
              viewBox="0 0 2 2"
              fill="none"
            >
              <circle cx="1" cy="1" r="1" fill="#242426" />
            </svg>
            <div>{bookingData.data.bookingTotalGuest} Guests</div>
          </div>

          {/* total price */}
          <div className="RequestSummaryWrapper" style={{borderTop:"1px solid #E5E5EA"}}>
            <div className="RequestStatusHeading3">
              <div>Reservation ID </div>
              <div>#{bookingData.data.reservationCode}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
  );
};

export default MerchantIndividual;
