import React from "react";
// import ReservationForm from "../Components/ReservationForm";
import Footer from "../Components/Footer";
import NewReservation from "../Components/NewReservation";
import NavbarThree from "../Components/NavbarThree";

const Reservation = () => {
  return (
    <>
      <NavbarThree />
      {/* <ReservationForm /> */}
      <NewReservation />
      <Footer />
    </>
  );
};

export default Reservation;
