import React from "react";
import "../Styles/Questions.css";
import { Accordion } from "@prismane/core";
import { Transition } from "@prismane/core";
import { LuPlusCircle } from "react-icons/lu";

const Questions = () => {
  return (
    <div className="question-card">
      <div className="left-div">
        <h2>Frequently Asked Question</h2>
        <p>Don’t worry, we've got your queries covered</p>
      </div>
      <div className="right-div">
        <Accordion defaultValue="first" className="hover:none">
          <Accordion.Item
            value="first"
            my={10}
            bg="#EDF0FF"
            p={6}
            justify="center"
            br={10}
            gap={2}
            color="#1C1C1C"
            className="hover:none"
          >
            <Accordion.Control
              className="outsideText"
              bg={[
                ["#EDF0FF", 600],
                { hover: ["#EDF0FF", 200], active: ["#EDF0FF", 700] },
              ]}
            >
              What are group package deals?
              <Accordion.Icon>
                {(active) => (
                  <Transition
                    as={LuPlusCircle}
                    style={{
                      transform: active ? "rotate(135deg)" : "rotate(0deg)",
                    }}
                  />
                )}
              </Accordion.Icon>
            </Accordion.Control>
            <Accordion.Panel className="insideText5">
              These are packages specially curated for groups with offers and
              discounts baked into the pricing. Larger the group, better the
              deal.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            value="second"
            my={10}
            bg="#EDF0FF;"
            p={6}
            justify="center"
            br={10}
            gap={2}
            color="#1C1C1C;"
            className="hover:bg-none"
          >
            <Accordion.Control
              className="outsideText"
              bg={[
                ["#EDF0FF", 600],
                { hover: ["#EDF0FF", 200], active: ["#EDF0FF", 700] },
              ]}
            >
              Why are group packages beneficial?
              <Accordion.Icon>
                {(active) => (
                  <Transition
                    as={LuPlusCircle}
                    style={{
                      transform: active ? "rotate(135deg)" : "rotate(0deg)",
                    }}
                  />
                )}
              </Accordion.Icon>
            </Accordion.Control>
            <Accordion.Panel className="insideText">
              Group packages offer various advantages, including personalised
              experiences, and concierge services.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            value="third"
            my={10}
            bg="#EDF0FF;"
            p={6}
            justify="center"
            br={10}
            gap={2}
            color="#1C1C1C;"
            className="hover:bg-none"
          >
            <Accordion.Control
              className="outsideText"
              bg={[
                ["#EDF0FF", 600],
                { hover: ["#EDF0FF", 200], active: ["#EDF0FF", 700] },
              ]}
            >
              What is the required group size for availing deals?
              <Accordion.Icon>
                {(active) => (
                  <Transition
                    as={LuPlusCircle}
                    style={{
                      transform: active ? "rotate(135deg)" : "rotate(0deg)",
                    }}
                  />
                )}
              </Accordion.Icon>
            </Accordion.Control>
            <Accordion.Panel className="insideText">
              The minimum group size required to avail of group deals may vary.
              In most caases, group packages start at 10 or more people. We
              recommend you review the terms and conditions for each venue
              during the reservation process.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            value="fourth"
            my={10}
            bg="#EDF0FF;"
            p={6}
            justify="center"
            br={10}
            gap={2}
            color="#1C1C1C;"
            className="hover:bg-none"
          >
            <Accordion.Control
              className="outsideText"
              bg={[
                ["#EDF0FF", 600],
                { hover: ["#EDF0FF", 200], active: ["#EDF0FF", 700] },
              ]}
            >
              What is the difference in the 4 packages?
              <Accordion.Icon>
                {(active) => (
                  <Transition
                    as={LuPlusCircle}
                    style={{
                      transform: active ? "rotate(135deg)" : "rotate(0deg)",
                    }}
                  />
                )}
              </Accordion.Icon>
            </Accordion.Control>
            <Accordion.Panel className="insideText">
              Venue offer four distinct package tiers: Silver, Gold, Platinum
              and Rose Gold. Each tier provides additional benefits and enhanced
              experiences. To learn more about the specific offerings, please
              compare the packages.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            value="fifth"
            my={10}
            bg="#EDF0FF;"
            p={6}
            justify="center"
            br={10}
            gap={2}
            color="#1C1C1C;"
            className="hover:bg-none"
          >
            <Accordion.Control
              className="outsideText"
              bg={[
                ["#EDF0FF", 600],
                { hover: ["#EDF0FF", 200], active: ["#EDF0FF", 700] },
              ]}
            >
              How can I book a group package deal?
              <Accordion.Icon>
                {(active) => (
                  <Transition
                    as={LuPlusCircle}
                    style={{
                      transform: active ? "rotate(135deg)" : "rotate(0deg)",
                    }}
                  />
                )}
              </Accordion.Icon>
            </Accordion.Control>
            <Accordion.Panel className="insideText">
              Booking is simple and convenient. Just follow along: <br />
              1. Pick what’s right for you: Search by date, outlet, time slots,
              and capacity to suit your group’s needs. <br /> 2. Make it your
              own: Compare and personalise package to suit your tastes and
              preferences. Group deals benefit you because they are based on
              group sizes. <br /> 3. Reserve your spot: Seal the deal and make
              your reservation final.
              <br /> 4. Invite & show up to enjoy: Invite your guests to an
              exclusive experience. Get a personalized pre-approved guest list
              for a smooth and effortless check-in experience.
            </Accordion.Panel>
          </Accordion.Item>

          <Accordion.Item
            value="sixth"
            my={10}
            bg="#EDF0FF;"
            p={6}
            justify="center"
            br={10}
            gap={2}
            color="#1C1C1C;"
            className="hover:bg-none"
          >
            <Accordion.Control
              className="outsideText"
              bg={[
                ["#EDF0FF", 600],
                { hover: ["#EDF0FF", 200], active: ["#EDF0FF", 700] },
              ]}
            >
              Can I customize a group package for my specific needs?
              <Accordion.Icon>
                {(active) => (
                  <Transition
                    as={LuPlusCircle}
                    style={{
                      transform: active ? "rotate(135deg)" : "rotate(0deg)",
                    }}
                  />
                )}
              </Accordion.Icon>
            </Accordion.Control>
            <Accordion.Panel className="insideText">
              Absolutely, you can customize your booking to fit your
              preferences. Choose from a variety of venues, tailor your package
              by selecting food and beverage options to create a unique
              experience.
            </Accordion.Panel>
          </Accordion.Item>
        </Accordion>
      </div>
    </div>
  );
};

export default Questions;
