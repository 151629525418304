import { React, useEffect, useState } from "react";
import "../../Styles/MobileDate.css";
import { Link, useLocation,useNavigate } from "react-router-dom";
import { Navbar2 } from "../NavbarTwo";
import DateModal from "../DateModal";
import TimeSlotModalIndividual from "./TimeSlotModalIndividual";
import { setEstateCodeForTheme, useTheme } from "../ColorContext";
const MobileDateIndividual = () => {
  const location = useLocation();
  const [selectedPeopleIndividual, setSelectedPeopleIndividual] = useState(0);
  const estateCode = localStorage.getItem("estateCode");
    const { themeColor } = useTheme();
    setEstateCodeForTheme(estateCode);
  const [validationErrors, setValidationErrors] = useState({
    date: "",
    timeSloIndividualt: "",
    selectedPeopleIndividual: "",
  });

  let reservationDataIndividualOne =
    location.state?.reservationDataIndividualOne || {};
  const packageName = "General Reservation";
  const category = reservationDataIndividualOne.category;
  const [isDateOpen, setIsDateOpen] = useState(false);
  const [isTimeOpen, setIsTimeOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [savedDate, setSavedDate] = useState(null);
  const [selectedTimeIndividual, setSelectedTimeIndividual] = useState(null);
  const [displayedTimeIndividual, setDisplayedTimeIndividual] = useState(null)
  const [savedTimeIndividual, setSavedTimeIndividual] = useState(null);

  useEffect(() => {
    const storedDate = sessionStorage.getItem("selectedDate");
    const storedTimeIndividual = sessionStorage.getItem("selectedTimeIndividual")
    const selectedPeopleIndividual = sessionStorage.getItem("selectedPeopleIndividual");
    const displayedTimeIndividual = sessionStorage.getItem("displayedTimeIndividual");
    if (storedDate) setSavedDate(new Date(storedDate));
    if (storedTimeIndividual) setSavedTimeIndividual(storedTimeIndividual);
    if (selectedPeopleIndividual) setSelectedPeopleIndividual(selectedPeopleIndividual);
    if (displayedTimeIndividual) setDisplayedTimeIndividual(displayedTimeIndividual);
  }, []);

  useEffect(() => {
    if (selectedDate) {
      sessionStorage.setItem("selectedDate", selectedDate.toISOString());
      setSavedDate(selectedDate);
    }
  }, [selectedDate]);

  useEffect(() => {
    if (selectedTimeIndividual) {
      sessionStorage.setItem("selectedTimeIndividual", selectedTimeIndividual);
      setSavedTimeIndividual(selectedTimeIndividual);
    }
  }, [selectedTimeIndividual]);

  useEffect(() => {
    if (displayedTimeIndividual) {
      sessionStorage.setItem("displayedTimeIndividual", displayedTimeIndividual);
      setDisplayedTimeIndividual(displayedTimeIndividual);
    }
  }, [selectedTimeIndividual]);
  useEffect(() => {
    if (selectedPeopleIndividual) {
      sessionStorage.setItem("selectedPeopleIndividual", selectedPeopleIndividual);
      setSelectedPeopleIndividual(selectedPeopleIndividual);
    }
  }, [selectedPeopleIndividual]);

  const handleSaveDate = () => {
    setSavedDate(selectedDate);
    if (selectedDate) {
      sessionStorage.setItem("selectedDate", selectedDate.toISOString());
    }
    setIsDateOpen(false);
  };

  
  const formattedSavedDate = savedDate
    ? savedDate.toLocaleDateString("en-US", { month: "long", day: "numeric" })
    : "Select a date";

  const handleSaveTime = () => {
    setSavedTimeIndividual(selectedTimeIndividual);
    if (selectedTimeIndividual) {
      sessionStorage.setItem("selectedTimeIndividual", selectedTimeIndividual);
    }
    setIsTimeOpen(false);
  };

  const clearSelectedPeopleError = () => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      selectedPeopleIndividual: "",
    }));
  };

  const handleInputChange = (value) => {
    if (!isNaN(value) && value >= 1 && value <= 10) {
      setSelectedPeopleIndividual(value);
      clearSelectedPeopleError();
    } else {
      const clampedValue = value >= 1 ? Math.min(Math.max(value, 10), 10) : 0;
      setSelectedPeopleIndividual(clampedValue);
      clearSelectedPeopleError();
    }
  };

  const handleInputNumberChange = (event) => {
    const value = parseInt(event.target.value, 10);
    handleInputChange(value);
  };
  const handleSliderChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setSelectedPeopleIndividual(value);
    clearSelectedPeopleError();
  };




  const validateForm = () => {
    let isValid = true;
    const errors = {
      date: "",
      timeSlotIndividual: "",
      selectedPeopleIndividual: "",
    };
    if (!savedDate) {
      errors.date = "Please select a date";
      isValid = false;
    }
    if (!savedTimeIndividual) {
      errors.timeSlotIndividual = "Please select a time slot";
      isValid = false;
    }
    if (selectedPeopleIndividual < 1 || selectedPeopleIndividual > 10) {
      errors.selectedPeopleIndividual = "Please select a valid number of guests";
      isValid = false;
    }
    setValidationErrors(errors);
    return isValid;
  };

  const navigate = useNavigate();

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  };
  
  
  const handleReservePhone = () => {
    if (!validateForm()) {
      return;
    }
    const formattedDate = formatDate(savedDate);
    const reservationDataIndividual = {
      Individualdate:formattedDate,
      timeSlot: selectedTimeIndividual,
      displayedTimeSlotIndividual: displayedTimeIndividual,
      selectedPeopleIndividual,
      displayDate:formattedSavedDate,
      category,
    };


    setSavedDate(null);
    setSelectedTimeIndividual(null);
    setDisplayedTimeIndividual(null);
    setSelectedPeopleIndividual(0)
    sessionStorage.setItem(
      "reservationDataIndividual",
      JSON.stringify(reservationDataIndividual)
    );

    navigate("/personalInfo/foodmenu", {
      state: { reservationDataIndividual },
    });
  };

  // ////////// slider functionality //////

  const [rangeProgress, setRangeProgress] = useState(((selectedPeopleIndividual - 1) / (10 - 1)) * 100);

  const handleSliderChangeWithProgress = (event) => {
    const value = parseInt(event.target.value, 10);
    const min = event.target.min;
    const max = event.target.max;
    const progress = ((value - min) / (max - min)) * 100;
    setRangeProgress(progress);
    setSelectedPeopleIndividual(value);
    clearSelectedPeopleError();
  };

  useEffect(() => {
    const initialProgress = ((selectedPeopleIndividual - 1) / (10 - 1)) * 100;
    setRangeProgress(initialProgress);

    const slider = document.querySelector('.slider-input');
    if (slider) {
      slider.style.setProperty('--range-progress', `${initialProgress}%`);
    }
  }, [selectedPeopleIndividual]);

  useEffect(() => {
    const slider = document.querySelector('.slider-input');
    if (slider) {
      slider.style.setProperty('--range-progress', `${rangeProgress}%`);
    }
  }, [rangeProgress]);

  
  return (
    <>
      <Navbar2 />
      <div className="PackageInfoMain2">
        {/* **** Left Side Content ***** */}
        <div className="PackageInfoInnerLeft2">
          <div className="packageLeftOne2">
            <div className="packageNameDiv2">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M11.082 2.33301H2.91536C2.76065 2.33301 2.61228 2.38217 2.50289 2.46969C2.39349 2.55721 2.33203 2.67591 2.33203 2.79967V11.1997C2.33203 11.3234 2.39349 11.4421 2.50289 11.5297C2.61228 11.6172 2.76065 11.6663 2.91536 11.6663H11.082C11.2367 11.6663 11.3851 11.6172 11.4945 11.5297C11.6039 11.4421 11.6654 11.3234 11.6654 11.1997V2.79967C11.6654 2.67591 11.6039 2.55721 11.4945 2.46969C11.3851 2.38217 11.2367 2.33301 11.082 2.33301Z"
                    fill="#4A6AFE"
                  />
                  <path
                    d="M5.83203 2.33301H8.16536V6.99967L6.9987 5.83301L5.83203 6.99967V2.33301Z"
                    fill="#DBE1FF"
                  />
                </svg>
                REGULAR
              </div>
             
            </div>
            <h1>{packageName}</h1>
            <p>Indian, Multi Cuisine</p>
           
          </div>
        </div>

        <div className="curved-line">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="390"
            height="18"
            viewBox="0 0 390 18"
            fill="none"
          >
            <path
              d="M1 1C99.9997 22.3663 289.999 22.3003 389 1"
              stroke="url(#paint0_linear_2112_1851)"
            />
            <defs>
              <linearGradient
                id="paint0_linear_2112_1851"
                x1="389"
                y1="9.00026"
                x2="0.999997"
                y2="9.0001"
                gradientUnits="userSpaceOnUse"
              >
                <stop stop-color="#B37E7D" stop-opacity="0.1" />
                <stop offset="0.510417" stop-color="#B37E7D" />
                <stop offset="1" stop-color="#B37E7D" stop-opacity="0.1" />
              </linearGradient>
            </defs>
          </svg>
        </div>

        {/* Date component */}

        <div className="mobile-comp">
          <div>
          <div className="inner-mobile-comp">
            <div onClick={() => setIsDateOpen(true)}>
              <div className="text-neutral-800 text-[13px] font-normal font-['Gilroy-Bold'] tracking-tight">
                DATE
              </div>
              <div className="text-neutral-800 text-base font-normal font-['Gilroy-Regular'] tracking-tight">
                {formattedSavedDate}
              </div>
            </div>
            <div onClick={() => setIsTimeOpen(true)}>
              <div className="text-neutral-800 text-[13px] font-normal font-['Gilroy-Bold'] tracking-tight">
                TIME SLOT
              </div>
              <div className="text-neutral-800 text-base font-normal font-['Gilroy-Regular'] tracking-tight">
                {displayedTimeIndividual || "Select a time slot"}
              </div>
            </div>
            <DateModal
              isOpen={isDateOpen}
              onClose={() => setIsDateOpen(false)}
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              handleSaveDate={handleSaveDate}
            />
            <TimeSlotModalIndividual
              isOpen={isTimeOpen}
              onClose={() => setIsTimeOpen(false)}
              selectedTime={selectedTimeIndividual}
              setDisplayedTime={setDisplayedTimeIndividual}
              setSelectedTime={setSelectedTimeIndividual}
              handleSaveTime={handleSaveTime}
            />
          </div>

          {/* *** slider div ****  */}
          <div className="guest-div" onClick={clearSelectedPeopleError}>
              {selectedPeopleIndividual ? (
                <span className="Selected-place-Individual">GROUP SIZE</span>
              ) : (
                ""
              )}
              <input
                placeholder={selectedPeopleIndividual ? "" : "GROUP SIZE"}
                type="number"
                min="1"
                max="10"
                value={
                  selectedPeopleIndividual > 0 ? selectedPeopleIndividual : ""
                }
                className="selectedPeopleInput"
                onChange={handleInputNumberChange}
                style={{
                  padding: selectedPeopleIndividual
                    ? "1.45rem 1rem 0.55rem 1rem"
                    : "1rem",
                }}
              />
              <div
                className="slider-wrapper"
                style={{ border: "none", padding: "0rem" }}
              >
                <div
                  className="slider-markers"
                  style={{ border: "none", padding: "0rem" }}
                >
                  <div className="slider-marker" style={{ left: "1%" }}>
                    1
                  </div>
                  <div className="slider-marker" style={{ left: "44.3%" }}>
                    5
                  </div>
                  <div className="slider-marker" style={{ left: "97%" }}>
                    10
                  </div>
                </div>
                <input
                  type="range"
                  min="1"
                  max="10"
                  step="1"
                  value={selectedPeopleIndividual}
                  onChange={handleSliderChangeWithProgress}
                  className="slider-input"
                 
                />
              </div>
              <p style={{ color: "red" }}>
                {validationErrors.selectedPeopleIndividual}
              </p>
            </div>
          </div>
          
        

            <div onClick={handleReservePhone} className="continue-bttn">
              <button style={{backgroundColor:themeColor }}>Continue</button>
            </div>
        </div>
      </div>
    </>
  );
};

export default MobileDateIndividual;
