// OutletContext.js
import React, { createContext, useContext, useEffect, useState } from 'react';

const OutletContext = createContext();

export const useOutletData = () => {
    const [outletData, setOutletData] = useState(null);
    const outletCode = localStorage.getItem("estateCode");

    useEffect(() => {
        if (outletCode) {
          fetchOutletData(outletCode);
        }
      }, [outletCode]);




    const fetchOutletData = async (code) => {
      try {
        const hostname = window.location.hostname;
        let apiUrl;
    
        if (hostname.includes("testgrouple.com") || hostname.includes("localhost")) {
          apiUrl = `https://server.testgrouple.com/api/v1/enduser/comapny/getOutlettInformation/${code}`;
        } else if (hostname.includes("grouple.tech")) {
          apiUrl = `https://production.grouple.tech/api/v1/enduser/comapny/getOutlettInformation/${code}`;
        }
    
        const response = await fetch(apiUrl);
        if (!response.ok) {
          throw new Error('Failed to fetch outlet information');
        }
        const data = await response.json();
        setOutletData(data.data[0]);
      } catch (error) {
        console.error(error);
        setOutletData(null);
      }
    };
    
  

  
    useEffect(() => {
      const handleStorageChange = (event) => {
        if (event.key === "estateCode" && event.newValue !== outletData?.estatecode) {
          fetchOutletData(event.newValue);
        }
      };
  
      window.addEventListener("storage", handleStorageChange);
  
      return () => {
        window.removeEventListener("storage", handleStorageChange);
      };
    }, [outletData?.estatecode]);
  
    return outletData;
};



export const OutletProvider = ({ children }) => {
  const [outletCode, setOutletCode] = useState('');
  const outletData = useOutletData(outletCode);

  return (
    <OutletContext.Provider value={{ outletData, setOutletCode }}>
      {children}
    </OutletContext.Provider>
  );
};

export const useOutletContext = () => useContext(OutletContext);
