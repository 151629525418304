import React, { useCallback, useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

const ScreenText = ({ screen, i, setCurrentImg, currentImg }) => {
  const [showAnimation, setShowAnimation] = useState(i === 0); 
  const ref = useRef(null);

  const toggleAnimation = useCallback(
    (entries) => {
      if (entries[0]?.isIntersecting && i !== currentImg) {
        setShowAnimation(true);
        setCurrentImg(i);
      }
    },
    [i, currentImg, setCurrentImg]
  );

  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.7
  };

  useEffect(() => {
    const observer = new IntersectionObserver(toggleAnimation, options);
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.disconnect();
    };
  }, [options, toggleAnimation, ref, i, showAnimation]);

  return (
    <div className={`screen-text ${showAnimation ? "text-visible" : ""}`} ref={ref}>
      <div className="screen-headingMain">{screen.headingMain}</div>
      <div className="screen-heading">{screen.heading}</div>
      <div className="screen-description">{screen.description}</div>
      <Link to="/location" style={{ textDecoration: "none" }}>
        <button className="reserveNow">Reserve Now</button>
      </Link>
    </div>
  );
};

export default ScreenText;
