import React from "react";
import "../Styles/Body.css";
import people from "../Images/people.png";
import arrow from "../Images/ARROW.png";
import { Link } from "react-router-dom";

const Body = () => {
  return (
    <div className="body-div">
      <h1>
        Elevating Group Booking <span>Experiences</span>{" "}
      </h1>

      <div className="cards-div">
        <div className="inside-card">
          <h2>GROUP BOOKING SOFTWARE</h2>
          <p>
            Our software help venues and experience providers to boost revenues,
            sell group deals faster while elevating the guest experience
            end-to-end.
          </p>

          <Link
            to="https://www.grouple.in/"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <div className="learn">
              <h3>Learn more</h3>
              <img src={arrow} alt="" className="arrowimg" />
            </div>
          </Link>
        </div>
        <div className="image-card">
          <img src={people} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Body;
