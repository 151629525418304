import { React, useState } from "react";
import "../../Styles/PersonalInfo.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import CountryFlag from "react-country-flag";
import { useLocation } from "react-router-dom";
import axios from "axios";
import { Navbar2 } from "../NavbarTwo";
import { setEstateCodeForTheme, useTheme } from "../ColorContext";

const PersonalInfoIndividual = () => {
  const location = useLocation();
  const hostname = window.location.hostname;
  let apiUrl;
  if (hostname.includes("testgrouple.com") || hostname.includes("localhost")) {
    apiUrl =
      "https://server.testgrouple.com/api/v1/beta/enduser/submitIndividualDetails";
  }
  if (hostname.includes("grouple.tech")) {
    apiUrl =
      "https://production.grouple.tech/api/v1/beta/enduser/submitIndividualDetails";
  }

  const estateCode = localStorage.getItem("estateCode");
  const { themeColor } = useTheme();
  setEstateCodeForTheme(estateCode);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dialCode: "",
    phoneNumber: "",
    note: "",
    confirmEmail: "",
  });

  const [errorMessage, setErrorMessage] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    confirmEmail: "",
  });
  const reservationData = location.state.reservationData || {};


  const isValidEmail = (email) => {
    return (
      /\S+@\S+\.\S+/.test(email) &&
      (email.endsWith("@gmail.com") ||
        email.endsWith("@yahoo.com") ||
        email.endsWith(".in"))
    );
  };

  const validateForm = () => {
    let valid = true;
    const errors = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      confirmEmail: "",
      note: "",
    };
    const nameRegex = /^\S+$/;

    if (
      formData.firstName.trim() === "" ||
      !nameRegex.test(formData.firstName)
    ) {
      valid = false;
      errors.firstName =
        "Please enter a valid first name (one word, no spaces).";
    }
    if (formData.lastName.trim() === "" || !nameRegex.test(formData.lastName)) {
      valid = false;
      errors.lastName = "Please enter a valid last name (one word, no spaces).";
    }
    if (formData.email.trim() === "" || !isValidEmail(formData.email)) {
      valid = false;
      errors.email =
        "Please enter a valid Gmail address (e.g., mailto:example@gmail.com).";
    }
    if (formData.phoneNumber.trim() === "") {
      valid = false;
      errors.phoneNumber = "Please enter your phone number.";
    }
    if (formData.confirmEmail !== formData.email) {
      valid = false;
      errors.confirmEmail = "Emails does not match";
    }
    setErrorMessage(errors);
    return valid;
  };
  const [requestSent, setRequestSent] = useState(false);
  const storedCompanyData = JSON.parse(localStorage.getItem("companyData"));
  const estateCodeFromLocalStorage = localStorage.getItem("estateCode");

  const selectedCompany = storedCompanyData[0];

  const selectedEstate = selectedCompany.estates.find(
    (estate) => estate.estatecode === estateCodeFromLocalStorage
  );

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const isFormValid = validateForm();
      if (!isFormValid) {
        return;
      }
      if (!requestSent) {
        setRequestSent(true);
        setTimeout(() => {
          setRequestSent(false);
        }, 8000);
      }

      const d = formData.dialCode.split("+");
      const phoneNumberWithoutDialCode = formData.phoneNumber.replace(d[1], "");
      const apiData = {
        bookerFirstName: formData.firstName,
        bookerLastName: formData.lastName,
        bookerEmail: formData.email,
        bookerInternationalCode: formData.dialCode,
        bookerPhone: phoneNumberWithoutDialCode,
        bookingCompany: selectedCompany.company_name,
        bookingOutlet: selectedEstate.estate_name,
        bookingOutletAddress: selectedEstate.address,
        bookingOutletCuisine: selectedEstate.cuisine,
        bookingPackageCategory: "À la Carte",
        bookingPackage: "General Reservations",
        bookingDate: reservationData.Individualdate,
        bookingSlotStartTime: reservationData.timeSlot,
        bookingSlotEndTime: reservationData.timeSlot,
        bookingTotalGuest: reservationData.selectedPeopleIndividual,
        outletCode: estateCodeFromLocalStorage,
        bookingNotes: formData.note || null,
        maxCapacity: 180,
        floatingCapacity: 350,
        companyCode: storedCompanyData[0].cmpcode,
        bookingGrouplePackageCategory:"Reservation"
      };

      // console.log("ApiDATA", apiData);

    
      let response = await axios.post(apiUrl, apiData);
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        note: "",
        confirmEmail: "",
      });

      // setIsHappyEatingPopupVisible2(true);

      sessionStorage.clear();
      localStorage.clear();
      setTimeout(() => {
        // setIsHappyEatingPopupVisible2(false);
        let baseUrl = "/personalReservation/j5DpR2Gk7Q";
        baseUrl += `/${response.data.data.random_reservation_code}`;
        window.open(baseUrl, "_blank");
        window.location.href = "/landingPage/janjaes";
      }, 100);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const [value, setValue] = useState("");

  return (
    <>

   
      <div className="mobile-none-div2">
      <Navbar2 />
      <div>
              <form className="reservationForm" style={{border:"none", marginTop:"-1rem"}}>
                <h1>Personal Details</h1>

                <div className="nameWrapper">
                  <div className="firstname-wrapper">
                    <label htmlFor="firstName">First Name</label>
                    <input
                      type="text"
                      id="firstName"
                      name="firstName"
                      value={formData.firstName}
                      onChange={(e) => {
                        const capitalizedValue =
                          e.target.value.charAt(0).toUpperCase() +
                          e.target.value.slice(1);

                        setErrorMessage({ ...errorMessage, firstName: "" });
                        setFormData({
                          ...formData,
                          firstName: capitalizedValue,
                        });
                      }}
                      required
                    />
                    {errorMessage.firstName && (
                      <p style={{ color: "red" }}>{errorMessage.firstName}</p>
                    )}
                  </div>

                  <div className="lastname-wrapper">
                    <label htmlFor="lastName">Last Name</label>
                    <input
                      type="text"
                      id="lastName"
                      name="lastName"
                      value={formData.lastName}
                      onChange={(e) => {
                        const capitalizedValueTwo =
                          e.target.value.charAt(0).toUpperCase() +
                          e.target.value.slice(1);

                        setErrorMessage({ ...errorMessage, lastName: "" });
                        setFormData({
                          ...formData,
                          lastName: capitalizedValueTwo,
                        });
                      }}
                      required
                    />
                    {errorMessage.lastName && (
                      <p style={{ color: "red" }}>{errorMessage.lastName}</p>
                    )}
                  </div>
                </div>

                <label htmlFor="email">Email Address</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.email}
                  onChange={(e) => {
                    setErrorMessage({ ...errorMessage, email: "" });
                    setFormData({ ...formData, email: e.target.value });
                  }}
                  required
                />
                {errorMessage.email && (
                  <p style={{ color: "red" }}>{errorMessage.email}</p>
                )}

                <label htmlFor="email">Confirm Email Address</label>
                <input
                  type="email"
                  id="email"
                  name="email"
                  value={formData.confirmEmail}
                  onChange={(e) => {
                    setErrorMessage({ ...errorMessage, confirmEmail: "" });
                    setFormData({ ...formData, confirmEmail: e.target.value });
                  }}
                  required
                />
                {errorMessage.confirmEmail && (
                  <p style={{ color: "red" }}>{errorMessage.confirmEmail}</p>
                )}

                <label htmlFor="phoneNumber">Phone Number</label>
                <PhoneInput
                  country={"ae"}
                  inputStyle={{
                    width: "100%",
                    height: "3rem",
                    borderRadius: "0.5rem",
                    border: "1px solid #a6a6a6",
                  }}
                  dropdownStyle={{
                    padding: "1rem",
                    height: "80rem",
                    width: "39rem",
                    borderRadius: "0.5rem",
                  }}
                  buttonStyle={{
                    marginTop: "0.3rem",
                    marginBottom: "0.3rem",
                    borderRight: "2px solid #293897",
                    background: "#fff",
                    borderTop: "none",
                    borderBottom: "none",
                  }}
                  name="phoneNumber"
                  value={formData.phoneNumber}
                  onChange={(value, country) => {
                    setErrorMessage({ ...errorMessage, phoneNumber: "" });
                    setFormData({
                      ...formData,
                      dialCode: country ? `+${country.dialCode}` : `+971`,
                      phoneNumber: value || "",
                    });
                  }}
                  maxLength={10}
                  buttonComponent={
                    <CountryFlag dialCode={formData.dialCode} svg />
                  }
                />
                {errorMessage.phoneNumber && (
                  <p style={{ color: "red" }}>{errorMessage.phoneNumber}</p>
                )}

                <label htmlFor="notes"> Reservation notes (Optional)</label>

                <textarea
                  onChange={(e) => {
                    setFormData({ ...formData, note: e.target.value });
                  }}
                  value={formData.note}
                  className="reservationInput3"
                  type="text"
                  name="note"
                  id="note"
                ></textarea>
              </form>
              <div className="ConfirmButtondiv">
                <h4>
                  <label
                    style={{
                      marginRight: "1rem",
                      position: "relative",
                      display: "inline-block",
                    }}
                  >
                    <input
                      type="checkbox"
                      name="agreement"
                      checked={value}
                      onChange={(e) => setValue(e.target.checked)}
                      style={{ display: "none" }}
                    />
                    <span
                      style={{
                        display: "inline-block",
                        width: "20px",
                        height: "20px",
                        backgroundColor: value ? "#4A6AFE" : "#e5e5ea",
                        border: `1px solid ${value ? "#4A6AFE" : "#CCCCCC"}`,
                        borderRadius: "3px",
                        cursor: "pointer",
                      }}
                    />
                    {value && (
                      <span
                        style={{
                          position: "absolute",
                          top: "10%",
                          left: "30%",
                          transform: "rotate(45deg)",
                          width: "8px",
                          height: "12px",
                          borderStyle: "solid",
                          borderWidth: "0 2px 2px 0",
                          borderColor: "#FFFFFF",
                        }}
                      />
                    )}
                  </label>
                  By clicking ”confirm” you agree to{" "}
                  <a
                    href="https://grouple.in/privacy"
                    target="blank"
                    style={{ margin: " 0rem 0.3rem" }}
                  >
                    Terms and Condition
                  </a>
                </h4>
                <button
                  type="submit"
                  onClick={handleFormSubmit}
                  disabled={!value || requestSent}
                  style={{ cursor: !value ? "not-allowed" : "pointer" , backgroundColor:themeColor}}
                >
                  Confirm
                </button>
              </div>
            </div>


        {/* confirm button
        <div className="ConfirmButtondiv">
          <h4>
            <label
              style={{
                marginRight: "1rem",
                position: "relative",
                display: "inline-block",
              }}
            >
              <input
                type="checkbox"
                name="agreement"
                checked={value}
                onChange={(e) => setValue(e.target.checked)}
                style={{ display: "none" }}
              />
              <span
                style={{
                  display: "inline-block",
                  width: "20px",
                  height: "20px",
                  backgroundColor: value ? "#4A6AFE" : "#e5e5ea",
                  border: `1px solid ${value ? "#4A6AFE" : "#CCCCCC"}`,
                  borderRadius: "3px",
                  cursor: "pointer",
                }}
              />
              {value && (
                <span
                  style={{
                    position: "absolute",
                    top: "10%",
                    left: "30%",
                    transform: "rotate(45deg)",
                    width: "8px",
                    height: "12px",
                    borderStyle: "solid",
                    borderWidth: "0 2px 2px 0",
                    borderColor: "#FFFFFF",
                  }}
                />
              )}
            </label>
            By clicking ”confirm” you agree to{" "}
            <a
              href="https://grouple.in/privacy"
              target="blank"
              style={{ margin: " 0rem 0.3rem" }}
            >
              Terms and Condition
            </a>
          </h4>
          <button
            type="submit"
            onClick={handleFormSubmit}
            disabled={!value || requestSent}
            style={{ cursor: !value ? "not-allowed" : "pointer" }}
          >
            Confirm
          </button>
        </div> */}
      </div>
    </>
  );
};

export default PersonalInfoIndividual;
