import { React, useEffect, useState } from "react";
import "../Styles/PackageDeals.css";
import ultra from "../Images/pattern.png";
import regular from "../Images/Pattern4.png";
import premium from "../Images/Pattern3.png";
import exclusive from "../Images/Pattern2.png";
import pattern from "../Images/VPattern.png";
import { Link } from "react-router-dom";
import { setEstateCodeForTheme, useTheme } from "./ColorContext";

const PackageDeals = () => {
  const [selectedPackage, setSelectedPackage] = useState("General");


  const estateCode = localStorage.getItem("estateCode")
  useEffect(() => {
    sessionStorage.clear();
    const companyData = JSON.parse(localStorage.getItem("companyData"));
   

    localStorage.clear();
    if (companyData) {
      localStorage.setItem("companyData", JSON.stringify(companyData));
    }
    if (estateCode) {
      localStorage.setItem("estateCode", estateCode);
    }

  });
  const handlePackageClick = (packageType) => {
    setSelectedPackage(packageType);
  };

  const { themeColor } = useTheme();
  setEstateCodeForTheme(estateCode)
  return (
    <div className="package">
      <h1>What you can experience</h1>
      <div className="package-buttons">
        <button
          onClick={() => handlePackageClick("General")}
          className={selectedPackage === "General" ? "focused" : ""}
        >

          Group Experiences

        </button>

        {/* <button
          onClick={() => handlePackageClick("Valentine")}
          className={selectedPackage === "Valentine" ? "focused" : ""}
        >
          Valentine Day 💞
        </button> */}

        <button
          onClick={() => handlePackageClick("Individual")}
          className={selectedPackage === " Individual" ? "focused" : ""}
        >
          À la Carte
        </button>
      </div>

      {/* General Category */}
      {selectedPackage === "General" && (
        <>
          {/* line 1 */}
          <div className="package-cards">
            {/* ultra premium */}

            {/* <div className="card1">
              <Link
                to={`/groupSize/janjaes?category=Ultra Exclusive&duration=3&price=449&packageName=Ultra Premium Beverage Package&dicsPrice=420&choiceM=4&choiceD=1&choiceA=4&pax=10&selectedPackage=General`}
                style={{ textDecoration: "none" }}
              >
                <img src={ultra} alt="regular" />
                <div className="inner-card">
                  <div className="regular">
                    <div className="inside-regular">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M11.082 2.33301H2.91536C2.76065 2.33301 2.61228 2.38217 2.50289 2.46969C2.39349 2.55721 2.33203 2.67591 2.33203 2.79967V11.1997C2.33203 11.3234 2.39349 11.4421 2.50289 11.5297C2.61228 11.6172 2.76065 11.6663 2.91536 11.6663H11.082C11.2367 11.6663 11.3851 11.6172 11.4945 11.5297C11.6039 11.4421 11.6654 11.3234 11.6654 11.1997V2.79967C11.6654 2.67591 11.6039 2.55721 11.4945 2.46969C11.3851 2.38217 11.2367 2.33301 11.082 2.33301Z"
                          fill="#4A6AFE"
                        />
                        <path
                          d="M5.83203 2.33301H8.16536V6.99967L6.9987 5.83301L5.83203 6.99967V2.33301Z"
                          fill="#DBE1FF"
                        />
                      </svg>
                      ULTRA EXCLUSIVE
                    </div>

                    <div className="choose">
                      {" "}
                      <Link
                        to={`/groupSize/janjaes?category=Ultra Exclusive&duration=3&price=449&packageName=Ultra Premium Beverage Package&dicsPrice=420&choiceM=4&choiceD=1&choiceA=4&pax=10&selectedPackage=General`}
                      >
                        <button className="choose-bttn">Choose</button>
                      </Link>
                    </div>
                  </div>

                  <div className="non-alcoholic">
                    <div>
                      <h2>Ultra Premium Beverage Package</h2>
                    </div>
                    <h3>Lowest at</h3>
                    <h4>
                      <span>AED 529</span> AED 449{" "}
                      <span
                        style={{
                          fontFamily: "Gilroy-Regular",
                          fontWeight: "400",
                          color: "#242426",
                          fontSize: "1.1rem",
                        }}
                      >
                        /person
                      </span>
                    </h4>
                  </div>
                </div>

                <div className="regular-food">
                  <div className="food-card">
                    <div className="icon-div">What’s Included</div>

                    <div className="whatIncludes">
                      <ul>
                        <li>3 Course Dining Experience</li>
                        <li>Exclusive Area for Group</li>
                        <li>Live Band Performance</li>
                        <li>Great Ambiance </li>
                      </ul>
                      <ul>
                        <li>Unlimited Imported Alcoholic Beverage</li>
                        <li>Memorable Photo Session</li>
                        <li>Personal Concierge & SPOC</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Link>
            </div> */}

            {/* premium card */}
            <div className="card1">
              <Link
                to={`/groupSize/janjaes?category=Exclusive&duration=3 - 4&price=449&packageName=Exclusive Package&dicsPrice=399&choiceM=3&choiceD=1&choiceA=4&pax=20&selectedPackage=General`}
                style={{ textDecoration: "none" }}
              >
                <img src={exclusive} alt="regular" />
                <div className="inner-card">
                  <div className="regular">
                  <div className="inside-regular-parent">
                      <div className="inside-regular3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <path
                            d="M11.082 2.33301H2.91536C2.76065 2.33301 2.61228 2.38217 2.50289 2.46969C2.39349 2.55721 2.33203 2.67591 2.33203 2.79967V11.1997C2.33203 11.3234 2.39349 11.4421 2.50289 11.5297C2.61228 11.6172 2.76065 11.6663 2.91536 11.6663H11.082C11.2367 11.6663 11.3851 11.6172 11.4945 11.5297C11.6039 11.4421 11.6654 11.3234 11.6654 11.1997V2.79967C11.6654 2.67591 11.6039 2.55721 11.4945 2.46969C11.3851 2.38217 11.2367 2.33301 11.082 2.33301Z"
                            fill="#4A6AFE"
                          />
                          <path
                            d="M5.83203 2.33301H8.16536V6.99967L6.9987 5.83301L5.83203 6.99967V2.33301Z"
                            fill="#DBE1FF"
                          />
                        </svg>
                        EXCLUSIVE
                      </div>
                     
                    </div>

                    {/* choose button */}
                    <div className="choose">
                      {" "}
                      <Link
                        to={`/groupSize/janjaes?category=Exclusive&duration=3 - 4&price=449&packageName=Exclusive Package&dicsPrice=399&choiceM=3&choiceD=1&choiceA=4&pax=20&selectedPackage=General`}
                      >
                        <button className="choose-bttn"style={{backgroundColor:themeColor }}>Choose</button>
                      </Link>
                    </div>
                  </div>

                  <div className="non-alcoholic">
                    <div>
                      <h2>Exclusive Package</h2>
                    </div>
                    <h3>Lowest at</h3>
                    <h4>
                      <span>AED 449</span> AED 399{" "}
                      <span
                        style={{
                          fontFamily: "Gilroy-Regular",
                          fontWeight: "400",
                          color: "#242426",
                          fontSize: "1.1rem",
                        }}
                      >
                        /person
                      </span>
                    </h4>
                  </div>
                </div>

                <div className="regular-food">
                  <div className="food-card">
                    <div className="icon-div">What’s Included</div>

                    <div className="whatIncludes">
                      <ul>
                        <li>3 Course Dining Experience</li>
                        <li>Exclusive Area for Group</li>
                        <li>Live Band Performance</li>
                        <li>Great Ambiance </li>
                      </ul>
                      <ul>
                        <li>Unlimited Imported Alcoholic Beverage</li>
                        <li>Memorable Photo Session</li>
                        <li>Personal Concierge & SPOC</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            {/* regular card */}
             <div className="card1">
              <Link
                to={`/groupSize/janjaes?category=Premium&duration=3&price=349&packageName=Premium Package&dicsPrice=299&bestVal=BEST VALUE&choiceM=3&choiceD=1&choiceA=4&pax=20&selectedPackage=General`}
                style={{ textDecoration: "none" }}
              >
                <img src={premium} alt="regular" />
                <div className="inner-card">
                  <div className="regular">
                    <div className="inside-regular-parent">
                      <div className="inside-regular3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <path
                            d="M11.082 2.33301H2.91536C2.76065 2.33301 2.61228 2.38217 2.50289 2.46969C2.39349 2.55721 2.33203 2.67591 2.33203 2.79967V11.1997C2.33203 11.3234 2.39349 11.4421 2.50289 11.5297C2.61228 11.6172 2.76065 11.6663 2.91536 11.6663H11.082C11.2367 11.6663 11.3851 11.6172 11.4945 11.5297C11.6039 11.4421 11.6654 11.3234 11.6654 11.1997V2.79967C11.6654 2.67591 11.6039 2.55721 11.4945 2.46969C11.3851 2.38217 11.2367 2.33301 11.082 2.33301Z"
                            fill="#4A6AFE"
                          />
                          <path
                            d="M5.83203 2.33301H8.16536V6.99967L6.9987 5.83301L5.83203 6.99967V2.33301Z"
                            fill="#DBE1FF"
                          />
                        </svg>
                        PREMIUM
                      </div>
                      <div className="inside-regular2">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <path
                            d="M6.99984 1.45825L5.216 5.09767L1.1665 5.68508L4.10067 8.55275L3.39834 12.5416L6.99984 10.6224L10.6013 12.5416L9.90484 8.55275L12.8332 5.68508L8.80584 5.09767L6.99925 1.45825H6.99984Z"
                            fill="#EE4622"
                            stroke="#EE4622"
                            stroke-width="0.875"
                            stroke-linejoin="round"
                          />
                        </svg>
                        BEST VALUE
                      </div>
                    </div>

                    {/* choose button */}
                    <div className="choose">
                      {" "}
                      <Link
                        to={`/groupSize/janjaes?category=Premium&duration=3&price=349&packageName=Premium Package&dicsPrice=299&bestVal=BEST VALUE&choiceM=3&choiceD=1&choiceA=4&pax=20&selectedPackage=General`}
                      >
                        <button className="choose-bttn" style={{backgroundColor:themeColor }}>Choose</button>
                      </Link>
                    </div>
                  </div>

                  <div className="non-alcoholic">
                    <div>
                      <h2>Premium Package</h2>
                    </div>
                    <h3>Lowest at</h3>
                    <h4>
                      <span>AED 349</span> AED 299{" "}
                      <span
                        style={{
                          fontFamily: "Gilroy-Regular",
                          fontWeight: "400",
                          color: "#242426",
                          fontSize: "1.1rem",
                        }}
                      >
                        /person
                      </span>
                    </h4>
                  </div>
                </div>

                <div className="regular-food">
                  <div className="food-card">
                    <div className="icon-div">What’s Included</div>

                    <div className="whatIncludes">
                      <ul>
                        <li>3 Course Dining Experience</li>
                        <li>Exclusive Area for Group</li>
                        <li>Live Band Performance</li>
                        <li>Great Ambiance </li>
                      </ul>
                      <ul>
                        <li>Unlimited Imported Alcoholic Beverage</li>
                        <li>Memorable Photo Session</li>
                        <li>Personal Concierge & SPOC</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>

          {/* line 2  */}
          <div className="package-cards">
           

            {/* non-alcoholic card */}
             <div className="card1">
              <Link
                to={`/groupSize/janjaes?category=Regular&duration=3&price=249&packageName=Regular Package&dicsPrice=199&choiceM=3&choiceD=1&choiceA=4&pax=20&selectedPackage=General`}
                style={{ textDecoration: "none" }}
              >
                <img src={regular} alt="regular" />
                <div className="inner-card">
                  <div className="regular">
                    <div className="inside-regular">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M11.082 2.33301H2.91536C2.76065 2.33301 2.61228 2.38217 2.50289 2.46969C2.39349 2.55721 2.33203 2.67591 2.33203 2.79967V11.1997C2.33203 11.3234 2.39349 11.4421 2.50289 11.5297C2.61228 11.6172 2.76065 11.6663 2.91536 11.6663H11.082C11.2367 11.6663 11.3851 11.6172 11.4945 11.5297C11.6039 11.4421 11.6654 11.3234 11.6654 11.1997V2.79967C11.6654 2.67591 11.6039 2.55721 11.4945 2.46969C11.3851 2.38217 11.2367 2.33301 11.082 2.33301Z"
                          fill="#4A6AFE"
                        />
                        <path
                          d="M5.83203 2.33301H8.16536V6.99967L6.9987 5.83301L5.83203 6.99967V2.33301Z"
                          fill="#DBE1FF"
                        />
                      </svg>
                      REGULAR
                    </div>
                   
                    <div className="choose">
                      {" "}
                      <Link
                        to={`/groupSize/janjaes?category=Regular&duration=3&price=249&packageName=Regular Package&dicsPrice=199&choiceM=3&choiceD=1&choiceA=4&pax=20&selectedPackage=General`}
                      >
                        <button className="choose-bttn" style={{backgroundColor:themeColor }}>Choose</button>
                      </Link>
                    </div>
                  </div>

                  <div className="non-alcoholic">
                    <div>
                      <h2>Regular Package</h2>
                    </div>
                    <h3>Lowest at</h3>
                    <h4>
                      <span>AED 249</span> AED 199{" "}
                      <span
                        style={{
                          fontFamily: "Gilroy-Regular",
                          fontWeight: "400",
                          color: "#242426",
                          fontSize: "1.1rem",
                        }}
                      >
                        /person
                      </span>
                    </h4>
                  </div>
                </div>

                <div className="regular-food">
                  <div className="food-card">
                    <div className="icon-div">What’s Included</div>

                    <div className="whatIncludes">
                      <ul>
                        <li>3 Course Dining Experience</li>
                        <li>Exclusive Area for Group</li>
                        <li>Live Band Performance</li>
                        <li>Great Ambiance </li>
                      </ul>
                      <ul>
                        <li>Unlimited Imported Alcoholic Beverage</li>
                        <li>Memorable Photo Session</li>
                        <li>Personal Concierge & SPOC</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Link>
            </div> 
          </div>
        </>
      )}

      {/* Individual Category */}
      {selectedPackage === "Individual" && (
        <>
          {/* line 1 */}
          <div className="package-cards2">
            {/* ultra premium */}

            <div className="card1">
              <Link
                to={`/personalInfo/janjaes?category=Gerneral Reservations`}
                style={{ textDecoration: "none" }}
              >
                <img src={pattern} alt="regular" />
                <div className="inner-card">
                  <div className="regular">
                    <div className="inside-regular">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M11.082 2.33301H2.91536C2.76065 2.33301 2.61228 2.38217 2.50289 2.46969C2.39349 2.55721 2.33203 2.67591 2.33203 2.79967V11.1997C2.33203 11.3234 2.39349 11.4421 2.50289 11.5297C2.61228 11.6172 2.76065 11.6663 2.91536 11.6663H11.082C11.2367 11.6663 11.3851 11.6172 11.4945 11.5297C11.6039 11.4421 11.6654 11.3234 11.6654 11.1997V2.79967C11.6654 2.67591 11.6039 2.55721 11.4945 2.46969C11.3851 2.38217 11.2367 2.33301 11.082 2.33301Z"
                          fill="#4A6AFE"
                        />
                        <path
                          d="M5.83203 2.33301H8.16536V6.99967L6.9987 5.83301L5.83203 6.99967V2.33301Z"
                          fill="#DBE1FF"
                        />
                      </svg>
                      REGULAR
                    </div>

                    {/* choose button */}
                    <div className="choose">
                      {" "}
                      <Link to={`/personalInfo/janjaes?category=Gerneral Reservations`}>
                        <button className="choose-bttn" style={{backgroundColor:themeColor }}>Choose</button>
                      </Link>
                    </div>
                  </div>

                  <div className="non-alcoholic">
                    <div>
                      <h2>Individual Package</h2>
                      <h3>Starting at</h3>
                      <h4>
                        AED 350{" "}
                        <span
                          style={{
                            fontFamily: "Gilroy-Regular",
                            fontWeight: "400",
                            color: "#242426",
                            fontSize: "1.1rem",
                            textDecoration: "none",
                          }}
                        >
                          /person
                        </span>
                      </h4>
                    </div>
                  </div>
                </div>

                <div className="regular-food">
                  <div className="food-card" style={{ width: "100%" }}>
                    <div className="icon-div">What's included</div>
                    <div
                      style={{ display: "flex", width: "100%", gap: "3rem" }}
                    >
                      <ul>
                        <li>Gourmet Dining Options</li>
                        <li>A Variety Of Beverages</li>
                        <li>Order Of The Menu</li>
                        <li>On- Site Entertainment</li>
                      </ul>
                      <ul>
                        <li>Luxurious Space With Modern Interiors</li>
                        <li>Butler Service </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </>
      )}

      {/* Valentine Category */}
      {/* {selectedPackage === "Valentine" && (
        <>

          <div className="package-cards2">


            <div className="card1">
              <Link
                to={`/valentineFandB?category=Valentine&duration=2&price=249&packageName=Valentine Package&pax=2&selectedPackage=Valentine`}
                style={{ textDecoration: "none" }}
              >
                <img src={pattern} alt="regular" />
                <div className="inner-card">
                  <div className="regular">
                    <div className="inside-regular">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="14"
                        height="14"
                        viewBox="0 0 14 14"
                        fill="none"
                      >
                        <path
                          d="M11.082 2.33301H2.91536C2.76065 2.33301 2.61228 2.38217 2.50289 2.46969C2.39349 2.55721 2.33203 2.67591 2.33203 2.79967V11.1997C2.33203 11.3234 2.39349 11.4421 2.50289 11.5297C2.61228 11.6172 2.76065 11.6663 2.91536 11.6663H11.082C11.2367 11.6663 11.3851 11.6172 11.4945 11.5297C11.6039 11.4421 11.6654 11.3234 11.6654 11.1997V2.79967C11.6654 2.67591 11.6039 2.55721 11.4945 2.46969C11.3851 2.38217 11.2367 2.33301 11.082 2.33301Z"
                          fill="#4A6AFE"
                        />
                        <path
                          d="M5.83203 2.33301H8.16536V6.99967L6.9987 5.83301L5.83203 6.99967V2.33301Z"
                          fill="#DBE1FF"
                        />
                      </svg>
                      VALENTINE SPECIAL
                    </div>


                    <div className="choose">
                      {" "}
                      <Link
                        to={`/valentineFandB?category=Valentine&duration=2&price=249&packageName=Valentine Package&pax=2&selectedPackage=Valentine`}
                      >
                        <button className="choose-bttn">Choose</button>
                      </Link>
                    </div>
                  </div>

                  <div className="non-alcoholic">
                    <div>
                      <h2>Valentine’s Day Package</h2>
                    </div>
                    <h3>Priced at</h3>
                    <h4>
                      AED 249{" "}
                      <span
                        style={{
                          fontFamily: "Gilroy-Regular",
                          fontWeight: "400",
                          color: "#242426",
                          fontSize: "1.1rem",
                          textDecoration: "none",
                        }}
                      >
                        /couple
                      </span>
                    </h4>
                  </div>
                </div>

                <div className="regular-food">
                  <div className="food-card" style={{ width: "100%" }}>
                    <div className="icon-div">What's included</div>
                    <div
                      style={{ display: "flex", width: "100%", gap: "3rem" }}
                    >
                      <ul>
                        <li>3 Course Dining Experience</li>
                        <li>Glass Of Red Wine For Two</li>
                        <li>Private Table For Two</li>
                        <li>Soft Candlelight Ambiance</li>
                      </ul>
                      <ul>
                        <li>Floral Elegance</li>
                        <li>Live Band Performance</li>
                        <li>Memorable Photo Session</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </>
      )} */}
    </div>
  );
};

export default PackageDeals;
