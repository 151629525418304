import React, { useState, Fragment, useEffect } from "react";
import Footer from "../../Components/Footer";
import "../../Styles/Request.css";
import rectangle from "../../Images/ReservationImage1.png";
import ConciergeImage from "../../Images/image 22.png";
import { Link } from "react-router-dom";
import logo from "../../Images/barbaar logo.png";
import { useLocation, useParams } from "react-router-dom";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Hourglass } from "react-loader-spinner";
import share from "../../Images/share.png";
import { Dialog, Transition } from "@headlessui/react";

const ValentineCustomer = () => {
  let [isOpen2, setIsOpen2] = useState(false);
  const [isCopied2, setIsCopied2] = useState(false);

  function openModal2() {
    setIsOpen2(true);
  }
  const { type, randomReservationID } = useParams();


  const [bookingData, setbookingData] = useState(null);
  useEffect(() => {
    if (randomReservationID) {
      const hostname = window.location.hostname;
      let apiUrl;
      if (hostname.includes("testgrouple.com") || hostname.includes("localhost")) {
        apiUrl =`https://server.testgrouple.com/api/v1/beta/enduser/viewValentinesReservation/${type}/${randomReservationID}`;
      }
      if (hostname.includes("grouple.tech")) {
        apiUrl =
        `https://production.grouple.tech/api/v1/beta/enduser/viewValentinesReservation/${type}/${randomReservationID}`;
      }
   

      fetch(apiUrl)
        .then((res) => res.json())
        .then((data) => {
          setbookingData(data);
          console.log("Data: " + data);
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
        });
    }
  }, [randomReservationID]);
  if (!bookingData) {
    return (
      <p
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "80vh",
        }}
      >
        <Hourglass
          visible={true}
          height="100"
          width="100"
          ariaLabel="hourglass-loading"
          wrapperStyle={{}}
          wrapperClass=""
          colors={["#306cce", "#72a1ed"]}
        />
      </p>
    );
  }

  const foodOption = [
    { id: 1, name: "Appetizer" },
    { id: 2, name: "Main Course" },
    { id: 3, name: "Dessert" },
  ];

  const renderFoodItemsThree = (categoryName) => {
    let renderFoodItemArray = [];
    switch (categoryName) {
      case "Appetizer":
        renderFoodItemArray =
          bookingData.data.bookingFoodItemSelected.food.appetizer;
        break;
      case "Main Course":
        renderFoodItemArray =
          bookingData.data.bookingFoodItemSelected.food.main_course;
        break;
      case "Dessert":
        renderFoodItemArray =
          bookingData.data.bookingFoodItemSelected.food.dessert;
        break;
      default:
        break;
    }
    return renderFoodItemArray.map((item, index) => (
      <div key={index} className="selected-food3">
        <div className="ItemDetailLogo3">
          {item.item_category === "Vegetarian" ? (
            <div className="veg-div">
              <svg
                width="13"
                height="13"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="15"
                  height="15"
                  rx="1.5"
                  stroke="#009600"
                />
                <circle cx="8" cy="8" r="4" fill="#009600" />
              </svg>
            </div>
          ) : item.item_category === "Non Vegetarian" ? (
            <div className="nonveg-div">
              <svg
                width="13"
                height="13"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="15"
                  height="15"
                  rx="1.5"
                  stroke="#963415"
                />
                <path
                  d="M4.38833 12C4.20992 12 4.09388 11.8122 4.17367 11.6527L7.78534 4.42932C7.87378 4.25243 8.12622 4.25243 8.21466 4.42933L11.8263 11.6527C11.9061 11.8122 11.7901 12 11.6117 12H4.38833Z"
                  fill="#963415"
                />
              </svg>
            </div>
          ) : null}
        </div>
        <div className="ItemDetailName3">{item.item_name}</div>
      </div>
    ));
  };

  let beverages = bookingData.data.bookingFoodItemSelected.beverage;
  const keysArray = Object.keys(beverages);

  const beverageOptions = keysArray.map((key, index) => {
    let modifiedName = key
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    modifiedName =
      modifiedName === "Non Alcoholic" ? "Non-Alcoholic" : modifiedName;

    return {
      id: index + 1,
      name: modifiedName,
    };
  });

  const renderBeverageItemsThree = (categoryName) => {
    let renderBeverageItemsArray = [];
    switch (categoryName) {
      case "Wines":
        renderBeverageItemsArray =
          bookingData.data.bookingFoodItemSelected.beverage.wines;
        break;
      default:
        break;
    }
    return renderBeverageItemsArray.map((item, index) => (
      <div key={index} className="selected-food3">
        <div className="ItemDetailLogo3">
          <div className="veg-div">
            <svg
              width="13"
              height="13"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="0.5"
                y="0.5"
                width="15"
                height="15"
                rx="1.5"
                stroke="#009600"
              />
              <circle cx="8" cy="8" r="4" fill="#009600" />
            </svg>
          </div>
        </div>
        <div className="ItemDetailName3">{item.item_name}</div>
      </div>
    ));
  };

  //*********** Share popup ************** //

  const copyToClipboard = () => {
    const hostingName = window.location.hostname;
    let url;
    if (
      hostingName.includes("testgrouple.com") ||
      hostingName.includes("localhost")
    ) {
      url = `https://beta-enduser.testgrouple.com/valentineInvitee/4G9hT2w6sQ/${randomReservationID}`;
    }
    if (hostingName.includes("grouple.tech")) {
      url = `https://grouple.tech/valentineInvitee/4G9hT2w6sQ/${randomReservationID}`;
    }

    navigator.clipboard
      .writeText(url)
      .then(() => setIsCopied2(true))
      .catch((error) => console.error("Failed to copy to clipboard", error));
  };

  const openWhatsApp = () => {
    const hostingName = window.location.hostname;
    let url;
    if (
      hostingName.includes("testgrouple.com") ||
      hostingName.includes("localhost")
    ) {
      url = `https://beta-enduser.testgrouple.com/valentineInvitee/4G9hT2w6sQ/${randomReservationID}`;
    }
    if (hostingName.includes("grouple.tech")) {
      url = `https://grouple.tech/valentineInvitee/4G9hT2w6sQ/${randomReservationID}`;
    }
    const whatsappMessage =
      encodeURIComponent(
        `Hey there! ${bookingData.data.bookerFirstName} ${bookingData.data.bookerLastName} 
        has invited you to ${bookingData.data.bookingOutlet}, ${bookingData.data.bookingDate} | 
        ${bookingData.data.bookingSlotStartTime} Click here to view booking details:`
      ) + url;
    const whatsappWebUrl = `https://web.whatsapp.com/send?text=${whatsappMessage}`;
    window.open(whatsappWebUrl, "_blank");
  };


  function closeModal() {
    setIsOpen2(false);
    setIsCopied2(false);
  }

  return (
    <div>
      <nav className="navbar-div2" style={{ display: "flex" }}>
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <Link to="/landingPage/barbaar" style={{ textDecoration: "none" }}>
            <img src={logo} alt="" className="barbaar-logo" />
          </Link>
        </div>
      </nav>

      <div className="RequestWrapper">
        {/* LEFT */}
        <div className="RequestWrapperLeft">
          <div className="RequestStatusWrapper">
            <div className="ReqStatusIndicatorParent">
              <div className="RequestSuccess">
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M12 2C6.49 2 2 6.49 2 12C2 17.51 6.49 22 12 22C17.51 22 22 17.51 22 12C22 6.49 17.51 2 12 2ZM16.78 9.7L11.11 15.37C10.97 15.51 10.78 15.59 10.58 15.59C10.38 15.59 10.19 15.51 10.05 15.37L7.22 12.54C6.93 12.25 6.93 11.77 7.22 11.48C7.51 11.19 7.99 11.19 8.28 11.48L10.58 13.78L15.72 8.64C16.01 8.35 16.49 8.35 16.78 8.64C17.07 8.93 17.07 9.4 16.78 9.7Z"
                      fill="#3C7D44"
                    />
                  </svg>
                </div>
                <div>Completed</div>
              </div>
            </div>
            <h1 className="RequestStatusHeading">
              Your reservation is confirmed
            </h1>
            <h1 className="RequestStatusHeading3">
              Reservation ID{" "}
              <span style={{ color: "#BE360D" }}>
                #{bookingData.data.reservationCode}
              </span>
            </h1>
            <p className="RequestStatusDetails">
              We are pleased to inform you that your reservation request has
              been received and confirmed. Please check your email for
              reservation confirmation from the restaurant.
            </p>
            <p className="RequestEmail">
              *In case of no email, Please check spam emails.
            </p>
            <p className="RequestMeanTime">
              We look forward to host you and ensuring a delightful stay. If you
              have any special requests or inquiries, feel free to reach out.
            </p>
          </div>
          <div className="ConciergeWrapper">
            <div className="ConciergeWrapperDetail">
              The personal concierge will reach out to you shortly. You can
              Contact him for any enquiries you have.
            </div>
            <div className="ConciergeWrapperCall">
              <div>
                <img src={ConciergeImage} alt="ConciergeImage" />
              </div>
              <div>
                <div className="ConciergeName">Mr. Harmesh Vaidya</div>
                <button className="ConciergeCallBtn">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M20.8472 14.8554L16.4306 12.8764L16.4184 12.8707C16.1892 12.7727 15.939 12.7333 15.6907 12.7562C15.4424 12.7792 15.2037 12.8636 14.9963 13.002C14.9718 13.0181 14.9484 13.0357 14.9259 13.0545L12.6441 14.9998C11.1984 14.2976 9.70595 12.8164 9.00376 11.3895L10.9519 9.07294C10.9706 9.0495 10.9884 9.02606 11.0053 9.00075C11.1407 8.79384 11.2229 8.55667 11.2445 8.31035C11.2661 8.06402 11.2264 7.81618 11.1291 7.58887V7.57762L9.14438 3.15356C9.0157 2.85662 8.79444 2.60926 8.51362 2.44841C8.2328 2.28756 7.9075 2.22184 7.58626 2.26106C6.31592 2.42822 5.14986 3.05209 4.30588 4.01615C3.4619 4.98021 2.99771 6.21852 3.00001 7.49981C3.00001 14.9436 9.05626 20.9998 16.5 20.9998C17.7813 21.0021 19.0196 20.5379 19.9837 19.6939C20.9477 18.85 21.5716 17.6839 21.7388 16.4136C21.7781 16.0924 21.7125 15.7672 21.5518 15.4864C21.3911 15.2056 21.144 14.9843 20.8472 14.8554ZM16.5 19.4998C13.3185 19.4963 10.2682 18.2309 8.01856 15.9813C5.76888 13.7316 4.50348 10.6813 4.50001 7.49981C4.49648 6.58433 4.82631 5.69887 5.42789 5.00879C6.02947 4.3187 6.86167 3.87118 7.76907 3.74981C7.7687 3.75355 7.7687 3.75732 7.76907 3.76106L9.73782 8.16731L7.80001 10.4867C7.78034 10.5093 7.76247 10.5335 7.74657 10.5589C7.60549 10.7754 7.52273 11.0246 7.5063 11.2825C7.48988 11.5404 7.54035 11.7981 7.65282 12.0307C8.5022 13.7679 10.2525 15.5051 12.0084 16.3536C12.2428 16.465 12.502 16.5137 12.7608 16.495C13.0196 16.4762 13.2692 16.3907 13.485 16.2467C13.5091 16.2305 13.5322 16.2129 13.5544 16.1942L15.8334 14.2498L20.2397 16.2232C20.2397 16.2232 20.2472 16.2232 20.25 16.2232C20.1301 17.1319 19.6833 17.9658 18.9931 18.5689C18.3028 19.172 17.4166 19.5029 16.5 19.4998Z"
                      fill="white"
                    />
                  </svg>
                  +971 54 320 2102
                </button>
              </div>
            </div>
          </div>

          <div>
            <div className="CustomerFoodMenuHead">Food menu</div>
            {/* {/ ************* Food menu  ********* /} */}
            <div className="closure-div2">
              {foodOption.map((category, index) => (
                <div key={category.id} className="w-full">
                  <div className="w-full rounded-2xl bg-white pb-2 pt-2">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className="flex w-full justify-between rounded-lg"
                            style={{
                              background: "white",
                              padding: "0.75rem 0.5rem",
                              borderRadius: open
                                ? " 0.5rem 0.5rem 0 0"
                                : " 0.5rem",
                              border: "1px solid #E5E5EA",
                              borderBottom: open ? "none" : "1px solid #E5E5EA",
                              boxShadow:
                                "0px 4px 12px 0px rgba(34, 34, 34, 0.06)",
                            }}
                          >
                            <span
                              style={{
                                fontWeight: "400",
                                paddingLeft: "0.5rem",
                              }}
                            >
                              {category.name}
                            </span>
                            <ChevronDownIcon
                              className={`${
                                open ? "rotate-180 transform" : ""
                              } h-5 w-5 text-black-700`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                            <div className="selected-food-list2">
                              {renderFoodItemsThree(category.name)}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              ))}
            </div>

            {/* {/ ****************Beverage menu ***** /} */}
            <div className="CustomerFoodMenuHead">Beverages menu</div>

            <div className="closure-div2">
              {beverageOptions.map((category, index) => (
                <div key={category.id} className="w-full">
                  <div className="w-full rounded-2xl bg-white pb-2 pt-2">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button
                            className="flex w-full justify-between rounded-lg"
                            style={{
                              background: "white",
                              padding: "0.75rem 0.5rem",
                              borderRadius: open
                                ? " 0.5rem 0.5rem 0 0"
                                : " 0.5rem",
                              border: "1px solid #E5E5EA",
                              borderBottom: open ? "none" : "1px solid #E5E5EA",
                              boxShadow:
                                "0px 4px 12px 0px rgba(34, 34, 34, 0.06)",
                            }}
                          >
                            <span
                              style={{
                                fontWeight: "400",
                                paddingLeft: "0.5rem",
                              }}
                            >
                              {category.name}{" "}
                            </span>
                            <ChevronDownIcon
                              className={`${
                                open ? "rotate-180 transform" : ""
                              } h-5 w-5 text-black-700`}
                            />
                          </Disclosure.Button>
                          <Disclosure.Panel className="pb-2 text-sm text-gray-500">
                            <div className="selected-food-list2">
                              {renderBeverageItemsThree(category.name)}
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div style={{ paddingBottom: "2rem" }}>
            <div className="MapHeading">Where you’ll be</div>
            <iframe
              title="Google Maps"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d18708.585519147462!2d55.311497065028014!3d25.221622679203087!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f433d48cf3833%3A0xb2e8ac77b1ee9451!2sBar%20Baar%20Freestyle%20Kitchen%20%26%20Bar!5e0!3m2!1sen!2sin!4v1701754283435!5m2!1sen!2sin"
              width="100%"
              height="450"
              style={{ border: "0px", borderRadius: "8px" }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>

        {/* RIGHT */}
        <div className="RequestWrapperRight">
          <div className="RequestWrapperChild">
            <img className="resCardImgOne" src={rectangle} alt="" />
            <button className="share-bttn2" type="button" onClick={openModal2}>
              {" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
              >
                <path
                  d="M15.75 10.6875V14.625C15.75 14.9233 15.6315 15.2095 15.4205 15.4204C15.2095 15.6314 14.9234 15.75 14.625 15.75H3.375C3.07663 15.75 2.79048 15.6314 2.5795 15.4204C2.36853 15.2095 2.25 14.9233 2.25 14.625V10.6875C2.25 10.5383 2.30926 10.3952 2.41475 10.2897C2.52024 10.1842 2.66332 10.125 2.8125 10.125C2.96168 10.125 3.10476 10.1842 3.21025 10.2897C3.31574 10.3952 3.375 10.5383 3.375 10.6875V14.625H14.625V10.6875C14.625 10.5383 14.6843 10.3952 14.7898 10.2897C14.8952 10.1842 15.0383 10.125 15.1875 10.125C15.3367 10.125 15.4798 10.1842 15.5852 10.2897C15.6907 10.3952 15.75 10.5383 15.75 10.6875ZM6.58547 6.02292L8.4375 4.17019V10.6875C8.4375 10.8366 8.49676 10.9797 8.60225 11.0852C8.70774 11.1907 8.85082 11.25 9 11.25C9.14918 11.25 9.29226 11.1907 9.39775 11.0852C9.50324 10.9797 9.5625 10.8366 9.5625 10.6875V4.17019L11.4145 6.02292C11.5201 6.12847 11.6632 6.18777 11.8125 6.18777C11.9618 6.18777 12.1049 6.12847 12.2105 6.02292C12.316 5.91738 12.3753 5.77422 12.3753 5.62495C12.3753 5.47569 12.316 5.33253 12.2105 5.22699L9.39797 2.41449C9.34573 2.36219 9.28369 2.3207 9.2154 2.29239C9.14712 2.26408 9.07392 2.24951 9 2.24951C8.92608 2.24951 8.85288 2.26408 8.7846 2.29239C8.71631 2.3207 8.65427 2.36219 8.60203 2.41449L5.78953 5.22699C5.68398 5.33253 5.62469 5.47569 5.62469 5.62495C5.62469 5.77422 5.68398 5.91738 5.78953 6.02292C5.89508 6.12847 6.03823 6.18777 6.1875 6.18777C6.33677 6.18777 6.47992 6.12847 6.58547 6.02292Z"
                  fill="#4A6AFE"
                />
              </svg>
              Share
            </button>
            <div className="RequestDateAndTimeWrapper">
              <div className="RequestDateChild">
                <div>Date of Reservation</div>
                <div>{bookingData.data.bookingDate}</div>
              </div>
              <div className="RequestTimeChild">
                <div>Time of Reservation</div>
                <div>
                  {bookingData.data.bookingSlotStartTime}
                </div>
              </div>
            </div>

            <div className="RequestSummaryWrapper">
              <div className="RequestSummaryChild">
                <div
                  className="reservationSumaryAdress"
                  style={{ alignItems: "flex-start" }}
                >
                  {" "}
                  <div>{bookingData.data.bookingOutlet}</div>
                  <div>{bookingData.data.bookingOutletAddress}</div>
                </div>
              </div>
              <div className="RequestSummaryChild">
                <div>{bookingData.data.bookingPackage}</div>
              </div>
              <div className="RequestSummaryChild">
                <div>{bookingData.data.bookingTotalGuest} Guests</div>
              </div>
              <div className="RequestSummaryChild">
                <div>
                  {" "}
                  AED{" "}
                  {Number(
                    bookingData.data.bookingTotalCost
                  ).toLocaleString()}{" "}
                  /-
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* *** Share popup ******  */}
        <Transition appear show={isOpen2} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={closeModal}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black/50" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-md  rounded-lg transform overflow-hidden  bg-white p-6 text-left align-middle shadow-xl transition-all">
                    <Dialog.Title
                      as="h3"
                      style={{
                        color: "#242426",
                        fontFamily: "Gilroy-SemiBold",
                        fontSize: "1.375rem",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal",
                      }}
                    >
                      Share this invite
                      <svg
                        onClick={closeModal}
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          right: "6%",
                          top: "9%",
                        }}
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="25"
                        viewBox="0 0 25 25"
                        fill="none"
                      >
                        <path
                          d="M24 8L8 24"
                          stroke="#242426"
                          stroke-width="2.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M8 8L24 24"
                          stroke="#242426"
                          stroke-width="2.66667"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </Dialog.Title>
                    <div className="flex column mt-3 gap-3">
                      <img src={share} alt="" />
                      <div style={{ marginTop: "1rem" }}>
                        <p
                          style={{
                            color: "#242426",
                            fontFamily: "Gilroy-SemiBold",
                            fontSize: "1rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            letterSpacing: "0.02rem",
                          }}
                        >
                          Bar Baar - Freestyle kitchen & Bar <br />
                        </p>
                        <p
                          style={{
                            color: "#8E8E93",
                            fontFamily: "Gilroy-Medium",
                            fontSize: "0.75rem",
                            fontStyle: "normal",
                            fontWeight: "400",
                            lineHeight: "normal",
                            marginTop: "-0.8rem",
                            letterSpacing: "0.01rem",
                          }}
                        >
                          Occidental AI Jaddaf, Dubai
                        </p>
                      </div>
                    </div>

                    <div className="mt-4 flex flex-col w-full gap-2">
    
                      <button
                        style={{
                          border: "1px solid #ABABAB",
                          borderRadius: "1.5rem",
                        }}
                        type="button"
                        onClick={openWhatsApp}
                        className="flex justify-center items-center rounded-lg border border-solid border-gray-400 bg-white-500 px-4 py-2 text-sm font-medium text-black-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      >
                        <svg
                          style={{ marginRight: "1rem" }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          viewBox="0 0 20 20"
                          fill="none"
                        >
                          <path
                            d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20Z"
                            fill="#29A71A"
                          />
                          <path
                            d="M14.409 5.5903C13.3686 4.5395 11.9869 3.89508 10.5132 3.77328C9.0395 3.65148 7.57078 4.06031 6.37196 4.92603C5.17314 5.79175 4.32315 7.05737 3.97529 8.49461C3.62744 9.93184 3.80463 11.4461 4.4749 12.7642L3.81695 15.9585C3.81012 15.9903 3.80993 16.0231 3.81638 16.055C3.82283 16.0869 3.83579 16.1171 3.85445 16.1437C3.88178 16.1841 3.92079 16.2153 3.96627 16.2329C4.01176 16.2506 4.06155 16.254 4.10899 16.2426L7.23968 15.5005C8.55406 16.1538 10.0576 16.3196 11.4828 15.9684C12.9079 15.6172 14.1623 14.7718 15.0226 13.5825C15.8829 12.3933 16.2934 10.9374 16.181 9.47392C16.0687 8.01044 15.4408 6.63427 14.409 5.5903ZM13.4329 13.3812C12.713 14.099 11.786 14.5729 10.7826 14.736C9.77915 14.8991 8.74982 14.7432 7.83968 14.2903L7.40331 14.0744L5.48399 14.5289L5.48968 14.5051L5.8874 12.5733L5.67377 12.1517C5.20872 11.2383 5.04467 10.2013 5.20511 9.18898C5.36556 8.17671 5.84227 7.24119 6.56695 6.51644C7.47752 5.60614 8.71236 5.09477 9.9999 5.09477C11.2874 5.09477 12.5223 5.60614 13.4329 6.51644C13.4406 6.52533 13.449 6.53368 13.4579 6.54144C14.3571 7.45407 14.8592 8.68522 14.8545 9.96647C14.8498 11.2477 14.3388 12.4752 13.4329 13.3812Z"
                            fill="white"
                          />
                          <path
                            d="M13.2623 11.9644C13.0271 12.3349 12.6555 12.7883 12.1885 12.9008C11.3703 13.0985 10.1146 12.9076 8.55212 11.4508L8.5328 11.4337C7.15893 10.1599 6.80212 9.09965 6.88848 8.25875C6.93621 7.78147 7.33393 7.34966 7.66916 7.06784C7.72216 7.0226 7.78501 6.9904 7.85268 6.9738C7.92035 6.9572 7.99096 6.95666 8.05888 6.97223C8.12679 6.9878 8.19012 7.01905 8.2438 7.06347C8.29748 7.10789 8.34002 7.16426 8.36802 7.22806L8.87371 8.36443C8.90657 8.43811 8.91875 8.51935 8.90894 8.59943C8.89913 8.6795 8.8677 8.7554 8.81802 8.81897L8.56234 9.15079C8.50748 9.21931 8.47438 9.30267 8.46729 9.39016C8.4602 9.47764 8.47945 9.56525 8.52257 9.6417C8.66575 9.89284 9.00893 10.2622 9.38962 10.6042C9.81689 10.9906 10.2908 11.344 10.5908 11.4644C10.671 11.4972 10.7593 11.5052 10.8441 11.4874C10.929 11.4696 11.0066 11.4267 11.0669 11.3644L11.3635 11.0656C11.4207 11.0091 11.4919 10.9689 11.5697 10.9489C11.6476 10.929 11.7293 10.93 11.8067 10.9519L13.0078 11.2928C13.0741 11.3132 13.1348 11.3484 13.1854 11.3958C13.2359 11.4432 13.275 11.5015 13.2995 11.5663C13.3241 11.6311 13.3335 11.7007 13.3271 11.7697C13.3206 11.8387 13.2985 11.9053 13.2623 11.9644Z"
                            fill="white"
                          />
                        </svg>
                        Whatsapp
                      </button>

                      <button
                        style={{
                          border: "1px solid #ABABAB",
                          borderRadius: "1.5rem",
                        }}
                        onClick={copyToClipboard}
                        type="button"
                        className="flex w-full justify-center items-center rounded-lg border border-solid border-gray-400 bg-white-500 px-4 py-2 text-sm font-medium text-black-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      >
                        <svg
                          style={{ marginRight: "0.5rem" }}
                          xmlns="http://www.w3.org/2000/svg"
                          width="28"
                          height="28"
                          viewBox="0 0 28 28"
                          fill="none"
                        >
                          <path
                            d="M23.625 3.5H9.625C9.39294 3.5 9.17038 3.59219 9.00628 3.75628C8.84219 3.92038 8.75 4.14294 8.75 4.375V8.75H4.375C4.14294 8.75 3.92038 8.84219 3.75628 9.00628C3.59219 9.17038 3.5 9.39294 3.5 9.625V23.625C3.5 23.8571 3.59219 24.0796 3.75628 24.2437C3.92038 24.4078 4.14294 24.5 4.375 24.5H18.375C18.6071 24.5 18.8296 24.4078 18.9937 24.2437C19.1578 24.0796 19.25 23.8571 19.25 23.625V19.25H23.625C23.8571 19.25 24.0796 19.1578 24.2437 18.9937C24.4078 18.8296 24.5 18.6071 24.5 18.375V4.375C24.5 4.14294 24.4078 3.92038 24.2437 3.75628C24.0796 3.59219 23.8571 3.5 23.625 3.5ZM17.5 22.75H5.25V10.5H17.5V22.75ZM22.75 17.5H19.25V9.625C19.25 9.39294 19.1578 9.17038 18.9937 9.00628C18.8296 8.84219 18.6071 8.75 18.375 8.75H10.5V5.25H22.75V17.5Z"
                            fill="#4A6AFE"
                          />
                        </svg>
                        {isCopied2 ? "URL Copied Succesfully!" : "Copy URL"}
                      </button>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      </div>
      <Footer />
    </div>
  );
};

export default ValentineCustomer;
