import React from "react";
import Rectangle1 from "../MobileScroll/Rectangle 1.png";
import Rectangle2 from "../MobileScroll/Rectangle 2.png";
import Rectangle3 from "../MobileScroll/Rectangle 3.png";
import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link } from "react-router-dom";
const CarouselForMobile = () => {
  return (
    <>
      <Carousel className="CarasuselForMobile">
        <Carousel.Item interval={3000} className="caruselItem20">
          <img
            src={Rectangle1}
            alt="First slide"
            className="CarasuselForMobileItem"
          />
          <div className="carouselMobileHead">BOOK</div>
          <div className="carouselMobileSubHead">
            Choose the perfect package
          </div>
          <div className="carouselMobilePara">
            Book an experience with loved ones, old pals, clients or colleagues.
            The larger the group, the better the deal!
          </div>
          <div className="carouselMobileResButton">
            <Link to="/location" style={{ textDecoration: "none" }}>
              <button className="reserveNowMobile">Reserve Now</button>
            </Link>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={3000} className="caruselItem20">
          <img
            src={Rectangle2}
            alt="Second slide"
            className="CarasuselForMobileItem"
          />
          <div className="carouselMobileHead">BOOK</div>
          <div className="carouselMobileSubHead">
            Choose the perfect package
          </div>
          <div className="carouselMobilePara">
            Book an experience with loved ones, old pals, clients or colleagues.
            The larger the group, the better the deal!
          </div>
          <div className="carouselMobileResButton">
            <Link to="/location" style={{ textDecoration: "none" }}>
              <button className="reserveNowMobile">Reserve Now</button>
            </Link>
          </div>
        </Carousel.Item>
        <Carousel.Item interval={3000} className="caruselItem20">
          <img
            src={Rectangle3}
            alt="Third slide"
            className="CarasuselForMobileItem"
          />
          <div className="carouselMobileHead">BOOK</div>
          <div className="carouselMobileSubHead">
            Choose the perfect package
          </div>
          <div className="carouselMobilePara">
            Book an experience with loved ones, old pals, clients or colleagues.
            The larger the group, the better the deal!
          </div>
          <div className="carouselMobileResButton">
            <Link to="/location" style={{ textDecoration: "none" }}>
              <button className="reserveNowMobile">Reserve Now</button>
            </Link>
          </div>
        </Carousel.Item>
      </Carousel>
    </>
  );
};

export default CarouselForMobile;
