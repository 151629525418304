import React, { useState } from "react";
import axios from "axios";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import "../../Styles/PersonalInfo.css";
import CountryFlag from "react-country-flag";
// import rectangle from "../../Images/ReservationImage1.png";
import { useLocation, useNavigate } from "react-router-dom";
import restaurant1 from "../../Images/moksha reserve.png";
import restaurant2 from "../../Images/pahadi reserve.png";
import "../../Styles/NewReservation.css";
import { setEstateCodeForTheme, useTheme } from "../ColorContext";

const ReservationIndividual = () => {
  const hostname = window.location.hostname;
  const navigate = useNavigate();
  let apiUrl;
  if (hostname.includes("testgrouple.com") || hostname.includes("localhost")) {
    apiUrl =
      "https://server.testgrouple.com/api/v1/beta/enduser/submitIndividualDetails";
  }
  if (hostname.includes("grouple.tech")) {
    apiUrl =
      "https://production.grouple.tech/api/v1/beta/enduser/submitIndividualDetails";
  }

  const estateCode = localStorage.getItem("estateCode");
  const { themeColor } = useTheme();
  setEstateCodeForTheme(estateCode);
  const location = useLocation();
  const { reservationData } = location.state || {};
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    dialCode: "",
    phoneNumber: "",
    note: "",
    confirmEmail: "",
  });

  const [errorMessage, setErrorMessage] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    confirmEmail: "",
  });

  const displayDate = reservationData.Individualdate;
  const date = new Date(displayDate);
  let showDate;
  const storedCompanyData = JSON.parse(localStorage.getItem("companyData"));
  if (
    Object.prototype.toString.call(date) === "[object Date]" &&
    !isNaN(date)
  ) {
    const options = { weekday: "short", day: "numeric", month: "short" };
    showDate = date.toLocaleDateString("en-GB", options);
  }

  const formatTimeTo12hr = (time) => {
    let [hours, minutes] = time.split(":");
    let period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${period}`;
  };

  // const [isHappyEatingPopupVisible2, setIsHappyEatingPopupVisible2] =
  //   useState(false);

  const isValidEmail = (email) => {
    return (
      /\S+@\S+\.\S+/.test(email) &&
      (email.endsWith("@gmail.com") ||
        email.endsWith("@yahoo.com") ||
        email.endsWith(".in"))
    );
  };

  const validateForm = () => {
    let valid = true;
    const errors = {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      confirmEmail: "",
      note: "",
    };
    const nameRegex = /^\S+$/;

    if (
      formData.firstName.trim() === "" ||
      !nameRegex.test(formData.firstName)
    ) {
      valid = false;
      errors.firstName =
        "Please enter a valid first name (one word, no spaces).";
    }
    if (formData.lastName.trim() === "" || !nameRegex.test(formData.lastName)) {
      valid = false;
      errors.lastName = "Please enter a valid last name (one word, no spaces).";
    }
    if (formData.email.trim() === "" || !isValidEmail(formData.email)) {
      valid = false;
      errors.email =
        "Please enter a valid Gmail address (e.g., mailto:example@gmail.com).";
    }
    if (formData.phoneNumber.trim() === "") {
      valid = false;
      errors.phoneNumber = "Please enter your phone number.";
    }
    if (formData.confirmEmail !== formData.email) {
      valid = false;
      errors.confirmEmail = "Emails does not match";
    }
    setErrorMessage(errors);
    return valid;
  };
  const [requestSent, setRequestSent] = useState(false);

  const estateCodeFromLocalStorage = localStorage.getItem("estateCode");

  const selectedCompany = storedCompanyData[0];

  const selectedEstate = selectedCompany.estates.find(
    (estate) => estate.estatecode === estateCodeFromLocalStorage
  );

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      const isFormValid = validateForm();
      if (!isFormValid) {
        return;
      }
      if (!requestSent) {
        setRequestSent(true);
        setTimeout(() => {
          setRequestSent(false);
        }, 8000);
      }

      const d = formData.dialCode.split("+");
      const phoneNumberWithoutDialCode = formData.phoneNumber.replace(d[1], "");
      const apiData = {
        bookerFirstName: formData.firstName,
        bookerLastName: formData.lastName,
        bookerEmail: formData.email,
        bookerInternationalCode: formData.dialCode,
        bookerPhone: phoneNumberWithoutDialCode,
        bookingCompany: selectedCompany.company_name,
        bookingOutlet: selectedEstate.estate_name,
        bookingOutletAddress: selectedEstate.address,
        bookingOutletCuisine: selectedEstate.cuisine,
        bookingPackageCategory: "À la Carte",
        bookingPackage: "General Reservations",
        bookingDate: reservationData.Individualdate,
        bookingSlotStartTime: reservationData.timeSlot,
        bookingSlotEndTime: reservationData.timeSlot,
        bookingTotalGuest: reservationData.selectedPeopleIndividual,
        outletCode: estateCodeFromLocalStorage,
        bookingNotes: formData.note || null,
        maxCapacity: 180,
        floatingCapacity: 350,
        companyCode: storedCompanyData[0].cmpcode,
        bookingGrouplePackageCategory: "Reservation",
      };

      // console.log("ApiDATA", apiData);

      let response = await axios.post(apiUrl, apiData);
      setFormData({
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        note: "",
        confirmEmail: "",
      });

      // setIsHappyEatingPopupVisible2(true);

      sessionStorage.clear();
      localStorage.clear();
      setTimeout(() => {
        // setIsHappyEatingPopupVisible2(false);
        let baseUrl = "/personalReservation/j5DpR2Gk7Q";
        baseUrl += `/${response.data.data.random_reservation_code}`;
        window.open(baseUrl, "_blank");
        window.location.href = "/landingPage/janjaes";
      }, 100);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  const [value, setValue] = useState("");

  const handleContinueMobile = () => {
    sessionStorage.setItem(
      "reservationDatafromDateAndTime2",
      JSON.stringify(reservationData)
    );
    navigate("/PersonalRes/janjaes", { state: { reservationData } });
  };

  const companyData = JSON.parse(localStorage.getItem("companyData"));

  const getEstateName = (estateCodeFromLocalStorage) => {
    const estate = companyData[0].estates.find(
      (estate) => estate.estatecode === estateCodeFromLocalStorage
    );
    return estate ? estate.estate_name : null;
  };

  const getEstateAddress = (estateCodeFromLocalStorage) => {
    const estate = companyData[0].estates.find(
      (estate) => estate.estatecode === estateCodeFromLocalStorage
    );
    return estate ? estate.address : null;
  };

  const getEstateCuisine = (estateCodeFromLocalStorage) => {
    const estate = companyData[0].estates.find(
      (estate) => estate.estatecode === estateCodeFromLocalStorage
    );
    return estate ? estate.cuisine : null;
  };

  const estateName = getEstateName(estateCodeFromLocalStorage);
  const estateAddress = getEstateAddress(estateCodeFromLocalStorage);
  const estateCuisine = getEstateCuisine(estateCodeFromLocalStorage);

  return (
    <>
      <div className="newReservation">
        <h1>Confirm and reserve</h1>

        <div className="outer-new-individual">
          {/* left side div */}

          <div className="inner-new1">
            {/* reservation form */}
            <div className="mobile-none-div">
              <div>
                <form
                  className="reservationForm"
                  style={{ border: "none", marginTop: "-1rem" }}
                >
                  <h1>Personal Details</h1>

                  <div className="nameWrapper">
                    <div className="firstname-wrapper">
                      <label htmlFor="firstName">First Name</label>
                      <input
                        type="text"
                        id="firstName"
                        name="firstName"
                        value={formData.firstName}
                        onChange={(e) => {
                          const capitalizedValue =
                            e.target.value.charAt(0).toUpperCase() +
                            e.target.value.slice(1);

                          setErrorMessage({ ...errorMessage, firstName: "" });
                          setFormData({
                            ...formData,
                            firstName: capitalizedValue,
                          });
                        }}
                        required
                      />
                      {errorMessage.firstName && (
                        <p style={{ color: "red" }}>{errorMessage.firstName}</p>
                      )}
                    </div>

                    <div className="lastname-wrapper">
                      <label htmlFor="lastName">Last Name</label>
                      <input
                        type="text"
                        id="lastName"
                        name="lastName"
                        value={formData.lastName}
                        onChange={(e) => {
                          const capitalizedValueTwo =
                            e.target.value.charAt(0).toUpperCase() +
                            e.target.value.slice(1);

                          setErrorMessage({ ...errorMessage, lastName: "" });
                          setFormData({
                            ...formData,
                            lastName: capitalizedValueTwo,
                          });
                        }}
                        required
                      />
                      {errorMessage.lastName && (
                        <p style={{ color: "red" }}>{errorMessage.lastName}</p>
                      )}
                    </div>
                  </div>

                  <label htmlFor="email">Email Address</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={(e) => {
                      setErrorMessage({ ...errorMessage, email: "" });
                      setFormData({ ...formData, email: e.target.value });
                    }}
                    required
                  />
                  {errorMessage.email && (
                    <p style={{ color: "red" }}>{errorMessage.email}</p>
                  )}

                  <label htmlFor="email">Confirm Email Address</label>
                  <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.confirmEmail}
                    onChange={(e) => {
                      setErrorMessage({ ...errorMessage, confirmEmail: "" });
                      setFormData({
                        ...formData,
                        confirmEmail: e.target.value,
                      });
                    }}
                    required
                  />
                  {errorMessage.confirmEmail && (
                    <p style={{ color: "red" }}>{errorMessage.confirmEmail}</p>
                  )}

                  <label htmlFor="phoneNumber">Phone Number</label>
                  <PhoneInput
                    country={"ae"}
                    inputStyle={{
                      width: "100%",
                      height: "3rem",
                      borderRadius: "0.5rem",
                      border: "1px solid #a6a6a6",
                    }}
                    dropdownStyle={{
                      padding: "1rem",
                      height: "80rem",
                      width: "39rem",
                      borderRadius: "0.5rem",
                    }}
                    buttonStyle={{
                      marginTop: "0.3rem",
                      marginBottom: "0.3rem",
                      borderRight: "2px solid #293897",
                      background: "#fff",
                      borderTop: "none",
                      borderBottom: "none",
                    }}
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={(value, country) => {
                      setErrorMessage({ ...errorMessage, phoneNumber: "" });
                      setFormData({
                        ...formData,
                        dialCode: country ? `+${country.dialCode}` : `+971`,
                        phoneNumber: value || "",
                      });
                    }}
                    maxLength={10}
                    buttonComponent={
                      <CountryFlag dialCode={formData.dialCode} svg />
                    }
                  />
                  {errorMessage.phoneNumber && (
                    <p style={{ color: "red" }}>{errorMessage.phoneNumber}</p>
                  )}

                  <label htmlFor="notes"> Reservation notes (Optional)</label>

                  <textarea
                    onChange={(e) => {
                      setFormData({ ...formData, note: e.target.value });
                    }}
                    value={formData.note}
                    className="reservationInput3"
                    type="text"
                    name="note"
                    id="note"
                  ></textarea>
                </form>
                <div className="ConfirmButtondiv">
                  <h4>
                    <label
                      style={{
                        marginRight: "1rem",
                        position: "relative",
                        display: "inline-block",
                      }}
                    >
                      <input
                        type="checkbox"
                        name="agreement"
                        checked={value}
                        onChange={(e) => setValue(e.target.checked)}
                        style={{ display: "none" }}
                      />
                      <span
                        style={{
                          display: "inline-block",
                          width: "20px",
                          height: "20px",
                          backgroundColor: value ? "#4A6AFE" : "#e5e5ea",
                          border: `1px solid ${value ? "#4A6AFE" : "#CCCCCC"}`,
                          borderRadius: "3px",
                          cursor: "pointer",
                        }}
                      />
                      {value && (
                        <span
                          style={{
                            position: "absolute",
                            top: "10%",
                            left: "30%",
                            transform: "rotate(45deg)",
                            width: "8px",
                            height: "12px",
                            borderStyle: "solid",
                            borderWidth: "0 2px 2px 0",
                            borderColor: "#FFFFFF",
                          }}
                        />
                      )}
                    </label>
                    By clicking ”confirm” you agree to{" "}
                    <a
                      href="https://grouple.in/privacy"
                      target="blank"
                      style={{ margin: " 0rem 0.3rem" }}
                    >
                      Terms and Condition
                    </a>
                  </h4>
                  <button
                    type="submit"
                    onClick={handleFormSubmit}
                    disabled={!value || requestSent}
                    style={{ cursor: !value ? "not-allowed" : "pointer" ,
                    backgroundColor:themeColor,
                    }}
                  >
                    Confirm
                  </button>
                </div>
              </div>
            </div>
          </div>

          {/* RIGHT */}
          <div className="RequestWrapperRight5">
            <div className="RequestWrapperChild5">
              {estateName === "Moksha" ? (
                <img className="resCardImgOne" src={restaurant1} alt="" />
              ) : (
                <img className="resCardImgOne" src={restaurant2} alt="" />
              )}

              {/* outlet addres */}
              <div className="everything-wrapper5">
                <div className="RequestDateAndTimeWrapper5">{estateName}</div>

                <div className="RequestSummaryChild5">{estateAddress}</div>
                <div className="RequestSummaryChildCusine5">{estateCuisine}</div>
                <div className="RequestSummaryChild25">General Reservations</div>
              </div>

              {/* bookin date/time/guest */}
              <div
                className="RequestSummaryChild3"
                style={{ border: "none", width: "55%" }}
              >
                <div>{showDate}</div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2"
                  height="2"
                  viewBox="0 0 2 2"
                  fill="none"
                >
                  <circle cx="1" cy="1" r="1" fill="#242426" />
                </svg>
                <div>{formatTimeTo12hr(reservationData.timeSlot)} </div>

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2"
                  height="2"
                  viewBox="0 0 2 2"
                  fill="none"
                >
                  <circle cx="1" cy="1" r="1" fill="#242426" />
                </svg>
                <div> {reservationData.selectedPeopleIndividual} Guests</div>
              </div>
            </div>
          </div>

          <div className="phoneIndividualDateAndTIme">
            <h2>Your experience</h2>

            <div className="date-infodiv">
              <div>
                <p>Package</p>
                <h5>Individual Package</h5>
              </div>
              <div>
                <p>Date</p>
                <h5>{showDate}</h5>
              </div>
              <div>
                <p>Time</p>
                <h5>{formatTimeTo12hr(reservationData.timeSlot)}</h5>
              </div>
              <div>
                <p>Group Size</p>
                <h5>{reservationData.selectedPeopleIndividual} guests</h5>
              </div>
            </div>
          </div>

          <div
            className="mobile-cnfrm"
            style={{ border: "none" }}
            onClick={handleContinueMobile}
          >
            <button style={{backgroundColor:themeColor }}>Continue</button>
          </div>

          {/* popup */}
          {/* {isHappyEatingPopupVisible2 && (
            <div className="food-popup-background-custom">
              <div className="food-popup-custom">
                <div className="inner-custom">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="61"
                    height="60"
                    viewBox="0 0 61 60"
                    fill="none"
                  >
                    <path
                      d="M43.8975 42.0797L29.1246 27.3067L27.4852 29.9971L25.6923 23.8744L19.1314 17.3135L15.4203 26.443L13.3532 25.3064L7.59726 40.6595V40.6607L7.76483 45.3479L5.64855 45.8553V45.8565L1.23669 57.6203C0.973029 58.3234 1.18513 59.0229 1.64096 59.4788C2.09562 59.9346 2.79285 60.1491 3.49945 59.8831L12.8071 56.3934L13.1645 54.215L16.4843 55.0142L23.7061 52.3061V49.6051L27.4852 50.8894L34.4879 48.2634L35.2238 46.1682L38.1381 46.8936H38.1393L43.2718 44.9694C44.4706 44.5195 44.7987 42.9808 43.8975 42.0797Z"
                      fill="#FF7D43"
                    />
                    <path
                      d="M43.2715 44.9702L38.1389 46.8943C38.1389 46.8943 38.139 46.8943 38.1378 46.8943L35.2235 46.169L34.4876 48.2642L27.4849 50.8902L23.7058 49.6059V52.3069L16.4839 55.015L13.1642 54.2158L12.8068 56.3942L3.49911 59.8838C2.79251 60.1498 2.09529 59.9354 1.64062 59.4796L31.469 29.6523L43.8972 42.0805C44.7983 42.9817 44.4702 44.5204 43.2715 44.9702Z"
                      fill="#E63950"
                    />
                    <path
                      d="M38.1378 46.894L34.4876 48.2639C30.7613 44.4145 28.0708 39.6429 26.681 34.4389C25.7776 31.0558 25.4237 27.4889 25.692 23.875L29.1243 27.3072C29.1758 28.7298 29.3352 30.1383 29.5988 31.5211C30.7156 37.3836 33.6908 42.7833 38.1378 46.894Z"
                      fill="#F0F7FF"
                    />
                    <path
                      d="M38.1379 46.8944L34.4877 48.2643C30.7614 44.4149 28.0709 39.6433 26.6812 34.4393L29.599 31.5215C30.7157 37.384 33.6909 42.7837 38.1379 46.8944Z"
                      fill="#DFE7F4"
                    />
                    <path
                      d="M27.4849 50.8892L23.7058 52.3059C21.0493 49.9271 18.8194 47.1242 17.1038 44.0154C13.9554 38.313 12.6445 31.7382 13.3529 25.3063L16.15 17.8477C16.5988 16.6536 18.135 16.3173 19.0397 17.222L19.1311 17.3134C15.5879 25.306 16.0122 34.1119 19.7053 41.4139C21.5626 45.0911 24.2144 48.3277 27.4849 50.8892Z"
                      fill="#F0F7FF"
                    />
                    <path
                      d="M27.4851 50.8903L23.706 52.307C21.0495 49.9282 18.8195 47.1253 17.104 44.0165L19.7054 41.415C21.5627 45.0922 24.2145 48.3288 27.4851 50.8903Z"
                      fill="#DFE7F4"
                    />
                    <path
                      d="M16.4842 55.0156L12.807 56.3948C11.436 55.0648 10.1869 53.6106 9.07598 52.0451C7.81523 50.2924 6.59374 48.1385 5.64844 45.8579V45.8567L7.59716 40.6621C8.39984 43.8705 9.75211 46.8657 11.5778 49.5432C12.9535 51.5633 14.5999 53.4019 16.4842 55.0156Z"
                      fill="#F0F7FF"
                    />
                    <path
                      d="M16.4843 55.0143L12.8072 56.3936C11.4362 55.0636 10.187 53.6093 9.07617 52.0438L11.578 49.542C12.9537 51.5621 14.6001 53.4006 16.4843 55.0143Z"
                      fill="#DFE7F4"
                    />
                    <path
                      d="M28.2912 19.1581C27.6048 18.4717 27.6048 17.3588 28.2912 16.6724C34.904 10.0596 32.8595 3.62064 32.8382 3.55631C32.5313 2.63538 33.0289 1.63993 33.9499 1.33292C34.8706 1.02579 35.8662 1.52369 36.1733 2.44461C36.2887 2.79123 38.9091 11.026 30.777 19.158C30.0907 19.8444 28.9776 19.8446 28.2912 19.1581Z"
                      fill="#8CD96B"
                    />
                    <path
                      d="M41.963 32.8295C42.6495 33.516 43.7623 33.516 44.4488 32.8295C51.0615 26.2168 57.5005 28.2613 57.5648 28.2826C58.4858 28.5895 59.4812 28.0918 59.7882 27.1709C60.0953 26.2502 59.5974 25.2545 58.6765 24.9475C58.3299 24.8321 50.0951 22.2117 41.9631 30.3438C41.2767 31.0301 41.2766 32.1432 41.963 32.8295Z"
                      fill="#5AB267"
                    />
                    <path
                      d="M59.0523 8.28205C57.5032 9.83118 54.74 8.18596 53.7733 8.59727C53.362 9.56752 55.0084 12.3271 53.4592 13.8751C51.9101 15.4242 49.1482 13.779 48.1802 14.1903C47.7678 15.1606 49.4153 17.919 47.8662 19.4681C46.3171 21.0173 43.5528 19.372 42.5872 19.7834C42.1747 20.7536 43.8223 23.512 42.2732 25.0612C40.6924 26.6419 37.8976 24.9252 36.9801 25.3823C36.5219 25.8334 35.8751 25.9834 35.2927 25.8275C35.0044 25.7514 34.7314 25.599 34.5052 25.3729C33.8186 24.6862 33.8186 23.573 34.5052 22.8863C35.847 21.5446 37.7254 21.7567 39.1116 22.0086C39.1562 22.0168 39.2007 22.025 39.244 22.0332C39.2358 21.9899 39.2276 21.9453 39.2194 21.9008C38.9687 20.5146 38.7554 18.6362 40.0983 17.2933C41.44 15.9515 43.3184 16.1636 44.7047 16.4156C44.7492 16.4238 44.7937 16.432 44.8371 16.4402C44.8289 16.3968 44.8207 16.3523 44.8125 16.3078C44.5617 14.9215 44.3484 13.0431 45.6913 11.7002C47.033 10.3585 48.9115 10.5706 50.2977 10.8225C50.3422 10.8307 50.3868 10.8389 50.4301 10.8471C50.4219 10.8038 50.4137 10.7593 50.4055 10.7147C50.1547 9.32847 49.9415 7.45007 51.2844 6.10717C52.6261 4.76545 54.5045 4.97755 55.8908 5.22949C55.9353 5.23769 55.9798 5.24589 56.0232 5.2541C56.015 5.21074 56.0068 5.16621 55.9986 5.12168C55.7478 3.73543 55.5345 1.85702 56.8774 0.514131C57.5629 -0.171377 58.6761 -0.171377 59.3628 0.514131C59.589 0.74029 59.7413 1.01332 59.8175 1.30276C59.9733 1.88398 59.8257 2.53081 59.3734 2.99016C58.921 3.89937 60.6178 6.7164 59.0523 8.28205Z"
                      fill="#FF7D43"
                    />
                    <path
                      d="M59.0526 8.28203C57.5034 9.83116 54.7403 8.18594 53.7736 8.59724C53.3623 9.5675 55.0087 12.3271 53.4595 13.8751C51.9104 15.4242 49.1484 13.779 48.1805 14.1903C47.768 15.1605 49.4156 17.919 47.8665 19.4681C46.3173 21.0172 43.553 19.372 42.5875 19.7833C42.175 20.7536 43.8226 23.512 42.2734 25.0612C40.6927 26.6419 37.8979 24.9252 36.9804 25.3822C36.5222 25.8334 35.8754 25.9834 35.293 25.8275L39.1119 22.0086C39.1564 22.0168 39.201 22.025 39.2443 22.0332C39.2361 21.9898 39.2279 21.9453 39.2197 21.9008L44.7049 16.4155C44.7495 16.4238 44.794 16.432 44.8374 16.4402C44.8291 16.3968 44.8209 16.3523 44.8127 16.3077L50.298 10.8225C50.3425 10.8307 50.387 10.8389 50.4304 10.8471C50.4222 10.8038 50.414 10.7592 50.4058 10.7147L55.891 5.22946C55.9356 5.23767 55.9801 5.24587 56.0234 5.25407C56.0152 5.21072 56.007 5.16619 55.9988 5.12166L59.8178 1.30273C59.9736 1.88395 59.826 2.53079 59.3736 2.99014C58.9213 3.89934 60.6181 6.71637 59.0526 8.28203Z"
                      fill="#E63950"
                    />
                    <path
                      d="M43.206 8.48808C44.1767 8.48808 44.9637 7.70113 44.9637 6.73037C44.9637 5.75961 44.1767 4.97266 43.206 4.97266C42.2352 4.97266 41.4482 5.75961 41.4482 6.73037C41.4482 7.70113 42.2352 8.48808 43.206 8.48808Z"
                      fill="#FFE470"
                    />
                    <path
                      d="M55.6342 20.9158C56.6049 20.9158 57.3919 20.1289 57.3919 19.1581C57.3919 18.1873 56.6049 17.4004 55.6342 17.4004C54.6634 17.4004 53.8765 18.1873 53.8765 19.1581C53.8765 20.1289 54.6634 20.9158 55.6342 20.9158Z"
                      fill="#FABE2C"
                    />
                    <path
                      d="M53.1483 38.3152C54.1191 38.3152 54.9061 37.5283 54.9061 36.5575C54.9061 35.5868 54.1191 34.7998 53.1483 34.7998C52.1776 34.7998 51.3906 35.5868 51.3906 36.5575C51.3906 37.5283 52.1776 38.3152 53.1483 38.3152Z"
                      fill="#FABE2C"
                    />
                    <path
                      d="M25.8051 10.9725C26.7758 10.9725 27.5628 10.1855 27.5628 9.21474C27.5628 8.24399 26.7758 7.45703 25.8051 7.45703C24.8343 7.45703 24.0474 8.24399 24.0474 9.21474C24.0474 10.1855 24.8343 10.9725 25.8051 10.9725Z"
                      fill="#FFE470"
                    />
                  </svg>

                  <h3>Thank You</h3>
                  <h1>We appreciate your reservation with us.</h1>
                </div>
              </div>
            </div>
          )} */}
        </div>
      </div>
    </>
  );
};

export default ReservationIndividual;
