import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import "../Styles/PackageInfo.css";
import header from "../Images/Image.png";
import { connect } from "react-redux";
import "../Styles/FoodMenu.css";
import { setEstateCodeForTheme, useTheme } from "./ColorContext";

const PackageInfo = ({ menuData }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const edit = searchParams.get("edit");

  const [expandedDescriptions, setExpandedDescriptions] = useState({});

  let reservationDatafromDateAndTime =
    location.state?.reservationDatafromDateAndTime || {};

  if (Object.keys(reservationDatafromDateAndTime).length === 0) {
    const localStorageData = sessionStorage.getItem(
      "reservationDatafromDateAndTime"
    );
    if (localStorageData) {
      reservationDatafromDateAndTime = JSON.parse(localStorageData);
    }
  }

  const [vegOnly, setVegOnly] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(
    Object.keys(menuData)[0]
  );
  const [currentSection, setCurrentSection] = useState(
    Object.keys(menuData)[0]
  );

  const packageCategory = reservationDatafromDateAndTime.category;
  const duration = reservationDatafromDateAndTime.duration;
  const initialPrice = reservationDatafromDateAndTime.initialPrice;
  const initialDiscountedPrice =
    reservationDatafromDateAndTime.initialDiscountedPrice;
  const pax = reservationDatafromDateAndTime.pax;
  const packageName = reservationDatafromDateAndTime.packageName;
  const choiceA = +reservationDatafromDateAndTime.choiceA;
  const choiceD = +reservationDatafromDateAndTime.choiceD;
  const choiceM = +reservationDatafromDateAndTime.choiceM;
  const bestValue = reservationDatafromDateAndTime.bestValue;
  const selectedPackage = reservationDatafromDateAndTime.selectedPackage;

  const [selectedItems, setSelectedItems] = useState(() => {
    const storedItems = JSON.parse(sessionStorage.getItem("selectedItems"));
    return (
      storedItems || {
        Appetizer: [],
        MainCourse: [],
        Dessert: [],
        Sides: menuData[packageCategory].Sides || [],
      }
    );
  });

  useEffect(() => {
    if (edit === "true") {
      const storedItems = JSON.parse(sessionStorage.getItem("selectedItems"));
      if (storedItems) {
        setSelectedItems(storedItems);
      }
    }
  }, [edit]);

  const selectedBeverages = {};

  const isContinueButtonDisabled = () => {
    const requiredItems = {
      Appetizer: choiceA,
      MainCourse: choiceM,
      Dessert: choiceD,
    };

    const isAllItemsSelected = Object.entries(requiredItems).every(
      ([category, requiredCount]) => {
        return selectedItems[category].length === requiredCount;
      }
    );

    return !isAllItemsSelected;
  };
  const isContinueButtonVisible = () => {
    const requiredItems = {
      Appetizer: choiceA,
      MainCourse: choiceM,
      Dessert: choiceD,
    };

    // Check if any category has at least one selected item
    const isAnyCategorySelected = Object.entries(requiredItems).some(
      ([category, requiredCount]) => {
        return selectedItems[category].length > 0;
      }
    );

    return !isAnyCategorySelected;
  };

  if (menuData.hasOwnProperty(packageCategory)) {
    menuData = menuData[packageCategory];
  }

  let allData = menuData;
  for (const category in allData) {
    if (
      category !== "Appetizer" &&
      category !== "MainCourse" &&
      category !== "Dessert" &&
      category !== "Sides" &&
      category !== "Beverage menu" &&
      category !== "Food menu" &&
      category !== "Spirits"
    ) {
      selectedBeverages[category] = allData[category];
    } else if (category === "Spirits") {
      for (const spiritCategory in allData[category]) {
        selectedBeverages[spiritCategory] = allData[category][spiritCategory];
      }
    }
  }

  const leftContainerRef = useRef(null);

  const getThreshold = () => {
    return window.innerWidth <= 641 ? -100 : -300;
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop;

      let foundCategory = null;

      Object.keys(menuData).forEach((category) => {
        const categoryElement = document.getElementById(category);
        if (categoryElement) {
          const { offsetTop, offsetHeight } = categoryElement;

          const fixedThreshold = getThreshold();
          const threshold = fixedThreshold;
          if (
            scrollPosition >= offsetTop - threshold &&
            scrollPosition < offsetTop + offsetHeight - threshold
          ) {
            foundCategory = category;
          }
        }
      });

      setSelectedCategory(foundCategory);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [menuData]);

  const handleCategoryClick = (event, category) => {
    event.preventDefault();
    setSelectedCategory(category);

    const categoryElement = document.getElementById(category);
    if (categoryElement) {
      const { top } = categoryElement.getBoundingClientRect();
      const offset = 100;
      const newPosition = window.scrollY + top - offset;

      window.scrollTo({
        top: newPosition,
        behavior: "smooth",
      });
    }
  };

  const handleAddRemoveItem = (category, item) => {
    const index = selectedItems[category].findIndex(
      (selectedItem) => selectedItem.id === item.id
    );

    const limit =
      category === "Appetizer"
        ? choiceA
        : category === "MainCourse"
        ? choiceM
        : category === "Dessert"
        ? choiceD
        : 0;

    if (index === -1 && selectedItems[category].length < limit) {
      setSelectedItems((prevState) => ({
        ...prevState,
        [category]: [...prevState[category], item],
      }));
    } else if (index !== -1) {
      const updatedSelectedItems = { ...selectedItems };
      updatedSelectedItems[category].splice(index, 1);
      setSelectedItems(updatedSelectedItems);
    }
  };

  // useEffect to update sessionStorage whenever selectedItems changes
  useEffect(() => {
    sessionStorage.setItem("selectedItems", JSON.stringify(selectedItems));
  }, [selectedItems]);

  let allCapsCategory = "";
  switch (packageCategory) {
    case "Regular":
      allCapsCategory = "REGULAR";
      break;
    case "Premium":
      allCapsCategory = "PREMIUM";
      break;
    case "Exclusive":
      allCapsCategory = "EXCLUSIVE";
      break;
    case "Ultra Exclusive":
      allCapsCategory = "ULTRA EXCLUSIVE";
      break;
    default:
      allCapsCategory = packageCategory;
  }
  const renderItems = (items, category) => {
    const filteredItems = vegOnly
      ? items.filter((item) => item.category !== "Non Vegetarian")
      : items;

    return filteredItems.map((item, i) => (
      <div key={i} className="FoodMenu-child">
        <div>
          <div>
            {item.category === "Non Vegetarian" ? (
              <svg
                width="13"
                height="13"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="15"
                  height="15"
                  rx="1.5"
                  stroke="#963415"
                />
                <path
                  d="M4.38833 12C4.20992 12 4.09388 11.8122 4.17367 11.6527L7.78534 4.42932C7.87378 4.25243 8.12622 4.25243 8.21466 4.42933L11.8263 11.6527C11.9061 11.8122 11.7901 12 11.6117 12H4.38833Z"
                  fill="#963415"
                />
              </svg>
            ) : (
              <svg
                width="13"
                height="13"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  x="0.5"
                  y="0.5"
                  width="15"
                  height="15"
                  rx="1.5"
                  stroke="#009600"
                />
                <circle cx="8" cy="8" r="4" fill="#009600" />
              </svg>
            )}
          </div>

          <div className="Food-menu-item-name">{item.item}</div>

          {/* read more descriptions */}
          <div className="Food-menu-item-desc">
            {item.description.length > 90 && !expandedDescriptions[item.id] ? (
              <>
                {`${item.description.slice(0, 90)}... `}
                <button
                  className="read-more-button"
                  onClick={() =>
                    setExpandedDescriptions({
                      ...expandedDescriptions,
                      [item.id]: true,
                    })
                  }
                >
                  Read More
                </button>
              </>
            ) : (
              item.description
            )}
          </div>
        </div>
        <div className="food-menu-picture-container">
          <img className="food-menu-picture" src={item.foodimg} alt="" />
          <div className="button-container">
            {["Appetizer", "Dessert", "MainCourse"].includes(category) ? (
              selectedItems[category].find(
                (selectedItem) => selectedItem.id === item.id
              ) ? (
                <button
                  className="button-selected"
                  onClick={() => handleAddRemoveItem(category, item)}
                  style={{backgroundColor:themeColor }}
                >
                  Added
                </button>
              ) : (
                <button
                  className="button-select"
                  onClick={() => handleAddRemoveItem(category, item)}
                  style={{color:themeColor, border:`1px solid ${themeColor}`}}
                >
                  Add
                </button>
              )
            ) : (
              <button className="button-selected" style={{backgroundColor:themeColor }}>Added</button>
            )}
          </div>
        </div>
      </div>
    ));
  };

  const renderCategoryHeader = (category) => {
    const selectedCount = (selectedItems[category] ?? []).length;
    switch (category) {
      case "Appetizer":
        return selectedCount > 0
          ? `Appetizer (${selectedCount} / ${choiceA})`
          : `Appetizer (Choose ${choiceA})`;
      case "MainCourse":
        return selectedCount > 0
          ? `Main Course (${selectedCount} / ${choiceM})`
          : `Main Course (Choose ${choiceM})`;
      case "Dessert":
        return selectedCount > 0
          ? `Dessert (${selectedCount} / ${choiceD})`
          : `Dessert (Choose ${choiceD})`;
      case "Sides":
        return `Sides (Complimentary)`;
      case "NonAlcohol":
        return `Non Alcohol (Unlimited)`;
      case "Wine":
        return `Wine (Unlimited)`;
      case "BottleBeer":
        return `Bottle Beer (Unlimited)`;
      case "Cocktail":
        return `Cocktail (Unlimited)`;
      case "Spirits":
        return `Spirits (Unlimited)`;
      case "SingleMoult":
        return `Single Moult `;
      case "Whiskey":
        return `Whiskey`;
      case "Rum":
        return `Rum`;
      case "Gin":
        return `Gin`;
      case "Vodka":
        return `Vodka`;
      case "Tequila":
        return `Tequila`;
      default:
        return category;
    }
  };

  const handleContinueClick = () => {
    const reservationData = {
      packageCategory,
      duration,
      initialPrice,
      initialDiscountedPrice,
      pax,
      packageName,
      choiceA,
      choiceD,
      choiceM,
      bestValue,
      selectedPackage,
      selectedItems,
      selectedBeverages,
      date: reservationDatafromDateAndTime.date,
      timeSlot: reservationDatafromDateAndTime.timeSlot,
      displayedTimeSot: reservationDatafromDateAndTime.displayTime,
      price: reservationDatafromDateAndTime.price,
      totalCost: reservationDatafromDateAndTime.totalCost,
      selectedPeople: reservationDatafromDateAndTime.selectedPeople,
      moneysaved: reservationDatafromDateAndTime.moneysaved,
      displayDate: reservationDatafromDateAndTime.displayDate,
      priceBeforeDiscount: reservationDatafromDateAndTime.totalDiscount,
      youSavedFixed: reservationDatafromDateAndTime.youSavedFixed,
    };
    sessionStorage.setItem("reservationData", JSON.stringify(reservationData));
    navigate("/groupSummary/janjaes", { state: { reservationData } });
  };

  const estateCode = localStorage.getItem("estateCode");
  const { themeColor } = useTheme();
  setEstateCodeForTheme(estateCode);
  return (
    <>
      <div
        style={{
          position: "sticky",
        }}
        className="PackageInfoMain"
      >
        <div className="PackageInfoInnerLeft">
          <img className="headerImgPackage" src={header} alt="foodImage" />
          <div className="packageLeftOne">
            <div className="packageNameDiv">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M11.082 2.33301H2.91536C2.76065 2.33301 2.61228 2.38217 2.50289 2.46969C2.39349 2.55721 2.33203 2.67591 2.33203 2.79967V11.1997C2.33203 11.3234 2.39349 11.4421 2.50289 11.5297C2.61228 11.6172 2.76065 11.6663 2.91536 11.6663H11.082C11.2367 11.6663 11.3851 11.6172 11.4945 11.5297C11.6039 11.4421 11.6654 11.3234 11.6654 11.1997V2.79967C11.6654 2.67591 11.6039 2.55721 11.4945 2.46969C11.3851 2.38217 11.2367 2.33301 11.082 2.33301Z"
                    fill="#4A6AFE"
                  />
                  <path
                    d="M5.83203 2.33301H8.16536V6.99967L6.9987 5.83301L5.83203 6.99967V2.33301Z"
                    fill="#DBE1FF"
                  />
                </svg>
                {allCapsCategory}
              </div>
              {bestValue && (
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="14"
                    height="12"
                    viewBox="0 0 14 12"
                    fill="none"
                  >
                    <path
                      d="M6.99935 0.458496L5.21552 4.09791L1.16602 4.68533L4.10018 7.55299L3.39785 11.5418L6.99935 9.62266L10.6008 11.5418L9.90435 7.55299L12.8327 4.68533L8.80535 4.09791L6.99876 0.458496H6.99935Z"
                      fill="#EE4622"
                      stroke="#EE4622"
                      stroke-width="0.875"
                      stroke-linejoin="round"
                    />
                  </svg>
                  BEST VALUE
                </div>
              )}
            </div>
            <h1>{packageName}</h1>
            <p>International , Indo Global</p>
            <div className="inside-nonAlcoholic">
              <div
                style={{
                  fontSize: "0.9rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "end",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 15 15"
                  fill="none"
                >
                  <path
                    d="M11 6C11 8.76 8.76 11 6 11C3.24 11 1 8.76 1 6C1 3.24 3.24 1 6 1C8.76 1 11 3.24 11 6Z"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.85494 7.58988L6.30494 6.66488C6.03494 6.50488 5.81494 6.11988 5.81494 5.80488V3.75488"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {reservationDatafromDateAndTime.displayTime} 
              </div>
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="2"
                  height="2"
                  viewBox="0 0 2 2"
                  fill="none"
                >
                  <circle cx="1" cy="1" r="1" fill="#8E8E93" />
                </svg>
              </div>
              <div
                style={{
                  fontSize: "0.9rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "end",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 15 15"
                  fill="none"
                >
                  <path
                    d="M4.57979 5.435C4.52979 5.43 4.46979 5.43 4.41479 5.435C3.22479 5.395 2.27979 4.42 2.27979 3.22C2.27979 1.995 3.26979 1 4.49979 1C5.72479 1 6.71978 1.995 6.71978 3.22C6.71478 4.42 5.76979 5.395 4.57979 5.435Z"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.20514 2C9.17514 2 9.95514 2.785 9.95514 3.75C9.95514 4.695 9.20514 5.465 8.27014 5.5C8.23014 5.495 8.18514 5.495 8.14014 5.5"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.07986 7.28C0.869863 8.09 0.869863 9.41 2.07986 10.215C3.45486 11.135 5.70986 11.135 7.08486 10.215C8.29486 9.405 8.29486 8.085 7.08486 7.28C5.71486 6.365 3.45986 6.365 2.07986 7.28Z"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.16992 10C9.52992 9.925 9.86992 9.78 10.1499 9.565C10.9299 8.98 10.9299 8.015 10.1499 7.43C9.87492 7.22 9.53992 7.08 9.18492 7"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                {reservationDatafromDateAndTime.selectedPeople} Guests
              </div>
            </div>
          </div>

          <div className="packageLeftTwo">
            <div className="foodMenu-container">
              <div className="left-container" ref={leftContainerRef}>
                {Object.keys(menuData).map((category) => {
                  const isFoodMenu =
                    category === "Food menu" ||
                    category === "food-menu-category";
                  return (
                    <div
                      key={category}
                      className={`category ${
                        selectedCategory === category ? "active" : ""
                      } ${
                        category === "Food menu" ? "food-menu-category" : ""
                      } ${
                        category === "Beverage menu"
                          ? "beverage-menu-category-res"
                          : ""
                      }`}
                      onClick={
                        isFoodMenu
                          ? null
                          : (event) => handleCategoryClick(event, category)
                      }
                    >
                      {category.replace(/([a-z])([A-Z])/g, "$1 $2")}
                    </div>
                  );
                })}
              </div>

              <div className="right-container">
                {Object.keys(menuData).map(
                  (category, index) =>
                    category !== "Beverage menu" &&
                    category !== "Food menu" && (
                      <section key={index} id={category}>
                        <div className="max-width">
                          <div className="FoodCategoryHeading">
                            <h2>{renderCategoryHeader(category)}</h2>
                            {category === "Appetizer" && (
                              <div className="vegOnlyToggleDiv">
                                <span>Veg only</span>
                                <div className="toggle-wrapper">
                                  <input
                                    className="toggle-checkbox"
                                    type="checkbox"
                                    checked={vegOnly}
                                    onChange={() => setVegOnly(!vegOnly)}
                                  />
                                  <div className="toggle-container">
                                    <div className="toggle-button">
                                      <div className="toggle-button-circles-container"></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                          <div
                            className={category === "Spirits" ? "aaaa" : "bbbb"}
                          >
                            {Array.isArray(menuData[category]) ? (
                              renderItems(menuData[category], category)
                            ) : (
                              <div className="food-menu-wrapper-parent">
                                {Object.keys(menuData[category]).map(
                                  (subCategory, subIndex) => (
                                    <div key={subIndex}>
                                      <h1 className="FoodCategoryHeading">
                                        {subCategory}
                                      </h1>
                                      <div className="food-menu-wrapper-child">
                                        {renderItems(
                                          menuData[category][subCategory],
                                          category
                                        )}
                                      </div>
                                    </div>
                                  )
                                )}
                              </div>
                            )}
                          </div>
                        </div>
                      </section>
                    )
                )}

                <div
                  className="PackageInfofooter"
                  style={{
                    display: isContinueButtonVisible() ? "none" : "flex",
                  }}
                >
                  <div className="continue-container">
                    <div className="continue-container-01">
                      <p className="continuecontainerItemsAdded">items added</p>
                      <p className="continuecontainerItemsSummary">
                        {selectedItems.Appetizer.length}
                        <span> x </span>Appetizers,{" "}
                        {selectedItems.MainCourse.length}
                        <span> x </span>Main Course,{" "}
                        {selectedItems.Dessert.length}
                        <span> x </span>Desserts, Sides & Unlimited
                      </p>
                    </div>
                    <button
                      onClick={handleContinueClick}
                      disabled={isContinueButtonDisabled()}
                      style={{
                        cursor: isContinueButtonDisabled()
                          ? "not-allowed"
                          : "pointer",
                        color: isContinueButtonDisabled() ? "" : "#fff",
                        boxShadow: isContinueButtonDisabled()
                          ? ""
                          : "0px -4px 12px 0px rgba(34, 34, 34, 0.12)",
                        backgroundColor: themeColor,
                      }}
                    >
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  menuData: state.menuReducer,
});

export default connect(mapStateToProps)(PackageInfo);
