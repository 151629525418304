import React from "react";
import "../Styles/Footer.css";
import vector from "../Images/Vector.png";

const Footer = () => {
  return (
    <footer className="footer-div">
      <a
        href="https://www.grouple.in/privacy"
        style={{ textDecoration: "none" }}
        target="blank"
      >
        {" "}
        <h2>Privacy policy</h2>{" "}
      </a>
      <div className="powered-by">
        <h3>Powered by</h3>
        <a
          href="https://www.grouple.in/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={vector} alt="vector" />
        </a>
      </div>
    </footer>
  );
};

export default Footer;
