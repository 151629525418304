import React, { useState, useEffect } from "react";
import "./MobileScroll.css";
import Reactangle1 from "./Rectangle 1.png";
import Reactangle2 from "./Rectangle 2.png";
import Reactangle3 from "./Rectangle 3.png";
import ScreenText from "./ScreenText";

const scrollData = [
  {
    demoImage: Reactangle1,
    headingMain: "BOOK",
    heading: "Choose the perfect package",
    description:
      "Book an experience with loved ones, old pals, clients or colleagues. The larger the group, the better the deal!",
  },
  {
    demoImage: Reactangle2,
    headingMain: "INVITE",
    heading: "Build your guest list",
    description:
      "All group experiences are exclusive. Invite your chosen people to join in on the fun.",
  },
  {
    demoImage: Reactangle3,
    headingMain: "ENJOY",
    heading: "Create Memories Together",
    description:
      "Celebration day is here! Just turn up and start creating memories together.",
  },
];

const MobileScroll = () => {
  const [currentImg, setCurrentImg] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      const scrollProgress = window.scrollY / window.innerHeight;
      const translateY = -100 * scrollProgress;

      document.querySelector(
        ".slide-top"
      ).style.transform = `translateY(${translateY}px)`;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <div className="mobile-scroll">
      <div className="mobile-mockup-wrapper non-mobile">
        <div className="mobile-mockup">
          <div className="mobile-mockup-screen">
            <img
              src={scrollData[currentImg].demoImage}
              alt="ReserveImage"
              className="mobile-screen-img slide-top"
              key={scrollData[currentImg].demoImage}
            />
          </div>
        </div>
      </div>
      <div className="scroll-full-screen-wrapper">
        {scrollData.map((screen, i) => (
          <div className="scroll-full-screen" key={i}>
            <ScreenText
              screen={screen}
              i={i}
              setCurrentImg={setCurrentImg}
              currentImg={currentImg}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default MobileScroll;
