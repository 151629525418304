import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../Styles/DateAndTime.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import "../../Styles/Datepicker.css";
import Rules from "../Rules";
import { Navbar2 } from "../NavbarTwo";
import Footer from "../Footer";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../Styles/OutletImageTwo.css";
import { setEstateCodeForTheme, useTheme } from "../ColorContext";


const Individualdate = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);

  const category = params.get("category");

  const estateCode = localStorage.getItem("estateCode");
  const { themeColor } = useTheme();
  setEstateCodeForTheme(estateCode);


  // *************** Date *************
  const [Individualdate, setIndividualDate] = useState(null);

  useEffect(() => {
    localStorage.setItem("Individualdate", Individualdate);
  }, [Individualdate]);

  const dayClassName = () => "custom-day";
  const today = new Date();
  today.setDate(today.getDate() + 0);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);

  const CustomInput = ({ value, onClick }) => (
    <div
      className={`custom-datepicker-input ${isCalendarOpen ? "open" : ""}`}
      onClick={() => {
        onClick();
        setIsCalendarOpen(!isCalendarOpen);
      }}
    >
      {value ? (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <div
            style={{ display: "flex", flexDirection: "column", gap: "0.4rem" }}
          >
            <span className="dateHeading">DATE</span>
            <span className="DateSelectedValue">{value}</span>
          </div>
          <span>
            <ChevronDownIcon
              className={`chevron-icon ${isCalendarOpen ? "up" : "down"}`}
            />
          </span>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
            alignItems: "center",
          }}
        >
          <span className="dateHeading">DATE</span>{" "}
          <ChevronDownIcon
            className={`chevron-icon ${isCalendarOpen ? "up" : "down"}`}
          />
        </div>
      )}
    </div>
  );

  const customNextIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        d="M9 18L15 12L9 6"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const customPrevIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="38"
      height="24"
      viewBox="0 0 44 24"
      fill="none"
    >
      <path
        d="M15 6L9 12L15 18"
        stroke="black"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );

  const CustomHeader = ({
    date,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }) => (
    <div className="custom-header">
      <div className="custom-header-month-wrapper">
        <div
          onClick={decreaseMonth}
          className="custom-prev-btn"
          disabled={prevMonthButtonDisabled}
        >
          {customPrevIcon}
        </div>
        <div className="custom-current-month">
          {date.toLocaleDateString("en-US", { month: "long", year: "numeric" })}
        </div>
        <div
          onClick={increaseMonth}
          className="custom-next-btn"
          disabled={nextMonthButtonDisabled}
        >
          {customNextIcon}
        </div>
      </div>

      <div className="custom-day-names">
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day, index) => (
          <div key={index} className="custom-day-name">
            {day}
          </div>
        ))}
      </div>
    </div>
  );

  // *************** Time Slot *************
  const [selectedTimeSlotIndividual, setSelectedTimeSlotIndividual] =
    useState(null);
  const [displayedTimeSlotIndividual, setDisplayedTimeSlotIndividual] =
    useState("Select a time slot");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const options = [
    { value: "19:00" },
    { value: "19:30" },
    { value: "20:00" },
    { value: "20:30" },
    { value: "21:00" },
    { value: "21:30" },
    { value: "22:00" },
    { value: "22:30" },
    { value: "23:00" },
    { value: "23:30" },
    { value: "00:00" },
    { value: "00:30" },
    { value: "01:00" },
    { value: "01:30" },
    { value: "02:00" },
    { value: "02:30" },
    { value: "03:00" },
  ];

  const handleSelectTimeSlot = (timeSlot) => {
    setSelectedTimeSlotIndividual(timeSlot);
    setDisplayedTimeSlotIndividual(formatTimeTo12hr(timeSlot));
    setIsDropdownOpen(false);
  };

  const formatTimeTo12hr = (time) => {
    let [hours, minutes] = time.split(":");
    let period = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    return `${hours}:${minutes} ${period}`;
  };
  // ************SLIDER LOGIC*******
  const [selectedPeopleIndividual, setSelectedPeopleIndividual] = useState(0);
  const handleInputChange = (value) => {
    if (!isNaN(value) && value >= 1 && value <= 20) {
      setSelectedPeopleIndividual(value);
    } else {
      const clampedValue = value >= 1 ? Math.min(Math.max(value, 10), 20) : 0;
      setSelectedPeopleIndividual(clampedValue);
    }
  };
  const handleInputNumberChange = (event) => {
    const value = parseInt(event.target.value, 10);
    handleInputChange(value);
  };
  const handleSliderChange = (event) => {
    const value = parseInt(event.target.value, 10);
    setSelectedPeopleIndividual(value);
  };
  // /**** Validation ******* */

  const [validationErrors, setValidationErrors] = useState({
    Individualdate: "",
    timeSlot: "",
    selectedPeopleIndividual: "",
  });
  const clearDateError = () => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      Individualdate: "",
    }));
  };
  const clearTimeSlotError = () => {
    setValidationErrors((prevErrors) => ({ ...prevErrors, timeSlot: "" }));
  };
  const clearSelectedPeopleError = () => {
    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      selectedPeopleIndividual: "",
    }));
  };
  const validateForm = () => {
    let isValid = true;
    const errors = {
      Individualdate: "",
      timeSlot: "",
      selectedPeopleIndividual: "",
    };
    if (!Individualdate) {
      errors.Individualdate = "Please select a date";
      isValid = false;
    }
    if (!selectedTimeSlotIndividual) {
      errors.timeSlot = "Please select a time slot";
      isValid = false;
    }
    if (selectedPeopleIndividual <= 0 || selectedPeopleIndividual > 20) {
      errors.selectedPeopleIndividual =
        "Please select a valid number of guests";
      isValid = false;
    }
    setValidationErrors(errors);
    return isValid;
  };

  useEffect(() => {
    const storedReservationData = sessionStorage.getItem(
      "reservationDataIndividual"
    );
    if (storedReservationData) {
      const parsedReservationData = JSON.parse(storedReservationData);
      setIndividualDate(
        parsedReservationData.Individualdate
          ? new Date(parsedReservationData.Individualdate)
          : null
      );

      setSelectedTimeSlotIndividual(parsedReservationData.timeSlot);
      setSelectedPeopleIndividual(
        parsedReservationData.selectedPeopleIndividual
      );
      setDisplayedTimeSlotIndividual(
        parsedReservationData.displayedTimeSlotIndividual
      );
    }
  }, []);

  const formatDate = (date, format = "YYYY-MM-DD") => {
    return date
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .split("/")
      .reverse()
      .join("-");
  };

  useEffect(() => {
    sessionStorage.clear();
    const companyData = JSON.parse(localStorage.getItem("companyData"));
    const estateCode = localStorage.getItem("estateCode")

    localStorage.clear();
    if (companyData) {
      localStorage.setItem("companyData", JSON.stringify(companyData));
    }
    if (estateCode) {
      localStorage.setItem("estateCode", estateCode);
    }

  });

  const handleReserve = () => {
    if (!validateForm()) {
      return;
    }

    const reservationDataIndividual = {
      Individualdate:
        Individualdate instanceof Date ? formatDate(Individualdate) : null,
      timeSlot: selectedTimeSlotIndividual,
      displayedTimeSlotIndividual: displayedTimeSlotIndividual,
      selectedPeopleIndividual,
      displayDate:
        Individualdate instanceof Date ? formatDate(Individualdate) : null,
      category,
    };

    setIndividualDate(null);
    setSelectedTimeSlotIndividual(null);
    sessionStorage.setItem(
      "reservationDataIndividual",
      JSON.stringify(reservationDataIndividual)
    );
    navigate("/personalInfo/foodmenu", {
      state: { reservationDataIndividual },
    });
  };

  const handleReserveMobile = () => {
    const reservationDataIndividualOne = {
      category,
    };
    sessionStorage.setItem(
      "reservationDataIndividual",
      JSON.stringify(reservationDataIndividualOne)
    );
    navigate("/personalGuestSize", {
      state: { reservationDataIndividualOne },
    });
  };

  return (
    <>
      <Navbar2 />
      <div className="PackageInfoMain2">
        {/* **** Left Side Content ***** */}
        <div className="PackageInfoInnerLeft2">
          <div className="packageLeftOne2" style={{ paddingBottom: "1rem" }}>
            <div className="packageNameDiv2">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                >
                  <path
                    d="M11.082 2.33301H2.91536C2.76065 2.33301 2.61228 2.38217 2.50289 2.46969C2.39349 2.55721 2.33203 2.67591 2.33203 2.79967V11.1997C2.33203 11.3234 2.39349 11.4421 2.50289 11.5297C2.61228 11.6172 2.76065 11.6663 2.91536 11.6663H11.082C11.2367 11.6663 11.3851 11.6172 11.4945 11.5297C11.6039 11.4421 11.6654 11.3234 11.6654 11.1997V2.79967C11.6654 2.67591 11.6039 2.55721 11.4945 2.46969C11.3851 2.38217 11.2367 2.33301 11.082 2.33301Z"
                    fill="#4A6AFE"
                  />
                  <path
                    d="M5.83203 2.33301H8.16536V6.99967L6.9987 5.83301L5.83203 6.99967V2.33301Z"
                    fill="#DBE1FF"
                  />
                </svg>
                REGULAR
              </div>
            </div>
            <h1> Individual Package</h1>
            <p>Indian, Multi Cuisine</p>

            <div className="inside-nonAlcoholic">
              <div
                style={{
                  fontSize: "1.1rem",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "end",
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  viewBox="0 0 15 15"
                  fill="none"
                >
                  <path
                    d="M4.57979 5.435C4.52979 5.43 4.46979 5.43 4.41479 5.435C3.22479 5.395 2.27979 4.42 2.27979 3.22C2.27979 1.995 3.26979 1 4.49979 1C5.72479 1 6.71978 1.995 6.71978 3.22C6.71478 4.42 5.76979 5.395 4.57979 5.435Z"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.20514 2C9.17514 2 9.95514 2.785 9.95514 3.75C9.95514 4.695 9.20514 5.465 8.27014 5.5C8.23014 5.495 8.18514 5.495 8.14014 5.5"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M2.07986 7.28C0.869863 8.09 0.869863 9.41 2.07986 10.215C3.45486 11.135 5.70986 11.135 7.08486 10.215C8.29486 9.405 8.29486 8.085 7.08486 7.28C5.71486 6.365 3.45986 6.365 2.07986 7.28Z"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M9.16992 10C9.52992 9.925 9.86992 9.78 10.1499 9.565C10.9299 8.98 10.9299 8.015 10.1499 7.43C9.87492 7.22 9.53992 7.08 9.18492 7"
                    stroke="#8E8E93"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                1-10 pax
              </div>
            </div>
          </div>

          <div className="curved-line">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="390"
              height="18"
              viewBox="0 0 390 18"
              fill="none"
            >
              <path
                d="M1 1C99.9997 22.3663 289.999 22.3003 389 1"
                stroke="url(#paint0_linear_2112_1851)"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_2112_1851"
                  x1="389"
                  y1="9.00026"
                  x2="0.999997"
                  y2="9.0001"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stop-color="#B37E7D" stop-opacity="0.1" />
                  <stop offset="0.510417" stop-color="#B37E7D" />
                  <stop offset="1" stop-color="#B37E7D" stop-opacity="0.1" />
                </linearGradient>
              </defs>
            </svg>
          </div>

          <div className="PackageInDetailWrapper" style={{ marginTop: "0rem" }}>
            <h1 className="PackageDeatailsHeaderr">What you’ll experience</h1>
            <div className="PackageInDeatilChild">
              <div className="PackageInDetailChildHeadingNew">
                Private Area for Group
              </div>
              <div className="PackageInDetailChildParaNew">
                Indulge in a delectable three-course gourmet dinner carefully
                curated by our renowned chefs. Each dish is crafted with love
                and passion, promising a culinary journey that will tantalize
                your taste buds.
              </div>
            </div>
            <div className="PackageInDeatilChild">
              <div className="PackageInDetailChildHeadingNew">
                Great Ambiance
              </div>
              <div className="PackageInDetailChildParaNew">
                Your evening begins at a beautifully adorned private area, set
                exclusively for your group. Enjoy the luxury of undivided
                attention in a cozy and intimate space.
              </div>
            </div>
            <div className="PackageInDeatilChild">
              <div className="PackageInDetailChildHeadingNew">
                Live Band Performance
              </div>
              <div className="PackageInDetailChildParaNew">
                Immerse yourself in the soulful tunes of our lives music
                performance by Malang Band, creating the perfect backdrop for an
                evening filled with enjoy. Let the party enhance the rock
                atmosphere of you and your group.
              </div>
            </div>
            <div className="PackageInDeatilChild">
              <div className="PackageInDetailChildHeadingNew">
                Memorable Photo Session
              </div>
              <div className="PackageInDetailChildParaNew">
                Capture the magic of the evening with a complimentary photo
                session. Professional photographers will be available to
                immortalize your special moments, ensuring you have tangible
                memories to cherish forever.
              </div>
            </div>
          </div>
        </div>

        {/* **** Right Side Content **** */}
        {/* date and time box */}
        <div className="IndividualDateTimeParent">
          <div className="IndividualDateTimeWrapper">
            <h1>Book your Reservation</h1>

            <div className="date-time-wrapper-two">
              {/* /*** Date div *****  */}
              <div
                className="date-div"
                onClick={() => {
                  clearDateError();
                  setIsDropdownOpen(false);
                }}
              >
                <DatePicker
                  className="individual-date"
                  selected={Individualdate}
                  onChange={(newDate) => {
                    setIndividualDate(newDate);
                    setIsCalendarOpen(false);
                  }}
                  dateFormat="dd-MM-yyyy"
                  minDate={today}
                  customInput={<CustomInput />}
                  calendarClassName="custom-calendar"
                  dayClassName={dayClassName}
                  open={isCalendarOpen}
                  onClickOutside={() => setIsCalendarOpen(false)}
                  nextMonthButtonLabel={customNextIcon}
                  previousMonthButtonLabel={customPrevIcon}
                  renderCustomHeader={CustomHeader}
                />
              </div>
              {/* *** Time div ***  */}
              <div
                className="slot-div"
                onClick={() => {
                  clearTimeSlotError();
                  setIsCalendarOpen(false);
                }}
                style={{ cursor: "pointer" }}
              >
                <div className="custom-dropdown">
                  <div
                    className={`dropdown-header ${
                      isDropdownOpen ? "active" : ""
                    }`}
                    onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                  >
                    {selectedTimeSlotIndividual ? (
                      <div className="Time-placeHolder">
                        <div>
                          <div className="Time-placeholder-child">
                            TIME SLOT
                          </div>{" "}
                          <div className="Time-Value">
                            {displayedTimeSlotIndividual}
                          </div>
                        </div>
                        <div>
                          <ChevronDownIcon
                            className={`chevron-icon ${
                              isDropdownOpen ? "up" : "down"
                            }`}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="Time-placeHolder">
                        <div className="Time-placeholder-child">TIME SLOT</div>
                        <div>
                          <ChevronDownIcon
                            className={`chevron-icon ${
                              isDropdownOpen ? "up" : "down"
                            }`}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                  {isDropdownOpen && (
                    <div className="dropdown-options-two">
                      {options.map((option) => (
                        <div
                          key={option.value}
                          className={`dropdown-option-two ${
                            selectedTimeSlotIndividual === option.value
                              ? "selected"
                              : ""
                          }`}
                          onClick={() => handleSelectTimeSlot(option.value)}
                        >
                          {formatTimeTo12hr(option.value)}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* *** slider div ****  */}
            <div className="guest-div" onClick={clearSelectedPeopleError}>
              {selectedPeopleIndividual ? (
                <span className="Selected-place-Individual">GROUP SIZE</span>
              ) : (
                ""
              )}
              <input
                placeholder={selectedPeopleIndividual ? "" : "GROUP SIZE"}
                type="number"
                min="1"
                max="10"
                value={
                  selectedPeopleIndividual > 0 ? selectedPeopleIndividual : ""
                }
                className="selectedPeopleInput"
                onChange={handleInputNumberChange}
                disabled={!selectedTimeSlotIndividual}
                style={{
                  cursor: selectedTimeSlotIndividual
                    ? "pointer"
                    : "not-allowed",
                  padding: selectedPeopleIndividual
                    ? "1.45rem 1rem 0.55rem 1rem"
                    : "1rem",
                }}
              />
              <div
                className="slider-wrapper"
                style={{ border: "none", padding: "0rem" }}
              >
                <div
                  className="slider-markers"
                  style={{ border: "none", padding: "0rem" }}
                >
                  <div className="slider-marker" style={{ left: "1%" }}>
                    1
                  </div>
                  <div className="slider-marker" style={{ left: "44.3%" }}>
                    5
                  </div>
                  <div className="slider-marker" style={{ left: "97%" }}>
                    10
                  </div>
                </div>
                <input
                  type="range"
                  min="1"
                  max="10"
                  step="1"
                  value={selectedPeopleIndividual}
                  onChange={handleSliderChange}
                  disabled={!selectedTimeSlotIndividual}
                  className="slider-input"
                  style={{
                    cursor: selectedTimeSlotIndividual
                      ? "pointer"
                      : "not-allowed",
                  }}
                />
              </div>
              <p style={{ color: "red" }}>
                {validationErrors.selectedPeopleIndividual}
              </p>
            </div>
            {/* *** reserve button *** */}
            <button
              className="ReserveButton"
              onClick={handleReserve}
              disabled={!selectedPeopleIndividual}
              style={{
                cursor: !selectedPeopleIndividual ? "not-allowed" : "pointer",
                backgroundColor:themeColor,
              }}
            >
              Reserve
            </button>
          </div>
        </div>
      </div>
      <Rules />

      <div 
      onClick={handleReserveMobile}
      className="book-bttn">
        <button style={{backgroundColor:themeColor }}>
        Book a reservation
        </button>
      </div>
      <Footer />
    </>
  );
};
export default Individualdate;
